import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Center,
  VStack,
  Box,
  Image,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input,
  FormErrorMessage,
  Button,
  InputRightElement,
  Heading,
} from '@chakra-ui/react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import lock from '../assets/img/resetpasswordlock.svg';
import { PasswordIcon } from '../assets/icons/PasswordIcon';
import { EyeOffIcon } from '../assets/icons/EyeOffIcon';
import { EyeOnIcon } from '../assets/icons/EyeOnIcon';
import { getUrlSearchParams } from '../util';

import purpledottedtriangleSrc from '../assets/img/purpledottedtriangle.svg';
import elipseSrc from '../assets/img/elipse.svg';

import axios from '../api/axios';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const query = getUrlSearchParams();
  const resetToken = query.get('resetToken');

  // password visibility
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  // yup schema and hook form setup
  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Your new password must be at least 8 characters long')
      .max(20, 'Your password is too long'),
  });

  const formOptions = {
    resolver: yupResolver(schema),
    mode: 'onChange',
  };

  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  // submit handle
  const submitForm = async (values) => {
    console.log(values);

    await axios.patch(
      `forgot_passwords/${uuid}`,
      {
        password: values.password,
        resetToken,
      },
      {
        headers: { 'Content-Type': 'application/merge-patch+json' },
      }
    );

    // post UI events
    reset();
    navigate('/login');
  };

  return (
    <Center minH="94vh" pt={10} pb={{ base: '40px', lg: '0' }}>
      <Box maxW="full" pos="relative" pb={{ lg: '120px' }}>
        <Box
          textAlign="center"
          position="relative"
          zIndex="1"
          bgColor="white"
          pt={{ base: '48px', '2xl': '70px' }}
          pb={{ base: '64px', '2xl': '120px' }}
          px="16px"
          borderRadius="20px"
          w="740px"
          maxW="full"
        >
          <Image
            src={purpledottedtriangleSrc}
            display={{ base: 'none', lg: 'block' }}
            pos="absolute"
            top={{ base: '-70px', '2xl': '-70px' }}
            right="-70px"
          />
          <form onSubmit={handleSubmit(submitForm)}>
            <VStack spacing="30px">
              <Image src={lock} />
              <Heading size="lg" color="primary.default">
                Choose a new password
              </Heading>
              <Box maxW="500px" w="full" py={{ '2xl': '30px' }}>
                <FormControl isInvalid={errors.password}>
                  <FormLabel htmlFor="password" textStyle="formLabels">
                    New password
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color={
                        errors.password ? 'error.default' : 'primary.light'
                      }
                      fontSize="22px"
                      children={<PasswordIcon />}
                    />
                    <Input
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="* * * * * * *"
                      {...register('password', { required: true })}
                    />
                    <InputRightElement
                      children={
                        <Button
                          h="40px"
                          size="lg"
                          mr={3}
                          variant="eyeButton"
                          alignItems="center"
                          onClick={handlePasswordVisibility}
                          fontSize="22px"
                          children={
                            showPassword ? <EyeOnIcon /> : <EyeOffIcon />
                          }
                        />
                      }
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.password?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Button
                type="submit"
                isFullWidth
                maxW="500px"
                isLoading={isSubmitting}
                // disabled={!isDirty || !isValid || isSubmitting}
              >
                Reset password
              </Button>
            </VStack>
          </form>
        </Box>
        <Image
          src={elipseSrc}
          display={{ base: 'none', lg: 'block' }}
          pos="absolute"
          bottom="20px"
          right="-100px"
          w="400px"
        />
      </Box>
    </Center>
  );
};

export default ResetPasswordPage;
