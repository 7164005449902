export const dummyTags = [
  { value: '1', label: 'Music' },
  { value: '2', label: 'Movies' },
  { value: '3', label: 'sport' },
  { value: '4', label: 'casino' },
  { value: '5', label: 'las vegas' },
  { value: '6', label: 'criminal mastermind' },
  { value: '7', label: 'card dealer' },
  { value: '8', label: 'development' },
  { value: '9', label: 'develop' },
  { value: '10', label: 'software' },
];

export const TAGS_LIMIT = 10;

export const stylesReactSelect = {
  // container: (base) => ({
  //   ...base,
  //   padding: '20px',
  // }),
  control: (base) => ({
    ...base,
    borderRadius: '25px',
    padding: '4px 0 4px 6px',
    outline: 'none',
    boxShadow: 'none',
    borderColor: '#CCC',
    transition: '0.3s',
    ':hover': {
      borderColor: 'black',
    },
    ':focus-within': {
      borderColor: 'black',
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: '#CCCCCC',
    paddingLeft: '8px',
    margin: '6px 0',
  }),
  input: (base) => ({
    ...base,
    paddingLeft: '6px',
    input: {
      textTransform: 'lowercase !important',
      // backgroundColor: 'red !important',
      minWidth: '30px !important',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '2px 0 2px 8px',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'rgba(221,221,221,0.5)',
      borderRadius: '20px',
      margin: '5px',
      padding: '0 6px 0 8px',
      transition: '0.3s',
      textTransform: 'lowercase',
      // ':hover': {
      //   backgroundColor: 'rgba(221,221,221,0.8)',
      // },
    };
  },
  multiValueLabel: (base) => ({
    ...base,
    color: '#2D2D2D',
    borderRadius: '20px 0 0 20px',
    fontSize: '16px',
    padding: '1px 3px',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    // color: data.color,
    ':hover': {
      color: '#CA3B1C',
      cursor: 'pointer',
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 10,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#E3E0FB' : isSelected ? '#F7F6FC' : undefined,
      color: '#240630',
      textTransform: 'lowercase',
      // backgroundColor: 'transparent',
      ':hover': {
        backgroundColor: '#E3E0FB',
      },
    };
  },
};

export const stylesReactSelectSingle = {
  placeholder: (base) => ({
    ...base,
    color: '#CCCCCC',
    paddingLeft: '30px',
  }),
  input: (base) => ({
    ...base,
    padding: '4px 0 4px 30px',
    marginRight: '7px',
    backgroundColor: 'rgba(255,255,255,0)',
    transition: 'background-color 0.3s',
    // ':hover': {
    //   backgroundColor: 'rgba(255,255,255,0.8)',
    // },
    ':focus-within': {
      backgroundColor: 'rgba(255,255,255,1)',
    },
  }),
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      // color: data ? 'blue' : 'orange',
      padding: '6px 0 6px 30px',
    };
  },
};

export const stylesReactSelectIndicators = {
  clearIndicator: (base) => ({
    ...base,
    alignSelf: 'flex-start',
    padding: '12px',
    marginTop: '2px',
    cursor: 'pointer',
    ':hover': {
      color: '#CA3B1C',
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    // display: 'none',
    backgroundColor: 'rgba(204,204,204,0.6)',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    // display: 'none',
    alignSelf: 'flex-start',
    marginTop: '2px',
  }),
};

export const stylesReactSelectIndicatorsForEmails = {
  clearIndicator: (base) => ({
    ...base,
    alignSelf: 'flex-start',
    padding: '12px',
    marginTop: '2px',
    cursor: 'pointer',
    ':hover': {
      color: '#CA3B1C',
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: 'none',
  }),
};
