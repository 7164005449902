import { Icon } from '@chakra-ui/react';

export const IconFacebook = (props) => (
  <Icon viewBox="0 0 15 30" {...props}>
    <path
      fill="currentColor"
      d="M9.57143 0.1C6.8 0.285713 5 1.27143 4.15714 3.04286C3.55714 4.31429 3.42857 5.15714 3.42857 7.71428V9.98571L1.75714 10.0286L0.0714286 10.0714L0.0285714 12.5286L0 15H1.71429H3.42857V22.5V30H6.71429H10V22.5V15H12.2143C14.3857 15 14.4286 15 14.4857 14.6714C14.5571 14.3571 15 10.4143 15 10.1429C15 10.0571 14.0429 10 12.4857 10H9.97143L10.0286 8.02857C10.0857 5.64286 10.2 5.38571 11.2857 5.15714C11.6857 5.07143 12.6857 5 13.5 5H15V2.5V0L12.6143 0.0285721C11.2857 0.0428562 9.92857 0.0714283 9.57143 0.1Z"
    />
  </Icon>
);
