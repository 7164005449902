import { Icon } from '@chakra-ui/react';

export const CategoryHeartIcon = (props) => (
  <Icon viewBox="0 0 56 51" fill="none" {...props}>
    <path
      d="M0.000244141 15.2295C0.170834 14.329 0.298776 13.4145 0.540445 12.5281C2.4738 5.19763 9.38269 0.0621092 17.0308 0.301298C20.9828 0.427927 24.4088 1.97562 27.4368 4.42379C27.5789 4.53635 27.7211 4.64891 27.8633 4.76147C27.8917 4.78961 27.9201 4.80368 28.0054 4.84589C28.4035 4.53635 28.8015 4.21274 29.228 3.9032C32.455 1.49724 36.0516 0.174669 40.1457 0.301298C47.737 0.526417 54.6601 6.6187 55.8116 14.0476C56.4229 18.0294 55.5273 21.6735 53.4375 25.0644C53.338 25.2332 53.0537 25.388 52.8547 25.388C48.5331 25.4021 44.2115 25.4021 39.9041 25.4161C39.5487 25.4161 39.4065 25.2614 39.2501 24.98C37.4874 21.7017 35.6962 18.4374 33.9192 15.1732C33.4216 14.2727 32.5403 13.8647 31.7584 14.3009C31.3604 14.5119 30.9907 14.934 30.7917 15.3421C28.7162 19.6052 26.6833 23.8966 24.6363 28.1738C24.551 28.3708 24.4515 28.5537 24.3093 28.8351C24.1671 28.6381 24.0534 28.4834 23.9539 28.3286C22.6176 26.2463 21.2813 24.1639 19.9308 22.0816C19.0494 20.7168 17.6847 20.7308 16.8318 22.1238C16.2347 23.1087 15.6377 24.1076 15.0264 25.0785C14.9269 25.2332 14.671 25.388 14.4862 25.388C10.719 25.4021 6.93757 25.4021 3.17037 25.388C2.95714 25.388 2.67282 25.191 2.55909 25.0081C1.23702 22.8976 0.398287 20.6183 0.142402 18.1279C0.128187 18.0294 0.0855393 17.9309 0.0571077 17.8324C0.000244357 16.9601 0.000244141 16.0878 0.000244141 15.2295Z"
      fill="#DB4049"
    />
    <path
      d="M5.34729 28.6369C5.67425 28.6369 5.85906 28.6369 6.05808 28.6369C9.18556 28.6369 12.3273 28.6229 15.4547 28.651C16.2935 28.651 16.8763 28.3415 17.2886 27.6239C17.6298 27.0189 18.0136 26.4279 18.3974 25.7807C19.9469 28.1867 21.4538 30.5223 22.9465 32.872C23.2308 33.3222 23.572 33.6599 24.098 33.7865C24.894 33.9694 25.6048 33.5895 26.0171 32.7313C27.1828 30.3112 28.3343 27.8912 29.5 25.4571C30.4524 23.4592 31.4191 21.4613 32.4142 19.3789C32.5422 19.604 32.6417 19.7588 32.7412 19.9276C34.1201 22.4462 35.4991 24.9647 36.8496 27.4973C37.276 28.3133 37.8873 28.651 38.8256 28.651C42.5075 28.6229 46.1894 28.6369 49.8712 28.6369C50.0703 28.6369 50.2693 28.6369 50.5678 28.6369C50.3972 28.8339 50.2835 28.9746 50.1698 29.0872C43.2182 35.9251 36.2809 42.7772 29.3294 49.6152C28.4196 50.5157 27.524 50.5157 26.6284 49.6293C19.6626 42.7772 12.7111 35.9251 5.74533 29.0731C5.64582 28.9605 5.54631 28.862 5.34729 28.6369Z"
      fill="#DB4049"
    />
  </Icon>
);
