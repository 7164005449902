import { Box, VStack, IconButton, Text, Link, useDisclosure } from '@chakra-ui/react';
import { IconPen } from '../../theme/icons/IconPen';
import AvatarUpload from './AvatarUpload';
import EditPersonalInfoModal from '../../components/modals/EditPersonalInfoModal';
import { getFullName } from '../../util';

const PersonalInfo = ({ user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <VStack spacing={5} mt="50px" mb={5}>
        <AvatarUpload />

        <Box pos="relative" px={7}>
          <Text
            fontSize="20px"
            lineHeight="130%"
            textStyle="bodySemiBold"
            textAlign="center"
          >
            {getFullName(user)}
          </Text>
          <IconButton
            onClick={onOpen}
            icon={<IconPen boxSize="20px" />}
            variant="iconicDark"
            size="md"
            pos="absolute"
            top="-7px"
            right="-10px"
            _hover={{ bg: 'transparent', color: 'primary.default' }}
          />
        </Box>
        {user?.biography ? (
          <Text
            color="primary.dark"
            fontSize="14px"
            lineHeight="24px"
            px={{ base: '30px', md: '14px' }}
            pb={4}
          >
            {user.biography}
          </Text>
        ) : (
          <Link
            onClick={onOpen}
            variant="secondaryDark"
            fontSize="14px"
            textStyle="bodyMedium"
          >
            +Add your biography
          </Link>
        )}
      </VStack>

      { user &&  <EditPersonalInfoModal isOpen={isOpen} onClose={onClose} user={user} />}
    </>
  );
};

export default PersonalInfo;
