import { Icon } from '@chakra-ui/react';

export const PasswordCheckboxEmptyIcon = (props) => (
  <Icon width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.491818 7.7264C0.817768 6.3184 1.48252 5.00161 2.43816 3.89275C3.70963 2.41742 5.42792 1.38923 7.33676 0.961517C9.2456 0.533805 11.2427 0.729489 13.0302 1.51939C13.0911 1.54625 13.147 1.57952 13.1975 1.61803C13.6779 1.84198 14.1399 2.10842 14.5777 2.41558C16.1754 3.53643 17.3648 5.13942 17.9687 6.98546C18.1489 7.53599 18.274 8.09932 18.3443 8.66761C18.3877 8.76712 18.4117 8.87687 18.4117 8.99219V9.74492C18.4117 9.7492 18.4117 9.75349 18.4117 9.75777C18.4123 10.7384 18.2513 11.7188 17.9289 12.6593C17.2992 14.4968 16.0873 16.0832 14.4742 17.1819C12.861 18.2806 10.9329 18.8327 8.97749 18.7559C7.02207 18.6791 5.14408 17.9774 3.62359 16.7556C3.57609 16.7174 3.53398 16.6751 3.49736 16.6296C2.8144 16.0602 2.2155 15.3905 1.72367 14.6387C0.658538 13.0106 0.152629 11.0846 0.28139 9.14807C0.296621 8.919 0.320633 8.69125 0.353243 8.46525C0.341051 8.36461 0.347641 8.26002 0.375713 8.15612L0.491818 7.7264ZM2.09371 8.11988C2.00909 8.49188 1.95336 8.87157 1.92781 9.25573C1.82246 10.8402 2.23639 12.416 3.10786 13.7481C3.97933 15.0802 5.26164 16.0973 6.76356 16.6475C8.26548 17.1978 9.90654 17.2519 11.442 16.8017C12.9774 16.3515 14.325 15.4211 15.2836 14.1492C16.2399 12.8807 16.7581 11.3404 16.7617 9.75682C16.7611 8.99209 16.6403 8.22725 16.3993 7.49046C15.9052 5.98006 14.932 4.66852 13.6248 3.75147C12.3177 2.83441 10.7467 2.36096 9.14606 2.40173C7.54545 2.4425 6.001 2.99531 4.74307 3.9777C3.48513 4.9601 2.5811 6.31945 2.16581 7.85302L2.09371 8.11988Z"
      fill="#F4F5F7"
    />
  </Icon>
);
