import { AspectRatio, Box } from '@chakra-ui/react';
import Iframe from 'react-iframe';

const VideoPlayer = ({ url, vimeoId }) => {
  // const vimeoId =
  //   // '701151216';
  //   // '700355550';
  //   // '554303728';
  //   // '565633731';
  //   '647781196';
  if (!vimeoId && !url) {
    return null;
  }

  return (
    <AspectRatio ratio={16 / 9}>
      <Box
        bg="primary.default"
        // h={{ base: '300px', md: '560px' }}
        pos="relative"
        w="full"
      >
        <Iframe
          // url={`https://player.vimeo.com/video/${vimeoId}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=137004`}
          url={url ? url : `https://player.vimeo.com/video/${vimeoId}`}
          allowFullScreen
          position="absolute"
          width="100%"
          height="100%"
        />
      </Box>
    </AspectRatio>
  );
};

export default VideoPlayer;
