import { SimpleGrid, GridItem, Box, Container, AspectRatio } from '@chakra-ui/react';

import { IconArrowRight } from '../../theme/icons/IconArrowRight';
import { IconChevronLeft } from '../../theme/icons/IconChevronLeft';
import { IconChevronLeftDouble } from '../../theme/icons/IconChevronLeftDouble';
import { IconChevronLeftPe } from '../../theme/icons/IconChevronLeftPe';
import { IconOpenInFull } from '../../theme/icons/IconOpenInFull';
import { IconDelete } from '../../theme/icons/IconDelete';
import { IconDeleteThin } from '../../theme/icons/IconDeleteThin';
import { IconTrash } from '../../theme/icons/IconTrash';
import { IconCamera } from '../../theme/icons/IconCamera';
import { IconPlay } from '../../theme/icons/IconPlay';
import { IconVideoAdd } from '../../theme/icons/IconVideoAdd';
import { IconPen } from '../../theme/icons/IconPen';
import { IconPenPlain } from '../../theme/icons/IconPenPlain';
import { IconAvatar } from '../../theme/icons/IconAvatar';
import { IconUsers } from '../../theme/icons/IconUsers';
import { IconGlobe } from '../../theme/icons/IconGlobe';
import { IconLock } from '../../theme/icons/IconLock';
import { IconCog } from '../../theme/icons/IconCog';
import { IconLogOut } from '../../theme/icons/IconLogOut';
import { IconCheckOK } from '../../theme/icons/IconCheckOK';
import { IconTriangle } from '../../theme/icons/IconTriangle';
import { IconSearch } from '../../theme/icons/IconSearch';
import { IconCopy } from '../../theme/icons/IconCopy';
import { IconCopied } from '../../theme/icons/IconCopied';
import { IconFlag } from '../../theme/icons/IconFlag';
import { IconLink } from '../../theme/icons/IconLink';
import { IconShare } from '../../theme/icons/IconShare';
import { IconFacebook } from '../../theme/icons/IconFacebook';
import { IconLinkedIn } from '../../theme/icons/IconLinkedIn';
import { IconTwitter } from '../../theme/icons/IconTwitter';
import { IconReply } from '../../theme/icons/IconReply';
import { IconDot } from '../../theme/icons/IconDot';
import { IconCategories } from '../../theme/icons/IconCategories';
import { IconRequestAccess } from '../../theme/icons/IconRequestAccess';
import { IconBlog } from '../../theme/icons/IconBlog';
import { IconMinus } from '../../theme/icons/IconMinus';
import { IconPlus } from '../../theme/icons/IconPlus';
import { IconLogo } from '../../theme/icons/IconLogo';
import { IconMenu } from '../../theme/icons/IconMenu';
import { IconEnvelope } from '../../theme/icons/IconEnvelope';
import { IconQuestion } from '../../theme/icons/IconQuestion';
import { IconJustPlus } from '../../theme/icons/IconJustPlus';
import { IconJustMinus } from '../../theme/icons/IconJustMinus';

const iconSize = '32px';

const monocolorIcons = [
  <IconArrowRight fontSize={iconSize} />,
  <IconChevronLeft fontSize={iconSize} />,
  <IconChevronLeftDouble fontSize={iconSize} />,
  <IconChevronLeftPe fontSize={iconSize} />,
  <IconOpenInFull fontSize={iconSize} />,
  <IconDelete fontSize={iconSize} />,
  <IconDeleteThin fontSize={iconSize} />,
  <IconTrash fontSize={iconSize} />,
  <IconCamera fontSize={iconSize} />,
  <IconPlay fontSize={iconSize} />,
  <IconVideoAdd fontSize={iconSize} />,
  <IconPen fontSize={iconSize} />,
  <IconPenPlain fontSize={iconSize} />,
  <IconAvatar fontSize={iconSize} />,
  <IconUsers fontSize={iconSize} />,
  <IconGlobe fontSize={iconSize} />,
  <IconLock fontSize={iconSize} />,
  <IconCog fontSize={iconSize} />,
  <IconLogOut fontSize={iconSize} />,
  <IconCheckOK fontSize={iconSize} />,
  <IconTriangle fontSize={iconSize} />,
  <IconSearch fontSize={iconSize} />,
  <IconCopy fontSize={iconSize} />,
  <IconCopied fontSize={iconSize} />,
  <IconFlag fontSize={iconSize} />,
  <IconLink fontSize={iconSize} />,
  <IconShare fontSize={iconSize} />,
  <IconFacebook fontSize={iconSize} />,
  <IconLinkedIn fontSize={iconSize} />,
  <IconTwitter fontSize={iconSize} />,
  <IconReply fontSize={iconSize} />,
  <IconDot fontSize={iconSize} />,
  <IconCategories fontSize={iconSize} />,
  <IconRequestAccess fontSize={iconSize} />,
  <IconBlog fontSize={iconSize} />,
  <IconMinus fontSize={iconSize} />,
  <IconPlus fontSize={iconSize} />,
  <IconLogo fontSize={iconSize} />,
  <IconMenu fontSize={iconSize} />,
  <IconEnvelope fontSize={iconSize} />,
  <IconQuestion fontSize={iconSize} />,
  <IconJustPlus fontSize={iconSize} />,
  <IconJustMinus fontSize={iconSize} />,
];

function MonocolorIcons() {
  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 2, md: 4, xl: 8 }} columnGap={4} rowGap={4}>
          {monocolorIcons.map((icon, index) => {
            return (
              <GridItem key={index} border="solid 1px #DDD">
                <AspectRatio ratio={1}>
                  <Box>{icon}</Box>
                </AspectRatio>
              </GridItem>
            );
          })}
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default MonocolorIcons;
