import { useContext } from 'react';
import TagsCacheContext from '../context/TagsCacheContext';

export default function useTagsCache() {
  const context = useContext(TagsCacheContext);
  if (!context) {
    throw new Error('useTagCache must be used within a TagsCacheContextProvider');
  }
  return context;
}
