import { Container, Text, Box, ListItem, UnorderedList } from '@chakra-ui/react';

const SectionInfoParticipants = () => {
  return (
    <Container bg="white">
      <Container maxW="1562px" py={0} pb={{ base: '40px', lg: '80px' }}>
        <Text
          as="h2"
          textStyle="sectionHeadline"
          textAlign="center"
          pb={{ base: '24px', md: '48px', lg: '100px' }}
        >
          Information for participants
        </Text>

        <Box
          bg="secondary.lighter"
          mx="auto"
          px={{ base: '20px', lg: '140px' }}
          py={{ base: '20px', lg: '80px' }}
          borderRadius="20px"
          maxWidth="1180px"
        >
          <Text
            as="h3"
            mb={'24px'}
            fontWeight={500}
            fontSize={{ base: '24px', md: '30px' }}
          >
            Bronze medal
          </Text>
          <Box
            my={'24px'}
            w={{ base: '40px', md: '120px' }}
            h="3px"
            bg="accent.default"
          ></Box>
          <Text fontSize={'18px'} mt={{ base: '24px', lg: '40px' }}>
            Participants in the DofE BRONZE MEDAL will create THREE videos over three
            months. You could interview a member or friend of your family, or someone
            outside it such as a resident of a care home. During the project, it is
            recommended to dedicate an hour a week to each video: first identifying and
            confirming an interviewee, gathering equipment and preparing questions,
            recording the interview and performing any necessary edits, and then uploading
            it to Storyvault.
          </Text>
        </Box>
        <Box
          bg="secondary.lighter"
          mx="auto"
          mt={{ base: '24px', md: '48px', lg: '80px' }}
          px={{ base: '20px', lg: '140px' }}
          py={{ base: '20px', lg: '80px' }}
          borderRadius="20px"
          maxWidth="1180px"
        >
          <Text
            as="h3"
            mb={'24px'}
            fontWeight={500}
            fontSize={{ base: '24px', md: '30px' }}
          >
            Silver medal
          </Text>
          <Box
            my={'24px'}
            w={{ base: '40px', md: '120px' }}
            h="3px"
            bg="accent.default"
          ></Box>
          <Text fontSize={'18px'} mt={{ base: '24px', lg: '40px' }}>
            Participants in the DofE SILVER MEDAL will be expected to be able to do
            everything involved in the Bronze level, but across SIX new videos (one per
            month).
          </Text>
          <Text fontSize={'18px'} mt={{ base: '24px', lg: '40px' }}>
            Everything needed to produce each video should now be carried out within two
            weeks. Weeks three and four should be dedicated to embellishing your videos
            with extra content such as captions and photo references from the subject’s
            personal collections or other publicly available sources.
          </Text>
          <Text fontSize={'18px'} mt={{ base: '24px', lg: '40px' }}>
            These videos should also demonstrate increasing skill and understanding of
            digital media and communications:
          </Text>
          <UnorderedList mx="0" mt={{ base: '24px', lg: '40px' }}>
            <ListItem mt={{ base: '24px', lg: '40px' }}>
              Broadening the choice of possible interviewees and selecting suitable
              interview subjects with a particular story to tell. These could be older
              people living in care homes or members of particular interest groups or
              ethnic groups.
            </ListItem>
            <ListItem mt={{ base: '24px', lg: '40px' }}>
              Researching the background of the topic you will be discussing to help the
              questions you will be answering. For example, is the interviewee old enough
              to have experienced rationing during or after World War II? If so, it would
              be good to approach the interview with a basic knowledge of how rationing
              worked.
            </ListItem>
            <ListItem mt={{ base: '24px', lg: '40px' }}>
              Finding and using more sophisticated visuals to illustrate the subject of a
              video. You may even consider shooting some new material yourself – such as
              going to and recording where a story took place. In some cases this might be
              freely available archive stills or film. More advanced participants might
              consider the use of graphics or animation.
            </ListItem>
          </UnorderedList>
        </Box>
        <Box
          bg="secondary.lighter"
          mx="auto"
          mt={{ base: '24px', md: '48px', lg: '80px' }}
          px={{ base: '20px', lg: '140px' }}
          py={{ base: '20px', lg: '80px' }}
          borderRadius="20px"
          maxWidth="1180px"
        >
          <Text
            as="h3"
            mb={'24px'}
            fontWeight={500}
            fontSize={{ base: '24px', md: '30px' }}
          >
            Gold medal
          </Text>
          <Box
            my={'24px'}
            w={{ base: '40px', md: '120px' }}
            h="3px"
            bg="accent.default"
          ></Box>
          <Text fontSize={'18px'} mt={{ base: '24px', lg: '40px' }}>
            Participants in the DofE GOLD MEDAL will be expected to create TWELVE videos
            over twelve months. These videos should demonstrate further development to a
            level which could enhance your opportunities to work in the media.
          </Text>
          <Text fontSize={'18px'} mt={{ base: '24px', lg: '40px' }}>
            As well as all of the additional activities included in the Silver level, your
            videos should demonstrate:
          </Text>
          <UnorderedList mx="0" mt={{ base: '24px', lg: '40px' }}>
            <ListItem mt={{ base: '24px', lg: '40px' }}>
              Clear evidence of diversity of interviewees and subject matter - with an
              appropriate emphasis on stories and experiences from people of different
              religious, ethnic or cultural backgrounds.
            </ListItem>
            <ListItem mt={{ base: '24px', lg: '40px' }}>
              More sophisticated use of visual references such as stills, captions, newly
              shot or archive movie.
            </ListItem>
            <ListItem mt={{ base: '24px', lg: '40px' }}>
              A basic understanding of context and balance in documentary-making; how to
              identify and take on board alternative points of view (both objective and
              subjective sources) and produce content which is not purely one-sided –
              possibly by approaching the same subject from a range of angles.
            </ListItem>
          </UnorderedList>
        </Box>
      </Container>
    </Container>
  );
};

export default SectionInfoParticipants;
