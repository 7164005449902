import { HStack } from '@chakra-ui/react';
import { useMemo, useContext, useRef, useEffect } from 'react';
import Flickity from 'react-flickity-component';
import './flickity.css';
import FlickityHorizontalControls from './FlickityHorizontalControls';
import FlickityControlsContext from '../../context/FlickityControlsContext';
import VideoSliderCard from '../videos/VideoSliderCard';

const flickityOptions = {
  pageDots: false,
  prevNextButtons: false,
  cellAlign: 'left',
  // contain: true,
};

const FlickityHorizontalVideos = ({ videos }) => {
  const flickityControlsCtx = useContext(FlickityControlsContext);
  const numberOfItems = videos.length;
  // console.log('numberOfItems(Videos): ', numberOfItems);

  let flkty = useRef(null);

  useEffect(() => {
    flkty?.current.on('select', () => {
      flickityControlsCtx.updateSelectedIndex(flkty.current.selectedIndex);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flkty.current]);

  return useMemo(() => {
    const flktyPrev = () => {
      flkty.current.previous();
    };  

    const flktyNext = () => {
      flkty.current.next();
    };

    return (
      <>
        <Flickity
          // flickityRef={(c) => (flkty = c)}
          flickityRef={(c) =>
            // eslint-disable-next-line no-sequences
            (flkty.current = c)
          }
          className={'flickity-horizontal'} // default ''
          elementType={'div'} // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
          static // default false
        >
          {videos.map((video, index) => {
            // console.log(video);
            return <VideoSliderCard key={index} cell={video} />;
          })}
        </Flickity>
        <HStack spacing="10px" mt={5} justifyContent="flex-end">
          <FlickityHorizontalControls
            onClickPrev={flktyPrev}
            onClickNext={flktyNext}
            numberOfItems={numberOfItems}
          />
        </HStack>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfItems, videos]);
};

export default FlickityHorizontalVideos;
