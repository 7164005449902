import { Box } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import img1 from '../../assets/img/carouselimage1.jpg';
import img2 from '../../assets/img/carouselimage2.jpg';
import img3 from '../../assets/img/carouselimage3.jpg';

import CarouselImage from './CarouselImage';

// 2nd carousel component
const CarouselSlide = () => {
  return (
    <>
      <Box className="swiper-landing-pagination"></Box>
      <Swiper
        className="swiper-landing"
        spaceBetween={30}
        slidesPerView={1}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          el: '.swiper-landing-pagination',
          type: 'bullets',
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        // style={{ opacity: 0.1 }}
        // onSlideChange={() => console.log('slide change')}
      >
        <SwiperSlide>
          <CarouselImage
            image={img1}
            text="Landing at Normandy spoken by British lieutenant"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage
            image={img2}
            text="Remembering Woodstock, three days of peace and music"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselImage image={img3} text="Surviving Hurricane Katrina" />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default CarouselSlide;
