import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Box,
  VStack,
  Container,
  Text,
  Image,
} from '@chakra-ui/react';
import KeySrc from '../../assets/vectors/key.svg';

const RequestedAccessModal = ({ onClose, isOpen }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <VStack spacing="48px">
            <Image src={KeySrc} />
            <Heading size="lg" color="primary.default">
              Your request for access has been sent
            </Heading>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container p={0} maxW="600px">
            <VStack
              mt={4}
              mb={{ base: 4, md: 9 }}
              textAlign="center"
              fontSize={{ md: '20px' }}
              spacing={10}
            >
              <Text textStyle="formLabels" lineHeight="27px">
                You will receive an email when your request is approved.
              </Text>
            </VStack>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Box maxW="284px" w="full">
            <Button onClick={onClose} isFullWidth>
              Got it
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RequestedAccessModal;
