import {
  Box,
  Flex,
  Avatar,
  Text,
  HStack,
  Link,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { useContext, useState } from 'react';
import { IconAvatar } from '../../theme/icons/IconAvatar';
import { IconDot } from '../../theme/icons/IconDot';
import SingleCommentActions from './SingleCommentActions';
import ReplyToComment from './ReplyToComment';
import EditComment from './EditComment';
import DeleteCommentModal from '../modals/DeleteCommentModal';
import { getImageUrlFromUser, isEmptyObject } from '../../util';
import { newLineToBr } from '../../util';

const SingleComment = ({
  comment,
  isVideoOwner,
  isReply,
  avatarSrc,
  commentForReply,
  canReply,
}) => {
  const [showFormForReply, setShowFormForReply] = useState(false);
  const [showFormForEdit, setShowFormForEdit] = useState(false);
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleShowFormForReply = () => {
    setShowFormForReply((prevState) => !prevState);
  };

  const handleShowFormForEdit = () => {
    setShowFormForEdit((prevState) => !prevState);
  };

  const myAvatarSrc = getImageUrlFromUser(user);
  return (
    <Box w="full">
      {showFormForEdit ? (
        <EditComment
          comment={comment}
          handleShowFormForEdit={handleShowFormForEdit}
          isEditForReply={isReply}
          myAvatarSrc={myAvatarSrc}
          commentForReply={commentForReply}
        />
      ) : (
        <>
          <Flex ml={isReply && { base: '40px', md: '60px' }}>
            <Link
              // to={`/channel/${cell.owner.slug}`}
              to={comment.channelUrl}
              as={RouterLink}
            >
              <Avatar
                icon={
                  <IconAvatar
                    fontSize={{ base: '16px', md: '22px' }}
                    color="secondary.lighter"
                  />
                }
                src={avatarSrc}
                bg="secondary.light"
                boxSize={{ base: '30px', md: '40px' }}
              />
            </Link>
            <Box ml={{ base: '10px', md: '20px' }}>
              <HStack spacing={5}>
                <Link
                  to={comment.channelUrl}
                  as={RouterLink}
                  _hover={{ textDecoration: 'underline' }}
                >
                  <Text
                    textStyle="bodySemiBold"
                    fontSize={{ base: '14px', md: '16px' }}
                    lineHeight="125%"
                    wordBreak="break-word"
                  >
                    {comment.firstName} {comment.lastName}
                  </Text>
                </Link>
                <HStack
                  flexShrink={0}
                  alignSelf="flex-start"
                  spacing="10px"
                  pos="relative"
                  top="1px"
                >
                  <IconDot fontSize="7px" color="secondary.dark" />
                  <Text
                    fontSize="13px"
                    lineHeight="125%"
                    color="primary.dark"
                    pos="relative"
                    top="1px"
                  >
                    {comment.time}
                  </Text>
                </HStack>
              </HStack>
              <Text
                color="primary.dark"
                lineHeight="26px"
                mt="5px"
                fontSize={{ base: '14px', md: '16px' }}
              >
                {newLineToBr(comment.content)}
              </Text>
            </Box>
          </Flex>

          {!isEmptyObject(user) && (
            <SingleCommentActions
              isVideoOwner={isVideoOwner}
              isCommentOwner={comment.isCommentOwner}
              isReply={isReply}
              handleShowFormForReply={handleShowFormForReply}
              handleShowFormForEdit={handleShowFormForEdit}
              onOpen={onOpen}
              myAvatarSrc={myAvatarSrc}
              canReply={canReply}
            />
          )}

          {showFormForReply && (
            <ReplyToComment
              comment={comment}
              handleShowFormForReply={handleShowFormForReply}
              myAvatarSrc={myAvatarSrc}
              commentForReply={commentForReply}
            />
          )}
        </>
      )}

      {comment?.replies?.length > 0 && (
        <VStack spacing={{ base: '30px', md: '40px' }} mt={{ base: '30px', md: '40px' }}>
          {comment.replies.map((reply) => {
            return (
              <SingleComment
                key={reply.id}
                comment={reply}
                isVideoOwner={isVideoOwner}
                isReply
                avatarSrc={reply.avatarSrc}
                commentForReply={comment}
              />
            );
          })}
        </VStack>
      )}

      <DeleteCommentModal
        isOpen={isOpen}
        onClose={onClose}
        data={comment}
        commentForReply={commentForReply}
        isVideoOwner={isVideoOwner}
      />
    </Box>
  );
};

export default SingleComment;
