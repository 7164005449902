import {
  Container,
  Text,
  Box,
  Grid,
  GridItem,
  Flex,
  Show,
  Button,
  Circle,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import PhotoSrc from '../../assets/img/photos/photo-34.webp';
import PhotoCompressedSrc from '../../assets/img/photos/photo-34.png';
import PictureOptimizedSingle from '../UI/PictureOptimizedSingle';
import { IconLogo } from '../../theme/icons/IconLogo';

const SectionCollage6 = () => {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <Container bg="white" px={0} py={{ base: '60px', '2xl': '88px' }}>
      <Container maxW="1562px">
        <Grid
          templateColumns={{ md: 'repeat(2, 1fr)' }}
          columnGap={{ md: '32px', lg: '100px' }}
          rowGap={10}
          alignItems="center"
        >
          <GridItem>
            <Flex pos="relative" justifyContent={{ base: 'center', md: 'right' }}>
              <Show above="2xl">
                <Circle
                  pos="absolute"
                  top="-50px"
                  left="-40px"
                  bg="#E7E6EB"
                  size="324px"
                />
              </Show>
              <PictureOptimizedSingle
                src={PhotoSrc}
                fallbackSrc={PhotoCompressedSrc}
                borderRadius="10px"
                width="708px"
                position="relative"
                zIndex="1"
              />
            </Flex>
          </GridItem>
          <GridItem>
            <Box maxW="600px">
              <Box display="inline-block">
                <Text textStyle="sectionEyebrow">Set your video's privacy</Text>
                <Text as="h2" textStyle="sectionHeadline" mt={6}>
                  Share your stories with the wider world
                </Text>
                <Box w="120px" h="3px" bg="accent.default" mt="30px"></Box>
              </Box>
              <Text textStyle="sectionText" mt="40px">
                We'd like to share your stories with everyone, but you can specify and
                restrict who sees them. You can decide whether to keep your uploaded
                videos private for your own use only, or to share them with named people,
                or to allow them to be viewed by anyone visiting the Storyvault platform
                and the wider world.
              </Text>
              <Box w={{ base: '100%', sm: 'auto' }}>
                <Button
                  as={RouterLink}
                  to={!isAuthenticated() ? '/login' : '/add-video'}
                  w={{ base: '100%', md: 'auto' }}
                  mt="40px"
                >
                  <IconLogo boxSize="20px" mr="10px" />
                  Upload a video
                </Button>
              </Box>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Container>
  );
};

export default SectionCollage6;
