import AppRoutes from './AppRoutes';
import { AuthProvider } from './context/AuthContext';
import { HttpProvider } from './context/HttpContext';
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import { AnalyticsProvider } from 'use-analytics';

const GA4 = process.env.REACT_APP_GOOGLE_ANALYTICS;
/* Initialize analytics & load plugins */
const analytics = Analytics({
  app: 'storyvault-react-app',
  plugins: [
    googleAnalytics({
      measurementIds: [`${GA4}`],
    }),
  ],
});

function App() {
  return (
    <AnalyticsProvider instance={analytics}>
      <AuthProvider>
        <HttpProvider>
          <AppRoutes />
        </HttpProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
}

export default App;
