import { Container, Text, Box, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconLogo } from '../../theme/icons/IconLogo';

const SectionConclusion5 = () => {
  return (
    <Container
      // bg="linear-gradient(180deg, #E3E0FB 0%, rgba(227, 224, 251, 0) 88.02%)"
      // bg="white"
      bg="secondary.lighter"
      px={0}
      py={{ base: '60px', '2xl': '88px' }}
    >
      <Container maxW="1562px">
        <Container maxW="900px" p={0} textAlign="center" pos="relative">
          <Box display="inline-block">
            <Text as="h2" textStyle="sectionHeadlineSm">
              Let your assessor know you’ll be choosing Storyvault for the Skills section
              of your DofE programme and create an account at the link below
            </Text>
            <Button
              as={RouterLink}
              to="/signup"
              w={{ base: '100%', md: 'auto' }}
              mt={{ base: '40px', md: '60px' }}
            >
              <IconLogo boxSize="20px" mr="10px" />
              Join Storyvault
            </Button>
          </Box>
        </Container>
      </Container>
    </Container>
  );
};

export default SectionConclusion5;
