import { Container, Text, Box, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconCategories } from '../../theme/icons/IconCategories';

const SectionConclusion4 = () => {
  return (
    <Container
      // bg="linear-gradient(180deg, #E3E0FB 0%, rgba(227, 224, 251, 0) 88.02%)"
      bg="white"
      px={0}
      py={{ base: '60px', '2xl': '88px' }}
    >
      <Container maxW="1562px">
        <Container p={0} textAlign="center" pos="relative">
          <Box display="inline-block">
            <Text as="h2" textStyle="sectionHeadline">
              Choose your favourite categories and improve your feed
            </Text>
            <Text fontSize="22px" lineHeight="150%" mt="30px">
              Go to Settings and add categories so we can recommend you video stories
              based on your interests
            </Text>
            <Button
              as={RouterLink}
              to="/settings/interests"
              w={{ base: '100%', md: 'auto' }}
              mt={{ base: '40px', md: '60px' }}
            >
              <IconCategories boxSize="20px" mr="10px" />
              Add categories
            </Button>
          </Box>
        </Container>
      </Container>
    </Container>
  );
};

export default SectionConclusion4;
