import { Icon } from '@chakra-ui/react';

export const IconTrash = (props) => (
  <Icon viewBox="0 0 38 41" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.808533 9.43131C0.808533 8.37375 1.66586 7.51642 2.72343 7.51642H35.2766C36.3342 7.51642 37.1915 8.37375 37.1915 9.43131C37.1915 10.4889 36.3342 11.3462 35.2766 11.3462H2.72343C1.66586 11.3462 0.808533 10.4889 0.808533 9.43131Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3863 4.11549C14.9349 4.11549 14.502 4.29482 14.1827 4.61403C13.8635 4.93325 13.6842 5.36619 13.6842 5.81762V7.51975H24.3225V5.81762C24.3225 5.36619 24.1432 4.93325 23.824 4.61403C23.5047 4.29482 23.0718 4.11549 22.6204 4.11549H15.3863ZM28.1523 7.51975V5.81762C28.1523 4.35046 27.5695 2.9434 26.532 1.90597C25.4946 0.868531 24.0875 0.285706 22.6204 0.285706H15.3863C13.9192 0.285706 12.5121 0.868531 11.4747 1.90597C10.4372 2.9434 9.85441 4.35046 9.85441 5.81762V7.51975H6.34377C5.2862 7.51975 4.42888 8.37707 4.42888 9.43464V34.7538C4.42888 36.2209 5.0117 37.628 6.04914 38.6654C7.08657 39.7029 8.49364 40.2857 9.96079 40.2857H28.0459C29.5131 40.2857 30.9201 39.7029 31.9576 38.6654C32.995 37.628 33.5778 36.2209 33.5778 34.7538V9.43464C33.5778 8.37707 32.7205 7.51975 31.6629 7.51975H28.1523ZM8.25866 11.3495V34.7538C8.25866 35.2052 8.438 35.6382 8.75721 35.9574C9.07642 36.2766 9.50936 36.4559 9.96079 36.4559H28.0459C28.4973 36.4559 28.9303 36.2766 29.2495 35.9574C29.5687 35.6382 29.748 35.2052 29.748 34.7538V11.3495H8.25866Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3797 16.5623C16.4372 16.5623 17.2946 17.4196 17.2946 18.4772V29.3283C17.2946 30.3858 16.4372 31.2431 15.3797 31.2431C14.3221 31.2431 13.4648 30.3858 13.4648 29.3283V18.4772C13.4648 17.4196 14.3221 16.5623 15.3797 16.5623Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6204 16.5623C23.6779 16.5623 24.5353 17.4196 24.5353 18.4772V29.3283C24.5353 30.3858 23.6779 31.2431 22.6204 31.2431C21.5628 31.2431 20.7055 30.3858 20.7055 29.3283V18.4772C20.7055 17.4196 21.5628 16.5623 22.6204 16.5623Z"
    />
  </Icon>
);
