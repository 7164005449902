import { Flex, Container, Heading, Button, Stack, Box } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import SearchByTerm from './SearchByTerm';
// import SearchByTag from './SearchByTag';
import SearchByTagWithSelect from './SearchByTagWithSelect';
import ClearSearchControls from './ClearSearchControls';
import SearchVideosList from '../videos/SearchVideosList';
import { useNavigate } from 'react-router-dom';
import MyChannelContext from '../../context/MyChannelContext';
import { TagsCacheContextProvider } from '../../context/TagsCacheContext';
import { IconJustPlus } from '../../theme/icons/IconJustPlus';
import { IconJustMinus } from '../../theme/icons/IconJustMinus';

const SearchContent = () => {
  const navigate = useNavigate();
  const {
    restartPageIndex,
    term,
    tag,
    tagId,
    tagName,
    category,
    clearCategory,
    clearTerm,
    clearTag,
    updateTag,
    clearAll,
    updateQueryString,
    queryString,
  } = useContext(MyChannelContext);

  // console.log({ tag, tagId, tagName })
  // console.log({ tagId })
  // console.log({ tagName })

  // const [term, setTerm] = useState(() => query.get('keyword') ?? '');
  // const [tag, setTag] = useState(() => query.get('tags') ?? '');
  // const [categoryId, setCategoryId] = useState(() => query.get('category') ?? '');
  // const [tags, setTags] = useState([]);
  // const [category, setCategory] = useState(initialCategoryState);
  // const [queryString, setQueryString] = useState('');

  // const clearTerm = () => {
  //   restartPageIndex();
  //   setTerm('');
  // };
  //
  // const clearTag = () => {
  //   restartPageIndex();
  //   setTag('');
  // };
  //
  // const clearCategory = () => {
  //   restartPageIndex();
  //   setCategory(initialCategoryState);
  // };

  // const clearAll = () => {
  //   restartPageIndex();
  //   clearTerm();
  //   clearTag();
  //   clearCategory();
  //   setAdvSearch(false);
  //   navigate(`/search`, { replace: true });
  // };

  const handleClearTag = () => {
    restartPageIndex();
    clearTag();
    // setAdvSearch(false)
    // navigate(`/search`, { replace: true });
  };

  const getSearchTermQuery = (term) => {
    const trimmed = term?.trim();
    if (trimmed !== '') {
      return `&keyword=${term}`;
    }
    return '';
  };

  const getSearchTagQuery = (tag) => {
    if (tag?.id > 0) {
      return `&tags=${tag.id}`;
    }
    return '';
  };

  const getSearchCategoryQuery = (category) => {
    if (category?.id > 0) {
      return `&category=${category.id}`;
    }
    return '';
  };

  useEffect(() => {
    const keywordQuery = getSearchTermQuery(term);
    const tagQuery = getSearchTagQuery(tag);
    const categoryQuery = getSearchCategoryQuery(category);
    const urlForSearch = `${keywordQuery}${tagQuery}${categoryQuery}`;

    updateQueryString(urlForSearch);

    // console.log('urlForSearch:', urlForSearch);
  }, [term, tag, category]);

  // function updateTerm(term) {
  //   restartPageIndex();
  //   setTerm(term);
  // }
  //
  // function updateTag(tag) {
  //   restartPageIndex();
  //   setTag(tag);
  // }
  //
  // function updateCategory(id, name) {
  //   restartPageIndex();
  //   setCategory({ id: id, name: name });
  // }

  // handle advanced search toggle
  const [advSearch, setAdvSearch] = useState(!!(tag || tagName || tagId));
  const handleSetAdvSearch = () => setAdvSearch(!advSearch);

  return (
    <Container pt="50px" mt={{ base: '80px', md: '100px' }} maxW="1562px">
      <Flex justifyContent="space-between" alignItems="flex-end" mb="50px">
        <Heading as="h1" size="lg">
          Search
        </Heading>
      </Flex>

      <Stack
        align={{ base: null, md: 'end' }}
        direction={{ base: 'column', md: 'row' }}
        spacing="30px"
        mb="35px"
        mt="-70px"
      >
        <SearchByTerm />

        <Button variant="solidLightGrey" py="25px" onClick={handleSetAdvSearch}>
          Advanced search
          {!advSearch ? (
            <IconJustPlus boxSize="14px" ml="10px" />
          ) : (
            <IconJustMinus boxSize="14px" ml="10px" />
          )}
        </Button>

        {/* <SearchByTag updateTag={updateTag} tag={tag} /> */}
      </Stack>

      {advSearch && (
        <Box mb="35px">
          <TagsCacheContextProvider>
            <SearchByTagWithSelect
              updateTag={updateTag}
              tag={tag}
              tagId={tagId}
              tagName={tagName}
            />
          </TagsCacheContextProvider>
        </Box>
      )}

      <ClearSearchControls
        term={term}
        clearTerm={clearTerm}
        tag={tag}
        clearTag={handleClearTag}
        category={category}
        clearCategory={clearCategory}
        clearAll={clearAll}
      />

      <SearchVideosList queryString={queryString} />
    </Container>
  );
};

export default SearchContent;
