import {
  Input,
  // Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  VStack,
  Button,
  Box,
  Heading,
  Text,
  Select,
  Flex,
  Spacer,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { IconChevronLeft } from '../../theme/icons/IconChevronLeft';
import { IconDeleteThin } from '../../theme/icons/IconDeleteThin';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import DropzoneVideo from '../common/DropzoneVideo';
import { TextareaAutosize } from '../common/TextareaAutosize';
import {
  PRIVACY_ONLY_ME,
  PRIVACY_ONLY_ME_CODE,
  PRIVACY_PUBLIC_CODE,
  PRIVACY_SHARED,
  PRIVACY_SHARED_CODE,
  TEXTAREA_LENGTH_MD,
} from '../../util/consts';
import VideoUploadPreviews from '../previews/VideoUploadPreviews';
import VideoUploadIsCompleted from '../previews/VideoUploadIsCompleted';

import DropzonePhotos from '../common/DropzonePhotos';
import { useContext, useState } from 'react';
import { VideoFormContext } from '../../context/VideoFormContext';
import { CategoriesContext } from '../../context/CategoriesContext';
import { HttpContext } from '../../context/HttpContext';
import CustomToast from '../UI/CustomToast';
import { useNavigate } from 'react-router-dom';
// import LocalVideoPlayer from '../previews/LocalVideoPlayer';

import RadioGroupPrivacy from './privacy/RadioGroupPrivacy';

import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import {
  // dummyTags,
  TAGS_LIMIT,
  stylesReactSelect,
  stylesReactSelectIndicators,
} from './reactSelectHelpers';
import { TagsContext } from '../../context/TagsContext';
import { normalizeShareEmails } from '../../util';
import { AuthContext } from '../../context/AuthContext';

export async function getPrivacyDetails(privacy, emails) {
  if (privacy === PRIVACY_SHARED) {
    const sharedMails = await normalizeShareEmails(emails);
    return { privacy: PRIVACY_SHARED_CODE, sharedMails };
  } else if (privacy === PRIVACY_ONLY_ME) {
    return { privacy: PRIVACY_ONLY_ME_CODE, sharedMails: [] };
  }

  return { privacy: PRIVACY_PUBLIC_CODE, sharedMails: [] };
}

export const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <IconDeleteThin fontSize="12px" />
    </components.MultiValueRemove>
  );
};

export const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <IconDeleteThin fontSize="12px" />
    </components.ClearIndicator>
  );
};

const FormCreateVideo = ({ video }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const { getAuthUser } = useContext(AuthContext);
  const { slug } = getAuthUser();
  const videoFormCtx = useContext(VideoFormContext);
  const { state: categoriesState } = useContext(CategoriesContext);
  const { state: tagsState, updateQuery } = useContext(TagsContext);
  const { items: tags } = tagsState;
  const { authAxios } = useContext(HttpContext);
  const { items: categories, status: statusCategories } = categoriesState;

  const [tagsLimitReached, setTagsLimitReached] = useState(false);

  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const placeholderTextSubject = isLargerThanMD
    ? 'Enter protagonist/main subject name'
    : 'Enter name';

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .max(250, 'Please enter 250 characters or less')
      .min(2, 'Please enter at least 2 characters or more')
      .required('Please enter story title'),
    category: Yup.string().required('Please select category'),
    subject: Yup.string().max(255, 'Please enter 255 characters or less'),
    about: Yup.string().max(
      TEXTAREA_LENGTH_MD,
      `About this story must be at most ${TEXTAREA_LENGTH_MD} characters long`
    ),
    // moreDetails: Yup.string().max(
    //   TEXTAREA_LENGTH_MD,
    //   `More details must be at most ${TEXTAREA_LENGTH_MD} characters long`
    // ),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: { privacy: 'public' },
  };

  const { register, handleSubmit, formState, watch, control } = useForm(formOptions);
  const { errors, isSubmitting, isDirty, isValid } = formState;

  const watchAbout = watch('about');
  let aboutLength = watchAbout?.length ?? video?.about?.length ?? 0;
  // const watchMoreDetails = watch('moreDetails');
  // let moreDetailsLength = watchMoreDetails?.length ?? video?.moreDetails?.length ?? 0;

  async function normalizeTags(tags) {
    if (tags?.length > 0) {
      let out = [];

      for (let i = 0; i < tags.length; i++) {
        if (tags[i]?.__isNew__) {
          try {
            const { data: newTag } = await authAxios({
              method: 'POST',
              url: `tags`,
              data: { title: tags[i].value },
              headers: {
                Accept: 'application/ld+json',
              },
            });

            // console.log(newTag['@id']);
            out.push(newTag['@id']);
          } catch (onErr) {
            console.log(onErr);
          }
        } else {
          out.push(tags[i].value);
        }
      }

      return out;
    }

    return [];
  }

  const onSubmit = async (values) => {
    try {
      const { privacy, sharedMails } = await getPrivacyDetails(
        values?.privacy,
        videoFormCtx.state.shareEmails
      );

      const tags = await normalizeTags(videoFormCtx.state.tags);
      const { video, photos } = videoFormCtx.state;

      let submittedData = {
        title: values.title,
        body: values.about,
        category: '/api/categories/' + values.category,
        upload: '/api/vimeo_uploads/' + video.id,
        subject: values.subject,
        // subjectDetails: values.moreDetails,
        privacy,
      };

      if (sharedMails?.length > 0) {
        submittedData = {
          ...submittedData,
          sharedMails,
        };
      }

      if (tags.length > 0) {
        submittedData = {
          ...submittedData,
          tags,
        };
      }

      if (photos.length > 0) {
        let images = [];
        photos.forEach((photo) => {
          images.push(photo.apiId);
        });
        submittedData = {
          ...submittedData,
          images,
        };
      }

      await authAxios({
        method: 'POST',
        url: `videos`,
        data: submittedData,
      });

      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="success"
            description="You have successfully created your video story."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });

      navigate(`/channel/${slug}`, { replace: true });
      // console.log(responseData);
    } catch (onErr) {
      console.log(onErr);
      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="error"
            description="Something went wrong."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    }
  };

  const videoStatus = videoFormCtx.getVideoStatus();
  const vimeoId = videoFormCtx.getVimeoId();

  const handleTagChange = async (newValue, actionMeta) => {
    videoFormCtx.updateTags(newValue);
    if (['remove-value', 'clear', 'pop-value'].includes(actionMeta.action)) {
      setTagsLimitReached(false);
    } else if (newValue.length >= TAGS_LIMIT) {
      setTagsLimitReached(true);
    }
  };

  // function handleCreateOption(inputValue) {
  //   console.log(inputValue);
  // }

  function handleInputChange(newValue, actionMeta) {
    // const { action, prevInputValue } = actionMeta;
    // console.log({ action, prevInputValue });
    if (newValue === '') {
      updateQuery('');
    } else {
      updateQuery(`&title=${newValue}`);
    }
  }

  return (
    <Box>
      <Heading size="lg" color="primary.default" mb={10} textAlign="center">
        Create video story
      </Heading>

      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={10}>
          <Box w="full">
            {!['uploading', 'uploaded'].includes(videoStatus) &&  <FormControl isInvalid={errors.title}>
              <FormLabel textStyle="formLabels">Upload video</FormLabel>
            </FormControl>}

            <Text color="primary.dark" fontSize="14px" lineHeight="150%" mb={4}>
              Your video should be no longer than 7 minutes. Please record your interview
              in sections or edit into shorter pieces for uploading.
            </Text>

            {videoStatus === 'idle' && <DropzoneVideo />}
            {videoStatus === 'uploading' && <VideoUploadPreviews />}
            {
              videoStatus === 'uploaded' &&
                vimeoId &&
                // videoFormCtx.state.video.file.preview && <VideoUploadPreviews title='Video is uploaded.' />
                videoFormCtx.state.video.file.preview && (
                  <VideoUploadIsCompleted imgW="100px" />
                )
              // videoFormCtx.state.video.file.preview && <LocalVideoPlayer />
            }
          </Box>

          <FormControl isInvalid={errors.title}>
            <FormLabel htmlFor="title" textStyle="formLabels">
              Story title{' '}
              <Text as="span" color="primary.light">
                (required)
              </Text>
            </FormLabel>
            <Input
              defaultValue={video?.title}
              id="title"
              type="text"
              placeholder="Enter story title"
              {...register('title')}
            />
            <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.category}>
            <FormLabel htmlFor="category" textStyle="formLabels">
              Choose category{' '}
              <Text as="span" color="primary.light">
                (required)
              </Text>
            </FormLabel>
            <Select
              defaultValue={video?.category}
              icon={<IconChevronLeft transform="rotate(-90deg)" />}
              placeholder="Select category"
              {...register('category')}
            >
              {categories.length > 0 &&
                statusCategories === 'resolved' &&
                categories.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {/*{id} - {name}*/}
                    {name}
                  </option>
                ))}
            </Select>
            <FormErrorMessage>{errors.category?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.tags}>
            <FormLabel htmlFor="tags" textStyle="formLabels">
              Add tags
            </FormLabel>
            <Text color="primary.dark" fontSize="14px" lineHeight="150%" mb={4}>
              Add up to {TAGS_LIMIT} tags that define what your video story is about.
            </Text>
            <CreatableSelect
              isLoading={false}
              isOptionDisabled={() => tagsLimitReached}
              onChange={handleTagChange}
              options={tags}
              // onCreateOption={handleCreateOption}
              onInputChange={handleInputChange}
              components={{ MultiValueRemove, ClearIndicator }}
              placeholder="Enter tags"
              isMulti
              // closeMenuOnSelect={false}
              styles={{ ...stylesReactSelect, ...stylesReactSelectIndicators }}
            />
            <FormErrorMessage>{errors.tags?.message}</FormErrorMessage>
            {tagsLimitReached && (
              <FormHelperText color="primary.light" textAlign="right">
                You have entered {TAGS_LIMIT} tags
              </FormHelperText>
            )}
          </FormControl>

          <RadioGroupPrivacy
            control={control}
            name="privacy"
            label="Choose who can see your video story"
          />

          {/* <FormControl>
            <FormLabel htmlFor="about" textStyle="formLabels">
              About this story
            </FormLabel>
            <Textarea
              id="about"
              type="text"
              placeholder="Describe what your story is about"
              resize="none"
              rows={textareaRows}
              maxLength="400"
              {...register('about')}
            />
          </FormControl> */}

          <FormControl isInvalid={errors.about}>
            <FormLabel htmlFor="about" textStyle="formLabels">
              About this story
            </FormLabel>
            <TextareaAutosize
              defaultValue={video?.about}
              id="about"
              type="text"
              placeholder="Describe what your story is about"
              maxLength={TEXTAREA_LENGTH_MD}
              {...register('about')}
            />
            <Flex justifyContent="space-between">
              <FormErrorMessage>{errors.about?.message}</FormErrorMessage>
              <Spacer />
              <FormHelperText
                color={
                  TEXTAREA_LENGTH_MD >= aboutLength ? 'primary.light' : 'error.default'
                }
                mx={5}
                flexShrink={0}
              >
                {aboutLength} / {TEXTAREA_LENGTH_MD}
              </FormHelperText>
            </Flex>
          </FormControl>

          <FormControl mt="20px !important" isInvalid={errors.subject}>
            <FormLabel htmlFor="subject" textStyle="formLabels">
              Who is the protagonist or main subject of this story?
            </FormLabel>
            <Input
              defaultValue={video?.subject}
              id="subject"
              type="text"
              placeholder={placeholderTextSubject}
              {...register('subject')}
            />
            <FormErrorMessage>{errors.subject?.message}</FormErrorMessage>
          </FormControl>

          {/*<FormControl isInvalid={errors.moreDetails}>*/}
          {/*  <FormLabel htmlFor="moreDetails" textStyle="formLabels">*/}
          {/*    More details about the protagonist/main subject*/}
          {/*  </FormLabel>*/}
          {/*  <TextareaAutosize*/}
          {/*    defaultValue={video?.moreDetails}*/}
          {/*    id="moreDetails"*/}
          {/*    type="text"*/}
          {/*    placeholder="Add more details about the protagonist/main subject"*/}
          {/*    maxLength={TEXTAREA_LENGTH_MD}*/}
          {/*    {...register('moreDetails')}*/}
          {/*  />*/}
          {/*  <Flex justifyContent="space-between">*/}
          {/*    <FormErrorMessage>{errors.moreDetails?.message}</FormErrorMessage>*/}
          {/*    <Spacer />*/}
          {/*    <FormHelperText*/}
          {/*      color={*/}
          {/*        TEXTAREA_LENGTH_MD >= moreDetailsLength*/}
          {/*          ? 'primary.light'*/}
          {/*          : 'error.default'*/}
          {/*      }*/}
          {/*      mx={5}*/}
          {/*      flexShrink={0}*/}
          {/*    >*/}
          {/*      {moreDetailsLength} / {TEXTAREA_LENGTH_MD}*/}
          {/*    </FormHelperText>*/}
          {/*  </Flex>*/}
          {/*</FormControl>*/}

          <Box mt="20px !important" w="full">
            <FormControl isInvalid={errors.title}>
              <FormLabel textStyle="formLabels">Add photos</FormLabel>
            </FormControl>
            <Text color="primary.dark" fontSize="14px" lineHeight="150%" mb={4}>
              Add up to 10 photos that additionally describe your story.
            </Text>

            <DropzonePhotos />
          </Box>

          <Box w="full" pt={{ md: 5 }}>
            <Button
              isFullWidth
              type="submit"
              isLoading={isSubmitting}
              disabled={
                !isDirty || !isValid || isSubmitting || videoStatus !== 'uploaded'
              }
            >
              Save video story
            </Button>
          </Box>
        </VStack>
      </form>
    </Box>
  );
};

export default FormCreateVideo;
