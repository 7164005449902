import { Center, Heading, Text, Box, Image } from '@chakra-ui/react';
import NoResultsSrc from '../../assets/vectors/noResults.svg';

const verticalSpace = { base: '24px', '2xl': '50px' };

const SearchListEmpty = () => {
  return (
    <Center>
      <Box maxW="600px" textAlign="center">
        <Image
          src={NoResultsSrc}
          my={verticalSpace}
          w={{ md: '314px' }}
          display="inline-block"
        />
        <Heading size="lg" mb={5}>
          Oops! No results found
        </Heading>
        <Text lineHeight="28px" mb={verticalSpace}>
          Please try another search. You can check the spelling, write another keywords,
          choose different category and tag.
        </Text>
      </Box>
    </Center>
  );
};

export default SearchListEmpty;
