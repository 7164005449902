import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Text, Image, FormLabel, Box, useToast } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { MAX_PHOTOS_FOR_VIDEO_STORY, TWENTY_MB } from '../../util/consts';
import PhotoUploadSrc from '../../assets/vectors/photoUpload.svg';
import { VideoFormContext } from '../../context/VideoFormContext';
import CustomToast from '../UI/CustomToast';
import PhotosUploadPreviewsVideoEdit from '../previews/PhotosUploadPreviewsVideoEdit';

const MAX_10_PHOTO_MSG = `Please upload up to 10 files`;

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  borderWidth: 1,
  borderRadius: '10px',
  borderColor: 'transparent',
  // borderColor: '#62606E',
  // borderStyle: 'dashed',
  borderStyle: 'none',
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2362606e' stroke-width='2' stroke-dasharray='8%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  backgroundColor: '#FFFFFF',
  outline: 'none',
  transition: 'border .24s ease-in-out, background 0.3s',
  minHeight: '165px',
};

const focusedStyle = {
  borderColor: 'transparent',
};

const acceptStyle = {
  borderColor: 'transparent',
  backgroundColor: '#E3E0FB',
};

const rejectStyle = {
  borderColor: 'transparent',
  backgroundColor: `rgba(202, 59, 28, 0.1)`,
};

function DropzoneEditPhotos({ photos }) {
  let currentId = photos.length;
  function getNewId() {
    return ++currentId;
  }
  const [files, setFiles] = useState(photos);
  const { setPhotos } = useContext(VideoFormContext);
  const toast = useToast();

  function onUpload(file, { url, apiId }) {
    setFiles((curr) =>
      curr.map((fw) => {
        if (fw.file === file) {
          return { ...fw, url, apiId };
        }
        return fw;
      })
    );
  }

  function showError(message) {
    toast({
      position: 'top',
      render: ({ onClose, id }) => (
        <CustomToast status="error" description={message} onClose={onClose} id={id} />
      ),
    });
  }

  function onDeleteById(id) {
    setFiles((curr) => curr.filter((fw) => fw.id !== id));
  }

  function showRejectedErrors(mappedRej) {
    try {
      for (let i = 0; i < mappedRej.length; i++) {
        const { errors, file } = mappedRej[i];
        if (errors.length > 0) {
          const { code } = errors[0];
          if (code === 'too-many-files') {
            showError(MAX_10_PHOTO_MSG);
            break;
          }
          if (code === 'file-too-large') {
            showError(
              `Error (${file.name}): Please upload a file not larger than 20 MB.`
            );
          } else {
            showError(
              `Error (${file.name}): The file you tried to upload is not supported. Please try uploading a different file.`
            );
          }
        }
      }
    } catch (onErr) {
      console.log(onErr);
    }
  }

  const onDrop = useCallback(
    (accFiles, rejFiles) => {
      const mappedAcc = accFiles.map((file) => ({ file, errors: [], id: getNewId() }));
      const mappedRej = rejFiles.map((r) => ({ ...r, id: getNewId() }));

      showRejectedErrors(mappedRej);
      if (files.length + mappedAcc.length > 10) {
        showError(MAX_10_PHOTO_MSG);
        return;
      }

      // setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
      setFiles((curr) => [...curr, ...mappedAcc]);
    },
    [showRejectedErrors, showError]
  );

  useEffect(() => {
    setPhotos(files);
  }, [files]);

  const maxFiles =
    MAX_PHOTOS_FOR_VIDEO_STORY - files.length > 0
      ? MAX_PHOTOS_FOR_VIDEO_STORY - files.length
      : 0;

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    // acceptedFiles,
  } = useDropzone({
    maxFiles,
    maxSize: TWENTY_MB,
    accept: ['image/*'],
    onDrop,
  });

  // const files = acceptedFiles.map((file) => (
  //   <Text key={file.path} wordBreak="break-word">
  //     {file.path} - {file.size} bytes
  //   </Text>
  // ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <Box w="full">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <Image src={PhotoUploadSrc} mb="10px" />
          <FormLabel
            textStyle="formLabels"
            color="primary.dark"
            mr={0}
            textAlign="center"
          >
            Drop photos here to upload or{' '}
            <Text
              as="span"
              color="secondary.dark"
              cursor="pointer"
              _hover={{ textDecoration: 'underline' }}
            >
              Browse
            </Text>
          </FormLabel>
          <Text color="primary.dark" fontSize="14px" lineHeight="150%">
            Maximum photo size: 20MB.
          </Text>
        </div>
      </Box>

      <PhotosUploadPreviewsVideoEdit
        files={files}
        onUpload={onUpload}
        onDeleteById={onDeleteById}
        showError={showError}
      />

      {/* <Box color="brand" textStyle="bodyFamilyRegular" fontSize="12px" lineHeight="120%">
        {files}
      </Box> */}
    </>
  );
}

export default DropzoneEditPhotos;
