import { IconButton } from '@chakra-ui/react';
import './flickity.css';
import { IconArrowRight } from '../../theme/icons/IconArrowRight';
import FlickityControlsContext from '../../context/FlickityControlsContext';
import { useContext } from 'react';

const FlickityHorizontalControls = ({ onClickPrev, onClickNext, numberOfItems }) => {
  const flickityControlsCtx = useContext(FlickityControlsContext);

  return (
    <>
      <IconButton
        onClick={onClickPrev}
        icon={<IconArrowRight boxSize="24px" />}
        variant="iconicDark"
        size="md"
        transform="rotate(-180deg)"
        disabled={flickityControlsCtx.selectedIndex === 0}
      />
      <IconButton
        onClick={onClickNext}
        icon={<IconArrowRight boxSize="24px" />}
        variant="iconicDark"
        size="md"
        disabled={flickityControlsCtx.selectedIndex === numberOfItems - 1}
      />
    </>
  );
};

export default FlickityHorizontalControls;
