import { AspectRatio, Flex, Image, FormLabel } from '@chakra-ui/react';
import vectorSrc from '../../assets/vectors/videoUploadIsCompleted.svg';

const VideoUploadIsCompleted = ({ borderRadius, imgW }) => {
  return (
    <AspectRatio ratio={16 / 9}>
      <Flex flexDir="column" bg="secondary.lighter" borderRadius={borderRadius}>
        <Image src={vectorSrc} mb={6} w={imgW ?? { base: '140px', sm: 'auto' }} />
        <FormLabel textStyle="formLabels" color="primary.dark" mr={0}>
          Video upload is complete!
        </FormLabel>
      </Flex>
    </AspectRatio>
  );
};

export default VideoUploadIsCompleted;
