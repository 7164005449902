const PictureOptimizedSingle = (props) => {
  return (
    <picture>
      <source srcSet={props?.src} type="image/webp" media="screen" />
      <img
        style={{
          borderRadius: props?.borderRadius,
          filter: props?.filter,
          width: props?.width,
          display: props?.display,
          position: props?.position,
          zIndex: props?.zIndex,
        }}
        loading="lazy"
        src={props?.fallbackSrc}
        alt={props.alt ? props.alt : ''}
      />
    </picture>
  );
};

export default PictureOptimizedSingle;
