import { Icon } from '@chakra-ui/react';

export const IconDelete = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="M11.5547 4.32227L4.26562 11.6113"
      stroke="#62606E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="currentColor"
      d="M4.26562 4.32227L11.5547 11.6113"
      stroke="#62606E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
