import { Icon } from '@chakra-ui/react';

export const IconCategories = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M6.15789 10.1527C5.77895 10.1527 5.49474 9.99224 5.30526 9.67141C5.11579 9.35058 5.12632 9.03493 5.33684 8.72445L10.6105 0.43467C10.8 0.14489 11.0632 0 11.4 0C11.7368 0 12 0.14489 12.1895 0.43467L17.4632 8.72445C17.6737 9.03493 17.6842 9.35058 17.4947 9.67141C17.3053 9.99224 17.0211 10.1527 16.6421 10.1527H6.15789ZM18.5053 24C16.9474 24 15.6421 23.4825 14.5895 22.4476C13.5368 21.4127 13.0105 20.1294 13.0105 18.5977C13.0105 17.066 13.5368 15.7827 14.5895 14.7477C15.6421 13.7128 16.9474 13.1953 18.5053 13.1953C20.0632 13.1953 21.3684 13.7128 22.4211 14.7477C23.4737 15.7827 24 17.066 24 18.5977C24 20.1294 23.4737 21.4127 22.4211 22.4476C21.3684 23.4825 20.0632 24 18.5053 24ZM0.947368 23.2238C0.673684 23.2238 0.447369 23.1358 0.268421 22.9599C0.0894738 22.784 0 22.5614 0 22.2924V14.7167C0 14.4476 0.0894738 14.2251 0.268421 14.0492C0.447369 13.8732 0.673684 13.7853 0.947368 13.7853H8.65263C8.92632 13.7853 9.15263 13.8732 9.33158 14.0492C9.51053 14.2251 9.6 14.4476 9.6 14.7167V22.2924C9.6 22.5614 9.51053 22.784 9.33158 22.9599C9.15263 23.1358 8.92632 23.2238 8.65263 23.2238H0.947368ZM18.5053 22.1371C19.5158 22.1371 20.3684 21.7956 21.0632 21.1125C21.7579 20.4295 22.1053 19.5912 22.1053 18.5977C22.1053 17.6041 21.7579 16.7658 21.0632 16.0828C20.3684 15.3997 19.5158 15.0582 18.5053 15.0582C17.4947 15.0582 16.6421 15.3997 15.9474 16.0828C15.2526 16.7658 14.9053 17.6041 14.9053 18.5977C14.9053 19.5912 15.2526 20.4295 15.9474 21.1125C16.6421 21.7956 17.4947 22.1371 18.5053 22.1371ZM1.89474 21.3609H7.70526V15.6481H1.89474V21.3609ZM7.86316 8.28978H14.9368L11.4 2.67012L7.86316 8.28978Z"
    />
  </Icon>
);
