import { Center, VStack, Box, Image, Text, Heading } from '@chakra-ui/react';

import CheckEmail from '../assets/img/checkyouremail.svg';

import purpledottedtriangleSrc from '../assets/img/purpledottedtriangle.svg';
import elipseSrc from '../assets/img/elipse.svg';

const SignupCheckEmailPage = () => {
  return (
    <Center minH="94vh" pt={10} pb={{ base: '40px', lg: '0' }}>
      <Box maxW="full" pos="relative" pb={{ lg: '120px' }}>
        <Box
          textAlign="center"
          position="relative"
          zIndex="1"
          bgColor="white"
          pt={{ base: '48px', '2xl': '70px' }}
          pb={{ base: '64px', '2xl': '120px' }}
          px="16px"
          borderRadius="20px"
          w="740px"
          maxW="full"
        >
          <Image
            src={purpledottedtriangleSrc}
            display={{ base: 'none', lg: 'block' }}
            pos="absolute"
            top={{ base: '-70px', '2xl': '-70px' }}
            right="-70px"
          />
          <VStack spacing={5}>
            <Image src={CheckEmail} />
            <VStack spacing={2}>
              <Heading size="lg" color="primary.default" pt={{ '2xl': '30px' }}>
                Thank you for registering!
              </Heading>
              <Text
                fontSize="20px"
                color="primary.default"
                textStyle="bodyMedium"
              >
                Please check your email inbox.
              </Text>
            </VStack>
            <Box maxW="500px" w="full" pt={{ '2xl': '10px' }}>
              <Text fontSize="16px" lineHeight="28px">
                Go to your inbox and click on the verification link to activate
                your Storyvault account.
              </Text>
            </Box>
          </VStack>
        </Box>
        <Image
          src={elipseSrc}
          display={{ base: 'none', lg: 'block' }}
          pos="absolute"
          bottom="20px"
          right="-100px"
          w="400px"
        />
      </Box>
    </Center>
  );
};

export default SignupCheckEmailPage;
