import { Icon } from '@chakra-ui/react';

export const IconDownload = (props) => (
  <Icon viewBox="0 0 25 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.114.555a1.35 1.35 0 0 0-.54.505c-.06.102-.085 2.196-.111 7.904l-.033 7.767-1.804-1.507c-1.889-1.575-2.006-1.65-2.495-1.555-.423.082-.814.58-.814 1.023 0 .095.046.3.105.45.09.232.482.587 2.794 2.523 1.478 1.235 2.8 2.298 2.937 2.36.54.238.527.245 3.601-2.326 1.537-1.289 2.847-2.42 2.905-2.516.378-.6.065-1.405-.619-1.616-.456-.143-.631-.041-2.598 1.61-1.003.845-1.843 1.534-1.863 1.534-.013 0-.039-3.499-.045-7.768-.02-7.726-.02-7.774-.157-7.958a1.716 1.716 0 0 0-.3-.32c-.201-.158-.722-.219-.963-.11Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.619 15.851a1.136 1.136 0 0 0-.547.607C.026 16.581 0 17.536 0 19.125c0 1.998.02 2.543.098 2.87.293 1.174 1.165 2.08 2.292 2.387.56.157 19.669.157 20.229 0 1.16-.313 2.084-1.316 2.325-2.51.046-.238.065-1.233.052-2.898-.02-2.427-.026-2.536-.156-2.714-.254-.361-.463-.47-.873-.47s-.619.109-.873.47c-.13.178-.137.294-.169 2.77-.033 2.474-.04 2.59-.17 2.768a1.817 1.817 0 0 1-.312.327c-.176.143-.182.143-9.939.143-9.756 0-9.762 0-9.938-.143a1.819 1.819 0 0 1-.313-.327c-.13-.177-.136-.293-.169-2.769-.032-2.475-.039-2.591-.17-2.769a1.714 1.714 0 0 0-.299-.32c-.221-.17-.736-.218-.996-.089Z"
    />
  </Icon>
);
