import SearchContent from '../components/search/SearchContent';
import { MyChannelContextProvider } from '../context/MyChannelContext';

import { CategoriesProvider } from '../context/CategoriesContext';
import UseCategories from '../hooks/useCategories';
import UseTags from '../hooks/useTags';
import { TagsProvider } from '../context/TagsContext';
// import { SearchContextProvider } from '../context/SearchContext';

const SearchPage = () => {
  return (
    <MyChannelContextProvider>
      <CategoriesProvider>
        <UseCategories>
          <TagsProvider>
            <UseTags>
              {/*<SearchContextProvider>*/}
                <SearchContent />
              {/*</SearchContextProvider>*/}
            </UseTags>
          </TagsProvider>
        </UseCategories>
      </CategoriesProvider>
    </MyChannelContextProvider>
  );
};

export default SearchPage;
