import { Icon } from '@chakra-ui/react';

export const IconPen = (props) => (
  <Icon viewBox="0 0 21 21" {...props}>
    <path
      fill="currentColor"
      d="M17.4258 0.382812C18.0061 0.382812 18.575 0.544421 19.0687 0.849529C19.5624 1.15464 19.9613 1.59119 20.2209 2.11027C20.4804 2.62935 20.5903 3.21045 20.5382 3.78846C20.486 4.36646 20.274 4.91853 19.9258 5.38281L18.6758 6.63281L14.3008 2.25781L15.5508 1.00781C16.0733 0.615312 16.722 0.382812 17.4258 0.382812ZM1.80078 14.7578L0.550781 20.3828L6.17578 19.1328L17.7383 7.57031L13.3633 3.19531L1.80078 14.7578ZM7.25328 14.7578L5.77703 16.2341L4.69953 15.1566L6.17578 13.6803L7.25328 14.7578Z"
    />
  </Icon>
);
