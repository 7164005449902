import { Box, Text } from '@chakra-ui/react';
import DoubleVideosCarousels from '../../../components/carousels/DoubleVideosCarousels';
import VideosListLoading from '../VideosListLoading';
import { useMemo, useContext } from 'react';
import { isFetching } from '../../../util';
import { TrendingVideosContext } from '../../../context/TrendingVideosContext';
import { normalizedVideoData } from '../VideosList';

const TrendingVideos = () => {
  const { state } = useContext(TrendingVideosContext);
  const { status, items } = state;
  const isLoading = isFetching(status);
  const data = useMemo(() => normalizedVideoData(items), [items]);

  return (
    <>
      <Box mb={{ base: '40px', '2xl': '80px' }}>
        <Text as="h2" textStyle="sectionHeadline" textAlign="center">
          Trending videos
        </Text>
        <Text textStyle="sectionText" mt="30px" textAlign="center">
          Discover the latest top trending videos
        </Text>
      </Box>

      {isLoading ? (
        <VideosListLoading startColor="secondary.light" endColor="secondary.mid" />
      ) : (
        <DoubleVideosCarousels data={data} key={Math.random()} />
      )}
    </>
  );
};

export default TrendingVideos;
