import {
  Text,
  Show,
  AspectRatio,
  Image,
  FormLabel,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { useCallback, useContext, useMemo } from 'react';
import { TWO_GB } from '../../util/consts';
import { VideoFormContext } from '../../context/VideoFormContext';
import { IconVideoAdd } from '../../theme/icons/IconVideoAdd';
import VideoUploadSrc from '../../assets/vectors/videoUpload.svg';
import CustomToast from '../UI/CustomToast';
import { isVideo } from '../../util';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  borderWidth: 1,
  borderRadius: '10px',
  borderColor: 'transparent',
  // borderColor: '#62606E',
  // borderStyle: 'dashed',
  borderStyle: 'none',
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2362606e' stroke-width='2' stroke-dasharray='8%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  backgroundColor: '#FFFFFF',
  outline: 'none',
  transition: 'border .24s ease-in-out, background 0.3s',
  cursor: 'auto',
};

const focusedStyle = {
  borderColor: 'transparent',
};

const acceptStyle = {
  borderColor: 'transparent',
  backgroundColor: '#E3E0FB',
};

const rejectStyle = {
  borderColor: 'transparent',
  backgroundColor: `rgba(202, 59, 28, 0.1)`,
};

let currentId = 0;
function getNewId() {
  return ++currentId;
}

function DropzoneVideo() {
  const toast = useToast();
  const { startUploadingVideo } = useContext(VideoFormContext);

  function showError(message) {
    toast({
      position: 'top',
      render: ({ onClose, id }) => (
        <CustomToast status="error" description={message} onClose={onClose} id={id} />
      ),
    });
  }

  function showRejectedErrors(mappedRej) {
    try {
      for (let i = 0; i < mappedRej.length; i++) {
        const { errors, file } = mappedRej[i];
        if (errors.length > 0) {
          const { code } = errors[0];
          if (code === 'file-too-large') {
            showError(`Error (${file.name}): Please upload a file not larger than 2GB.`);
          } else {
            showError(
              `Error (${file.name}): The file you tried to upload is not supported. Please try uploading a different file.`
            );
          }
        }
      }
    } catch (onErr) {
      console.log(onErr);
    }
  }

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      const accFilesWithPreview = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      const mappedRej = rejectedFiles.map((r) => ({ ...r, id: getNewId() }));
      showRejectedErrors(mappedRej);

      const file = accFilesWithPreview.length > 0 ? accFilesWithPreview[0] : null;
      if (file) {
        if (isVideo(file)){
          startUploadingVideo(file);
        } else {
          showError(
            `Error (${file.name}): The file you tried to upload is not supported. Please try uploading a different file.`
          );
        }
      }
    },
    [showRejectedErrors]
  );

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    // acceptedFiles,
    open,
  } = useDropzone({
    multiple: false,
    maxFiles: 1,
    maxSize: TWO_GB,
    onDrop,
    noClick: true,
  });

  // const files = acceptedFiles.map((file) => (
  //   <Text key={file.path} wordBreak="break-word">
  //     {file.path} - {file.size} bytes
  //   </Text>
  // ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <AspectRatio ratio={16 / 9}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <Show above="md">
            <Image src={VideoUploadSrc} mb={4} />
          </Show>
          <FormLabel textStyle="formLabels" color="primary.dark" mr={0}>
            Drag and drop your video here to upload or
          </FormLabel>
          <Button variant="solidLight" px="32px" mt="12px" mb="10px" onClick={open}>
            <IconVideoAdd boxSize="24px" mr="10px" />
            Choose video
          </Button>
          <Text color="primary.dark" fontSize="14px" lineHeight="150%">
            Maximum video size: 2GB.
          </Text>
        </div>
      </AspectRatio>

      {/* <Box
        color="brand"
        textStyle="bodyFamilyRegular"
        fontSize="12px"
        lineHeight="120%"
        mt="14px"
      >
        {files}
      </Box> */}
    </>
  );
}

export default DropzoneVideo;
