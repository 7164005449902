import moment from 'moment';

export function getDefaultDate(date) {
  try {
    return moment(date).format('MMMM D, YYYY');
  } catch (onError) {
    console.log(onError);
    return '';
  }
}

export function formatDate(date) {
  return `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')} ${String(
    date.getSeconds()
  ).padStart(2, '0')}.${String(date.getMilliseconds()).padStart(3, '0')}`;
}

export function getDurationAgo(date) {
  try {
    return moment(date).fromNow();
  } catch (onErr) {
    console.log(onErr);
    return '';
  }
}