import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HttpContext } from '../context/HttpContext';
import { VideoFormContext } from '../context/VideoFormContext';
import { AuthContext } from '../context/AuthContext';

function UseEditVideo({ children }) {
  const navigate = useNavigate();
  const { slug: videoSlug } = useParams();

  const { authAxios } = useContext(HttpContext);
  const { updateAllDataForEdit, updateStatus } = useContext(VideoFormContext);
  const { getAuthUser } = useContext(AuthContext);
  const { slug } = getAuthUser();

  useEffect(() => {
    const getData = async () => {
      try {
        updateStatus('pending');
        const { data } = await authAxios.get(`videos/${videoSlug}`);
        updateAllDataForEdit({
          data,
          status: 'resolved',
          error: null,
        });
      } catch (onError) {
        console.log(onError);
        updateAllDataForEdit({
          data: null,
          status: 'rejected',
          error: 'Something went wrong',
        });
        navigate(`/channel/${slug}`, { replace: true });
      }
    };

    getData();
  }, [authAxios, videoSlug]);

  return <>{children}</>;
}

export default UseEditVideo;
