import { Container } from '@chakra-ui/react';
// import CategoriesBrowseContent from '../components/categories/CategoriesBrowseContent';
import FlickityTaglines from '../components/carousels/FlickityTaglines';
import FeaturedVideos from '../components/videos/lists/FeaturedVideos';
import TrendingVideos from '../components/videos/lists/TrendingVideos';
import RecommendedVideos from '../components/videos/lists/RecommendedVideos';
import { RecommendedVideosProvider } from '../context/RecommendedVideosContext';
import UseRecommendedVideos from '../hooks/useRecommendedVideos';
import { FeaturedVideosProvider } from '../context/FeaturedVideosContext';
import UseFeaturedVideos from '../hooks/useFeaturedVideos';
import { TrendingVideosProvider } from '../context/TrendingVideosContext';
import UseTrendingVideos from '../hooks/useTrendingVideos';
import HeroLoggedHome from '../components/heroes/HeroLoggedHome';
import SectionDiscoverVideos from '../components/sections/SectionDiscoverVideos';
import { TaglinesContext } from '../context/TaglinesContext';
import { useContext } from 'react';

const HomePage = () => {
  const { state: taglinesState } = useContext(TaglinesContext);
  const { status, featuredItems, lastAddedItem } = taglinesState;

  return (
    <Container mt={{ base: '80px', md: '100px' }} p={0}>
      {/*<Container pt={{ base: '50px', lg: '60px' }} pb={{ lg: '10px' }} maxW="1562px">*/}
      {/*  <CategoriesBrowseContent />*/}
      {/*</Container>*/}
      <HeroLoggedHome />

      {((featuredItems.length > 0 || lastAddedItem) && status === 'resolved') && (
        <FlickityTaglines
          items={featuredItems}
          oneItem={lastAddedItem}
          // loading={['idle', 'pending'].includes(status)}
        />
      )}

      <Container maxW="1562px" py="50px">
        <FeaturedVideosProvider>
          <UseFeaturedVideos>
            <FeaturedVideos />
          </UseFeaturedVideos>
        </FeaturedVideosProvider>
      </Container>

      <Container bg="secondary.lighter" p={0}>
        <Container maxW="1562px" py="50px">
          <TrendingVideosProvider>
            <UseTrendingVideos>
              <TrendingVideos />
            </UseTrendingVideos>
          </TrendingVideosProvider>
        </Container>
      </Container>

      <Container maxW="1562px" py="50px" pos="relative">
        <RecommendedVideosProvider>
          <UseRecommendedVideos>
            <RecommendedVideos />
          </UseRecommendedVideos>
        </RecommendedVideosProvider>
      </Container>

      <SectionDiscoverVideos />
    </Container>
  );
};

export default HomePage;
