export const TWENTY_MB = 20971520; // 20971520 = 20 MB
export const TWO_GB = 2147483648; // 2147483648 = 2048 MB

export const TEXTAREA_LENGTH_MD = 500;
export const MAX_PHOTOS_FOR_VIDEO_STORY = 10;

export const PRIVACY_PUBLIC_CODE = 0;
export const PRIVACY_SHARED_CODE = 1;
export const PRIVACY_ONLY_ME_CODE = 2;

export const PRIVACY_PUBLIC = 'public';
export const PRIVACY_ONLY_ME = 'me';
export const PRIVACY_SHARED = 'specific';

export const PRIVACY_OPTIONS = [PRIVACY_PUBLIC, PRIVACY_ONLY_ME, PRIVACY_SHARED];

export const PAGE_SIZE_OPTIONS = [12, 24, 36, 48];

export const SOCIAL_MEDIA_BRAND_COLORS = {
  LinkedIn: '#0072B1',
  Facebook: '#4267B2',
  Twitter: '#00ACEE',
};

export const REPORT_REASONS = [
  {
    value: '1',
    main: 'Sexually explicit',
    sub: 'Graphic sexual activity, nudity or other types of sexual references',
  },
  {
    value: '2',
    main: 'Hate speech',
    sub: 'Intimidation or hatred against groups of people or individuals',
  },
  {
    value: '3',
    main: 'Violent or dangerous',
    sub: 'Excessively violent or repulsive, or shows dangerous acts that may result in physical harm',
  },
  {
    value: '4',
    main: 'Disinformation',
    sub: 'Verifiably false or misleading information that is intended to deceive people',
  },
];

export const COMMENT_NEW = 'comment-new';
export const COMMENT_EDIT = 'comment-edit';
export const COMMENT_REPLY = 'comment-reply';
export const COMMENT_REPLY_EDIT = 'comment-reply-edit';
