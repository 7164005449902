import { Icon } from '@chakra-ui/react';

export const IconVideoAdd = (props) => (
  <Icon viewBox="0 0 40 28" {...props}>
    <path
      fill="currentColor"
      d="M38.3333 24C37.9833 24 37.65 23.9 37.3667 23.6833L25.7 15.35C25.2667 15.0333 25 14.5333 25 14C25 13.4667 25.2667 12.95 25.7 12.65L37.3667 4.31668C37.8667 3.95001 38.5333 3.90001 39.1 4.18334C39.65 4.46668 40 5.03334 40 5.66668V22.3333C40 22.9667 39.65 23.5333 39.1 23.8167C38.8667 23.9333 38.6 24 38.3333 24ZM29.5333 14L36.6667 19.1V8.91668L29.5333 14.0167V14Z"
    />
    <path
      fill="currentColor"
      d="M23.3333 27.3333H5C2.25 27.3333 0 25.0833 0 22.3333V5.66667C0 2.91667 2.25 0.666672 5 0.666672H23.3333C26.0833 0.666672 28.3333 2.91667 28.3333 5.66667V22.3333C28.3333 25.0833 26.0833 27.3333 23.3333 27.3333ZM5 4C4.08333 4 3.33333 4.75 3.33333 5.66667V22.3333C3.33333 23.25 4.08333 24 5 24H23.3333C24.25 24 25 23.25 25 22.3333V5.66667C25 4.75 24.25 4 23.3333 4H5Z"
    />
    <path
      fill="currentColor"
      d="M11.8 8.08334V12.6167H7.38336V15.4667H11.8V20H14.8834V15.4667H19.3V12.6167H14.8834V8.08334H11.8Z"
    />
  </Icon>
);
