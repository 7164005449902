import { SimpleGrid, GridItem, Container } from '@chakra-ui/react';
import defaultPosterSrc from '../../assets/img/carouselimage2.png';
import VideoIsUploading from '../previews/VideoIsUploading';
import VideoIsProcessing from '../previews/VideoIsProcessing';
import VideoUploadIsCompleted from '../previews/VideoUploadIsCompleted';
import VideoIsProcessingWithPoster from '../previews/VideoIsProcessingWithPoster';
import VideoIsUploaded from '../previews/VideoIsUploaded';
import VideoPlayer from '../previews/VideoPlayer';
import VideoNoAccess from '../previews/VideoNoAccess';
// import LocalVideoPlayer from '../previews/LocalVideoPlayer';

function Previews() {
  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 1, lg: 2 }} columnGap={4} rowGap={4}>
          <GridItem>
            <VideoIsUploading percentage={50} />
          </GridItem>
          <GridItem>
            <VideoIsProcessing componentSize="large" borderRadius="10px" />
          </GridItem>
          <GridItem>
            <VideoUploadIsCompleted borderRadius="10px" imgW="100px" />
          </GridItem>
          <GridItem>
            <VideoIsProcessingWithPoster componentSize="large" borderRadius="10px" />
          </GridItem>
          <GridItem>
            <VideoIsUploaded poster={defaultPosterSrc} duration="07:11" />
          </GridItem>
          <GridItem>
            <VideoPlayer vimeoId="710002360" />
          </GridItem>
          <GridItem>
            <VideoNoAccess />
          </GridItem>
          {/* <GridItem>
            <AspectRatio ratio={16 / 9}>
              <Box
                bg="primary.default"
                pos="relative"
                w="full"
              >
                <video width="100%" height="100%" controls>
                  <source
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    type="video/mp4"
                  />
                </video>
              </Box>
            </AspectRatio>
          </GridItem> */}
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default Previews;
