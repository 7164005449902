import {
  Container,
  Box,
  Heading,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useMediaQuery,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import SettingsSidebar from '../../components/settings/SettingsSidebar';
import { IconChevronLeft } from '../../theme/icons/IconChevronLeft';

const itemMt = '8px';

const SettingsLayout = (props) => {
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');

  return (
    <Container maxW="1494px" mt={{ base: '120px', md: '140px' }} mb={10}>
      <Box bg="white" borderRadius="20px" p={{ base: '32px 16px', md: '50px 50px 60px' }}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={{ base: '30px', md: '40px' }}
        >
          <Heading size="lg" color="primary.default">
            Settings
          </Heading>
          {!isLargerThanLG && (
            <Box>
              <Menu variant="small">
                <MenuButton>
                  <IconChevronLeft fontSize="32px" transform="rotate(-90deg)" />
                </MenuButton>
                <MenuList>
                  <MenuItem as={RouterLink} to="/settings/profile">
                    Edit Profile
                  </MenuItem>
                  <MenuItem as={RouterLink} to="/settings/password" mt={itemMt}>
                    Change Password
                  </MenuItem>
                  <MenuItem as={RouterLink} to="/settings/interests" mt={itemMt}>
                    Edit Interests
                  </MenuItem>
                  <MenuItem as={RouterLink} to="/settings/delete-account" mt={itemMt}>
                    Delete Account
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          )}
        </Flex>

        <Flex>
          {isLargerThanLG && <SettingsSidebar />}

          <Box
            flex="1"
            borderTop={!isLargerThanLG && 'solid 1px #DDD'}
            borderLeft={isLargerThanLG && 'solid 1px #DDD'}
            pt={!isLargerThanLG && { base: '30px', md: '40px' }}
          >
            <Container maxW="632px" px={isLargerThanLG ? '1rem' : '0px'}>
              {props.children}
            </Container>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default SettingsLayout;
