import {
  Box,
  Heading,
  Flex,
  Avatar,
  Text,
  LinkBox,
  LinkOverlay,
  IconButton,
  HStack,
  useDisclosure,
  Link,
} from '@chakra-ui/react';

import { Link as RouterLink } from 'react-router-dom';
import VideoIsProcessing from '../previews/VideoIsProcessing';
import VideoIsUploaded from '../previews/VideoIsUploaded';
import { IconAvatar } from '../../theme/icons/IconAvatar';
import { IconPenPlain } from '../../theme/icons/IconPenPlain';
import { IconTrash } from '../../theme/icons/IconTrash';
import PrivacyTagIcon from './privacy/PrivacyTagIcon';
import DeleteVideoModal from '../modals/DeleteVideoModal';
import { getPrivacy } from '../../util';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const VideoListCard = ({ cell }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getAuthUser } = useContext(AuthContext);

  const canEdit = cell.owner.id === getAuthUser().id ?? false;

  return (
    <>
      <Box role="group" wordBreak="break-word">
        <LinkBox>
          {cell.status === 'processing' ? (
            <VideoIsProcessing
              borderRadius="10px"
              progressSize="sm"
              imgW={{ base: '140px', '2xl': '164px' }}
            />
          ) : (
            // Recommended size for Poster thumb would be: width 720x, ratio 16:9
            <VideoIsUploaded poster={cell.poster} duration={cell.duration} />
          )}

          <LinkOverlay as={RouterLink} to={cell.exampleForNestedObject.singlePageUrl}>
            <Heading
              fontSize="18px"
              lineHeight="150%"
              mt={4}
              color="primary.default"
              transition="0.3s"
              // _groupHover={{ color: 'secondary.dark' }}
              noOfLines={3}
            >
              {cell.title}
            </Heading>
          </LinkOverlay>

          <Flex mt="10px" alignItems="center">
            <Avatar
              icon={<IconAvatar fontSize="16px" color="secondary.lighter" />}
              src={cell.avatar}
              // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC9oMJmsng6zbrYeqqIGjKbYdi8lb1HRQw-Q&usqp=CAU"
              bg="secondary.light"
              boxSize="26px"
              alignSelf="flex-start"
              to={`/channel/${cell.owner.slug}`}
              as={RouterLink}
            />
            <Box ml="10px" flex={1}>
              <Link
                to={`/channel/${cell.owner.slug}`}
                as={RouterLink}
                _hover={{ color: 'primary.default', textDecoration: 'underline' }}
              >
                <Text fontSize="14px" lineHeight="130%">
                  {cell.firstName} {cell.lastName}
                </Text>
              </Link>
            </Box>
            {canEdit && (
              <HStack ml="10px" alignSelf="flex-start" spacing="10px">
                <IconButton
                  onClick={onOpen}
                  icon={<IconTrash boxSize="16px" />}
                  variant="iconicDark"
                  bg="secondary.light"
                  size="sm"
                  aria-label={`trash-${cell.id}`}
                />
                <IconButton
                  as={RouterLink}
                  to={`/videos/edit/${cell.slug}`}
                  icon={<IconPenPlain boxSize="16px" />}
                  variant="iconicDark"
                  bg="secondary.light"
                  size="sm"
                  aria-label={`edit-${cell.id}`}
                />
                <PrivacyTagIcon
                  privacy={getPrivacy(cell.privacy)}
                  url={`/videos/edit/${cell.slug}`}
                />
              </HStack>
            )}
          </Flex>
        </LinkBox>
      </Box>

      <DeleteVideoModal
        isOpen={isOpen}
        onClose={onClose}
        data={cell}
        parent="video_list_card"
      />
    </>
  );
};

export default VideoListCard;
