import { IconDot } from '../../theme/icons/IconDot';
import styles from '../../css/animationPulse.module.css';

const AnimationPulse = () => {
  return (
    <>
      {[...Array(3).keys()].map((item, index) => {
        const randomIndex = Math.floor(Math.random() * 8);
        const positionClass = `position${randomIndex}`;
        return (
          <IconDot
            key={index}
            fontSize="10px"
            iconclass={[
              styles.animationPulseIcon,
              styles.position,
              styles[positionClass],
            ].join(' ')}
            dotclass={styles.animationPulseDot}
          />
        );
      })}
    </>
  );
};

export default AnimationPulse;
