import {
  Box,
  Flex,
  LinkBox,
  AspectRatio,
  SkeletonCircle,
  SkeletonText,
} from '@chakra-ui/react';

const VideoListCardSkeleton = ({ startColor, endColor }) => {
  const skeletonStartColor = startColor ?? 'secondary.lighter';
  const skeletonEndColor = endColor ?? 'secondary.light';

  return (
    <Box role="group">
      <LinkBox>
        <AspectRatio ratio={16 / 9}>
          <Box bg={skeletonStartColor} borderRadius="10px"></Box>
        </AspectRatio>
        <SkeletonText
          my={4}
          noOfLines={2}
          spacing="4"
          startColor={skeletonStartColor}
          endColor={skeletonEndColor}
        />

        <Flex mt="10px" alignItems="center">
          <SkeletonCircle
            size="40px"
            startColor={skeletonStartColor}
            endColor={skeletonEndColor}
          />
          <Box ml="10px" width="50%">
            <SkeletonText
              noOfLines={1}
              spacing="4"
              startColor={skeletonStartColor}
              endColor={skeletonEndColor}
            />
          </Box>
        </Flex>
      </LinkBox>
    </Box>
  );
};

export default VideoListCardSkeleton;
