import React, { createContext, useState } from 'react';
import axios from 'axios';

const AuthContext = createContext();
const { Provider } = AuthContext;

function parseJwt(jwt) {
  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

const publicAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const KEYS = {
  token: process.env.REACT_APP_TOKEN,
  exp: process.env.REACT_APP_EXP,
  user: process.env.REACT_APP_USER,
  refreshToken: process.env.REACT_APP_REFRESH_TOKEN,
  refreshTokenTTL: process.env.REACT_APP_REFRESH_TOKEN_TTL_SEC,
};

function getRefreshTokenExpInSeconds() {
  try {
    return (
      Math.floor(new Date().getTime() / 1000) +
      parseInt(process.env.REACT_APP_REFRESH_TOKEN_TTL_SEC, 10)
    );
  } catch (onError) {
    console.log(onError);
    return 0;
  }
}

function getExpiresAt(token) {
  const payload = parseJwt(token);
  let { exp } = payload;
  const refreshTokenExp = getRefreshTokenExpInSeconds();
  if (exp < refreshTokenExp) {
    exp = refreshTokenExp;
  }

  return exp;
}

const AuthProvider = ({ children }) => {
  const token = localStorage.getItem(KEYS.token);
  const exp = localStorage.getItem(KEYS.exp);
  const user = localStorage.getItem(KEYS.user);
  const refreshToken = localStorage.getItem(KEYS.refreshToken);

  const [authState, setAuthState] = useState({
    token,
    exp,
    user: user ? JSON.parse(user) : {},
    refreshToken,
  });

  const setAuthInfo = ({ token, user, refreshToken }) => {
    const exp = getExpiresAt(token);
    localStorage.setItem(KEYS.token, token);
    localStorage.setItem(KEYS.user, JSON.stringify(user));
    localStorage.setItem(KEYS.exp, exp);
    localStorage.setItem(KEYS.refreshToken, refreshToken);

    setAuthState({ token, user, exp, refreshToken });
  };

  const setUser = (user) => {
    localStorage.setItem(KEYS.user, JSON.stringify(user));
    setAuthState({
      ...authState,
      user,
    });
  };

  const getUser = () => {
    const { user } = authState;
    return user;
  };

  const getUserCategories = () => {
    const { user } = authState;

    return user?.categories ?? [];
  };

  const updateUserCategory = (category, type) => {
    if (!['add', 'remove'].includes(type)) {
      return;
    }
    const { user } = authState;

    if (type === 'add') {
      const prev  = getUserCategories();
      let newArray = prev.concat([category]);
      setUser({
        ...user,
        categories: newArray,
      });
      return;
    }

    if (type === 'remove') {
      let categories  = getUserCategories();
      categories = categories.filter((x) => parseInt(x.id, 10) !== parseInt(category.id));
      setUser({
        ...user,
        categories,
      });
    }
  };

  function setUserImage(image) {
    const user = getUser();
    setUser({
      ...user,
      image: image,
    });
  }

  const setTokens = ({ token, refreshToken }) => {
    const exp = getExpiresAt(token);
    localStorage.setItem(KEYS.token, token);
    localStorage.setItem(KEYS.exp, exp);
    localStorage.setItem(KEYS.refreshToken, refreshToken);

    setAuthState({
      ...authState,
      token,
      exp,
      refreshToken,
    });
  };
  const logout = async () => {
    try {
      localStorage.removeItem(KEYS.token);
      localStorage.removeItem(KEYS.exp);
      localStorage.removeItem(KEYS.user);
      localStorage.removeItem(KEYS.refreshToken);
      setAuthState({
        token: null,
        exp: null,
        user: {},
        refreshToken: null,
      });
    } catch (onError) {
      console.log(onError);
    }
  };
  const isAuthenticated = () => {
    if (!authState.exp) {
      return false;
    }
    return new Date() < new Date(authState.exp * 1000);
  };

  const getAccessToken = () => {
    return localStorage.getItem(KEYS.token);
  };

  const getRefreshToken = () => {
    return localStorage.getItem(KEYS.refreshToken);
  };

  const getNewToken = async () => {
    try {
      const { token, refresh_token } = await publicAxios.post('/token/refresh', {
        refresh_token: getRefreshToken(),
      });
      setTokens({ token, refreshToken: refresh_token });
    } catch (onError) {
      console.log(onError);
      return onError;
    }
  };

  const getNewTokenForRequest = async (failedRequest) => {
    console.log(failedRequest);
    try {
      const { data } = await publicAxios.post('/token/refresh', {
        refresh_token: getRefreshToken(),
      });

      const { token, refresh_token: refreshToken } = data;
      const payload = parseJwt(token);
      const { exp } = payload;
      localStorage.setItem(KEYS.token, token);
      localStorage.setItem(KEYS.refreshToken, refreshToken);
      localStorage.setItem(KEYS.exp, exp);
      // console.log({ token, refresh_token })
      failedRequest.response.config.headers[`xtoken`] = `Bearer ${token}`;
      setTokens({ token, refreshToken });

      return Promise.resolve();
    } catch (onError) {
      console.log(onError);
      // console.log(typeof onError);
      // console.log(JSON.stringify(onError));
      // for (let key in onError) {
      //   if (onError.hasOwnProperty(key)) {
      //     console.log(onError[key]);
      //   }
      // }
      await logout();
    }
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        setAuthTokens: (tokens) => setTokens(tokens),
        setAuthUser: (user) => setUser(user),
        getAuthUser: () => getUser(),
        getAuthUserCategories: () => getUserCategories(),
        updateAuthUserCategory: (category, type) => updateUserCategory(category, type),
        setAuthUserImage: (image) => setUserImage(image),
        logout,
        isAuthenticated,
        getAccessToken,
        getNewToken,
        getNewTokenForRequest,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
