import { Center, Heading, Text, Box, Button, Image } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import VideoIsUploadingSrc from '../../assets/vectors/videoIsUploading.svg';

const verticalSpace = { base: '24px', '2xl': '50px' };

const VideosListEmptyForOthers = () => {
  return (
    <Center>
      <Box maxW="600px" textAlign="center">
        <Image
          src={VideoIsUploadingSrc}
          mb={verticalSpace}
          w={{ md: '314px' }}
          display="inline-block"
        />
        <Heading size="lg" mb={5}>
          { `This user doesn't have any videos yet.` }
        </Heading>
        <Text lineHeight="28px" mb={verticalSpace}>
          When the user start sharing video story alongside other eyewitness accounts of remarkable
          events. Videos will show up here.
        </Text>
        <Button
          as={RouterLink}
          to="/"
          variant="solid"
          px={{ md: '62px' }}
          mb="24px"
        >
          {/*<IconVideoAdd boxSize="24px" mr="10px" />*/}
          Back to Home Page
        </Button>
      </Box>
    </Center>
  );
};

export default VideosListEmptyForOthers;
