import { IconButton } from '@chakra-ui/react';
import { IconDelete } from '../../theme/icons/IconDelete';

const DeleteCta = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      icon={<IconDelete boxSize="16px" />}
      size="xs"
      border="solid 1px"
      borderColor="primary.dark"
      bg="white"
      _hover={{ bg: 'secondary.lighter' }}
      _active={{ bg: 'secondary.lighter' }}
      zIndex="1"
      pos="absolute"
      top="-5px"
      right="-5px"
    />
  );
};

export default DeleteCta;
