import { Box, Flex, Avatar, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconAvatar } from '../../theme/icons/IconAvatar';
import LeaveCommentForm from './LeaveCommentForm';
import { COMMENT_REPLY } from '../../util/consts';

const ReplyToComment = ({
  comment,
  handleShowFormForReply,
  myAvatarSrc,
  commentForReply,
}) => {
  return (
    <Flex ml={{ base: '40px', md: '60px' }} mt={{ base: '30px', md: '40px' }}>
      <Link to={comment.channelUrl} as={RouterLink}>
        <Avatar
          icon={
            <IconAvatar
              fontSize={{ base: '16px', md: '22px' }}
              color="secondary.lighter"
            />
          }
          src={myAvatarSrc}
          bg="secondary.light"
          boxSize={{ base: '30px', md: '40px' }}
        />
      </Link>
      <Box ml={{ base: '10px', md: '20px' }} w="full">
        <LeaveCommentForm
          comment={comment}
          commentFormType={COMMENT_REPLY}
          handleShowFormForReply={handleShowFormForReply}
          commentForReply={commentForReply}
        />
      </Box>
    </Flex>
  );
};

export default ReplyToComment;
