import { Box, Text, HStack, Image } from '@chakra-ui/react';

import { CarouselPlay } from './CarouselPlay';

import '../../helper/carouselPagination.css';

// 3rd carousel component
const CarouselImage = (props) => {
  return (
    <Box
      position="relative"
      w="100%"
      h={{ sm: '300px', md: '380px', lg: '400px', xl: '430px', '2xl': '500px' }}
    >
      <Image
        src={props.image}
        objectFit="cover"
        objectPosition="50% 50%"
        h={{
          sm: '300px',
          md: '380px',
          lg: '400px',
          xl: '430px',
          '2xl': '500px',
        }}
        borderRadius="30px"
      />
      <HStack
        w={[50, 100, 200, 300, 400, 500]}
        position="absolute"
        bottom="8%"
        left="5"
        spacing={2}
        align="flex-start"
      >
        <CarouselPlay />
        <Text
          color="white"
          fontSize={['sm', 'md', 'xl', '2xl']}
          maxW="100%"
        >
          {props.text}
        </Text>
      </HStack>
    </Box>
  );
};

export default CarouselImage;
