import { Container, Text, Box, Show, Image } from '@chakra-ui/react';
import DotsSrc from '../../assets/vectors/21dotsGray.svg';

import { useMemo, useContext } from 'react';
import VideosListLoading from '../videos/VideosListLoading';
import DoubleVideosCarousels from '../carousels/DoubleVideosCarousels';
import { FeaturedVideosContext } from '../../context/FeaturedVideosContext';
import { isFetching } from '../../util';
import { normalizedVideoData } from '../videos/VideosList';
import FlickityViewMoreVideosCTA from '../carousels/FlickityViewMoreVideosCTA';

const SectionEditorsPickWide = () => {
  const { state } = useContext(FeaturedVideosContext);
  const { status, items } = state;
  const isLoading = isFetching(status);
  const data = useMemo(() => normalizedVideoData(items), [items]);

  return (
    <Container
      bg="white"
      px={0}
      pt={{ base: '60px', '2xl': '88px' }}
      pb={{ base: '50px', '2xl': '70px' }}
    >
      <Container maxW="1562px" pos="relative">
        <Show above="lg">
          <Image
            src={DotsSrc}
            pos="absolute"
            top={{ lg: '-166px', '2xl': '-192px' }}
            left="16px"
          />
          <Image
            src={DotsSrc}
            pos="absolute"
            top={{ lg: '-44px', '2xl': '-74px' }}
            left="16px"
          />
        </Show>
        <Box>
          <Box mb={{ base: '40px', '2xl': '80px' }}>
            <Text as="h2" textStyle="sectionHeadline" textAlign="center">
              Editor’s Picks
            </Text>
            <Text textStyle="sectionText" mt="30px" textAlign="center">
              Here’s just a few of the videos we think you’d like to see
            </Text>
          </Box>

          {isLoading ? (
            <VideosListLoading startColor="secondary.light" endColor="secondary.mid" />
          ) : (
            <DoubleVideosCarousels data={data} key={Math.random()}>
              <FlickityViewMoreVideosCTA />
            </DoubleVideosCarousels>
          )}
        </Box>
      </Container>
    </Container>
  );
};

export default SectionEditorsPickWide;
