import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Box,
  VStack,
  Container,
  Text,
  Image,
} from '@chakra-ui/react';
import SuccessSrc from '../../assets/vectors/success.svg';

const ReportThankYouModal = ({ onClose, isOpen }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <VStack spacing="48px">
            <Image src={SuccessSrc} />
            <Heading size="lg" color="primary.default">
              Thank you for reporting!
            </Heading>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container p={0} maxW="404px">
            <VStack
              mt={4}
              mb={{ base: 4, md: 9 }}
              textAlign="center"
              fontSize={{ md: '20px' }}
              spacing={10}
            >
              <Text textStyle="formLabels" lineHeight="27px">
                We have received your report. Thank you for helping to keep Storyvault a
                safe space.
              </Text>
            </VStack>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Box maxW="284px" w="full">
            <Button onClick={onClose} isFullWidth>
              Got it
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReportThankYouModal;
