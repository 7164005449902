import { Icon } from '@chakra-ui/react';

export const IconMinus = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <path
      fill="currentColor"
      d="M18.4947 0.0391731C15.5305 0.320564 13.325 0.945873 10.8301 2.21212C7.87378 3.72068 5.22247 6.09686 3.28286 8.97328C2.46948 10.1926 1.46839 12.2483 0.98349 13.7022C0.600261 14.8746 0.287421 16.2112 0.0997176 17.5322C-0.0332392 18.4545 -0.0332392 21.5342 0.0997176 22.4565C0.428199 24.7623 1.03824 26.779 1.9924 28.7096C3.06387 30.8982 4.11971 32.3677 5.85597 34.1107C7.58441 35.8303 9.07822 36.9012 11.1821 37.9329C12.6524 38.652 13.5987 39.0038 15.0926 39.379C19.0031 40.3638 22.9292 40.1762 26.7458 38.824C27.9112 38.4097 29.929 37.4014 30.9692 36.7058C33.3076 35.1503 35.4506 32.9539 36.9835 30.5465C37.5466 29.6632 38.4539 27.7873 38.8214 26.7477C40.6594 21.5733 40.3387 16.0236 37.922 11.1697C36.9522 9.21559 35.8651 7.67577 34.34 6.10468C32.6507 4.35381 30.9926 3.15009 28.7793 2.0558C26.4643 0.914608 24.3057 0.297112 21.7404 0.0626221C20.9427 -0.00772476 19.136 -0.0233574 18.4947 0.0391731ZM21.6622 18.3138L25.5023 18.3529L29.3424 18.392L29.6474 18.603C29.8117 18.7203 30.0385 18.947 30.148 19.1111C30.3122 19.3534 30.3435 19.4863 30.3435 19.9944C30.3435 20.5024 30.3122 20.6353 30.148 20.8776C30.0385 21.0418 29.8117 21.2684 29.6474 21.3857L29.3424 21.5967L25.5023 21.6358L21.6622 21.6749H18.2992L14.4591 21.6358L10.619 21.5967L10.3139 21.3857C9.82903 21.0418 9.62569 20.6822 9.5944 20.0803C9.56312 19.4394 9.77428 18.9782 10.2748 18.6265L10.6111 18.392L14.4512 18.3529L18.2992 18.3138H21.6622Z"
    />
  </Icon>
);
