import { Container, Text, Box, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconLogo } from '../../theme/icons/IconLogo';

const SectionConclusion2 = () => {
  return (
    <Container bg="white" px={0} py={{ base: '60px', '2xl': '88px' }}>
      <Container maxW="1562px">
        <Container maxW="1100px" p={0} textAlign="center" pos="relative">
          <Box display="inline-block">
            <Text as="h2" textStyle="sectionHeadline">
              So don’t let your family’s stories be lost for ever!
            </Text>
            <Button
              as={RouterLink}
              to="/signup"
              w={{ base: '100%', md: 'auto' }}
              mt={{ base: '40px', md: '60px' }}
            >
              <IconLogo boxSize="20px" mr="10px" />
              Upload a video
            </Button>
          </Box>
        </Container>
      </Container>
    </Container>
  );
};

export default SectionConclusion2;
