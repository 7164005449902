import { Icon } from '@chakra-ui/react';

export const CategoryMovieIcon = (props) => (
  <Icon viewBox="0 0 49 51" fill="none" {...props}>
    <path
      d="M45.4611 0.550781C45.9509 0.804641 46.1674 1.22406 46.2927 1.75385C46.6002 3.07834 46.9762 4.40282 47.3065 5.72731C47.4774 6.38955 47.1812 6.91934 46.5091 7.0849C42.1235 8.15553 37.7265 9.20408 33.3408 10.2637C33.2953 10.2747 33.2497 10.2637 33.1586 10.2637C33.2041 10.076 33.2383 9.89943 33.2839 9.73387C33.9104 7.6147 34.5483 5.49552 35.1634 3.36531C35.266 3.02315 35.414 2.86863 35.7786 2.78033C38.6264 2.10705 41.4742 1.4117 44.3106 0.727379C44.4701 0.694267 44.6182 0.617006 44.7663 0.561819C44.9941 0.550781 45.2333 0.550781 45.4611 0.550781Z"
      fill="#616161"
    />
    <path
      d="M1.43298 27.8242C17.3238 27.8242 33.1348 27.8242 48.9914 27.8242C48.9914 28.0229 48.9914 28.1995 48.9914 28.3761C48.9914 35.2965 48.9914 42.2059 48.9914 49.1263C48.9914 50.2301 48.6611 50.5502 47.522 50.5502C32.6564 50.5502 17.8022 50.5502 2.93663 50.5502C1.77472 50.5502 1.43298 50.219 1.43298 49.1043C1.43298 42.2059 1.43298 35.3076 1.43298 28.4092C1.43298 28.2216 1.43298 28.045 1.43298 27.8242Z"
      fill="#616161"
    />
    <path
      d="M1.94464 17.9571C6.70618 17.9571 11.4791 17.9571 16.3318 17.9571C16.2293 18.1557 16.1609 18.2992 16.0812 18.4317C14.9193 20.3853 13.7574 22.3499 12.5955 24.3036C12.4701 24.5243 12.3448 24.6788 12.0259 24.6678C8.59713 24.6568 5.17975 24.6568 1.75099 24.6568C1.67125 24.6568 1.59151 24.6457 1.42064 24.6347C1.42064 24.4581 1.42064 24.2815 1.42064 24.1159C1.42064 22.4272 1.37508 20.7275 1.43203 19.0387C1.48899 17.6149 0.942209 16.3346 0.657427 14.988C0.486559 14.1823 0.235951 13.3766 0.0423003 12.5708C-0.117177 11.9086 0.178995 11.3788 0.851079 11.2132C5.23671 10.1426 9.63373 9.09406 14.0307 8.03448C14.0763 8.02344 14.1219 8.02344 14.2244 8.02344C13.9054 9.12717 13.5979 10.1978 13.2789 11.2684C12.9258 12.4825 12.5385 13.6966 12.2081 14.9218C12.1056 15.286 11.9234 15.4406 11.5475 15.5288C8.50599 16.2463 5.46453 16.9968 2.42307 17.7253C2.26359 17.7584 2.10412 17.8025 1.94464 17.8467C1.95603 17.8908 1.95603 17.924 1.94464 17.9571Z"
      fill="#616161"
    />
    <path
      d="M16.1936 24.6236C16.2733 24.4691 16.3303 24.3366 16.41 24.2152C17.5719 22.2616 18.7339 20.308 19.8958 18.3433C20.0438 18.1005 20.1805 17.957 20.5109 17.957C24.9876 17.9681 29.4758 17.9681 33.9526 17.9681C34.0323 17.9681 34.112 17.9791 34.2487 17.9901C34.169 18.1447 34.1234 18.2771 34.0551 18.3985C32.8818 20.3742 31.7199 22.3389 30.5352 24.3035C30.4327 24.4691 30.1934 24.6457 30.0226 24.6457C25.4889 24.6678 20.9665 24.6567 16.4328 24.6567C16.3645 24.6567 16.2961 24.6457 16.1936 24.6236Z"
      fill="#616161"
    />
    <path
      d="M31.6412 3.82031C31.1514 5.47592 30.6844 7.07634 30.2173 8.68779C30.0009 9.41626 29.7958 10.1558 29.5452 10.8842C29.4883 11.0388 29.3288 11.2374 29.1807 11.2705C24.7267 12.3522 20.2728 13.4228 15.8188 14.4934C15.8074 14.4934 15.796 14.4824 15.7277 14.4383C16.0238 13.4339 16.3314 12.4074 16.6276 11.3809C17.0035 10.0895 17.3794 8.78713 17.7781 7.49576C17.835 7.31916 18.0173 7.08737 18.1882 7.04322C22.608 5.96156 27.0278 4.90197 31.4476 3.83135C31.4931 3.82031 31.5273 3.83135 31.6412 3.82031Z"
      fill="#616161"
    />
    <path
      d="M49.001 24.6457C44.0116 24.6457 39.102 24.6457 34.1354 24.6457C34.2037 24.5022 34.2379 24.3808 34.3062 24.2814C35.4795 22.2947 36.6642 20.319 37.8375 18.3213C37.9856 18.0674 38.1565 17.957 38.464 17.957C41.5397 17.9681 44.6039 17.957 47.6796 17.9681C48.6023 17.9681 49.001 18.3544 49.001 19.2374C49.001 21.0254 49.001 22.8135 49.001 24.6457Z"
      fill="#616161"
    />
  </Icon>
);
