import { Icon } from '@chakra-ui/react';

export const EyeOnIcon = (props) => (
  <Icon viewBox="0 0 23 23" fill="none" width="90px">
    <g clipPath="url(#clip0_221_3133)">
      <path
        d="M1.88281 12.3594C1.88281 12.3594 5.88281 4.35938 12.8828 4.35938C19.8828 4.35938 23.8828 12.3594 23.8828 12.3594C23.8828 12.3594 19.8828 20.3594 12.8828 20.3594C5.88281 20.3594 1.88281 12.3594 1.88281 12.3594Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8828 15.3594C14.5397 15.3594 15.8828 14.0162 15.8828 12.3594C15.8828 10.7025 14.5397 9.35938 12.8828 9.35938C11.226 9.35938 9.88281 10.7025 9.88281 12.3594C9.88281 14.0162 11.226 15.3594 12.8828 15.3594Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_221_3133">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.882812 0.359375)"
        />
      </clipPath>
    </defs>
  </Icon>
);
