import { Container, Box, Text, VStack, Divider, Grid, GridItem } from '@chakra-ui/react';
import PhotoStewartSrc from '../assets/img/photos/photo-stewart.webp';
import PhotoStewartCompressedSrc from '../assets/img/photos/photo-stewart-compressed.jpg';
import PhotoStewartsDadSrc from '../assets/img/photos/photo-stewarts-dad.webp';
import PhotoStewartsDadCompressedSrc from '../assets/img/photos/photo-stewarts-dad-compressed.jpg';
import PictureOptimizedSingle from '../components/UI/PictureOptimizedSingle';

const WhoAreWePage = () => {
  return (
    <Container
      maxW="1332px"
      mt={{ base: '120px', md: '150px' }}
      mb={{ base: '50px', md: '100px' }}
    >
      <Box
        pt={{ base: '40px', lg: '60px' }}
        pb={{ base: '60px', lg: '100px' }}
        bg="white"
        borderRadius="20px"
      >
        <Container maxW="1032px">
          <Text
            as="h1"
            textStyle="headingSemiBold"
            fontSize={{ base: '32px', lg: '40px' }}
            lineHeight="150%"
            mb="40px"
          >
            Who Are We?
          </Text>
          <Text
            fontSize="22px"
            fontWeight="500"
            lineHeight="150%"
            color="primary.default"
          >
            The founder of Storyvault.com is Stuart Prebble. Stuart has had a long and
            distinguished career as a TV producer and is the author of eleven books.
          </Text>

          <Divider my={{ base: 6, md: 9 }} />

          <Grid templateColumns={{ md: '250px 1fr' }} gap={{ base: 6, md: 10 }}>
            <GridItem align="center">
              <Grid
                templateColumns={{ sm: '1fr 1fr', md: 'auto' }}
                gap={{ base: 6, md: '40px' }}
              >
                <GridItem align="center">
                  <PictureOptimizedSingle
                    alt="Stewart Prebble"
                    src={PhotoStewartSrc}
                    fallbackSrc={PhotoStewartCompressedSrc}
                  />
                </GridItem>
                <GridItem align="center">
                  <PictureOptimizedSingle
                    alt="Stewart Prebble's dad"
                    src={PhotoStewartsDadSrc}
                    fallbackSrc={PhotoStewartsDadCompressedSrc}
                  />
                </GridItem>
              </Grid>
            </GridItem>
            <GridItem>
              <VStack spacing={5} alignItems="flex-start">
                <Text
                  fontSize="22px"
                  fontWeight="500"
                  lineHeight="150%"
                  color="primary.default"
                >
                  What are his reasons for launching the Storyvault.com service?
                </Text>
                <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                  My father was orphaned aged 14, fended for himself until he was old
                  enough to join the army, became a paratrooper and fought on World War 2,
                  was wounded in battle, and later was sent to Palestine to police the
                  mandate. He married, had two sons, and worked in insurance until he
                  retired. When he died aged 74 I realised that he was the last of his
                  generation, and I would never have the opportunity to ask him any
                  details of his early life. He’s gone, and so have his memories and
                  stories. For ever.
                </Text>
                <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                  I had no excuse because I’ve been a TV producer all my working life, but
                  then I realised that none of us has any excuse for letting these stories
                  die, because most of us are walking around with a high-quality video
                  camera in our pockets. The only thing stopping us is that “we never get
                  around to it”, and by the time we think about it, it’s too late. As it
                  was for me.
                </Text>
                <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                  That’s the main reason why I launched storyvault.com. Not only can we
                  preserve our friends’ or families’ important memories to pass them on to
                  our children and grandchildren, but we can make them available to
                  students of modern history. In the past, the record of our times has
                  been written by academics or politicians, or the victors in battle. But
                  now there’s absolutely no reason why the stories of ordinary people
                  shouldn’t be preserved too. Storyvault.com truly is an idea whose time
                  has come.
                </Text>
                <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                  We want storyvault.com to become a living resource for students of
                  history or media or IT, allowing them to access first-hand witness
                  accounts of the incidents that tell us who we are and make up our lives.
                </Text>
              </VStack>
            </GridItem>
          </Grid>
          <Divider my={{ base: 6, md: 9 }} />
          <Text
            fontSize="22px"
            fontWeight="500"
            lineHeight="150%"
            color="primary.default"
          >
            We like to think that storyvault.com is your chance to give someone you care
            about a very unusual gift – immortality.
          </Text>
        </Container>
      </Box>
    </Container>
  );
};

export default WhoAreWePage;
