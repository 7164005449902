import { useNavigate, useParams } from 'react-router-dom';
import { Center, VStack, Box, Image, Spinner, Heading } from '@chakra-ui/react';
import CheckEmail from '../assets/img/checkyouremail.svg';
import { useContext, useEffect } from 'react';
import { HttpContext } from '../context/HttpContext';
import { AuthContext } from '../context/AuthContext';

import purpledottedtriangleSrc from '../assets/img/purpledottedtriangle.svg';
import elipseSrc from '../assets/img/elipse.svg';

const SignupConfirmationPage = () => {
  const { confirmationtoken } = useParams();
  const navigate = useNavigate();
  const { publicAxios } = useContext(HttpContext);
  const { setAuthState } = useContext(AuthContext);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: responseData } = await publicAxios.post(
          `/user_confirmation`,
          {
            confirmationToken: confirmationtoken,
          }
        );

        // console data
        console.log(responseData);

        const { token, user } = responseData;
        console.log({ token, user });

        setAuthState({ token, user, refreshToken: '' });

        // post-success UI events
        navigate('/home');
      } catch (error) {
        console.log(error);
        navigate('/');
      }
    };

    getData();
  }, [confirmationtoken, navigate, publicAxios, setAuthState]);

  return (
    <Center minH="94vh" pt={10} pb={{ base: '40px', lg: '0' }}>
      <Box maxW="full" pos="relative" pb={{ lg: '120px' }}>
        <Box
          textAlign="center"
          position="relative"
          zIndex="1"
          bgColor="white"
          pt={{ base: '48px', '2xl': '70px' }}
          pb={{ base: '64px', '2xl': '120px' }}
          px="16px"
          borderRadius="20px"
          w="740px"
          maxW="full"
        >
          <Image
            src={purpledottedtriangleSrc}
            display={{ base: 'none', lg: 'block' }}
            pos="absolute"
            top={{ base: '-70px', '2xl': '-70px' }}
            right="-70px"
          />
          <VStack spacing={10} p={50}>
            <Image src={CheckEmail} />
            <Heading size="lg" color="primary.default" pt={{ '2xl': '30px' }}>
              Verifying your email...
            </Heading>
            <Spinner size="xl" />
          </VStack>
        </Box>
        <Image
          src={elipseSrc}
          display={{ base: 'none', lg: 'block' }}
          pos="absolute"
          bottom="20px"
          right="-100px"
          w="400px"
        />
      </Box>
    </Center>
  );
};

export default SignupConfirmationPage;
