import { Icon } from '@chakra-ui/react';

export const IconUsers = (props) => (
  <Icon viewBox="0 0 48 41" {...props}>
    <path
      fill="currentColor"
      d="M2.90119 27.9815C4.74015 26.1354 7.23574 25.097 9.83931 25.097H26.0283C28.6318 25.097 31.1274 26.1354 32.9664 27.9815C34.8051 29.8274 35.8369 32.3295 35.8369 34.9371V39.0001C35.8369 39.9468 35.0694 40.7143 34.1227 40.7143C33.176 40.7143 32.4085 39.9468 32.4085 39.0001V34.9371C32.4085 33.2345 31.7347 31.6029 30.5374 30.401C29.3404 29.1993 27.7183 28.5254 26.0283 28.5254H9.83931C8.14931 28.5254 6.52718 29.1993 5.33013 30.401C4.13286 31.6029 3.45904 33.2345 3.45904 34.9371V39.0001C3.45904 39.9468 2.69157 40.7143 1.74484 40.7143C0.798114 40.7143 0.0306396 39.9468 0.0306396 39.0001V34.9371C0.0306396 32.3295 1.06244 29.8274 2.90119 27.9815Z"
    />
    <path
      fill="currentColor"
      d="M17.914 4.1427C14.3965 4.1427 11.5337 7.00705 11.5337 10.5544C11.5337 14.1018 14.3965 16.9661 17.914 16.9661C21.4314 16.9661 24.2942 14.1018 24.2942 10.5544C24.2942 7.00705 21.4314 4.1427 17.914 4.1427ZM8.1053 10.5544C8.1053 5.12614 12.4905 0.714294 17.914 0.714294C23.3374 0.714294 27.7226 5.12614 27.7226 10.5544C27.7226 15.9827 23.3374 20.3945 17.914 20.3945C12.4905 20.3945 8.1053 15.9827 8.1053 10.5544Z"
    />
    <path
      fill="currentColor"
      d="M38.5249 26.6311C38.7625 25.7147 39.698 25.1643 40.6144 25.4018C42.72 25.9476 44.5841 27.1796 45.9148 28.9029C47.2455 30.6261 47.9677 32.7432 47.9693 34.9218L47.9693 38.986C47.9693 39.9327 47.2019 40.7002 46.2551 40.7002C45.3084 40.7002 44.5409 39.9327 44.5409 38.986V34.9243C44.5409 34.9242 44.5409 34.9244 44.5409 34.9243C44.5398 33.5021 44.0682 32.121 43.2013 30.9983C42.3344 29.8757 41.1216 29.075 39.7542 28.7206C38.8377 28.483 38.2874 27.5475 38.5249 26.6311Z"
    />
    <path
      fill="currentColor"
      d="M30.4494 2.26514C30.6851 1.34822 31.6194 0.795963 32.5364 1.03164C34.6476 1.5743 36.5175 2.80658 37.8522 4.53272C39.1868 6.25874 39.9105 8.38064 39.9105 10.5639C39.9105 12.7471 39.1868 14.869 37.8522 16.595C36.5175 18.3211 34.6476 19.5534 32.5364 20.0961C31.6194 20.3318 30.6851 19.7795 30.4494 18.8626C30.2137 17.9457 30.766 17.0113 31.6829 16.7756C33.054 16.4232 34.2705 15.6224 35.14 14.4979C36.0095 13.3733 36.4821 11.9892 36.4821 10.5639C36.4821 9.13849 36.0095 7.75442 35.14 6.62983C34.2705 5.50536 33.054 4.70453 31.6829 4.35211C30.766 4.11643 30.2137 3.18206 30.4494 2.26514Z"
    />
  </Icon>
);
