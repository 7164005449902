import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Box,
  Input,
  FormControl,
  VStack,
  HStack,
  SimpleGrid,
  GridItem,
  InputGroup,
  InputLeftElement,
  useMediaQuery,
  useToast,
  useClipboard,
} from '@chakra-ui/react';
import { IconLink } from '../../theme/icons/IconLink';
import { IconCopy } from '../../theme/icons/IconCopy';
import { IconCopied } from '../../theme/icons/IconCopied';
import CustomToast from '../UI/CustomToast';
import SocialMediaShareBoxes from '../common/SocialMediaShareBoxes';

const ShareModal = ({ isOpen, onClose, title, video }) => {
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const urlToShare = window.location.href;
  const { hasCopied, onCopy } = useClipboard(urlToShare);
  const toast = useToast();

  const handleOnCopyLink = async (e) => {
    try {
      e.preventDefault();
      onCopy();

      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="success"
            description="Copied!"
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg" color="primary.default">
            {title}
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing="40px" mt={4} mb={4}>
            <HStack spacing={10}>
              <SocialMediaShareBoxes video={video} />
            </HStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Box maxW="450px" w="full">
            <Heading size="lg" color="primary.default" mb={10} textAlign="center">
              Copy link
            </Heading>
            <HStack>
              <SimpleGrid w="full" maxW="450px" columns={3} columnGap={5} rowGap={5}>
                <GridItem colSpan={isLargerThanMD ? '2' : '3'}>
                  <FormControl>
                    <InputGroup>
                      <Input
                        id="copyLink"
                        type="text"
                        name="copyLink"
                        placeholder=""
                        defaultValue={urlToShare}
                        isReadOnly
                      />
                      <InputLeftElement
                        pointerEvents="none"
                        color="primary.default"
                        fontSize="22px"
                        children={<IconLink />}
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={isLargerThanMD ? '1' : '3'}>
                  <Button isFullWidth py="25px" onClick={handleOnCopyLink}>
                    {hasCopied ? (
                      <IconCopied boxSize="28px" mr="10px" />
                    ) : (
                      <IconCopy boxSize="24px" mr="10px" />
                    )}
                    {hasCopied ? 'Copied' : 'Copy'}
                  </Button>
                </GridItem>
              </SimpleGrid>
            </HStack>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
