import React, { useContext, useEffect } from 'react';
import { HttpContext } from '../context/HttpContext';
import {
  FeaturedVideosContext,
  INITIAL_FEATURED_VIDEOS_PERP_AGE,
} from '../context/FeaturedVideosContext';

function UseFeaturedVideos({ children }) {
  const { publicAxios } = useContext(HttpContext);
  const { updateState, state } = useContext(FeaturedVideosContext);
  const itemsPerPage = state?.perPage ?? INITIAL_FEATURED_VIDEOS_PERP_AGE;

  useEffect(() => {
    const getData = async () => {
      try {
        updateState({
          ...state,
          status: 'pending',
        });

        const { data } = await publicAxios.get(
          `/videos?page=1&itemsPerPage=${itemsPerPage}&featured=true`,
          {
            headers: { Accept: 'application/ld+json' },
          }
        );
        const total = data['hydra:totalItems'];
        const items = data['hydra:member'];

        updateState({
          items,
          total,
          status: 'resolved',
          error: null,
        });
      } catch (onError) {
        console.log(onError);
        updateState({
          items: [],
          total: 0,
          status: 'rejected',
          error: 'Something went wrong',
        });
      }
    };

    getData();
  }, [publicAxios]);

  return <>{children}</>;
}

export default UseFeaturedVideos;
