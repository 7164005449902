import { Icon } from '@chakra-ui/react';

export const CategoryGhostIcon = (props) => (
  <Icon viewBox="0 0 43 51" fill="none" {...props}>
    <path
      d="M23.222 50.293C22.081 50.293 20.9285 50.293 19.7875 50.293C19.7414 50.2706 19.7068 50.2371 19.6607 50.226C17.9204 49.8574 16.1801 49.4889 14.4398 49.1092C13.2757 48.8524 12.1117 48.5732 10.9015 48.8077C8.75782 49.2209 6.61412 49.6452 4.48196 50.0473C2.41894 50.4381 0.471175 49.1985 0.0793177 47.1995C-0.0474599 46.5742 0.0101661 45.9041 0.0332166 45.2564C0.171519 41.8726 0.344398 38.4888 0.494226 35.0939C0.713205 30.1466 0.932184 25.1994 1.13964 20.241C1.22031 18.2978 1.42777 16.377 1.98098 14.5008C5.2772 3.34442 17.8973 -2.67491 29.0883 1.75863C36.4875 4.6957 40.7288 10.2013 41.6623 17.907C42.0657 21.2014 42.0427 24.5405 42.1925 27.8685C42.3539 31.3527 42.5037 34.837 42.665 38.3213C42.7803 41.035 42.9416 43.7599 42.9993 46.4848C43.0454 48.8188 40.8902 50.5052 38.5275 50.0585C37.0292 49.7793 35.5309 49.4666 34.0326 49.1762C32.6957 48.9194 31.3703 48.562 29.9988 48.83C29.3765 48.9529 28.7541 49.0869 28.1317 49.2209C26.4836 49.5671 24.847 49.9356 23.222 50.293ZM23.6484 22.2065C23.683 24.0268 24.0748 25.7578 25.1352 27.2989C27.1982 30.2695 30.8517 30.2695 32.9262 27.2989C33.8021 26.037 34.2401 24.6299 34.3669 23.1334C34.5513 21.0004 34.1825 18.979 32.9377 17.1699C30.8632 14.1658 27.1982 14.1658 25.1236 17.1587C24.0748 18.6887 23.683 20.3973 23.6484 22.2065ZM19.3495 22.2065C19.338 20.2968 18.8078 18.4988 17.8397 17.1252C15.8343 14.2663 12.3537 14.177 10.233 16.9465C8.85002 18.7445 8.45816 20.8217 8.60799 23.0105C8.73476 24.7639 9.26493 26.3943 10.429 27.7903C12.4113 30.1466 15.5692 30.1243 17.5285 27.7456C18.8539 26.104 19.3034 24.2167 19.3495 22.2065Z"
      fill="#616161"
    />
  </Icon>
);
