import {
  Text,
  SimpleGrid,
  GridItem,
  Box,
  Container,
  Heading,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';

const colors = ['primary.default', 'primary.dark', 'primary.light'];
const textStyles = [
  'headingSemiBold',
  'bodySemiBold',
  'bodyMedium',
  'bodyMediumSmall',
  'bodyRegular',
  'formLabels',
];

function Typography() {
  return (
    <Container
      bg={useColorModeValue('white', 'primaryDark')}
      borderBottom="solid 1px #DDD"
    >
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 1, md: 2 }} columnGap={4} rowGap={4}>
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <Box fontSize="24px" my={4}>
              Font family: Poppins
            </Box>
            <VStack alignItems="flex-start">
              <Text size="lg" fontFamily="Poppins Bold, sans-serif">
                Poppins Bold (700, buttons)
              </Text>
              <Heading size="lg">Poppins SemiBold (600, Heading, lg)</Heading>
              <Text textStyle="bodyMedium">Poppins Medium (500, body)</Text>
              <Text>Poppins Regular (400, body)</Text>
            </VStack>
          </GridItem>

          <GridItem>
            <Box fontSize="20px" my={4}>
              Text colors
            </Box>
            <VStack alignItems="flex-start">
              {colors.map((color, index) => {
                return (
                  <Text key={index} color={color}>
                    {color}
                  </Text>
                );
              })}
            </VStack>
          </GridItem>

          <GridItem>
            <Box fontSize="20px" my={4}>
              Text styles
            </Box>
            <VStack alignItems="flex-start">
              {textStyles.map((textStyle, index) => {
                return (
                  <Text key={index} textStyle={textStyle}>
                    {textStyle}
                  </Text>
                );
              })}
            </VStack>
          </GridItem>
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default Typography;
