import { SimpleGrid, GridItem, Container, Tag, TagLabel, Link } from '@chakra-ui/react';

const EmailExemplars = () => {
  const emailNumbers = [
    { number: '3', name: 'Shared video' },
    { number: '8', name: 'Request access' },
  ];

  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 1, sm: 2, md: 4 }} columnGap={4} rowGap={4}>
          {emailNumbers.map((email, index) => {
            return (
              <GridItem key={index}>
                <Link
                  href={`/assets/emails/exemplars/e${email.number}.html`}
                  isExternal
                  textDecoration="none !important"
                >
                  <Tag>
                    <TagLabel>
                      e{email.number} - {email.name}
                    </TagLabel>
                  </Tag>
                </Link>
              </GridItem>
            );
          })}
        </SimpleGrid>
      </Container>
    </Container>
  );
};

export default EmailExemplars;
