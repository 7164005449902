import { Box, Flex, useMediaQuery } from '@chakra-ui/react';

import LayoutRightSideTemplate from '../components/UI/LayoutRightSideTemplate';
import Carousel from '../components/Carousel/Carousel';

const LayoutAuth = (props) => {
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  return (
    <Box>
      {/* display only signup || login on tablet and less */}
      {!isLargerThanMD && <Box>{props.children}</Box>}
      {/* display both signup/login and right side part on tablet and more */}
      {isLargerThanMD && (
        <Flex w="100%">
          {/* signup || login */}
          <Box w="50%">
            {props.children}
          </Box>
          <Box w="50%" position="relative" minH="100vh" overflow="hidden">
            {/* triangle and circle layout */}
            <LayoutRightSideTemplate />
            {/* carousel component */}
            <Box
              width="70%"
              height="30%"
              position="absolute"
              top="15%"
              right="15%"
              zIndex="10"
            >
              <Carousel />
            </Box>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default LayoutAuth;
