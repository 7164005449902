import { Container } from '@chakra-ui/react';
import HeroFAQ from '../components/heroes/HeroFAQ';
import AccordionComponent from '../components/common/AccordionComponent';
import { FAQ_ITEMS } from '../util/static';

const FAQPage = () => {
  return (
    <>
      <HeroFAQ />

      <Container
        maxW="882px"
        mt={{ base: '40px', lg: '75px' }}
        mb={{ base: '80px', lg: '120px' }}
      >
        <AccordionComponent items={FAQ_ITEMS} />
      </Container>
    </>
  );
};

export default FAQPage;
