import { AspectRatio, Flex, Progress, Image, FormLabel, Text } from '@chakra-ui/react';
import VideoIsUploadingSrc from '../../assets/vectors/videoIsUploading.svg';

const VideoIsProcessing = ({ componentSize, borderRadius, progressSize, imgW, title }) => {
  return (
    <AspectRatio ratio={16 / 9}>
      <Flex flexDir="column" bg="secondary.lighter" borderRadius={borderRadius}>
        <Image
          src={VideoIsUploadingSrc}
          mb={componentSize === 'large' ? 6 : 4}
          w={imgW ?? { base: '140px', sm: 'auto' }}
        />
        {componentSize === 'large' ? (
          <FormLabel textStyle="formLabels" color="primary.dark" mr={0}>
            {title ?? "Video is being processed."}
          </FormLabel>
        ) : (
          <Text
            color="primary.dark"
            lineHeight="22px"
            textStyle="bodyMedium"
            textAlign="center"
          >
            {title ?? "Video is being processed."}
          </Text>
        )}
        <Progress
          className="progress-slow"
          isIndeterminate
          w="full"
          pos="absolute"
          bottom="0"
          size={progressSize}
        />
      </Flex>
    </AspectRatio>
  );
};

export default VideoIsProcessing;
