import { Icon } from '@chakra-ui/react';

export const IconLogo = (props) => (
  <Icon viewBox="0 0 21 20" {...props}>
    <path
      fill="currentColor"
      d="M-4.37114e-07 10L15 1.33974L15 18.6603L-4.37114e-07 10Z"
    />
    <rect fill="currentColor" x="18" y="1" width="3" height="18" />
  </Icon>
);
