import { Icon } from '@chakra-ui/react';

export const IconCheckOK = (props) => (
  <Icon viewBox="0 0 41 41" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.6376 4.41202C24.2478 2.90162 20.4606 2.52744 16.8408 3.34529C13.221 4.16314 9.96254 6.1292 7.55139 8.95024C5.14024 11.7713 3.7056 15.2962 3.46143 18.9992C3.21725 22.7022 4.17663 26.3849 6.19647 29.4981C8.21632 32.6113 11.1884 34.9882 14.6695 36.2743C18.1506 37.5604 21.9541 37.6867 25.5129 36.6345C29.0716 35.5823 32.1949 33.4079 34.4169 30.4356C36.6389 27.4633 37.8406 23.8524 37.8427 20.1413V18.4305C37.8427 17.6599 38.4674 17.0351 39.2381 17.0351C40.0087 17.0351 40.6334 17.6599 40.6334 18.4305V20.1421C40.631 24.455 39.2344 28.6523 36.6521 32.1066C34.0697 35.5609 30.44 38.0879 26.3041 39.3107C22.1683 40.5335 17.7479 40.3867 13.7024 38.8921C9.65678 37.3974 6.20273 34.6351 3.85534 31.017C1.50796 27.399 0.393005 23.119 0.676776 18.8155C0.960548 14.512 2.62784 10.4156 5.42998 7.13706C8.23213 3.85855 12.019 1.57368 16.2258 0.623209C20.4326 -0.327263 24.834 0.107591 28.7734 1.86292C29.4773 2.17656 29.7937 3.00146 29.4801 3.70537C29.1664 4.40929 28.3415 4.72567 27.6376 4.41202Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.2242 4.27128C40.7694 4.81592 40.7699 5.69941 40.2252 6.2446L21.6206 24.8678C21.3589 25.1297 21.004 25.2769 20.6338 25.277C20.2636 25.2771 19.9085 25.1301 19.6468 24.8683L14.0654 19.2869C13.5204 18.742 13.5204 17.8585 14.0654 17.3136C14.6103 16.7687 15.4938 16.7687 16.0387 17.3136L20.6329 21.9079L38.2509 4.27226C38.7956 3.72707 39.679 3.72663 40.2242 4.27128Z"
    />
  </Icon>
);
