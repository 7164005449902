import { Img, HStack, Circle, Box } from '@chakra-ui/react';
import { useMemo, useContext, useRef, useEffect } from 'react';
import Flickity from 'react-flickity-component';
import './flickity.css';
import { IconOpenInFull } from '../../theme/icons/IconOpenInFull';
import FlickityHorizontalControls from './FlickityHorizontalControls';
import FlickityControlsContext from '../../context/FlickityControlsContext';
import { getImageUrlFromVideo } from '../../util';

const flickityOptions = {
  pageDots: false,
  prevNextButtons: false,
  cellAlign: 'left',
  // contain: true,
};

const FlickityHorizontal = ({ photos }) => {
  const flickityControlsCtx = useContext(FlickityControlsContext);
  const numberOfItems = photos.length;

  let flkty = useRef(null);

  useEffect(() => {
    flkty?.current.on('select', () => {
      flickityControlsCtx.updateSelectedIndex(flkty.current.selectedIndex);
    });
    flkty?.current.on('staticClick', (event, pointer, cellElement, cellIndex) => {
      flickityControlsCtx.updateInitialIndexForGallery(cellIndex);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flkty.current]);

  return useMemo(() => {

    const flktyPrev = () => {
      flkty.current.previous();
    };

    const flktyNext = () => {
      flkty.current.next();
    };

    return (
      <>
        <Flickity
          // flickityRef={(c) => (flkty = c)}
          flickityRef={(c) => (
            // eslint-disable-next-line no-sequences
            (flkty.current = c)
          )}
          className={'flickity-horizontal'} // default ''
          elementType={'div'} // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
          static // default false
        >
          {photos.map((photo, index) => {
            return (
              <Box key={index} role="group">
                <Circle
                  pos="absolute"
                  w="30px"
                  h="30px"
                  bg="rgba(36, 6, 48, 0.4)"
                  color="secondary.light"
                  top="10px"
                  right="30px"
                  pointerEvents="none"
                  opacity="0"
                  transition="0.3s ease-in-out"
                  _groupHover={{ opacity: '1' }}
                >
                  <IconOpenInFull fontSize="16px" />
                </Circle>
                <Img
                  src={getImageUrlFromVideo(photo, 'width_440')}
                  alt=""
                  h={{ base: '180px', sm: '220px' }}
                  maxW="none"
                  borderRadius="10px"
                  mr={5}
                  cursor="pointer"
                />
              </Box>
            );
          })}
        </Flickity>
        <HStack spacing="10px" mt={5} justifyContent="flex-end">
          <FlickityHorizontalControls
            onClickPrev={flktyPrev}
            onClickNext={flktyNext}
            numberOfItems={numberOfItems}
          />
        </HStack>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfItems, photos]);
};

export default FlickityHorizontal;
