import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
  Center,
  VStack,
  Box,
  Image,
  Text,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input,
  FormErrorMessage,
  Button,
  Link,
  useToast,
  Heading,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { EmailIcon } from '../assets/icons/EmailIcon';
import lock from '../assets/img/resetpasswordlock.svg';
import axios from '../api/axios';

import CustomToast from '../components/UI/CustomToast';

import purpledottedtriangleSrc from '../assets/img/purpledottedtriangle.svg';
import elipseSrc from '../assets/img/elipse.svg';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  // yup schema and hook form setup
  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Please enter a valid email address')
      .email('Please enter a valid email address'),
  });

  const formOptions = {
    resolver: yupResolver(schema),
    mode: 'onChange',
  };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  // submit handle
  const submitForm = async (data) => {
    const { email } = data;

    try {
      await axios.post(
        '/forgot_passwords',
        JSON.stringify({ email: email }),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      // post-success UI events
      navigate('/check-email');
    } catch (error) {
      console.log(error);

      // post-error UI events
      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="error"
            description="Sorry, we don’t recognize that email address. Please check your email address and try again."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    }
  };

  return (
    <Center minH="94vh" pt={10} pb={{ base: '40px', lg: '0' }}>
      <Box maxW="full" pos="relative" pb={{ lg: '120px' }}>
        <Box
          textAlign="center"
          position="relative"
          zIndex="1"
          bgColor="white"
          py={{ base: '32px', '2xl': '70px' }}
          px="16px"
          borderRadius="20px"
          w="740px"
          maxW="full"
        >
          <Image
            src={purpledottedtriangleSrc}
            display={{ base: 'none', lg: 'block' }}
            pos="absolute"
            top={{ base: '-70px', '2xl': '-70px' }}
            right="-70px"
          />
          <form onSubmit={handleSubmit(submitForm)}>
            <VStack spacing="30px">
              <Image src={lock} />
              <Heading size="lg" color="primary.default">
                Forgot your password?
              </Heading>
              <Box maxW="500px" w="full">
                <Text fontSize="16px" lineHeight="28px">
                  Fill in your email address and we'll send you the instructions
                  for choosing the new password.
                </Text>
              </Box>
              <Box maxW="500px" w="full" py={{ '2xl': '30px' }}>
                <FormControl isInvalid={errors.email}>
                  <FormLabel htmlFor="email" textStyle="formLabels">
                    Email
                  </FormLabel>
                  <InputGroup>
                    <Input
                      id="email"
                      type="email"
                      placeholder="example@mail.com"
                      {...register('email', { required: true })}
                    />
                    <InputLeftElement
                      pointerEvents="none"
                      color={errors.email ? 'error.default' : 'primary.light'}
                      fontSize="22px"
                      children={<EmailIcon />}
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>
              </Box>
              <Button
                type="submit"
                isFullWidth
                maxW="500px"
                isLoading={isSubmitting}
                // disabled={!isDirty || !isValid || isSubmitting}
              >
                Reset password
              </Button>
            </VStack>
            <Box mt="20px">
              <Link
                as={RouterLink}
                to="/login"
                textDecoration="underline"
                color="accent.default"
                textStyle="bodySemiBold"
              >
                Log in
              </Link>
            </Box>
          </form>
        </Box>
        <Image
          src={elipseSrc}
          display={{ base: 'none', lg: 'block' }}
          pos="absolute"
          bottom="20px"
          right="-100px"
          w="400px"
        />
      </Box>
    </Center>
  );
};

export default ForgotPasswordPage;
