import { Fragment } from 'react';

import { Container } from '@chakra-ui/react';

import LayoutHeaderPlain from './LayoutHeaderPlain';

// Layout component used for SignupConfirmationPage, SignupCheckEmailPage, ForgotPasswordPage, PasswordCheckEmailPage, ResetPasswordPage, PickCategoriesPage

const LayoutPlain = (props) => {
  return (
    <Fragment>
      <Container bgColor="secondary.lighter">
        <LayoutHeaderPlain />
        {props.children}
      </Container>
      {/* 
      Triangle dots and circle background - needs modification and responsiveness
      <Box width='500px'>
        <LayoutTriangleAndCircle />
      </Box> 
      */}
    </Fragment>
  );
};

export default LayoutPlain;
