import { Container, Text, Box } from '@chakra-ui/react';

const SectionDofeSecond = () => {
  return (
    <Box bg="white">
      <Container
        maxW="1562px"
        py={{ base: '40px', md: '80px', lg: '120px' }}
        pos="relative"
        overflow="hidden"
      >
        {/* <Show above="2xl">
        <Image src={DotsSrc} pos="absolute" top="24px" left="16px" />
      </Show> */}
        <Container maxW="900px" p={0} textAlign="center" pos="relative">
          <Box display="inline-block" textAlign="center">
            <Text as="h2" textStyle="sectionHeadline">
              How many times have you heard someone telling an amazing story and thought
              <i>“we should be recording this before it’s too late?”</i>
            </Text>
            {/* <Box w="120px" h="3px" bg="accent.default" mt="30px"></Box> */}
          </Box>
        </Container>
      </Container>
    </Box>
  );
};

export default SectionDofeSecond;
