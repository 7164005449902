import { Icon } from '@chakra-ui/react';

export const CategoryNarrowIcon = (props) => (
  <Icon viewBox="0 0 60 51" fill="none" {...props}>
    <path
      d="M59.4945 46.1539C52.061 33.8627 44.6275 21.6713 37.0907 9.3801C35.542 6.88188 34.0966 4.38366 32.548 1.98537C31.8253 0.786227 30.4831 0.286583 29.2442 0.686299C28.4182 0.986085 27.902 1.48573 27.3858 2.18523C22.0172 11.0789 16.6485 19.8726 11.1766 28.7663C7.66632 34.5622 4.15605 40.358 0.542527 46.2538C0.129553 46.8534 -0.0769335 47.5529 0.0263099 48.2524C0.33604 49.6514 1.36847 50.5508 3.02037 50.5508H50.3058C52.4739 50.5508 54.7453 50.5508 56.9134 50.5508C58.2556 50.5508 59.288 49.8513 59.701 48.7521C60.2172 47.7528 60.0107 46.9533 59.4945 46.1539ZM35.3355 39.6585L27.4891 45.8541L24.1853 37.7599L27.6955 40.0583L30.2766 31.2645L23.9788 34.6621L26.8696 16.9747L33.5804 16.8748L29.6572 27.5672L38.3296 23.9697L30.7928 40.5579L35.3355 39.6585Z"
      fill="#E5A80A"
    />
  </Icon>
);
