import { Icon } from '@chakra-ui/react';

export const CategoryChanceIcon = (props) => (
  <Icon viewBox="0 0 57 51" fill="none" {...props}>
    <path
      d="M0.0126449 2.36386C0.391989 1.40902 0.935717 0.627783 2.02317 0.379772C2.32665 0.305369 2.64277 0.292969 2.95889 0.292969C9.74916 0.292969 16.5268 0.292969 23.3171 0.292969C25.1632 0.292969 26.2633 1.35941 26.2633 3.16989C26.2633 9.82897 26.2633 16.4756 26.2633 23.1347C26.2633 24.9452 25.1632 26.024 23.3171 26.0364C16.5268 26.0488 9.74916 26.0364 2.95889 26.0364C1.41622 26.0364 0.455214 25.2924 0 23.8788C0.0126448 16.6989 0.0126449 9.53135 0.0126449 2.36386ZM16.0463 12.9539C16.0463 11.4534 14.8071 10.2258 13.2897 10.2382C11.7976 10.2506 10.5711 11.4534 10.5711 12.9291C10.5711 14.4172 11.8229 15.6572 13.3276 15.6324C14.8324 15.62 16.0463 14.4172 16.0463 12.9539ZM17.6901 6.00961C17.7028 7.47287 18.9293 8.67572 20.4214 8.67572C21.9388 8.67572 23.178 7.44807 23.1653 5.96001C23.1527 4.49674 21.9388 3.30629 20.434 3.30629C18.8914 3.30629 17.6775 4.50914 17.6901 6.00961ZM3.11063 20.295C3.11063 21.7707 4.32453 22.9735 5.81662 22.9859C7.334 22.9983 8.58583 21.7831 8.58583 20.295C8.58583 18.8317 7.37193 17.6289 5.8672 17.6165C4.33717 17.6041 3.11063 18.7945 3.11063 20.295ZM23.1653 20.295C23.1653 18.8193 21.9514 17.6289 20.4467 17.6165C18.9167 17.6041 17.6901 18.8069 17.6901 20.3074C17.6901 21.7831 18.904 22.9859 20.4087 22.9983C21.9261 22.9983 23.1653 21.7831 23.1653 20.295ZM3.11063 5.99721C3.11063 7.47287 4.32453 8.67572 5.81662 8.68812C7.32135 8.70052 8.58583 7.47287 8.58583 5.98481C8.58583 4.50914 7.35929 3.3187 5.8672 3.30629C4.32453 3.29389 3.09798 4.48434 3.11063 5.99721Z"
      fill="#0096FF"
    />
    <path
      d="M22.7976 36.9112C22.9999 36.3904 23.1264 35.8324 23.4172 35.3736C26.983 29.6693 30.5615 23.9775 34.14 18.2981C35.0757 16.81 36.5931 16.4504 38.1105 17.3556C43.9524 20.8774 49.7817 24.3991 55.6109 27.9333C57.0398 28.8013 57.4065 30.3018 56.5213 31.703C52.9302 37.4321 49.3391 43.1611 45.7353 48.8777C44.7996 50.3658 43.2822 50.7006 41.7522 49.783C35.9482 46.286 30.1442 42.7643 24.3403 39.2673C23.4678 38.7217 22.9367 37.9901 22.7976 36.9112ZM37.592 26.408C39.1094 26.3956 40.336 25.1679 40.3107 23.6799C40.2854 22.2166 39.0462 21.0262 37.5415 21.0386C36.0367 21.051 34.8102 22.291 34.8355 23.7791C34.8481 25.2423 36.0747 26.4204 37.592 26.408ZM39.5899 43.3595C39.552 44.8476 40.7533 46.0628 42.2959 46.1124C43.7501 46.1496 45.0272 44.922 45.0651 43.4711C45.1031 41.9954 43.8765 40.7554 42.3465 40.7306C40.8797 40.6934 39.6279 41.8838 39.5899 43.3595ZM37.4656 33.4515C37.4277 34.9396 38.6542 36.1672 40.1842 36.192C41.6384 36.2168 42.9282 34.9892 42.9535 33.5383C42.9787 32.0626 41.7522 30.8226 40.2222 30.8102C38.7301 30.7854 37.5035 31.9634 37.4656 33.4515Z"
      fill="#AEAEAE"
    />
  </Icon>
);
