import {
  Container,
  Text,
  Box,
  Image,
  Grid,
  GridItem,
  Flex,
  Show,
  Circle,
  Button,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconLogo } from '../../theme/icons/IconLogo';
import PhotoSrc from '../../assets/img/photos/photo-doea-01.webp';
import PhotoCompressedSrc from '../../assets/img/photos/photo-doea-01.jpg';
import DotsSrc from '../../assets/vectors/purpleLightDottedTriangle.svg';
import PictureOptimizedSingle from '../UI/PictureOptimizedSingle';

const SectionDofeFirst = ({isAuthenticated}) => {
  return (
    <Container bg="secondary.lighter" px={0} py={{ base: '60px', '2xl': '140px' }}>
      <Container maxW="1562px">
        <Grid
          templateColumns={{ md: 'repeat(2, 1fr)' }}
          columnGap={{ md: '32px', lg: '100px' }}
          rowGap={10}
          alignItems="center"
        >
          <GridItem order={{ md: 2 }}>
            <Flex pos="relative" justifyContent={{ base: 'center', md: 'left' }}>
              <Show above="2xl">
                <Image src={DotsSrc} pos="absolute" top="-60px" right="0px" />
                <Circle
                  pos="absolute"
                  bottom="-120px"
                  left="-120px"
                  bg="#E7E6EB"
                  size="400px"
                ></Circle>
              </Show>
              <PictureOptimizedSingle
                src={PhotoSrc}
                fallbackSrc={PhotoCompressedSrc}
                borderRadius="10px"
                width="648px"
                position="relative"
                zIndex="1"
              />
            </Flex>
          </GridItem>
          <GridItem order={{ md: 1 }}>
            <Box maxW="670px">
              <Box display="inline-block" textAlign="left">
                <Text as="h2" textStyle="sectionHeadline">
                  Help us to create a living archive of the memories of ordinary and
                  extraordinary people
                </Text>
              </Box>
              <Text textStyle="sectionText" mt="40px">
                Storyvault.com encourages young people to find and record a video
                interview with people with an interesting story from their own lives. They
                may be a family member, or a friend, neighbour, or anyone else with a
                story to tell
              </Text>
              { !isAuthenticated && <Button as={RouterLink} to="/signup" mt={{base: '40px', md: '60px'}}>
                <IconLogo boxSize="20px" mr="10px"/>
                Join Storyvault
              </Button>}
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Container>
  );
};

export default SectionDofeFirst;
