import { Icon } from '@chakra-ui/react';

export const IconLink = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4169 3.08093C23.485 1.08167 26.2549 -0.0245917 29.1299 0.000414898C32.005 0.0254215 34.7553 1.17969 36.7883 3.21462C38.8214 5.24955 39.9746 8.00232 39.9996 10.88C40.0246 13.7578 38.9193 16.5302 36.9219 18.6002L36.8997 18.6228L31.4172 24.1103C31.4171 24.1104 31.417 24.1105 31.417 24.1105C30.3054 25.2235 28.9677 26.0841 27.4946 26.6342C26.0213 27.1842 24.4472 27.4107 22.8788 27.2983C21.3104 27.1859 19.7846 26.7371 18.4047 25.9826C17.0249 25.228 15.8233 24.1852 14.8814 22.925C14.2769 22.116 14.442 20.9696 15.2502 20.3645C16.0585 19.7594 17.2038 19.9246 17.8083 20.7336C18.4362 21.5738 19.2373 22.269 20.1572 22.772C21.0771 23.2751 22.0943 23.5742 23.1399 23.6492C24.1855 23.7241 25.2349 23.5731 26.2171 23.2064C27.1992 22.8397 28.0911 22.2659 28.8322 21.5238L28.8324 21.5236L34.3032 16.0478C35.6283 14.6689 36.3612 12.8253 36.3446 10.9118C36.328 8.99335 35.5592 7.15817 34.2038 5.80155C32.8484 4.44493 31.0149 3.67542 29.0982 3.65875C27.1856 3.64211 25.3429 4.37638 23.9651 5.70373L20.8316 8.82189C20.1158 9.53417 18.9587 9.53079 18.2471 8.81435C17.5354 8.09791 17.5388 6.93971 18.2546 6.22743L21.398 3.09944L21.4169 3.08093Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5055 13.3658C13.9787 12.8158 15.5529 12.5893 17.1212 12.7017C18.6896 12.8141 20.2154 13.2629 21.5953 14.0174C22.9752 14.772 24.1768 15.8148 25.1186 17.075C25.7232 17.884 25.558 19.0304 24.7498 19.6355C23.9416 20.2406 22.7963 20.0754 22.1917 19.2664C21.5638 18.4262 20.7627 17.731 19.8428 17.228C18.9229 16.7249 17.9057 16.4258 16.8601 16.3508C15.8145 16.2759 14.7651 16.4269 13.7829 16.7936C12.8008 17.1603 11.9089 17.7341 11.1678 18.4762L5.69678 23.9522C4.37173 25.3311 3.63879 27.1747 3.6554 29.0882C3.67205 31.0066 4.44086 32.8418 5.79624 34.1984C7.15162 35.5551 8.98512 36.3246 10.9018 36.3413C12.8136 36.3579 14.6555 35.6243 16.033 34.298L19.1463 31.1819C19.8601 30.4675 21.0172 30.4675 21.7309 31.1819C22.4446 31.8962 22.4446 33.0544 21.7309 33.7688L18.6058 36.8968L18.5831 36.9191C16.515 38.9183 13.7452 40.0246 10.8701 39.9996C7.995 39.9746 5.24475 38.8203 3.21168 36.7854C1.17861 34.7504 0.0253982 31.9977 0.000414519 29.12C-0.0245692 26.2422 1.08068 23.4698 3.07811 21.3998L3.10037 21.3772L8.58282 15.8897C8.58289 15.8896 8.58274 15.8898 8.58282 15.8897C9.69441 14.7768 11.0324 13.9159 12.5055 13.3658Z"
    />
  </Icon>
);
