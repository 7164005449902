import { Container, Text, Box, Button, Show, Image } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconLogo } from '../../theme/icons/IconLogo';

import defaultPosterSmallSrc from '../../assets/img/photos/photo-40.webp';
import defaultPosterSrc from '../../assets/img/photos/photo-40.webp';
import defaultPosterCompressedSrc from '../../assets/img/photos/photo-40-compressed.jpg';
import OverlayPhoto from '../UI/ornaments/OverlayPhoto';
import PictureOptimized from '../UI/PictureOptimized';

const SectionDiscoverVideos = ({ posterSrcSmall }) => {
  return (
    <Container
      pos="relative"
      bg="primary.dark"
      color="primary.white"
      px={0}
      py={{ base: '60px', md: '88px', '2xl': '150px' }}
    >
      <Show below="sm">
        <Image
          src={posterSrcSmall ?? defaultPosterSmallSrc}
          pos="absolute"
          objectFit="cover"
          objectPosition="center center"
          h="full"
          w="full"
        />
      </Show>
      <Show above="sm">
        <PictureOptimized
          src={defaultPosterSrc}
          fallbackSrc={defaultPosterCompressedSrc}
          objectPosition="center center"
        />
      </Show>
      <OverlayPhoto bg="#0F0800" opacity="0.4" mixBlendMode="normal" />

      <Container maxW="1562px" pos="relative">
        <Box maxW="600px">
          <Box display="inline-block">
            <Text fontWeight={500} fontSize={{ base: '16px', md: '24px' }}>
              Ready for more?
            </Text>
            <Text as="h2" textStyle="sectionHeadline" mt={6}>
              Discover more great stories on Storyvault blog!
            </Text>
          </Box>
          <Box w={{ base: '100%', sm: 'auto' }}>
            <Button
              as={RouterLink}
              to="/blog"
              onClick={() => {
                window.location.href = '/blog';
              }}
              w={{ base: '100%', md: 'auto' }}
              mt="40px"
            >
              <IconLogo boxSize="20px" mr="10px" />
              Explore Storyvault blog
            </Button>
          </Box>
        </Box>
      </Container>
    </Container>
  );
};

export default SectionDiscoverVideos;
