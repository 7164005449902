import {
  Container,
  Text,
  Box,
  Image,
  Grid,
  GridItem,
  Flex,
  Show,
  Button,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconLogo } from '../../theme/icons/IconLogo';
import PhotoSrc from '../../assets/img/photos/photo-doea-02.webp';
import PhotoCompressedSrc from '../../assets/img/photos/photo-doea-02.jpg';
import DotsSrc from '../../assets/vectors/purpleLightDottedTriangle.svg';
import PictureOptimizedSingle from '../UI/PictureOptimizedSingle';
import { IconVideoAdd } from "../../theme/icons/IconVideoAdd"

const SectionDofeThird = ({isAuthenticated}) => {
  return (
    <Container px={0} py={{ base: '60px', '2xl': '140px' }}>
      <Container maxW="1562px">
        <Grid
          templateColumns={{ md: 'repeat(2, 1fr)' }}
          columnGap={{ md: '32px', lg: '100px' }}
          rowGap={10}
          alignItems="center"
        >
          <GridItem>
            <Flex pos="relative" justifyContent={{ base: 'center', md: 'right' }}>
              <Show above="2xl">
                <Image
                  src={DotsSrc}
                  pos="absolute"
                  top="-60px"
                  left="0px"
                  transform="scaleX(-1)"
                />
              </Show>
              <PictureOptimizedSingle
                src={PhotoSrc}
                fallbackSrc={PhotoCompressedSrc}
                borderRadius="10px"
                width="648px"
                position="relative"
                zIndex="1"
              />
            </Flex>
          </GridItem>
          <GridItem>
            <Box maxW="600px">
              <Grid templateColumns="auto 1fr" gap="20px">
                <Box
                  w={{ base: '20px', md: '40px' }}
                  h="3px"
                  mt="13px"
                  bg="accent.default"
                ></Box>
                <Text textStyle="sectionText">
                  Their story could be about a personal life - experience such as an early
                  memory or meeting a famous person, or about a big event such as being
                  caught up in a war or a natural disaster.
                </Text>
                <Box
                  w={{ base: '20px', md: '40px' }}
                  h="3px"
                  mt="13px"
                  bg="accent.default"
                ></Box>
                <Text textStyle="sectionText">
                  These recordings become a precious archive for families, as well as a
                  unique and searchable resource for students or anyone else interested in
                  our shared history.
                </Text>
              </Grid>
              { isAuthenticated ? (<Button
                as={RouterLink}
                to="/add-video"
                // w={{ base: '100%', md: 'auto' }}
                mt={{ base: '20px', md: '40px' }}
                ml={{ base: '40px', md: '60px' }}
              >
                <IconVideoAdd boxSize="24px" mr="10px" />
                Add video story
              </Button>) : (<Button
                as={RouterLink}
                to="/signup"
                mt={{ base: '20px', md: '40px' }}
                ml={{ base: '40px', md: '60px' }}
              >
                <IconLogo boxSize="20px" mr="10px" />
                Share your story
              </Button>)}
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Container>
  );
};

export default SectionDofeThird;
