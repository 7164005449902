import { Box, Text, CloseButton, Flex } from '@chakra-ui/react';
import { IconTriangle } from '../../theme/icons/IconTriangle';
import { IconCheckOK } from '../../theme/icons/IconCheckOK';
import { newLineToBr } from '../../util';

const CustomToast = (props) => {
  const { onClose, status, description } = props;
  const statusesExclamation = ['error'];

  const renderIcon = (status) => {
    switch (status) {
      case 'success':
        return <IconCheckOK boxSize="32px" />;
      case 'error':
        return <IconTriangle boxSize="36px" />;
      case 'info':
        return <IconTriangle boxSize="20px" />;
      case 'warning':
        return <IconTriangle boxSize="20px" />;
      default:
        return null;
    }
  };

  return (
    <Box
      // boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.05)"
      bg={statusesExclamation.includes(status) ? '#FDEAE6' : 'secondary.light'}
      color={statusesExclamation.includes(status) ? 'error.default' : 'secondary.dark'}
      w={{ base: '100%', md: '500px' }}
      p="30px 20px"
      borderRadius="5px"
      pos="relative"
    >
      <Flex alignItems="center">
        <Box alignSelf="flex-start">{renderIcon(status)}</Box>
        <Box flex="1" mx={4} textAlign="center">
          <Text textStyle="bodyMediumSmall" lineHeight="150%">
            {/* {description} */}
            {newLineToBr(description)}
            {/* {description.split('\n').map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              );
            })} */}
          </Text>
        </Box>
      </Flex>
      <CloseButton onClick={onClose} pos="absolute" top="0" right="0" />
    </Box>
  );
};

export default CustomToast;
