import {
  Flex,
  HStack,
  Image,
  Link,
  Button,
  IconButton,
  useMediaQuery,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Box,
  VStack,
  Avatar,
  Text,
  Show,
} from '@chakra-ui/react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useContext } from 'react';
import { getImageUrlFromUser, isPublicRoute } from '../util';
import LogoSrc from '../assets/img/logo.svg';
import { IconBlog } from '../theme/icons/IconBlog';
import { IconCrown } from '../theme/icons/IconCrown';
import { IconAvatar } from '../theme/icons/IconAvatar';
import { IconLogo } from '../theme/icons/IconLogo';
import SearchByTermInHeader from '../components/search/SearchByTermInHeader';
import { IconPlay } from '../theme/icons/IconPlay';
import { IconCategories } from '../theme/icons/IconCategories';
import { IconSearch } from '../theme/icons/IconSearch';
import { IconQuestion } from '../theme/icons/IconQuestion';
import { IconEnvelope } from '../theme/icons/IconEnvelope';
import { IconVideoAdd } from '../theme/icons/IconVideoAdd';
import { IconLogOut } from '../theme/icons/IconLogOut';
import { IconCog } from '../theme/icons/IconCog';
import HeaderDropdownCategories from './HeaderDropdownCategories';

const HeaderLogged = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { getAuthUser, logout } = useContext(AuthContext);
  const user = getAuthUser();
  const { slug } = user;
  const src = getImageUrlFromUser(user);
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const iconSpacer = '50px';
  const itemMt = '16px';

  async function handleLogout() {
    await logout();
    if (!isPublicRoute(location.pathname)) {
      navigate('/', { replace: true });
    }
  }

  return (
    <Flex
      as="header"
      w="full"
      minH="80px"
      position="fixed"
      zIndex="10"
      px={{ base: '16px', '2xl': '50px' }}
      py="20px"
      bg="white"
      boxShadow="0px 10px 30px rgba(0, 0, 0, 0.05)"
      alignItems="center"
      justifyContent="space-between"
    >
      <Link as={RouterLink} to="/" pointerEvents="auto" flexShrink={0} mr={4}>
        <Image src={LogoSrc} maxH={{ base: '32px', md: 'none' }} />
      </Link>
      <HStack spacing={{ base: 'clamp(7px, 2vw, 50px)', '2xl': 'clamp(7px, 3vw, 50px)' }}>
        {isLargerThanMD ? (
          <>
            {isLargerThanXL && (
              <Link
                as={RouterLink}
                to="/"
                // variant="primaryDefault"
                _hover={{ color: 'primary.default', textDecoration: 'underline' }}
                fontSize="18px"
                textStyle="bodyMedium"
              >
                Home
              </Link>
            )}
            {isLargerThanXL && (
              <Link
                as={RouterLink}
                to="/about"
                // onClick={() => {
                //   window.location.href = '/about';
                // }}
                // variant="primaryDefault"
                _hover={{ color: 'primary.default', textDecoration: 'underline' }}
                fontSize="18px"
                textStyle="bodyMedium"
              >
                About
              </Link>
            )}
            {isLargerThanXL && <HeaderDropdownCategories />}
            {isLargerThanXL && (
              <Link
                as={RouterLink}
                to="/blog"
                onClick={() => {
                  window.location.href = '/blog';
                }}
                // variant="primaryDefault"
                _hover={{ color: 'primary.default', textDecoration: 'underline' }}
                fontSize="18px"
                textStyle="bodyMedium"
              >
                Blog
              </Link>
            )}
            {isLargerThanXL && (
              <Link
                as={RouterLink}
                to="/dofe"
                // variant="primaryDefault"
                _hover={{ color: 'primary.default', textDecoration: 'underline' }}
                fontSize="18px"
                textStyle="bodyMedium"
              >
                DofE
              </Link>
            )}
            {isLargerThanLG && <SearchByTermInHeader />}
            {isLargerThanLG && <Divider orientation="vertical" h="60px" />}

            <Button as={RouterLink} to="/add-video" flexShrink={0}>
              <IconVideoAdd boxSize="24px" mr="10px" />
              Add video story
            </Button>
          </>
        ) : (
          <>
            <IconButton
              aria-label="header-search-aria"
              as={RouterLink}
              to="/search"
              icon={<IconSearch boxSize="20px" />}
              variant="iconicDark"
              bg="secondary.light"
              size="sm"
            />
            <IconButton
              aria-label="header-add-video-aria-mobile"
              as={RouterLink}
              to="/add-video"
              icon={<IconVideoAdd boxSize="20px" />}
              variant="iconicDark"
              bg="secondary.light"
              size="sm"
            />
          </>
        )}

        {/* Box element introduced because Popper doesn't allow it's elements to have margins (which here comes from HStack for Menu) */}
        <Box>
          <Menu>
            <MenuButton>
              {/* Recommended size for Avatar thumb would be: square 200x200px */}

              <Show breakpoint="(max-width: 767px)">
                <Avatar
                  icon={
                    <IconAvatar
                      fontSize={{ base: '16px', md: '22px' }}
                      color="secondary.lighter"
                    />
                  }
                  src={src}
                  // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC9oMJmsng6zbrYeqqIGjKbYdi8lb1HRQw-Q&usqp=CAU"
                  bg="secondary.light"
                  boxSize={{ base: '32px', md: '40px' }}
                />
              </Show>
              <Show breakpoint="(min-width: 768px)">
                <VStack align="center">
                  <Avatar
                    icon={
                      <IconAvatar
                        fontSize={{ base: '16px', md: '22px' }}
                        color="secondary.lighter"
                      />
                    }
                    src={src}
                    // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC9oMJmsng6zbrYeqqIGjKbYdi8lb1HRQw-Q&usqp=CAU"
                    bg="secondary.light"
                    boxSize={{ base: '32px', md: '40px' }}
                  />
                  <Text
                    textStyle="bodyMediumSmall"
                    color="primary.dark"
                    lineHeight="1em"
                    style={{ marginTop: '6px', whiteSpace: 'nowrap' }}
                  >
                    My Profile
                  </Text>
                </VStack>
              </Show>
            </MenuButton>
            <MenuList mt={{ base: '26px', md: '23px' }} px="30px">
              <IconPlay
                pos="absolute"
                top={{ base: '12px', md: '9px' }}
                right={{ base: '8px', md: '24px' }}
                transform="rotate(-90deg)"
                color="white"
                pointerEvents="none"
              />
              {!isLargerThanXL && (
                <>
                  <MenuItem as={RouterLink} to="/about" px={0}>
                    <Box as="span" w={iconSpacer}>
                      <IconLogo color="primary.dark" boxSize="22px" />
                    </Box>
                    About Company
                  </MenuItem>
                  <MenuItem as={RouterLink} to="/all-categories" px={0} mt={itemMt}>
                    <Box as="span" w={iconSpacer}>
                      <IconCategories color="primary.dark" boxSize="24px" />
                    </Box>
                    Browse Categories
                  </MenuItem>
                  {!isLargerThanLG && (
                    <MenuItem as={RouterLink} to="/search" px={0} mt={itemMt}>
                      <Box as="span" w={iconSpacer}>
                        <IconSearch color="primary.dark" boxSize="24px" />
                      </Box>
                      Search Storyvault
                    </MenuItem>
                  )}
                  <MenuItem
                    as={RouterLink}
                    to="/blog"
                    onClick={() => {
                      window.location.href = '/blog';
                    }}
                    px={0}
                    mt={itemMt}
                  >
                    <Box as="span" w={iconSpacer}>
                      <IconBlog color="primary.dark" boxSize="24px" />
                    </Box>
                    Blog
                  </MenuItem>
                  <MenuItem as={RouterLink} to="/dofe" px={0} mt={itemMt}>
                    <Box as="span" w={iconSpacer}>
                      <IconCrown color="primary.dark" boxSize="24px" />
                    </Box>
                    DofE
                  </MenuItem>
                  <MenuItem as={RouterLink} to="/faq" px={0} mt={itemMt}>
                    <Box as="span" w={iconSpacer}>
                      <IconQuestion color="primary.dark" boxSize="24px" />
                    </Box>
                    FAQ
                  </MenuItem>
                  <MenuItem as={RouterLink} to="/contact-us" px={0} mt={itemMt}>
                    <Box as="span" w={iconSpacer}>
                      <IconEnvelope color="primary.dark" boxSize="24px" />
                    </Box>
                    Contact Us
                  </MenuItem>
                </>
              )}
              <MenuItem
                as={RouterLink}
                to={`/channel/${slug}`}
                px={0}
                mt={!isLargerThanXL && itemMt}
              >
                <Box as="span" w={iconSpacer}>
                  <IconAvatar color="primary.dark" boxSize="24px" />
                </Box>
                My Channel
              </MenuItem>
              <MenuItem as={RouterLink} to="/settings/profile" px={0} mt={itemMt}>
                <Box as="span" w={iconSpacer}>
                  <IconCog color="primary.dark" boxSize="24px" />
                </Box>
                Settings
              </MenuItem>
              <MenuDivider mx="0" />
              <MenuItem onClick={handleLogout} px={0} mt={itemMt}>
                <Box as="span" w={iconSpacer}>
                  <IconLogOut color="primary.dark" boxSize="24px" />
                </Box>
                Log Out
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
    </Flex>
  );
};

export default HeaderLogged;
