import {
  Heading,
  Text,
  VStack,
  Image,
  Box,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import SettingsLayout from '../../components/settings/SettingsLayout';
import DeleteAccountSrc from '../../assets/vectors/deleteAccount.svg';
import DeleteAccountModal from '../../components/modals/DeleteAccountModal';

const SettingsDeleteAccount = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <SettingsLayout>
      <VStack spacing={{ base: '40px', sm: '60px' }} alignItems="flex-start">
        <Heading size="lg" color="primary.default">
          Delete Account
        </Heading>
        <Box>
          <Image src={DeleteAccountSrc} mt="-20px" />
        </Box>
        <Text textStyle="bodyMedium" mt="15px" mb={10}>
          If you delete your account all your content will be removed and you won’t be
          able to log in anymore. Would you like to delete your account permanently?
        </Text>

        <Button isFullWidth onClick={onOpen}>
          Delete permanently
        </Button>
      </VStack>

      <DeleteAccountModal isOpen={isOpen} onClose={onClose} />
    </SettingsLayout>
  );
};

export default SettingsDeleteAccount;
