import {
  Box,
  Flex,
  Text,
  HStack,
  Link,
  Circle,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/react';
import { IconChevronLeft } from '../../theme/icons/IconChevronLeft';
import CommentsContext, { NEWEST, OLDEST } from '../../context/CommentsContext';
import { useContext } from 'react';
const itemMt = '8px';

const CommentsNavigation = () => {
  const commentsContextCtx = useContext(CommentsContext);

  function showAllComments(e) {
    e.preventDefault();
    commentsContextCtx.updatePerPage(commentsContextCtx.totalComments);
  }

  function handleSortBy(e, sortBy) {
    e.preventDefault();
    // console.log({ sortBy });
    if ([NEWEST, OLDEST].includes(sortBy)) {
      commentsContextCtx.updateSortBy(sortBy);
    }
  }

  return (
    <>
      <Flex mt={10} alignItems={{ md: 'center' }} flexDir={{ base: 'column', sm: 'row' }}>
        <HStack
          spacing="25px"
          alignItems="center"
          mr="25px"
          mb={{ base: '12px', sm: '0' }}
        >
          <Text
            as="h2"
            color="primary.dark"
            textTransform="uppercase"
            textStyle="formLabels"
            lineHeight="150%"
          >
            Comments
          </Text>

          {commentsContextCtx.totalComments > 0 && (
            <Circle
              size="30px"
              fontSize="14px"
              textStyle="bodyMedium"
              color="secondary.dark"
              border="solid 1px"
              borderColor="secondary.dark"
            >
              {commentsContextCtx.totalComments > 999
                ? '1k+'
                : commentsContextCtx.totalComments}
            </Circle>
          )}
        </HStack>

        <Flex flex="1" alignItems="center" justifyContent="space-between">
          {commentsContextCtx.totalComments > commentsContextCtx.state.perPage ? (
            <Link
              variant="secondaryDark"
              fontSize="14px"
              textStyle="bodySemiBold"
              onClick={showAllComments}
            >
              View all comments
            </Link>
          ) : (
            <Box></Box>
          )}

          {/* Box element introduced because Popper doesn't allow it's elements to have margins (which here comes from HStack for Menu) */}
          {commentsContextCtx.totalComments > 0 && (
            <Box alignSelf="flex-end">
              <Menu variant="small">
                <MenuButton>
                  <Link variant="secondaryDark" fontSize="14px" textStyle="bodySemiBold">
                    Sort by
                    <IconChevronLeft
                      fontSize="24px"
                      transform="rotate(-90deg)"
                      pos="relative"
                      top="-1px"
                    />
                  </Link>
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup
                    defaultValue="newest"
                    // title="CommentsSortBy"
                    type="radio"
                  >
                    <MenuItemOption
                      value="newest"
                      onClick={(e) => handleSortBy(e, NEWEST)}
                    >
                      Newest first
                    </MenuItemOption>
                    <MenuItemOption
                      value="oldest"
                      onClick={(e) => handleSortBy(e, OLDEST)}
                      mt={itemMt}
                    >
                      Oldest first
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </Box>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default CommentsNavigation;
