import Sidebar from '../components/user/Sidebar';
import ChannelContent from '../components/user/ChannelContent';
import { Flex, useMediaQuery } from '@chakra-ui/react';
import { MyChannelContextProvider } from '../context/MyChannelContext';
import React, { useContext } from 'react';
import UserContext from '../context/UserContext';
import { AuthContext } from '../context/AuthContext';
import { isLoggedUserChannel } from '../util';

const ChannelPage = () => {
  const { getAuthUser } = useContext(AuthContext);
  const { state } = useContext(UserContext);
  let { data: user, status } = state;
  const done = ['resolved', 'rejected'].includes(status);
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  const loggedUser = getAuthUser();

  if (done) {
    const isMyChannel = isLoggedUserChannel(user, loggedUser)

    return (
      <MyChannelContextProvider>
        <Flex>
          {isLargerThanMD && isMyChannel && (
            <Sidebar user={user} isMyChannel={isMyChannel} />
          )}
          <ChannelContent user={user} />
        </Flex>
      </MyChannelContextProvider>
    );
  }

  return null;
};

export default ChannelPage;
