import { Icon } from '@chakra-ui/react';

export const CategoryWorkIcon = (props) => (
  <Icon viewBox="0 0 54 51" fill="none" {...props}>
    <path
      d="M54 14.5146C54 17.3373 54 20.16 54 22.9826C53.6708 23.9728 52.9301 24.4509 51.9072 24.6899C47.0869 25.8394 42.2783 27.0231 37.4579 28.1955C35.8119 28.5938 34.1659 29.0036 32.5199 29.4019C32.4964 29.3336 32.4729 29.2995 32.4729 29.2654C32.4729 28.2979 32.4729 27.3305 32.4611 26.3516C32.4376 24.8379 31.4148 23.8818 29.8863 23.8818C27.8994 23.8818 25.9125 23.9046 23.9255 23.8704C23.0673 23.859 22.4912 24.2346 22.0209 24.8492C21.6212 25.3614 21.5153 25.9533 21.5271 26.5906C21.5389 27.5126 21.5271 28.4459 21.5271 29.4019C21.2802 29.345 21.1039 29.3223 20.9393 29.2767C14.5552 27.7174 8.18289 26.1581 1.79882 24.6102C0.599608 24.3143 0 23.5859 0 22.368C0 20.0234 0 17.6674 0 15.3227C0 13.6951 0.799478 12.9098 2.48073 12.9098C5.04376 12.9098 7.60679 12.9098 10.1816 12.9098C10.3932 12.9098 10.5931 12.9098 10.8635 12.9098C10.8635 12.6708 10.8635 12.4887 10.8635 12.3066C10.8635 9.97331 10.8635 7.64006 10.8635 5.31818C10.8635 2.55242 12.8504 0.640288 15.7074 0.628906C23.2319 0.628906 30.7446 0.628906 38.2691 0.628906C41.1613 0.628906 43.1248 2.54104 43.1365 5.34094C43.1365 7.6742 43.1365 10.0075 43.1365 12.3293C43.1365 12.5114 43.1365 12.6935 43.1365 12.8984C43.4187 12.8984 43.6303 12.8984 43.8419 12.8984C46.3344 12.8984 48.8387 12.9326 51.3312 12.887C52.6244 12.887 53.5415 13.3082 54 14.5146ZM14.0261 12.887C22.7028 12.887 31.3207 12.887 39.9739 12.887C39.9739 12.7391 39.9739 12.6253 39.9739 12.5001C39.9739 10.0985 39.9739 7.70835 39.9739 5.3068C39.9739 4.27106 39.386 3.71336 38.3044 3.70197C38.2221 3.70197 38.128 3.70197 38.0457 3.70197C30.6858 3.70197 23.3259 3.71336 15.966 3.69059C14.8138 3.69059 13.9673 4.15724 14.0026 5.60273C14.0732 7.70835 14.0144 9.81397 14.0144 11.931C14.0261 12.2269 14.0261 12.5342 14.0261 12.887Z"
      fill="#8A5936"
    />
    <path
      d="M1.89424 27.7852C8.48993 29.39 15.0033 30.9834 21.5402 32.5769C21.5402 33.3053 21.5402 33.9996 21.5402 34.6939C21.552 36.3442 22.6219 37.4141 24.3266 37.4369C26.1019 37.4596 27.8773 37.4596 29.6526 37.4369C31.3926 37.4141 32.4625 36.3442 32.4743 34.6711C32.4743 34.1248 32.4978 33.5785 32.4625 33.0435C32.439 32.6793 32.5683 32.5541 32.921 32.4631C37.6356 31.3249 42.3384 30.1639 47.053 29.0144C48.7225 28.6046 50.3802 28.2063 52.1085 27.7852C52.1085 28.0128 52.1085 28.1949 52.1085 28.377C52.1085 35.0126 52.1085 41.6481 52.1085 48.2723C52.1085 49.843 51.2972 50.6283 49.6865 50.6283C34.5552 50.6283 19.4357 50.6283 4.30443 50.6283C2.69372 50.6283 1.87073 49.843 1.87073 48.2837C1.87073 41.6367 1.87073 34.9784 1.87073 28.3315C1.89424 28.1835 1.89424 28.0128 1.89424 27.7852Z"
      fill="#8A5936"
    />
    <path
      d="M29.2753 26.8633C29.2753 29.3673 29.2753 31.8371 29.2753 34.3525C27.7704 34.3525 26.2655 34.3525 24.7371 34.3525C24.7371 31.8599 24.7371 29.3786 24.7371 26.8633C26.2537 26.8633 27.7586 26.8633 29.2753 26.8633Z"
      fill="#8A5936"
    />
  </Icon>
);
