import { Container, Box } from '@chakra-ui/react';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

import HeroAbout from '../components/heroes/HeroAbout';
// import SectionTemplate from '../components/sections/SectionTemplate';
import SectionIntro from '../components/sections/SectionIntro';
import SectionCollage3 from '../components/sections/SectionCollage3';
import SectionCollageReversed1 from '../components/sections/SectionCollageReversed1';
import SectionCollage4 from '../components/sections/SectionCollage4';
import SectionConclusion2 from '../components/sections/SectionConclusion2';
import SectionCollageReversed4 from '../components/sections/SectionCollageReversed4';
import SectionCollage6 from '../components/sections/SectionCollage6';
import SectionCollageReversed2 from '../components/sections/SectionCollageReversed2';

const AboutPage = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Container mt={{ base: '80px', md: '100px' }} p={0}>
      <HeroAbout />

      <SectionIntro />

      <Box py={{ base: '60px', '2xl': '52px' }}>
        <SectionCollage3 />
        <SectionCollageReversed1 />
        <SectionCollage4 />
      </Box>
      <Box bg="secondary.lighter" py={{ base: '60px', '2xl': '52px' }}>
        <SectionCollageReversed4 />
        <SectionCollage6 />
        <SectionCollageReversed2 />
      </Box>

      {!isAuthenticated() && (
        <Box bg="white" py={{ base: '60px', '2xl': '52px' }}>
          <SectionConclusion2 />
        </Box>
      )}
    </Container>
  );
};

export default AboutPage;
