import { Avatar, AvatarBadge, Box, Spinner, useToast } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { IconAvatar } from '../../theme/icons/IconAvatar';
import { IconCamera } from '../../theme/icons/IconCamera';
import CustomToast from '../UI/CustomToast';
import { TWENTY_MB } from '../../util/consts';
import { HttpContext } from '../../context/HttpContext';
import { getImageUrlFromUser } from '../../util';
import { AuthContext } from '../../context/AuthContext';

const AvatarUpload = () => {
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const { getAuthUser, setAuthUserImage } = useContext(AuthContext);
  const user = getAuthUser();
  const src = getImageUrlFromUser(user);

  const onClickUpload = (e) => {
    if (isUploading) {
      e.preventDefault();
    }
  };

  function showError(message) {
    toast({
      position: 'top',
      render: (props) => (
        <CustomToast
          status="error"
          description={message}
          onClose={props.onClose}
          id={props.id}
        />
      ),
    });
  }

  function validImage(e, file) {
    const acceptedFormats = [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/x-png',
      'image/heif',
    ];
    if (file) {
      let errorMessage = null;
      if (file.size > TWENTY_MB) {
        errorMessage = 'This file is too big. Please upload a file up to 20MB.';
      } else if (!acceptedFormats.includes(file.type)) {
        // validation for type, return the function if rule is not applied
        errorMessage = `Please upload one of the following formats 'JPG', 'JPEG', 'PNG', 'HEIC'. `;
      }

      if (errorMessage) {
        showError(errorMessage);
        return false;
      } else {
        return true;
      }
    }
  }

  const onChange = (e) => {
    let file = e.target.files[0];

    if (validImage(e, file)) {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('imageFile', file);

      authAxios({
        url: '/user_images',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/ld+json',
        },
        data: formData,
      })
        .then(({ data: responseData }) => {
          const { id, filename, filename_200_200 } = responseData;
          const apiId = responseData['@id'];
          setAuthUserImage({
            id,
            apiId,
            filename,
            filename_200_200,
          });
        })
        .catch((onError) => {
          console.log(onError);
          if ('isAxiosError' in onError) {
            if (onError.isAxiosError) {
              let message = `Error (${file.name}): The file you tried to upload is not supported. Please try uploading a different file.`;
              // console.log(message);
              toast({
                position: 'top',
                render: (props) => (
                  <CustomToast
                    status="error"
                    description={message}
                    onClose={props.onClose}
                    id={props.id}
                  />
                ),
              });
            }
          }
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  };

  return (
    <Box>
      <input
        onChange={(e) => onChange(e)}
        style={{ display: 'none' }}
        accept="image/*"
        id="upload-photo"
        // multiple
        type="file"
      />
      <label htmlFor="upload-photo" onClick={(e) => onClickUpload(e)}>
        {/* Recommended size for Avatar thumb would be: square 200x200px */}
        <Avatar
          src={src}
          // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC9oMJmsng6zbrYeqqIGjKbYdi8lb1HRQw-Q&usqp=CAU"
          boxSize="100px"
          icon={
            <IconAvatar
              fontSize="48px"
              color={isUploading ? 'secondary.dark' : 'secondary.lighter'}
              transition="0.3s"
              _groupHover={{ color: 'secondary.dark' }}
            />
          }
          cursor="pointer"
          bg="secondary.light"
          role="group"
        >
          {isUploading ? (
            <Spinner
              thickness="2px"
              speed="1s"
              color="secondary.dark"
              boxSize="100px"
              pos="absolute"
              top="0"
              left="0"
            />
          ) : (
            <AvatarBadge
              boxSize="28px"
              right="6px"
              bottom="6px"
              bg="secondary.dark"
              borderColor="secondary.dark"
            >
              <IconCamera fontSize="14px" color="secondary.lighter" />
            </AvatarBadge>
          )}
        </Avatar>
      </label>
    </Box>
  );
};

export default AvatarUpload;
