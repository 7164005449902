import { Icon } from '@chakra-ui/react';

export const IconQuestion = (props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.81818C5.48131 1.81818 1.81818 5.48131 1.81818 10C1.81818 14.5187 5.48131 18.1818 10 18.1818C14.5187 18.1818 18.1818 14.5187 18.1818 10C18.1818 5.48131 14.5187 1.81818 10 1.81818ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2328 6.38342C9.80961 6.31083 9.37437 6.39036 9.00419 6.60792C8.634 6.82548 8.35276 7.16703 8.21027 7.57208C8.04366 8.04571 7.52465 8.29459 7.05102 8.12798C6.5774 7.96137 6.32851 7.44236 6.49512 6.96873C6.7801 6.15863 7.34258 5.47553 8.08295 5.04041C8.82331 4.60529 9.69378 4.44623 10.5402 4.59141C11.3866 4.73659 12.1543 5.17664 12.7073 5.83361C13.2603 6.49045 13.5629 7.32175 13.5618 8.18033C13.5614 9.57156 12.5299 10.4903 11.7933 10.9814C11.3973 11.2454 11.0077 11.4395 10.7208 11.5671C10.576 11.6314 10.4541 11.6802 10.3662 11.7137C10.3222 11.7305 10.2866 11.7434 10.2606 11.7527L10.229 11.7637L10.219 11.7672L10.2154 11.7684L10.214 11.7688L10.2129 11.7692C9.73659 11.928 9.22176 11.6706 9.06299 11.1942C8.90432 10.7183 9.16129 10.2038 9.63699 10.0446L9.6515 10.0396C9.66548 10.0346 9.68841 10.0263 9.71898 10.0146C9.78028 9.99127 9.87144 9.95487 9.98234 9.90558C10.2067 9.80585 10.499 9.65907 10.7848 9.46854C11.4117 9.0506 11.7436 8.60598 11.7436 8.1795L11.7436 8.17814C11.7442 7.74876 11.5929 7.33301 11.3164 7.00452C11.0399 6.67604 10.656 6.45601 10.2328 6.38342Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.08984 14.5458C9.08984 14.0437 9.49686 13.6367 9.99893 13.6367H10.008C10.5101 13.6367 10.9171 14.0437 10.9171 14.5458C10.9171 15.0479 10.5101 15.4549 10.008 15.4549H9.99893C9.49686 15.4549 9.08984 15.0479 9.08984 14.5458Z"
    />
  </Icon>
);
