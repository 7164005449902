import { Icon } from '@chakra-ui/react';

export const IconBlog = (props) => (
  <Icon viewBox="0 0 41 40" {...props}>
    <path
      fill="currentColor"
      d="M0.978857 0.216217C0.752967 0.338425 0.442368 0.620449 0.282363 0.836662L0 1.24089V20.0141V38.7873L0.235302 39.1351C0.367071 39.3325 0.640022 39.6052 0.837676 39.7462L1.20475 40H20.0007H38.7966L39.1637 39.7462C39.3614 39.6052 39.6343 39.3325 39.7661 39.1351L40.0014 38.7873V20.0235V1.25029L39.7472 0.883667C39.6061 0.686253 39.3331 0.413631 39.1355 0.282021L38.7872 0.0470047L20.0948 0.0188026L1.39299 0L0.978857 0.216217ZM36.0765 5.98825L36.0954 7.9906H20.0007H3.90602V6.03525C3.90602 4.95417 3.93425 4.0423 3.9719 4.0141C4.00014 3.9765 11.238 3.9577 20.0383 3.9671L36.0483 3.9953L36.0765 5.98825ZM36.0765 24.0376L36.0483 36.0517H20.0007H3.95308L3.92484 24.0376L3.90602 12.0329H20.0007H36.0954L36.0765 24.0376Z"
    />
    <path
      fill="currentColor"
      d="M9.12989 18.2092C7.58631 19.0176 7.56748 21.0294 9.10165 21.819C9.44049 21.9882 9.79815 21.9977 19.9726 21.9977C30.0153 21.9977 30.5142 21.9882 30.8718 21.819C32.4154 21.1046 32.4342 19.0176 30.9 18.228C30.5612 18.0588 30.2036 18.0494 19.982 18.0494C10.5417 18.0588 9.38402 18.0682 9.12989 18.2092Z"
    />
    <path
      fill="currentColor"
      d="M9.14884 26.1433C8.73684 26.275 8.15666 26.9706 8.03895 27.4688C7.82033 28.3619 8.15666 29.3114 8.86296 29.772L9.25815 30.0352H14.219H19.1799L19.5583 29.8002C20.1216 29.443 20.4243 28.8978 20.4664 28.1645C20.5084 27.497 20.3487 27.0364 19.9198 26.5852C19.3985 26.0305 19.4994 26.0399 14.2443 26.0493C11.4527 26.0493 9.3086 26.0963 9.14884 26.1433Z"
    />
  </Icon>
);
