import React, { useContext, useEffect } from 'react';
import { HttpContext } from '../context/HttpContext';
import { CategoriesContext } from '../context/CategoriesContext';

function UseCategories({ children }) {
  const { publicAxios: axiosInstance } = useContext(HttpContext);
  const { updateState, state } = useContext(CategoriesContext);

  useEffect(() => {
    function getSortedCategories(categories) {
      return categories
      //   .sort(function (a, b) {
      //   const nameA = a.name.toLowerCase(); // ignore upper and lowercase
      //   const nameB = b.name.toLowerCase(); // ignore upper and lowercase
      //   if (nameA < nameB) {
      //     return -1;
      //   }
      //   if (nameA > nameB) {
      //     return 1;
      //   }
      //
      //   // names must be equal
      //   return 0;
      // })
        ;
    }

    function moveToTheEnd(arr, word) {
      if (arr?.length > 0) {
        arr.forEach((elem, index) => {
          if (elem?.name?.toLowerCase() === word.toLowerCase()) {
            arr.splice(index, 1);
            arr.push(elem);
          }
        });
      }

      return arr;
    }

    const getData = async () => {
      try {
        updateState({
          ...state,
          status: 'pending',
        });

        const { data } = await axiosInstance.get('/categories', {
          headers: { Accept: 'application/ld+json' },
        });

        const categories = getSortedCategories(data['hydra:member']);
        const items = moveToTheEnd(categories, 'Other');
        const total = data['hydra:totalItems'];

        updateState({
          items,
          total,
          status: 'resolved',
          error: null,
        });
      } catch (onError) {
        console.log(onError);
        updateState({
          items: [],
          total: 0,
          status: 'rejected',
          error: 'Something went wrong',
        });
      }
    };

    getData();
  }, [axiosInstance]);

  return <>{children}</>;
}

export default UseCategories;
