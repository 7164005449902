import FlickityHorizontal from './FlickityHorizontal';
import FlickityPhotoGalleryCTA from './FlickityPhotoGalleryCTA';
import FlickityPhotoGalleryDrawer from './FlickityPhotoGalleryDrawer';
import { FlickityControlsContextProvider } from '../../context/FlickityControlsContext';

const PhotoCarousels = ({ photos, videoId }) => {
  if (photos?.length > 0) {
    return (
      // FlickityControlsContextProvider - just for FE purposes, we can use another Context for BE-RE-FE flow
      <FlickityControlsContextProvider>
        {/* Recommended size for 'photos' thumb would be: height 440x with preserved ratio */}
        <FlickityHorizontal photos={photos} key={videoId} />

        <FlickityPhotoGalleryCTA />

        {/* Recommended size for 'photosForFullscreen' thumb would be: preserved ratio within dimensions 1920x1080px */}
        {/* Recommended size for 'photosForNav' thumb would be: height 440x with preserved ratio */}
        <FlickityPhotoGalleryDrawer photosForFullscreen={photos} photosForNav={photos} />
      </FlickityControlsContextProvider>
    );
  }

  return null;
};

export default PhotoCarousels;
