import { AspectRatio, Image, Flex, Progress, Box } from '@chakra-ui/react';
import photoPlaceholderSrc from '../../assets/vectors/photoPlaceholder.svg';
import DeleteCta from '../common/DeleteCta';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { getImageUrlFromVideo } from '../../util';

const PhotoUploadPreview = ({ photo, file, onUpload, onDelete, showError }) => {
  const authContext = useContext(AuthContext);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    async function upload() {
      const { url, apiId } = await uploadFile(file, setProgress);
      onUpload(file, { url, apiId });
    }

    upload();
  }, []);

  function uploadFile(file, onProgress) {
    const url = process.env.REACT_APP_API_URL + '/video_images';

    return new Promise((res, rej) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      xhr.setRequestHeader('xtoken', `Bearer ${authContext.getAccessToken()}`);

      xhr.onload = () => {
        const responseData = JSON.parse(xhr.responseText);
        const { filename, filename_width_440, id } = responseData;
        // const apiId = responseData['@id'];
        const apiId = '/api/video_images/' + id;
        const url = filename_width_440 ?? filename;
        res({ url, apiId });
      };
      xhr.onerror = (evt) => {
        showError(
          `Error (${photo.file.name}): The file you tried to upload is not supported. Please try uploading a different file.`
        );
        onDelete(photo.file);
        return rej(evt);
      };
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentage = (event.loaded / event.total) * 100;
          onProgress(Math.round(percentage));
        }
      };

      const formData = new FormData();
      formData.append('imageFile', file);

      xhr.send(formData);
    });
  }

  async function removePhotoHandler() {
    try {
      onDelete(photo.file);
    } catch (onErr) {
      console.log(onErr);
    }
  }

  console.log(photo);

  return (
    <Box pos="relative">
      {photo.url && <DeleteCta onClick={removePhotoHandler} />}
      <AspectRatio ratio={19 / 12}>
        <Flex flexDir="column" bg="secondary.lighter" borderRadius="10px">
          <Image
            src={
              !photo.file
                ? getImageUrlFromVideo(photo, 'width_440')
                : photo.url ?? photoPlaceholderSrc
            }
            pos="absolute"
            objectFit="cover"
            h="full"
            w="full"
          />
          {!photo?.url && (
            <Progress value={progress} size="sm" w="full" pos="absolute" bottom="0" />
          )}
        </Flex>
      </AspectRatio>
    </Box>
  );
};

export default PhotoUploadPreview;
