import React, { useContext, useEffect } from 'react';
import { HttpContext } from '../context/HttpContext';
import { TaglinesContext } from '../context/TaglinesContext';

function UseTaglines({ children }) {
  const { publicAxios: axiosInstance } = useContext(HttpContext);
  const { updateState, state } = useContext(TaglinesContext);

  useEffect(() => {
    function getAllItems(items) {
      let lastAddedItem = null;
      const featuredItems = items.filter(item => item.featured);


      if (featuredItems.length === 0 && items.length > 0) {
        lastAddedItem = items[items.length - 1];
      }

      return { featuredItems, lastAddedItem };
    }
    const getData = async () => {
      try {
        updateState({
          ...state,
          status: 'pending',
        });

        const { data } = await axiosInstance.get('/taglines', {
          headers: { Accept: 'application/ld+json' },
        });

        const items = data['hydra:member'];
        const { featuredItems, lastAddedItem } = getAllItems(items);
        const total = data['hydra:totalItems'];

        updateState({
          items,
          featuredItems,
          lastAddedItem,
          total,
          status: 'resolved',
          error: null,
        });
      } catch (onError) {
        console.log(onError);
        updateState({
          items: [],
          featuredItems: [],
          lastAddedItem: null,
          total: 0,
          status: 'rejected',
          error: 'Something went wrong',
        });
      }
    };

    getData();
  }, [axiosInstance]);

  return <>{children}</>;
}

export default UseTaglines;
