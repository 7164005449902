import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Box,
  VStack,
  SimpleGrid,
  GridItem,
  useMediaQuery,
  Text,
  Container,
  useToast,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { HttpContext } from '../../context/HttpContext';
import CustomToast from '../UI/CustomToast';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const DeleteAccountModal = ({ onClose, isOpen }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { authAxios } = useContext(HttpContext);
  const { getAuthUser, logout } = useContext(AuthContext);
  const { slug } = getAuthUser();
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  async function handleConfirmedDelete(e) {
    try {
      const headers = {
        'Content-Type': 'application/ld+json',
      };

      e.preventDefault();
      const data = {};
      await authAxios.post(`/users/${slug}/delete-account`, data, { headers });

      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="success"
            description="You have successfully deleted your account."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
      onClose();
      await logout();
      navigate('/', { replace: true });
    } catch (onErr) {
      console.log(onErr);
      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="error"
            description="Something went wrong."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    }
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg" color="primary.default">
            Delete Account
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container p={0} maxW="600px">
            <VStack
              spacing={5}
              mt={4}
              mb={{ base: 4, md: 9 }}
              textAlign="center"
              fontSize={{ md: '20px' }}
            >
              <Text>
                Deleting your account is permanent and cannot be undone! You won’t be able
                to log in anymore.
              </Text>
              <Text color="error.default" textStyle="bodySemiBold">
                Are you sure you want to delete your account?
              </Text>
            </VStack>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Box maxW="600px" w="full">
            <SimpleGrid
              w="full"
              maxW="600px"
              columns={2}
              columnGap={{ md: '30px' }}
              rowGap={7}
            >
              <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                <Button onClick={onClose} isFullWidth variant="outlineError">
                  Cancel
                </Button>
              </GridItem>
              <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                <Button onClick={handleConfirmedDelete} variant="solidError" isFullWidth>
                  Delete
                </Button>
              </GridItem>
            </SimpleGrid>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteAccountModal;
