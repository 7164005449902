import {
  Flex,
  Container,
  Heading,
  Link,
  useMediaQuery,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import PersonalInfo from './PersonalInfo';
import VideosList from '../videos/VideosList';
import { useContext } from 'react';
import UserContext from '../../context/UserContext';

const ChannelContent = ({ user }) => {
  const { state: userState } = useContext(UserContext);
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const userData = userState?.data ?? null;
  const channelData = userData?.channelData ?? null;

  return (
    <Container
      // overflow="auto"
      px={{ base: '16px', md: '46px' }}
      pt="50px"
      pb={{ md: '20px' }}
      mt={{ base: '80px', md: '100px' }}
      maxW={{ md: '1622px' }}
    >
      <Flex justifyContent="space-between" alignItems="flex-end" mb="50px">
        <Heading size="lg">
          {channelData?.firstName ? `${channelData.firstName}'s Channel` : `Channel`}
        </Heading>
        {!isLargerThanMD && user && (
          <>
            <Link
              onClick={onOpen}
              variant="secondaryDark"
              fontSize="14px"
              textStyle="bodyMedium"
            >
              Personal info
            </Link>
            <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="xs">
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody bg="secondary.lighter" p={0}>
                  {user && <PersonalInfo user={user} />}
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </>
        )}
      </Flex>

      <VideosList user={user} />
    </Container>
  );
};

export default ChannelContent;
