import { Heading, Text, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import SettingsLayout from '../../components/settings/SettingsLayout';
import { CategoriesProvider } from '../../context/CategoriesContext';
import UseCategories from '../../hooks/useCategories';
import PickCategories from '../../components/settings/PickCategories';

const SettingsInterestsPage = () => {
  return (
    <CategoriesProvider>
      <UseCategories>
        <SettingsLayout>
          <Heading size="lg" color="primary.default">
            Edit Interests
          </Heading>
          <Text textStyle="bodyMedium" mt="15px" mb={10}>
            You can add or remove topics you have chosen to improve your feed. This will
            also help us recommend stories you'll like.
            <br />
            You can go to{' '}
            <Link as={RouterLink} to="/all-categories" color="accent.default">
              Categories
            </Link>{' '}
            page anytime, and browse more videos related to other categories.
          </Text>

          <PickCategories />
        </SettingsLayout>
      </UseCategories>
    </CategoriesProvider>
  );
};

export default SettingsInterestsPage;
