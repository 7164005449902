import { createContext, useState } from 'react';

const FlickityControlsContext = createContext({
  selectedIndex: '',
  updateSelectedIndex: (index) => {},
  initialIndexForGallery: '',
  updateInitialIndexForGallery: (index) => {},
});

export function FlickityControlsContextProvider(props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [initialIndexForGallery, setInitialIndexForGallery] = useState(-1);

  function updateSelectedIndexHandler(index) {
    setSelectedIndex(index);
  }

  function updateInitialIndexForGallery(index) {
    setInitialIndexForGallery(index);
  }

  const context = {
    selectedIndex: selectedIndex,
    updateSelectedIndex: updateSelectedIndexHandler,
    initialIndexForGallery: initialIndexForGallery,
    updateInitialIndexForGallery: updateInitialIndexForGallery,
  };

  return (
    <FlickityControlsContext.Provider value={context}>
      {props.children}
    </FlickityControlsContext.Provider>
  );
}

export default FlickityControlsContext;
