import React, { useContext, useEffect } from 'react';
import { HttpContext } from '../context/HttpContext';
import { TagsContext } from '../context/TagsContext';
import { useDebounce } from './useDebounce';

const ITEMS_PER_PAGE = 10;

function UseTags({ children }) {
  const { publicAxios: axiosInstance } = useContext(HttpContext);
  const { updateState, state } = useContext(TagsContext);
  const { query } = state;

  const debouncedQuery = useDebounce(query, 200);

  useEffect(() => {
    const getData = async () => {
      const itemsPerPage = `?itemsPerPage=${ITEMS_PER_PAGE}`;
      try {
        updateState({
          ...state,
          status: 'pending',
        });

        const { data } = await axiosInstance.get(`/tags${itemsPerPage}${query}`, {
          headers: { Accept: 'application/ld+json' },
        });

        const tags = data['hydra:member'];
        const items = tags.map((tag) => ({ ...tag, value: tag['@id'], label: tag.title.toLowerCase() }));

        updateState({
          items,
          total: data['hydra:totalItems'],
          status: 'resolved',
          error: null,
        });
      } catch (onError) {
        console.log(onError);
        updateState({
          items: [],
          total: 0,
          status: 'rejected',
          error: 'Something went wrong',
        });
      }
    };

    getData();
  }, [axiosInstance, debouncedQuery]);

  return <>{children}</>;
}

export default UseTags;
