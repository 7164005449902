import { Heading, Text, Flex, Tag, TagLabel } from '@chakra-ui/react';
import DoubleVideosCarousels from '../../../components/carousels/DoubleVideosCarousels';
import FlickityViewAllCategoriesCTA from '../../carousels/FlickityViewAllCategoriesCTA';
import VideosListLoading from '../VideosListLoading';
import SectionConclusion4 from '../../sections/SectionConclusion4';

import { useContext, useMemo } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

import { RecommendedVideosContext } from '../../../context/RecommendedVideosContext';
import { normalizedSingleVideoData } from '../../../hooks/useSingleVideo';
import { isFetching } from '../../../util';

const RecommendedVideos = () => {
  const { state } = useContext(RecommendedVideosContext);
  const { getAuthUserCategories } = useContext(AuthContext);
  const navigate = useNavigate();
  const pickedCategories = getAuthUserCategories();
  const { status, items } = state;
  const isLoading = isFetching(status);
  // console.log({ status, total, items, error, isLoading });
  const data = useMemo(() => normalizedSingleVideoData(items), [items]);

  function handleClickCategory(e, id) {
    e.preventDefault();
    navigate(`/search?category=${id}`);
  }

  if (data.length === 0 && !isLoading) {
    return <SectionConclusion4 />;
  }

  return (
    <>
      <Heading size="lg">Recommended for you</Heading>
      <Text
        textStyle="bodyMedium"
        fontSize={{ base: '16px', md: '20px' }}
        mt="15px"
        mb="40px"
      >
        Discover the videos based on the categories you have chosen
      </Text>

      {pickedCategories.length > 0 && (
        <Flex flexWrap="wrap" mb={7}>
          {pickedCategories.map((pickedCategory) => {
            return (
              <Tag
                flexShrink={0}
                alignSelf="flex-start"
                mr={5}
                mb={3}
                key={pickedCategory.id}
              >
                <TagLabel onClick={(e) => handleClickCategory(e, pickedCategory.id)}>
                  {pickedCategory.name}
                </TagLabel>
              </Tag>
            );
          })}
        </Flex>
      )}

      {isLoading ? (
        <VideosListLoading />
      ) : (
        <DoubleVideosCarousels data={data} key={Math.random()}>
          <FlickityViewAllCategoriesCTA />
        </DoubleVideosCarousels>
      )}
    </>
  );
};

export default RecommendedVideos;
