import { createContext, useReducer } from 'react';

function tagsCacheReducer(state, action) {
  switch (action.type) {
    case 'ADD_TAG': {
      return { ...state, [action.id]: action.data };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const TagsCacheContext = createContext({});

export function TagsCacheContextProvider(props) {
  const [cache, dispatch] = useReducer(tagsCacheReducer, {});

  return <TagsCacheContext.Provider value={[cache, dispatch]} {...props} />;
}

export default TagsCacheContext;
