import { HStack, VStack, useMediaQuery } from '@chakra-ui/react';
import { useMemo, useContext } from 'react';
import Flickity from 'react-flickity-component';
import './flickity.css';
import FlickityHorizontalControls from './FlickityHorizontalControls';
import FlickityControlsContext from '../../context/FlickityControlsContext';
import VideoSliderCard from '../videos/VideoSliderCard';

const flickityOptions = {
  pageDots: false,
  prevNextButtons: false,
  cellAlign: 'left',
  // contain: true,
};

const FlickityHorizontalDoubleVideos = ({ videos }) => {
  const flickityControlsCtx = useContext(FlickityControlsContext);
  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const numberOfItems = videos.length;
  const numberOfItemsHalfed = Math.ceil(videos.length / 2);

  return useMemo(() => {
    let flkty = null;

    const flktyPrev = () => {
      flkty.previous();
    };

    const flktyNext = () => {
      flkty.next();
    };

    return (
      <>
        <Flickity
          // flickityRef={(c) => (flkty = c)}
          flickityRef={(c) => (
            // eslint-disable-next-line no-sequences
            (flkty = c),
            c.on('select', () => {
              flickityControlsCtx.updateSelectedIndex(flkty.selectedIndex);
            })
          )}
          className={'flickity-horizontal'} // default ''
          elementType={'div'} // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
          static // default false
        >
          {isLargerThan2XL
            ? [...Array(numberOfItemsHalfed).keys()].map((doubleItem, index) => {
                return (
                  <VStack
                    spacing={{ base: '30px', md: '40px' }}
                    mr="30px"
                    maxW="full"
                    key={index}
                    // height="full" // Flickity will not render properly, so we need minH instead
                    minH="full"
                    justifyContent="space-between"
                    // bg="antiquewhite"
                  >
                    <VideoSliderCard cell={videos[2 * index]} mr="0" forDoubleSlider />
                    {2 * index + 1 < videos.length && (
                      <VideoSliderCard cell={videos[2 * index + 1]} mr="0" forDoubleSlider />
                    )}
                  </VStack>
                );
              })
            : videos.map((video, index) => {
                return <VideoSliderCard key={index} cell={video} />;
              })}
        </Flickity>
        <HStack spacing="10px" mt={5} justifyContent="flex-end">
          <FlickityHorizontalControls
            onClickPrev={flktyPrev}
            onClickNext={flktyNext}
            numberOfItems={isLargerThan2XL ? numberOfItemsHalfed : numberOfItems}
          />
        </HStack>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfItemsHalfed, numberOfItems, videos]);
};

export default FlickityHorizontalDoubleVideos;
