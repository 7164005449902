import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Box, Center, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import { AuthContext } from '../context/AuthContext';
import CheckEmail from '../assets/img/checkyouremail.svg';

function RedirectPage() {
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    return <Navigate to="/home" />;
  }

  return (
    <Center minH="94vh" pt={10} pb={10}>
      <Box
        textAlign="center"
        position="relative"
        zIndex="2"
        bgColor="#fff"
        pt={20}
        pb={20}
        borderRadius="20px"
      >
        <VStack spacing={10} p={50}>
          <Box>
            <Image src={CheckEmail} />
          </Box>
          <Box>
            <Text fontSize="4xl" fontWeight="600">
              Redirecting...
            </Text>
          </Box>
          <Box>
            <Spinner size="xl" />
          </Box>
        </VStack>
      </Box>
    </Center>
  );
}

export default RedirectPage;
