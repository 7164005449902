import { Container } from '@chakra-ui/react';
import VideoIsProcessing from '../../components/previews/VideoIsProcessing';
import VideoPlayer from '../../components/previews/VideoPlayer';
import VideoNoAccess from '../../components/previews/VideoNoAccess';
import { useContext } from 'react';
import SingleVideoContext from '../../context/SingleVideoContext';

const HeroVideoStory = () => {
  const { getVimeoId, isCompleted, isPrivate } = useContext(SingleVideoContext);
  const vimeoId = getVimeoId();

  return (
    <Container px={0} bg="primary.default" mt={{ base: '80px', md: '100px' }}>
      <Container maxW="1000px" px={0}>
        {vimeoId ? (
          <VideoPlayer vimeoId={vimeoId} />
        ) : isCompleted() ? (
          <VideoNoAccess isPrivate={isPrivate()}/>
        ) : (
          <VideoIsProcessing componentSize="large" />
        )}
      </Container>
    </Container>
  );
};

export default HeroVideoStory;
