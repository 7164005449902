import React, { useContext, useEffect } from 'react';
import { HttpContext } from '../context/HttpContext';
import SingleVideoContext from '../context/SingleVideoContext';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import {
  getDefaultDuration,
  getImageUrlFromUser,
  // getVimeoIdFromVimeoVideo,
} from '../util';

export function normalizedSingleVideoData(data) {
  if (data?.length > 0) {
    // return [];
    return data.map((item) => {
      return {
        ...item,
        poster:
          item.upload?.thumb ??
          'https://placeimg.com/720/405/' + Math.floor(Math.random() * 100),
        firstName: item.owner?.firstName,
        lastName: item.owner?.lastName,
        avatar: getImageUrlFromUser(item.owner),
        duration: getDefaultDuration(item.upload?.duration),
        status: item.upload?.transcodingStatus === 'complete' ? 'uploaded' : 'processing',
        exampleForNestedObject: {
          singlePageUrl: '/videos/' + item.slug,
        },
      };
    });
  }

  return [];
}

function arrayUnique(array) {
  let a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
}

function UseSingleVideo({ children }) {
  const navigate = useNavigate();
  const { slug } = useParams();

  const { authAxios, publicAxios } = useContext(HttpContext);
  const { updateState } = useContext(SingleVideoContext);
  const { getAuthUser, isAuthenticated } = useContext(AuthContext);
  const { slug: userSlug , id: loggedUserId } = getAuthUser();
  const axiosInstance = isAuthenticated() ? authAxios : publicAxios;

  useEffect(() => {
    const getData = async () => {
      try {
        let { data } = await axiosInstance.get(`videos/${slug}`);
        const { data: relatedData } = await axiosInstance.get(`/videos/${slug}/related`, {
          headers: { Accept: 'application/ld+json' },
        });
        let related = relatedData['hydra:member'];
        let total = relatedData['hydra:totalItems'];
        if (total === 0) {
          const { data: userVideos } = await axiosInstance.get(
            `/users/${data.owner.slug}/videos?page=1&itemsPerPage=20`,
            {
              headers: { Accept: 'application/ld+json' },
            }
          );
          related = arrayUnique(related.concat(userVideos['hydra:member']));
          related = related.filter((x) => x.slug !== slug);
          related = related.slice(0, 20);
        }

        const relatedVideos = normalizedSingleVideoData(related);

        updateState({
          // data: { ...data, canReport, canEdit, relatedVideos, canRequestPermission },
          data: { ...data, relatedVideos },
          status: 'resolved',
          error: null,
        });
      } catch (onError) {
        console.log(onError);
        const status = onError.response?.status ?? null;
        if (status === 404) {
          navigate(`/`, { replace: true });
        }
        // console.log(onError.response);
        // console.log(typeof onError);
        // console.log(JSON.stringify(onError));
        // for (let key in onError) {
        //   if (onError.hasOwnProperty(key)) {
        //     console.log(onError[key]);
        //   }
        // }
        updateState({
          data: null,
          status: 'rejected',
          error: 'Something went wrong',
        });
        // if (userSlug === undefined) {
        //   navigate(`/login`, { replace: true });
        // } else {
        //   navigate(`/channel/${userSlug}`, { replace: true });
        // }
      }
    };

    getData();
  }, [axiosInstance, slug, isAuthenticated, loggedUserId, userSlug, navigate]);

  return <>{children}</>;
}

export default UseSingleVideo;
