import {
  Container,
  Text,
  Box,
  Image,
  Grid,
  GridItem,
  Flex,
  Show,
} from '@chakra-ui/react';
import PhotoSrc from '../../assets/img/photos/photo-38.webp';
import PhotoCompressedSrc from '../../assets/img/photos/photo-38-compressed.jpg';
import DotsSrc from '../../assets/vectors/purpleLightDottedTriangle.svg';
import PictureOptimizedSingle from '../UI/PictureOptimizedSingle';

const SectionCollage7 = () => {
  return (
    <Container px={0} py={{ base: '60px', '2xl': '88px' }}>
      <Container maxW="1562px">
        <Grid
          templateColumns={{ md: 'repeat(2, 1fr)' }}
          columnGap={{ md: '32px', lg: '100px' }}
          rowGap={10}
          alignItems="center"
        >
          <GridItem>
            <Flex pos="relative" justifyContent={{ base: 'center', md: 'right' }}>
              <Show above="2xl">
                <Image
                  src={DotsSrc}
                  pos="absolute"
                  top="-60px"
                  left="0px"
                  transform="scaleX(-1)"
                />
              </Show>
              <PictureOptimizedSingle
                src={PhotoSrc}
                fallbackSrc={PhotoCompressedSrc}
                borderRadius="10px"
                width="648px"
                position="relative"
                zIndex="1"
              />
            </Flex>
          </GridItem>
          <GridItem>
            <Box maxW="600px">
              <Box display="inline-block" textAlign="left">
                <Text as="h2" textStyle="sectionHeadline">
                  Take away ”if only…” regrets
                </Text>
                <Box w="120px" h="3px" bg="accent.default" mt="30px"></Box>
              </Box>
              <Text textStyle="sectionText" mt="40px">
                How much would you like to be able to show your future grandchildren an
                interview you’ve recorded with their great-grandparents? Or it could be a
                family friend, neighbour, or even the woman from the corner shop who came
                here from another country. Most people enjoy being asked about their lives
                and memories, so go ahead and don’t wait until tomorrow.
              </Text>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Container>
  );
};

export default SectionCollage7;
