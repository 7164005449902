import { Link as RouterLink } from 'react-router-dom';

import { Box, Container, Flex, Link, Image } from '@chakra-ui/react';

import Logo from '../assets/img/logo.svg';

const LayoutHeaderPlain = () => {
  return (
    <Box as="header" maxH="6vh" w="full" pt={4} pb={3}>
      <Container>
        <Flex alignItems="center" justifyContent="space-between">
          <Link as={RouterLink} to="/" pointerEvents="auto">
            <Image src={Logo} />
          </Link>
        </Flex>
      </Container>
    </Box>
  );
};

export default LayoutHeaderPlain;
