import {
  useRadioGroup,
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import RadioCardPrivacy from './RadioCardPrivacy';
import { useController, Control, FieldValues } from 'react-hook-form';
import { PRIVACY_OPTIONS } from '../../../util/consts';

const RadioGroupPrivacy = ({ control, name, label }) => {
  const {
    field,
    formState: { errors },
  } = useController({
    control,
    name,
  });

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange: field.onChange,
    value: field.value,
    // defaultValue: 'public', // (in uncontrolled mode)
  });

  const group = getRootProps();

  return (
    <FormControl isInvalid={!!errors[name]}>
      <FormLabel textStyle="formLabels">{label}</FormLabel>
      <Box {...group}>
        {PRIVACY_OPTIONS.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCardPrivacy key={value} {...radio}>
              {value}
            </RadioCardPrivacy>
          );
        })}
      </Box>
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default RadioGroupPrivacy;
