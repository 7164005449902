import { Icon } from '@chakra-ui/react';

export const IconBronzeMedal = (props) => (
  <Icon width="60" height="65" viewBox="0 0 60 65" {...props}>
    <path
      fill="#41596B"
      d="M44.815 64.999c-1.421-2.471-2.83-4.942-4.264-7.413a1865.106 1865.106 0 0 0-3.944-6.814c.18-.064.346-.153.525-.204 4.085-1.172 7.683-3.21 10.73-6.165 1.127-1.108 2.126-2.343 3.176-3.528 2.957 5.095 5.928 10.19 8.963 15.412-1.486-.077-2.843-.127-4.187-.204-1.908-.102-3.816-.23-5.737-.306-.217-.012-.563.128-.653.293-1.51 2.904-2.996 5.821-4.494 8.738-.026.05-.013.115-.013.178-.025.013-.064.013-.102.013ZM23.38 50.786c-2.663 4.611-5.339 9.209-8.002 13.82-.077.127-.116.255-.18.382h-.128c-.076-.191-.128-.395-.217-.573-1.37-2.675-2.753-5.337-4.085-8.025-.269-.547-.576-.662-1.165-.624-3.137.204-6.274.357-9.603.523 3.009-5.197 5.954-10.267 8.899-15.336.14.153.294.293.422.459 2.536 3.26 5.621 5.846 9.386 7.578 1.51.713 3.111 1.21 4.673 1.796Z"
    />
    <path
      fill="#FF9838"
      d="M23.38 50.786c-1.562-.586-3.162-1.083-4.673-1.784-3.765-1.732-6.85-4.33-9.386-7.578-.128-.166-.281-.306-.422-.459-1.204-1.821-2.318-3.68-3.111-5.731-.948-2.459-1.537-4.993-1.716-7.63-.448-6.75 1.46-12.8 5.723-18.036C13.88 4.55 19.181 1.442 25.608.385 32.907-.812 39.59.78 45.506 5.2c5.493 4.101 8.847 9.578 9.987 16.316 1.165 6.878-.307 13.208-4.238 18.99l-.23.383c-1.05 1.184-2.05 2.42-3.176 3.528-3.035 2.968-6.632 4.993-10.73 6.165-.18.05-.346.14-.525.204-2.15.293-4.302.713-6.466.828-2.253.101-4.532-.268-6.748-.828ZM29.936 7.11c-10.422.038-18.694 8.254-18.796 18.444-.103 10.724 8.348 18.94 18.783 18.965 10.538.026 18.656-8.24 18.822-18.405.18-10.661-8.463-19.03-18.809-19.004Z"
    />
    <path
      fill="#CE7430"
      d="M29.936 7.11c10.346-.013 18.989 8.342 18.81 19.003-.167 10.177-8.285 18.43-18.823 18.405-10.435-.025-18.886-8.228-18.783-18.965.102-10.19 8.373-18.405 18.796-18.444Zm-8.49 30.046c-.038.968.705 1.427 1.55.994 2.023-1.032 4.046-2.064 6.03-3.16.68-.369 1.23-.356 1.896 0 1.959 1.07 3.944 2.077 5.915 3.121.423.23.833.332 1.242.013.385-.293.436-.675.359-1.133-.41-2.28-.768-4.573-1.19-6.866-.103-.573.063-.968.46-1.35a257.635 257.635 0 0 0 4.98-4.904c.231-.242.36-.7.334-1.057-.039-.471-.461-.65-.922-.726-2.292-.331-4.571-.688-6.863-.98-.653-.09-1.024-.37-1.306-.943-.973-2.013-1.985-4.025-2.97-6.037-.231-.459-.474-.854-1.076-.841-.564.013-.794.395-1.012.828a2693.88 2693.88 0 0 1-2.996 6.088c-.23.471-.525.79-1.075.866-2.267.319-4.533.675-6.8.981-.537.076-1.01.166-1.203.752-.205.585.18.904.538 1.248 1.613 1.566 3.24 3.107 4.827 4.712.282.293.5.803.486 1.21-.025.777-.243 1.542-.384 2.306-.268 1.643-.55 3.286-.82 4.878Z"
    />
    <path
      fill="#FEFEFD"
      d="M21.447 37.158c.27-1.58.55-3.223.832-4.866.129-.764.346-1.528.385-2.305.012-.408-.205-.917-.487-1.21-1.575-1.605-3.214-3.146-4.827-4.713-.359-.344-.73-.662-.538-1.248.205-.586.679-.675 1.204-.752 2.266-.318 4.532-.662 6.799-.98.55-.077.858-.395 1.075-.867a2693.88 2693.88 0 0 1 2.997-6.088c.217-.433.448-.815 1.011-.828.602-.013.845.37 1.076.84.985 2.013 1.997 4.013 2.97 6.038.269.573.653.854 1.306.943 2.292.293 4.571.662 6.863.98.461.064.884.255.922.726.026.344-.102.816-.333 1.058a257.352 257.352 0 0 1-4.98 4.903c-.398.383-.564.79-.462 1.35.41 2.28.781 4.573 1.191 6.866.077.458.026.84-.358 1.133-.41.319-.82.217-1.242-.012-1.972-1.045-3.957-2.051-5.916-3.12-.666-.37-1.216-.383-1.895 0-1.997 1.082-4.02 2.113-6.03 3.158-.859.42-1.588-.038-1.563-1.006Z"
    />
  </Icon>
);
