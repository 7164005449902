import { Icon } from '@chakra-ui/react';

export const IconLogOut = (props) => (
  <Icon viewBox="0 0 41 41" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.0568 3.28701C5.37675 3.28701 4.72457 3.55715 4.2437 4.03801C3.76284 4.51888 3.49269 5.17107 3.49269 5.85111V34.5691C3.49269 35.2491 3.76284 35.9013 4.2437 36.3822C4.72457 36.863 5.37676 37.1332 6.0568 37.1332H14.2619C15.1116 37.1332 15.8004 37.822 15.8004 38.6716C15.8004 39.5213 15.1116 40.2101 14.2619 40.2101H6.0568C4.5607 40.2101 3.12589 39.6158 2.06799 38.5579C1.01009 37.5 0.415771 36.0652 0.415771 34.5691V5.85111C0.415771 4.35502 1.01009 2.9202 2.06799 1.8623C3.12589 0.804403 4.5607 0.210083 6.0568 0.210083H14.2619C15.1116 0.210083 15.8004 0.898876 15.8004 1.74854C15.8004 2.59821 15.1116 3.28701 14.2619 3.28701H6.0568Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5331 8.86581C28.1339 8.26501 29.108 8.26501 29.7088 8.86581L39.9652 19.1222C40.566 19.723 40.566 20.6971 39.9652 21.2979L29.7088 31.5543C29.108 32.1552 28.1339 32.1552 27.5331 31.5543C26.9323 30.9535 26.9323 29.9794 27.5331 29.3786L36.7016 20.2101L27.5331 11.0415C26.9323 10.4407 26.9323 9.46662 27.5331 8.86581Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7235 20.2101C12.7235 19.3604 13.4123 18.6716 14.2619 18.6716H38.8773C39.727 18.6716 40.4158 19.3604 40.4158 20.2101C40.4158 21.0598 39.727 21.7485 38.8773 21.7485H14.2619C13.4123 21.7485 12.7235 21.0598 12.7235 20.2101Z"
    />
  </Icon>
);
