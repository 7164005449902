import { Icon, Box } from '@chakra-ui/react';

// 4th carousel component (last)
export const CarouselPlay = () => (
  <Box w={[5, 6, 8]}>
    <Icon width="30" height="30" viewBox="0 0 39 38" fill="none">
      <circle cx="19.5525" cy="19.3571" r="18.6423" fill="#E81E42" />
      <path
        d="M29.0273 20.0974L13.7641 28.9096L13.7641 11.2851L29.0273 20.0974Z"
        fill="#FFF6F7" 
      />
    </Icon>
  </Box>
);
