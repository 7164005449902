import { Text } from '@chakra-ui/react';
import DiscoverSrc from '../assets/vectors/discover.svg';
import WatchSrc from '../assets/vectors/watch.svg';
import UploadSrc from '../assets/vectors/upload.svg';
import ShareSrc from '../assets/vectors/share.svg';
import DiscoverDarkSrc from '../assets/vectors/discoverDark.svg';
import WatchDarkSrc from '../assets/vectors/watchDark.svg';
import UploadDarkSrc from '../assets/vectors/uploadDark.svg';
import ShareDarkSrc from '../assets/vectors/shareDark.svg';
import PeopleDarkSrc from '../assets/vectors/peopleDark.svg';
import EduDarkSrc from '../assets/vectors/eduDark.svg';

export function renderStaticSubtitle(text) {
  return (
    <Text
      as="h2"
      textStyle="headingSemiBold"
      fontSize={{ base: '24px', lg: '30px' }}
      lineHeight="150%"
      mb="20px"
    >
      {text}
    </Text>
  );
}

export const FAQ_ITEMS = [
  {
    title: 'What is the purpose of storyvault.com?',
    text: 'We want to preserve important memories or eyewitness accounts from ordinary people, and make them available to future generations of your family and to the wider world – for study, research, or personal interest.',
  },
  {
    title: 'Why would I want to interview my family members?',
    text: 'Because in months or years to come you may find it’s too late to discover more about your family’s unique stories. And how nice will it be to be able to show your own grandchildren a recorded interview with their great great grandparents?',
  },
  {
    title: 'Is it only about older people?',
    text: 'No. Older people frequently have great stories, but you don’t have to be old to have important experiences to record. We want to preserve stories from anyone who has witnessed or been part of any event which may be of interest to future generations and students of history.',
  },
  {
    title: 'Who else might be a good subject for interview',
    text: "You’ll find that people may sometimes say “nothing interesting has ever happened to me”, but when you get them talking, you’ll be amazed! You might easily discover that the older man who runs the post office was a ’mod’ in the sixties or saw The Beatles in concert. The older lady next door with an unfamiliar accent will almost certainly have an extraordinary story to tell about how she came to this country. You’ll usually find that people love being asked about their life-stories, and you may well make a new friend in the process.",
  },
  {
    title: 'What sort of questions should I be asking?',
    text: 'Maybe the person has a particular story they’d like to tell, like the day they met someone famous or were caught up in a major incident. If they’re older, perhaps they served in the armed forces or took part in the Grosvenor Square anti-war demonstrations? They may have experienced racism in this country or abroad, or danced at the famous Hacienda club in Manchester. Perhaps you could ask “how did our family come to this country in the first place?” or “what was the best moment of your life?” Or even “what was the worst?”',
  },
  {
    title: 'How should I set up my interview?',
    text: 'Try to find somewhere quiet and well-lit, and make sure the person you are interviewing is sitting comfortably. Ideally they should have some natural light falling on their face, and definitely not directly behind them. Make sure phones are switched to silent and you’re unlikely to be interrupted. Put your phone or camera on a steady surface and try to include at least head and shoulders, and perhaps include anything about the immediate surroundings which adds interest or information about the sitter.',
  },
  {
    title: 'What are the benefits of inter-generational dialogue?',
    text: 'It’s understandable that young people may have little interest in their older relatives. But sooner or later many of us start asking questions like “who am I?” and “where did I come from?” Encouraging young people to interview their older family, friends or neighbours now will enable them to understand their roots and heritage. It also encourages respect for older people and enhances family values. The same goes for interviewing younger people who have a story to tell – listening to others helps encourage mutual understanding and empathy.',
  },
  {
    title: 'Who can view any videos I upload onto Storyvault?',
    text: 'The Storyvault mission is to share your stories and memories as widely as possible. However you can decide to keep your uploaded videos private for your own use only, or to share them with named people, or to allow them to be viewed by anyone visiting the Storyvault platform.',
  },
  {
    title: 'Who will own my uploaded videos?',
    text: 'You will always own the copyright in any videos you upload, and will be able to edit or delete them at will.  However by uploading them you accept and acknowledge that they may be used for research, study, or just the interest of other Storyvault users.',
  },
  {
    title: 'Who will be able to comment on my videos?',
    text: 'Any Storyvault user who has signed in will be able to comment on any content you upload onto the site. We will inform you when a new comment has been made. You will then be able to reply to any comment or, if you find a comment offensive, you may immediately delete it.',
  },
  {
    title: 'How does Storyvault control the content of the site? ',
    text: 'Storyvault is free of charge to users and all content is generated by our users. We will respond as quickly as possible to any reports of abuse or inappropriate material. However we cannot guarantee an instant response, and cannot be held liable for any infringement of copyright, defamation, or other breaches of the law or our own rules committed by Storyvault users.',
  },
  {
    title: 'Will my uploaded videos be stored safely for ever?',
    text: 'That’s our hope and intention – we’d like to think your great grandchildren will be able to see your interview with your grandma! But of course we cannot give any guarantees. Therefore we strongly advise Storyvault users to keep their own secure copy of their videos or other uploaded content.',
  },
  {
    title: 'Will my videos be used by any other site or media?',
    text: 'Aside from your own friends and family, the most frequent use of your uploaded material will be by students of history or others with an interest in the subject you’re covering. However Storyvault Ltd is also a TV production company, and by uploading your content onto the site (and marking it as available to the wider public), you are giving Storyvault the right to use your content in TV productions. Please see Terms and Conditions for more details.',
  },
  {
    title: 'What will you do with my personal information or data?',
    text: 'Unless you give us further permission to do so, we will not sell information about you to any outside company or advertiser. We will only use information you give us to enable us to invite you to view content relevant to your interests.',
  },
];

export const ORNAMENT_LIST_LANDING = [
  { iconColor: 'secondary.light', text: 'Encourages understanding of history' },
  {
    iconColor: 'accent.light',
    text: 'Watch and enjoy interviews with family members living abroad',
  },
  {
    iconColor: 'secondary.light',
    text: 'Encourages respect for the elderly and “family values”',
  },
  { iconColor: 'accent.light', text: 'Underpins our sense of personal identity' },
];

export const FEATURES = [
  {
    src: DiscoverSrc,
    srcDark: DiscoverDarkSrc,
    title: 'Discover',
    text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur culpa.',
  },
  {
    src: WatchSrc,
    srcDark: WatchDarkSrc,
    title: 'Watch',
    text: 'Nibh ipsum consequat nisl vel pretium lectus quam. Platea dictumst quisque sagittis',
  },
  {
    src: UploadSrc,
    srcDark: UploadDarkSrc,
    title: 'Upload',
    text: 'Pharetra convallis posuere morbi leo urna molestie at elementum eu. ',
  },
  {
    src: ShareSrc,
    srcDark: ShareDarkSrc,
    title: 'Share',
    text: 'Ipsum consequat nisl vel pretium lectus quam id. Adipiscing elit pellentesque habitant morbi tristique. ',
  },
];

export const TERMS_LIST = [
  {
    text: 'The storyvault.com website (the Site) is owned and operated by Storyvault Ltd, company number 14106851, registered at 9 Winckley Square, Preston, PR1 3HP. United Kingdom. By using the Site you agree to be bound by these terms (the Terms) together with the privacy and cookie policy accessible in the Site (the Privacy Policy and the Cookie Policy respectively).',
  },
  {
    text: 'These Terms, the Privacy Policy and the Cookie Policy affect your legal rights and obligations so please read them carefully. If you do not agree to be bound by these Terms, the Privacy Policy and/or the Cookie Policy, do not use the Site.',
  },
  {
    text: 'We reserve the right to update these Terms from time to time at our discretion. If we do so, the updated version will be effective as soon as it is accessible. You are responsible for regularly reviewing these Terms so that you are aware of any changes to them.',
  },
  {
    text: 'To register on the Storyvault.com website you must be at least 11 years old. If you are aged between 11 and 16 years old, you must obtain your parent or guardian’s consent to register on the Site.',
  },
  {
    text: 'When you register at the Site, you provide certain information which we shall process in accordance with the terms of our Privacy Policy.  You are responsible for all activities that occur under your account.  You may only register on the Site using your own details – so you cannot register in someone else’s name.  All information you provide to us on registration must be accurate and complete.',
  },
  {
    text: 'You may register an account or log on to your account via a third-party network such as Facebook or Google. To do this, you hereby authorize Storyvault to pre-populate the registration information gathered from such third-party networks and to use the third-party credentials to allow you to log on to your account. In doing so you warrant and undertake that you comply and will continue to comply with the terms and conditions applicable to the relevant third-party network.',
  },
  {
    text: 'You can at any time deactivate your account in your settings area. If you do so, Storyvault will delete any data we may hold about you.',
  },
  {
    text: 'Storyvault users must only upload videos, images or other content that you have made or that you are authorised to use. This means that you must not upload videos that you did not make, or use content in your videos that someone else owns the copyright to, such as music tracks, sections of copyrighted programmes or videos made by other people, without necessary authorisations.',
  },
  {
    text: 'Users uploading your own videos onto Storyvault retain the copyright in your content. However by uploading your videos onto the site, you give permission for them to be viewed by those you authorise. You can retain the right to keep videos private, or you can name specific people authorised to view it, or you can make them available to all Storyvault users. (See also clause 19 below).',
  },
  {
    text: 'Storyvault users uploading content to the channel undertake that the material has been produced in good faith, that it is not defamatory of any living person, that it will not include any nudity or indecency, that it will not incite anyone to violence or hatred, and that the content is a reasonable, fair and accurate account of the events described.',
  },
  {
    text: 'By uploading content to Storyvault, you are giving permission for authorised site visitors to make use of your content for any purposes of research, study, or personal interest. This may mean that your videos and the people featured in them are quoted in essays, books, on-line, in new videos, or in any other format now known or still to be invented.',
  },
  {
    text: 'Storyvault users uploading content to the site accept that the site administrators reserve the right to edit or delete content they deem to be inappropriate for any reason. This may include, but is not limited to, issues of inaccuracy, unfairness, defamation, decency, or merely that the content is deemed inappropriate to the purposes of the Storyvault platform. Storyvault administrators may give reasons for editing or deleting content, but are not obliged to do so. In all cases their decisions in such matters will be final and not subject to appeal.',
  },
  {
    text: 'Storyvault users may not use the platform for the purposes of advertising products or services for commercial or other use.',
  },
  {
    text: 'Storyvault will use reasonable endeavours to comply with users’ instructions concerning those authorised to view your content. However the platform cannot be held responsible for inadvertent or accidental viewing by others, or if those authorised to view your videos share their content with other unauthorised people.',
  },
  {
    text: 'Any Storyvault user wishing to quote from or use extracts of videos on the site remains subject to the same laws regarding fairness, defamation, incitement etc as any other content creator. Storyvault.com will take reasonable measures to ensure that content found on the site is not misused. However it is the responsibility of those using content on the platform to ensure that their use is fair, legal and honest. The Storyvault platform accepts no responsibility for any such misuse, and by uploading content onto the site, you acknowledge that you hold the site blameless for any misuse or abuse of your content by site users.',
  },
  {
    text: 'Storyvault users uploading content onto the storyvault.com platform may edit or delete their own content at any time. However this cannot operate retrospectively, and uploading users must accept that their content may already have been used or copied by platform users who have previously been authorised to do so.',
  },
  {
    text: 'Storyvault users may comment on videos uploaded by others; however in doing so they must ensure that nothing written is abusive, defamatory, offensive or otherwise inappropriate. Any user abusing others may be removed from site membership.',
  },
  {
    text: 'Storyvault users uploading videos acknowledge that other users of the site may comment on their content, and that such comments may be unwelcome, unfair, abusive or defamatory. Users uploading content are able to delete any such comments as soon as you see them, and are invited to report them to Storyvault administrators. Storyvault will take reasonable measures to deal with such reports in a timely fashion. However users uploading content accept that the platform has no responsibility or liability for unwelcome, unfair or defamatory comments made by other users of the platform.',
  },
  {
    text: 'Storyvault.com is a free service provided to its users. In return for the free on-line service, Storyvault users uploading content agree that Storyvault Ltd may use and include uploaded content in its productions for TV or other media, without further payment. By uploading content onto the site, copyright owners give to Storyvault Ltd the complete right to use and include videos in programmes for all media, whether now known or still to be invented. Storyvault will use reasonable endeavours to inform copyright owners of the inclusion of their content in such programmes.',
  },
];

export const ABOUT_STORIES = [
  {
    src: WatchDarkSrc,
    title: 'Engaging & unique stories revealed',
    text: 'Everyone has a life-story to tell - they don’t have to be famous to be fascinating - so let’s record and upload video interviews with family, friends and neighbours to keep them safe for future generations.',
  },
  {
    src: PeopleDarkSrc,
    title: 'Learning tool for history students',
    text: 'Help us to create an easily searchable archive of living memories, bringing our past to life for use by historians, students and anyone interested in how we came to be who we are today. Stories from the sinking of the Titanic to the fall of Berlin Wall – or just what we used to do on our holidays!',
  },
  {
    src: EduDarkSrc,
    title: 'Cherished family heirloom for posterity',
    text: 'Storyvault encourages inter-generational dialogue, promoting respect and family values. So record and pass on your family’s unique stories so that future generations will get to know what your life was really like, and where they came from. Recollect most treasured family memories through video interviews that will echo into the future for your descendants.',
  },
];
