import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Container } from '@chakra-ui/react';
import SectionDofeFirst from '../components/sections/SectionDofeFirst';
import SectionDofeSecond from '../components/sections/SectionDofeSecond';
import SectionDofeThird from '../components/sections/SectionDofeThird';
import SectionDofeFourth from '../components/sections/SectionDofeFourth';
import SectionDofeFifth from '../components/sections/SectionDofeFifth';
import SectionCourses from '../components/sections/SectionCourses';
import SectionInfoParticipants from '../components/sections/SectionInfoParticipants';
import SectionConclusion5 from '../components/sections/SectionConclusion5';
import SectionDocDownloadLinks from '../components/sections/SectionDocDownloadLinks';

const PublicHomePage = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const isAuthenticatedUser = isAuthenticated();

  return (
    <Container mt={{ base: '80px', md: '100px' }} p={0}>
      <SectionDofeFourth />
      <SectionDofeFirst isAuthenticated={isAuthenticatedUser} />
      <SectionDofeSecond />
      <SectionDofeThird isAuthenticated={isAuthenticatedUser} />
      <SectionDofeFifth />
      <SectionCourses />
      <SectionInfoParticipants />
      <SectionDocDownloadLinks />
      {!isAuthenticatedUser && <SectionConclusion5 />}
    </Container>
  );
};

export default PublicHomePage;
