import { Container, Text, Box } from '@chakra-ui/react';

const SectionIntro = () => {
  return (
    <Box bg="secondary.lighter">
      <Container
        maxW="1562px"
        py={{ base: '80px', '2xl': '120px' }}
        pos="relative"
        overflow="hidden"
      >
        {/* <Show above="2xl">
        <Image src={DotsSrc} pos="absolute" top="24px" left="16px" />
      </Show> */}
        <Container maxW="900px" p={0} textAlign="center" pos="relative">
          <Box display="inline-block" textAlign="center" mb="40px">
            <Text as="h2" textStyle="sectionHeadline">
              What is Storyvault?
            </Text>
            {/* <Box w="120px" h="3px" bg="accent.default" mt="30px"></Box> */}
          </Box>

          <Container maxW="950px" p={0}>
            <Text textStyle="sectionText">
              You don’t have to be famous to have great stories, and so Storyvault is a
              living archive of fascinating memories told by ordinary people. They can be
              anything from living through the Blitz to your first time at Glastonbury.
              From experiencing racism to meeting the Queen. From the happiest day of your
              life, to the worst. Storyvault users record and upload video interviews with
              family, friends or neighbours. They become a precious archive for your
              family and friends, but also a unique resource for students or for anyone
              else interested in our shared history.
            </Text>
          </Container>
        </Container>
      </Container>
    </Box>
  );
};

export default SectionIntro;
