import { Icon } from '@chakra-ui/react';

export const IconReply = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.79709 3.47826C5.1821 3.47826 4.59229 3.72256 4.15743 4.15743C3.72256 4.59229 3.47826 5.1821 3.47826 5.79709V34.0622L8.62531 28.9151C8.95146 28.589 9.39382 28.4058 9.85506 28.4058H34.2029C34.8178 28.4058 35.4077 28.1615 35.8425 27.7266C36.2774 27.2917 36.5217 26.7019 36.5217 26.0869V5.79709C36.5217 5.1821 36.2774 4.5923 35.8425 4.15743C35.4077 3.72256 34.8179 3.47826 34.2029 3.47826H5.79709ZM1.69793 1.69793C2.7851 0.610763 4.25961 0 5.79709 0H34.2029C35.7403 0 37.2149 0.610763 38.302 1.69793C39.3892 2.78509 40 4.25961 40 5.79709V26.0869C40 27.6244 39.3892 29.0989 38.302 30.1861C37.2149 31.2733 35.7403 31.884 34.2029 31.884H10.5754L2.96888 39.4906C2.47149 39.988 1.72346 40.1367 1.07359 39.8676C0.423725 39.5984 0 38.9642 0 38.2608V5.79709C0 4.25961 0.610763 2.7851 1.69793 1.69793Z"
    />
  </Icon>
);
