import { Container, Text, Box, Image, Stack, StackDivider } from '@chakra-ui/react';
import LogoDofea from '../../assets/img/photos/logo-dofea.svg';
import Logo from '../../assets/img/logo.svg';

const SectionDofeFourth = () => {
  return (
    <Box bg="white">
      <Container
        maxW="1562px"
        py={{ base: '40px', md: '80px', lg: '140px' }}
        pos="relative"
        overflow="hidden"
      >
        <Container maxW="900px" p={0} textAlign="center" pos="relative">
          <Box display="inline-block" textAlign="center">
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '20px', md: '54px' }}
              justify="center"
              align="center"
              divider={<StackDivider borderColor="primary.light" />}
              mb={{ base: '20px', md: '80px', xl: '140px' }}
            >
              <Image
                src={Logo}
                alt="Storyvault logo"
                height={{ base: '80px', lg: '120px' }}
              />
              <Image src={LogoDofea} alt="Duke of Edinburg's award logo" height="120px" />
            </Stack>
            <Text as="h1" textStyle="sectionHeadlineSm">
              Storyvault.com is free, easily accessible, and can be used for the skills
              section of your Duke of Edinburgh Award
            </Text>
          </Box>
        </Container>
      </Container>
    </Box>
  );
};

export default SectionDofeFourth;
