import { Icon } from '@chakra-ui/react';

export const IconCopied = (props) => (
  <Icon viewBox="0 0 65 40" {...props}>
    <path
      fill="currentColor"
      d="M61.9741 0.289448C61.5304 0.526268 58.1154 4.28909 46.2836 17.5642C37.961 26.9055 31.1174 34.5496 31.0502 34.5759C30.983 34.589 27.6889 31.0762 23.7226 26.7476C19.7428 22.4191 16.3143 18.7352 16.0991 18.5641C15.6151 18.1826 14.553 18.051 13.9614 18.2878C13.3967 18.5115 12.7782 19.2483 12.6706 19.814C12.4824 20.8008 12.6572 21.0376 17.2554 26.0372C19.6621 28.6422 21.6251 30.8262 21.6251 30.892C21.6251 31.0236 18.5596 34.5101 18.3848 34.5759C18.331 34.6022 14.9832 31.0236 10.9362 26.6424C6.8892 22.248 3.39345 18.551 3.16488 18.4063C2.64052 18.1037 1.72624 18.0773 1.10777 18.3668C0.153158 18.8141 -0.303979 20.235 0.220384 21.1429C0.34139 21.3665 4.18671 25.603 8.74463 30.5631C15.5882 37.9966 17.1344 39.6017 17.5916 39.7859C18.9226 40.299 19.2453 40.0885 22.0823 36.9046C23.3999 35.4179 24.5158 34.1812 24.5831 34.1417C24.6368 34.1154 25.82 35.3258 27.2049 36.8388C30.1897 40.0885 30.5258 40.299 31.8435 39.7859C32.3006 39.6017 34.8686 36.7862 48.3407 21.6691C57.1339 11.8279 64.4615 3.56547 64.6497 3.31549C65.0934 2.71029 65.1203 1.52618 64.7035 0.960442C64.3808 0.526268 63.3993 0 62.9018 0C62.7136 0 62.2968 0.131565 61.9741 0.289448Z"
    />
    <path
      fill="currentColor"
      d="M47.7626 2.02564C47.2786 2.23615 47.4668 2.02564 36.4821 14.3403C31.9914 19.3662 28.2267 23.6948 28.1192 23.9447C27.8503 24.5368 27.9578 25.3788 28.3477 25.9183C28.6704 26.3656 29.5444 26.8261 30.0553 26.8392C31.0502 26.8524 31.0368 26.8655 40.8786 15.827C46.176 9.90652 50.4382 5.01222 50.5726 4.70961C51.3524 3.05187 49.497 1.28887 47.7626 2.02564Z"
    />
  </Icon>
);
