import { Container, Flex, Image, Show, Text } from '@chakra-ui/react';
import HeroContactUs from '../components/heroes/HeroContactUs';
import DotsSrc from '../assets/vectors/21dotsGray.svg';
import ContactUsForm from '../components/sections/ContactUsForm';

const ContactUsPage = () => {
  return (
    <>
      <HeroContactUs />

      <Container maxW="1562px" mt={10} mb={{ base: '50px', md: '100px' }} pos="relative">
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Show above="xl">
            <Image src={DotsSrc} mr="30px" />
          </Show>
          <Container
            maxW="1000px"
            borderRadius="15px"
            boxShadow="0px 4px 40px rgba(36, 6, 48, 0.09)"
            mt={{ base: '-100px', md: '-120px' }}
            bg="white"
            pt={{ base: '50px', md: '80px' }}
            pb={{ base: '50px', md: '100px' }}
          >
            <Container maxW="600px" p={0}>
              <Text
                textStyle="bodyMedium"
                fontSize={{ base: '20px', lg: '25px' }}
                lineHeight="150%"
                mb={{ base: '40px', lg: '60px' }}
              >
                Enter your details
              </Text>
            </Container>

            <ContactUsForm />
          </Container>
          <Show above="xl">
            <Image src={DotsSrc} ml="30px" />
          </Show>
        </Flex>
      </Container>
    </>
  );
};

export default ContactUsPage;
