import { Icon } from '@chakra-ui/react';

export const IconJustPlus = (props) => (
  <Icon viewBox="0 0 14 14" {...props}>
    <path
      fill="currentColor"
      d="M7 0a1 1 0 0 1 1 1v4.999L13 6a1 1 0 0 1 0 2l-5-.001V13a1 1 0 0 1-2 0V7.999L1 8a1 1 0 1 1 0-2l5-.001V1a1 1 0 0 1 1-1Z"
    />
  </Icon>
);
