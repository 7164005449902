// import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';

import App from './App';

import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import { customTheme } from './theme/CustomTheme';
import Fonts from './theme/Fonts';
// import '@fontsource/poppins';
// import '@fontsource/poppins/500.css';
// import '@fontsource/poppins/600.css';
// import '@fontsource/poppins/700.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <>
    <ChakraProvider resetCSS theme={customTheme}>
      <Fonts />
      <App />
    </ChakraProvider>
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
