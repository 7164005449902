import { Grid, Box, Text } from '@chakra-ui/react';
import PhotoUploadPreview from './PhotoUploadPreview';

const PhotosUploadPreviews = ({ files: photos, onUpload, onDelete, showError }) => {
  const renderContent = () =>
    photos.length > 0 ? (
      <Box mt={5}>
        <Text fontSize="14px" lineHeight="130%" mb={3}>
          Uploaded photos
        </Text>
        <Grid
          templateColumns={{ base: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)' }}
          columnGap={3}
          rowGap={4}
        >
          {photos.map((photo) => (
            <PhotoUploadPreview
              key={photo.id}
              photo={photo}
              file={photo.file}
              onDelete={onDelete}
              showError={showError}
              onUpload={onUpload}
            />
          ))}
        </Grid>
      </Box>
    ) : null;
  return renderContent();
};

export default PhotosUploadPreviews;
