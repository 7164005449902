import { Icon } from '@chakra-ui/react';

export const IconDeleteThin = (props) => (
  <Icon viewBox="0 0 13 13" {...props}>
    <path
      fill="currentColor"
      d="M0.678813 0.292094C0.984938 -0.0140313 1.48126 -0.0140313 1.78739 0.292094L6.45898 4.96369L11.1306 0.292094C11.4367 -0.0140313 11.933 -0.0140313 12.2392 0.292094C12.5453 0.598219 12.5453 1.09455 12.2392 1.40067L7.56756 6.07227L12.2392 10.7439C12.5453 11.05 12.5453 11.5463 12.2392 11.8524C11.933 12.1586 11.4367 12.1586 11.1306 11.8524L6.45898 7.18084L1.78739 11.8524C1.48126 12.1586 0.984938 12.1586 0.678813 11.8524C0.372687 11.5463 0.372687 11.05 0.678813 10.7439L5.35041 6.07227L0.678813 1.40067C0.372687 1.09455 0.372687 0.598219 0.678813 0.292094Z"
    />
  </Icon>
);
