import React, { useContext, useEffect } from 'react';
import { HttpContext } from '../context/HttpContext';
import {
  INITIAL_ORDER_VIEW_COUNTER,
  INITIAL_TRENDING_VIDEOS_PERP_AGE,
  TrendingVideosContext,
} from '../context/TrendingVideosContext';

function UseTrendingVideos({ children }) {
  const { authAxios } = useContext(HttpContext);
  const { updateState, state } = useContext(TrendingVideosContext);
  const itemsPerPage = state?.perPage ?? INITIAL_TRENDING_VIDEOS_PERP_AGE;
  const orderViewCounter = state?.orderViewCounter ?? INITIAL_ORDER_VIEW_COUNTER;

  useEffect(() => {
    const getData = async () => {
      try {
        updateState({
          ...state,
          status: 'pending',
        });

        const { data } = await authAxios.get(
          `/videos?page=1&itemsPerPage=${itemsPerPage}&order[viewCounter]=${orderViewCounter}&includeInTrending=true`,
          {
            headers: { Accept: 'application/ld+json' },
          }
        );
        const total = data['hydra:totalItems'];
        const items = data['hydra:member'];

        updateState({
          items,
          total,
          status: 'resolved',
          error: null,
        });
      } catch (onError) {
        console.log(onError);
        updateState({
          items: [],
          total: 0,
          status: 'rejected',
          error: 'Something went wrong',
        });
      }
    };

    getData();
  }, [authAxios]);

  return <>{children}</>;
}

export default UseTrendingVideos;
