import { Icon } from '@chakra-ui/react';

export const IconDot = (props) => (
  <Icon viewBox="0 0 9 10" {...props} className={props.iconclass}>
    <ellipse
      className={props.dotclass}
      fill="currentColor"
      cx="4.5"
      cy="5.4975"
      rx="4.5"
      ry="4.49859"
      transform="rotate(-180 4.5 5.4975)"
    />
  </Icon>
);
