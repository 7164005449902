import {
  Container,
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  VStack,
  Link,
  Image,
  Flex,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CategoriesContext } from '../../context/CategoriesContext';
import { getCategoryVector } from '../../util/categories';
import { IconArrowRight } from '../../theme/icons/IconArrowRight';
import DotsSrc from '../../assets/vectors/4dots.svg';
import AnimationPulse from '../UI/AnimationPulse';
import styles from '../../css/animationPulse.module.css';
// import { dummyTags } from '../videos/reactSelectHelpers';

const AllCategoriesContent = () => {
  const { state } = useContext(CategoriesContext);
  const { items } = state;

  return (
    <Container
      maxW={{
        base: '100%',
        sm: '464px',
        md: '928px',
        lg: '946px',
        '2xl': '1428px',
      }}
      mt={{ base: '80px', md: '100px' }}
      pt={{ base: '50px', md: '100px' }}
      pb={{ base: '50px', md: '100px' }}
    >
      <Box>
        <Heading size="lg" textAlign={{ base: 'center', md: 'left' }}>
          Categories
        </Heading>
        <Text
          textStyle="bodyMedium"
          fontSize="20px"
          mt="15px"
          mb="50px"
          textAlign={{ base: 'center', md: 'left' }}
        >
          Explore video stories by category
        </Text>
        <Grid
          templateColumns={{
            base: '1fr',
            sm: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(2, 1fr)',
            '2xl': 'repeat(3, 1fr)',
          }}
          columnGap={{ base: '32px', lg: '50px' }}
          rowGap={{ base: '32px', lg: '50px' }}
        >
          {items.map((item) => {
            if (item.name === 'Other') {
              return null;
            }
            return (
              <GridItem
                key={item.id}
                borderRadius="20px"
                bg="secondary.lighter"
                p="20px"
                pos="relative"
                pb="60px"
                className={styles.animationPulseParent}
              >
                <AnimationPulse />
                <Flex justifyContent="space-between">
                  <Image src={DotsSrc} />
                  {getCategoryVector(item.id)}
                  <Image src={DotsSrc} transform="scaleX(-1)" />
                </Flex>
                <VStack mt="20px">
                  <Link
                    as={RouterLink}
                    to={item?.id ? `/search?category=${item.id}` : `/search`}
                    variant="primaryDefault"
                    textStyle="bodySemiBold"
                    fontSize={{ base: '23px', md: '25px' }}
                    textAlign="center"
                  >
                    <Text as="h2">{item.name}</Text>
                  </Link>
                  {!item.tags.length ? (
                    <Flex alignItems="center" mt="15px !important">
                      <Text fontSize="24px" lineHeight="30px" color="secondary.light">
                        #
                      </Text>
                      <Text color="secondary.mid">&nbsp;No available tags</Text>
                    </Flex>
                  ) : (
                    <Flex
                      flexWrap="wrap"
                      justifyContent="center"
                      m="15px -5px 0 !important"
                    >
                      {item.tags
                        // .sort(() => 0.5 - Math.random())
                        // .slice(0, 3)
                        .map((tag, index) => {
                          return (
                            <Tag
                              as={RouterLink}
                              to={
                                item?.id && tag
                                  ? `/search?category=${item.id}&tag=${encodeURIComponent(
                                      tag.trim()
                                    )}`
                                  : `/search`
                              }
                              key={index}
                              variant="solidDark"
                              m="0 5px 10px"
                              bg={index % 2 === 0 ? 'secondary.mid' : ''}
                            >
                              <TagLabel
                                fontFamily="Poppins Medium, sans-serif"
                                maxW="160px"
                              >
                                <Text isTruncated>#{tag.toLowerCase().trim()}</Text>
                              </TagLabel>
                            </Tag>
                          );
                        })}
                    </Flex>
                  )}
                </VStack>
                <Box
                  textAlign="center"
                  pos="absolute"
                  bottom="20px"
                  left="20px"
                  right="20px"
                >
                  <Link
                    as={RouterLink}
                    to={item?.id ? `/search?category=${item.id}` : `/search`}
                    variant="primaryDefault"
                    fontSize="14px"
                    role="group"
                  >
                    Browse all videos
                    <IconArrowRight
                      fontSize="24px"
                      transition="0.3s"
                      ml="15px"
                      pos="relative"
                      right="0"
                      _groupHover={{ right: '-5px' }}
                    />
                  </Link>
                </Box>
              </GridItem>
            );
          })}
        </Grid>
      </Box>
    </Container>
  );
};

export default AllCategoriesContent;
