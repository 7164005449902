// import { createRef } from 'react';
import { CommentsContextProvider } from '../../context/CommentsContext';
import CommentsNavigation from './CommentsNavigation';
import LeaveComment from './LeaveComment';
import CommentsList from './CommentsList';
import UseVideoComments from '../../hooks/useVideoComments';
import { useEffect, useRef } from 'react';
import { getUrlSearchParams } from '../../util';

const CommentsContent = ({ isVideoOwner, canComment, canReply }) => {
  const ref = useRef(null);
  let query = getUrlSearchParams();
  const section = query.get('section');

  const scrollToLeaveComment = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (ref?.current) {
      if (section === 'leave-comment') {
        scrollToLeaveComment(ref);
      }
    }
  }, [section]);

  // const messagesEndRef = createRef();
  // function scrollToBottom() {
  //   setTimeout(function () {
  //     messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }, 350);
  // }

  return (
    <CommentsContextProvider>
      <UseVideoComments>
        <CommentsNavigation
        // scrollToBottom={scrollToBottom}
        />
        <div ref={ref} />
        {canComment && <LeaveComment />}
        <CommentsList isVideoOwner={isVideoOwner} canReply={canReply} />
        {/*<div ref={messagesEndRef} />*/}
      </UseVideoComments>
    </CommentsContextProvider>
  );
};

export default CommentsContent;
