import { Container, Box, Text, VStack } from '@chakra-ui/react';
// import { renderStaticSubtitle } from '../util/static';

const GuidanceApproachingCareHomesPage = () => {
  return (
    <Container
      maxW="1332px"
      mt={{ base: '120px', md: '150px' }}
      mb={{ base: '50px', md: '100px' }}
    >
      <Box
        pt={{ base: '40px', lg: '60px' }}
        pb={{ base: '60px', lg: '100px' }}
        bg="white"
        borderRadius="20px"
      >
        <Container maxW="1032px">
          <Text
            as="h1"
            textStyle="headingSemiBold"
            fontSize={{ base: '32px', lg: '40px' }}
            lineHeight="150%"
            mb={{ base: '40px', lg: '60px' }}
          >
            Guidance for Storyvault users on approaching and dealing with residents of
            care homes and/or other residences
          </Text>
          <VStack spacing={10} alignItems="flex-start">
            <Box>
              <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                Unless you have an existing family or other connection with a particular
                Care Home, and are therefore already known to the staff there, initial
                contact should be made through your school or by your parents or carer.
                <br />
                <br />
                In general, older people enjoy meeting and speaking to younger people, and
                so your approach will usually be welcomed. Once you have been introduced
                by your school or your carer, you will probably be required to write a
                letter or send an email outlining what you are asking for. You should
                compose this yourself, but it should include the following information:
              </Text>
            </Box>
            <Box>
              <Text
                marginBlock={{ base: 6, md: 12 }}
                marginInline={{ base: 0, md: 12, lg: 16, xl: 20 }}
                fontSize="18px"
                lineHeight="150%"
                color="primary.dark"
              >
                Dear ___________(named person)
                <br />
                <br />
                I am an __ year old pupil at __________________ school. I am undertaking a
                project which requires me to contact and interview one or more older
                people who would enjoy telling some stories from earlier in their lives.
                (The project is part of my bronze/silver/gold medal course for the Duke of
                Edinburgh Award Scheme – delete or amend as necessary.) I would record the
                interview on a smartphone or video-camera, with the intention of uploading
                it onto a website at www.storyvault.com. The object of the website is to
                collect recorded stories which can then be available for friends and
                family, and for students who are studying particular topics from living
                memory.
                <br />
                <br />
                I am writing to ask if any of your residents might be willing to be
                interviewed for this purpose. If so, I would like to arrange a time when I
                can come along to meet them.
                <br />
                <br />I look forward to hearing from you.
              </Text>
            </Box>
            <Box>
              <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                Once you receive a reply from the Care Home, you will need to read and
                take careful note of any rules and policies they apply to visitors.
                Remember that the staff there have an important responsibility for the
                welfare of their residents, and so you should take their rules very
                seriously.
                <br />
                <br />
                Before you go to visit the Care Home, think very carefully about how you
                will describe what you are trying to achieve. Remember that the person may
                not know much about the internet, so do your best to frame your
                explanations clearly.
                <br />
                <br />
              </Text>
              <Text fontSize="18px" lineHeight="150%" color="primary.default">
                <b>
                  For detailed guidance on how to approach your interviewee, and essential
                  safety information, please also read and study our “guidance for young
                  people”, available on storyvault.com.
                </b>
              </Text>
            </Box>
          </VStack>
        </Container>
      </Box>
    </Container>
  );
};

export default GuidanceApproachingCareHomesPage;
