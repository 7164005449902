import { Icon } from '@chakra-ui/react';

export const IconCrown = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M22 19v2H2v-2h20ZM12 2l5 6.5L22 5v12H2V5l5 3.5L12 2Zm0 3.28-4.58 5.955L4 8.84V15h16V8.841l-3.42 2.394L12 5.28Z"
    />
  </Icon>
);

<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"></svg>;
