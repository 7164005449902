import { Icon } from '@chakra-ui/react';

export const IconCamera = (props) => (
  <Icon viewBox="0 0 15 13" {...props}>
    <path
      fill="currentColor"
      d="M7.55273 5.21641C8.71193 5.21641 9.65273 6.15721 9.65273 7.31641C9.65273 8.47561 8.71193 9.41641 7.55273 9.41641C6.39353 9.41641 5.45273 8.47561 5.45273 7.31641C5.45273 6.15721 6.39353 5.21641 7.55273 5.21641ZM12.6861 2.18307C13.7141 2.18307 14.5527 3.02167 14.5527 4.04974V10.5831C14.5527 11.6111 13.7141 12.4497 12.6861 12.4497H2.4194C1.39133 12.4497 0.552734 11.6111 0.552734 10.5831V4.04974C0.552734 3.02167 1.39133 2.18307 2.4194 2.18307H4.05273L4.42467 1.19141C4.60713 0.710273 5.17553 0.316406 5.68607 0.316406H9.4194C9.92993 0.316406 10.4983 0.710273 10.6808 1.19141L11.0527 2.18307H12.6861ZM7.55273 10.5831C9.3536 10.5831 10.8194 9.11727 10.8194 7.31641C10.8194 5.51554 9.3536 4.04974 7.55273 4.04974C5.75187 4.04974 4.28607 5.51554 4.28607 7.31641C4.28607 9.11727 5.75187 10.5831 7.55273 10.5831Z"
    />
  </Icon>
);
