import { Icon } from '@chakra-ui/react';

export const IconPenPlain = (props) => (
  <Icon viewBox="0 0 41 40" {...props}>
    <path
      fill="currentColor"
      d="M33.2533 4.07637C32.848 4.07637 32.4467 4.1562 32.0723 4.31129C31.6978 4.46638 31.3576 4.69371 31.071 4.98028L6.99554 29.0558L5.35887 35.0569L11.36 33.4202L35.4355 9.34474C35.7221 9.05816 35.9494 8.71795 36.1045 8.34352C36.2596 7.9691 36.3394 7.56779 36.3394 7.16251C36.3394 6.75723 36.2596 6.35592 36.1045 5.98149C35.9494 5.60707 35.7221 5.26686 35.4355 4.98028C35.1489 4.69371 34.8087 4.46638 34.4343 4.31129C34.0599 4.1562 33.6585 4.07637 33.2533 4.07637ZM30.5123 0.545213C31.3813 0.185263 32.3127 0 33.2533 0C34.1939 0 35.1252 0.185263 35.9942 0.545213C36.8632 0.905163 37.6528 1.43275 38.3179 2.09785C38.983 2.76295 39.5106 3.55254 39.8706 4.42153C40.2305 5.29054 40.4158 6.22192 40.4158 7.16251C40.4158 8.1031 40.2305 9.03448 39.8706 9.90348C39.5106 10.7725 38.983 11.5621 38.3179 12.2272L13.8597 36.6854C13.6089 36.9362 13.297 37.1172 12.9548 37.2106L2.99029 39.9281C2.28464 40.1206 1.52998 39.9202 1.01279 39.403C0.4956 38.8858 0.295188 38.1311 0.487637 37.4255L3.20522 27.461C3.29855 27.1188 3.47956 26.8069 3.73037 26.5561L28.1886 2.09785C28.8537 1.43275 29.6433 0.905163 30.5123 0.545213Z"
    />
  </Icon>
);
