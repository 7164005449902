import { Icon } from '@chakra-ui/react';

export const IconOpenInFull = (props) => (
  <Icon viewBox="0 0 48 48" {...props}>
    <path
      fill="currentColor"
      d="M6 42V27H9V36.9L36.9 9H27V6H42V21H39V11.1L11.1 39H21V42Z"
    />
  </Icon>
);
