import { Container, Box, Text, VStack } from '@chakra-ui/react';
import { renderStaticSubtitle } from '../util/static';

const GuidanceTeachersPage = () => {
  return (
    <Container
      maxW="1332px"
      mt={{ base: '120px', md: '150px' }}
      mb={{ base: '50px', md: '100px' }}
    >
      <Box
        pt={{ base: '40px', lg: '60px' }}
        pb={{ base: '60px', lg: '100px' }}
        bg="white"
        borderRadius="20px"
      >
        <Container maxW="1032px">
          <Text
            as="h1"
            textStyle="headingSemiBold"
            fontSize={{ base: '32px', lg: '40px' }}
            lineHeight="150%"
            mb={{ base: '40px', lg: '60px' }}
          >
            Guidance for teachers
          </Text>
          <VStack spacing={10} alignItems="flex-start">
            <Box>
              <Text
                fontSize="18px"
                fontStyle="italic"
                lineHeight="150%"
                color="accent.default"
              >
                Storyvault is an exciting and innovative way to preserve the memories of
                those closest to us, whilst at the same time, building a brilliant
                resource to be referenced in all aspects of social history. The notes
                below are intended for young people who wish to use the storyvault
                website, and to record their own interviews and conversations. These
                highlighted notes – in red italics - are for teachers and other adults who
                work with children and young people, just to give a little more guidance.
              </Text>
            </Box>
            <Box>
              {renderStaticSubtitle(
                'How to set up and carry out your Storyvault interview'
              )}
              <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                Most people enjoy being asked to tell stories from their lives.
                <br />
                <br />
                Sometimes people may say “nothing much has happened to me”, but you’ll
                often find that when they start talking, they’ve got amazing tales to
                tell.
                <br />
                <br />
                Here are some thoughts about how to select and approach the person you
                want to interview, and then to make sure your recording is a success.
                <br />
                <br />
              </Text>
              <Text
                fontSize="18px"
                lineHeight="150%"
                fontStyle="italic"
                color="accent.default"
              >
                Adult guidance: The way storyvault works best, is when the conversations
                are between multi-generational pairings – child and grandfather, mum and
                son, niece and great uncle etc. It is also important to explain to the
                young people taking part that the purpose is for their parent, grandparent
                or other talk-partner to recall aspects of their own history, and to pass
                on those stories to others.
              </Text>
            </Box>
            <Box>
              {renderStaticSubtitle('How to select the person to interview')}
              <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                The first people to think about when choosing who to interview might be a
                member of your own family. Maybe a good place to start is the people you
                live with, or those who visit you regularly.
                <br />
                <br />
                If none of these people is available, you might think about asking a
                friend of the family, a teacher or a neighbour. In any case, you should
                always ask your parent or carer if it’s OK to approach someone to ask for
                an interview. They may also give you advice about the best way to make the
                approach.
                <br />
                <br />
                It can be very worthwhile to interview older people living in residential
                care homes or in the community, but you should make any approach through
                your school. Older people may be vulnerable or unwell, and your teachers
                will be able to put in place any measures necessary to ensure that
                everyone is safe.
                <br />
                <br />
              </Text>
              <Text
                fontSize="18px"
                lineHeight="150%"
                fontStyle="italic"
                color="accent.default"
              >
                Adult guidance: Teachers and other adults who work with children and young
                people should carefully consider any specific family circumstances when
                looking for opportunities to create conversations. It is important that
                the needs of children and young people in care; those who have recently
                lost a grandparent; those who may have estranged family members; are
                carefully considered, with thoughtful and meaningful alternatives offered,
                so that the young person taking part doesn’t feel either excluded or
                vulnerable.
                <br />
                <br />
                Speaking with adults outside of the immediate school community, such as
                visiting an old people’s home, may be really valuable. However, it is
                important that adults consider the following: 1) a risk assessment for how
                any visit may take place; 2) the needs and vulnerabilities, as well as the
                medical needs of any resident taking part; 3) the absolute requirement to
                get consent before any filming takes place; 4) agreeing a particular
                topic, with a plan that is adhered to during the visit. Also you might
                consider whether children and young people could visit the home, or
                whether special arranged visits to the school could be made by the
                residents.
              </Text>
            </Box>
            <Box>
              {renderStaticSubtitle('How to approach the person you want to interview')}
              <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                Most people enjoy a good chat, but some people won’t have been recorded
                before, so it’s important that they understand what you are trying to do.
                <br />
                <br />
                It’s best, if possible, to approach the person directly. You might say
                something like, “There is a new website which is collecting people’s
                memories. It’s so that your stories can be preserved for future members of
                the family, or for anyone who’s interested in knowing more about the way
                we live our lives. If you’re happy to let me do so, I’d like to ask you
                some questions, and record your answers on video, and then put the
                recording on the website.”
                <br />
                <br />
                Make sure the person understands what you are asking for, and is freely
                giving permission for you to go ahead. Remember that on storyvault.com you
                can restrict access to your videos to just yourself, or just the people
                you allow to see them.
                <br />
                <br />
              </Text>
              <Text
                fontSize="18px"
                lineHeight="150%"
                fontStyle="italic"
                color="accent.default"
              >
                Adult guidance: It is important that any adult working with children and
                young people on this project, actually checks out the website themselves!
                This may sound obvious, but as soon as you have spent a little time
                exploring, you will see then potential of what is being achieved. It might
                be very useful for you to create an introductory letter from the school or
                other organisation. You know your community, parents and grandparents, so
                it would be good to phrase the letter to meet their expectations. Also
                think about the issues of informed consent which should also be referenced
                in your letter.
              </Text>
            </Box>
            <Box>
              {renderStaticSubtitle('Where and how to make your recording')}
              <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                The most important thing is that you and the person you are interviewing
                are comfortable in your surroundings. Choose two chairs so that you can
                sit opposite the person you will be interviewing, just as in a normal
                conversation.
                <br />
                <br />
                You need to choose an area where you won’t be disturbed by too much noise.
                It would be good to find somewhere with some natural daylight, but don’t
                sit the person you are interviewing directly in front of a window.
                <br />
                <br />
                If there is no natural light, make sure some artificial light is falling
                on the face of your interviewee, but obviously you must make sure they
                aren’t going to be dazzled.
                <br />
                <br />
                If you are interviewing someone who isn’t part of your family, always make
                sure that other people are close by. Don’t ever interview someone you
                don’t know when alone in a room with the door closed.
                <br />
                <br />
                Make sure once again that your interviewee is aware that you are recording
                your conversation, and that the recording will be uploaded onto the
                Storyvault website.
                <br />
                <br />
                Make sure your smart-phone or camera are fully charged, and then test your
                device by recording a few words and playing them back to ensure that
                everything is working well.
                <br />
                <br />
                Frame your picture so that the person you are interviewing is in the
                centre – not too close or too far away. Sometimes it’s nice to widen the
                shot to include any objects such as family photos or ornaments which will
                help us to know more about your interviewee.
                <br />
                <br />
              </Text>
              <Text
                fontSize="18px"
                lineHeight="150%"
                fontStyle="italic"
                color="accent.default"
              >
                Adult guidance: All interviews taking place in school should be part of a
                trio, as a minimum: e.g. child/young person, interviewee and teacher/other
                adult to look after the filming and to provide adequate safeguarding
                supervision. Try to find a space that isn’t being used for music lessons
                or other activity where you might be disturbed! If space is at a premium,
                you might want to find a quiet space on the field, perhaps. Most schools
                will have access to an iPad or other device, so it is important you ensure
                the device is fully charged before you begin. If you have a specially
                planned activity, such as inviting someone in for an interview, it may
                also be useful to have a backup device – just in case.
              </Text>
            </Box>
            <Box>
              {renderStaticSubtitle('What questions should I be asking?')}
              <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                Before you go to meet the person you are interviewing, think carefully
                about what questions you could ask which will make them feel relaxed and
                talking freely. Make a list, but always listen carefully to the answers
                and don’t be afraid to abandon your prepared questions, and say “tell me
                more about…”
                <br />
                <br />
                It’s nice to begin by asking the interviewee to introduce themselves –
                with their name, their age and place of birth and where they are living
                now.
                <br />
                <br />
                Sometimes your interviewee will have a particular story they want to tell…
                it might be about a famous person they’ve met, or attending their first
                concert, or how they came to live where they do. If so, you could simply
                say “can you tell me about the time when you…?”
                <br />
                <br />
                If they have no particular story to tell, you could start with something
                like, “can you tell me your first memory as a child?” Or perhaps, “can you
                tell me about your holidays when you were growing up?” If it seems
                appropriate, you could ask “how did you first meet your
                husband/wife/partner?” or “can you tell me what it was like going to
                school?” If the person wasn’t born in Britain, you might ask “how and when
                did you first come to this country?”
                <br />
                <br />
                It's always good to get people talking about how things felt, or what they
                were like. We are interested in “I went to school in Wandsworth,” but it’s
                much more interesting to hear about what the teachers were like, what the
                lessons were like, what school-trips they went on, or “did you ever get
                into any trouble?”
                <br />
                <br />
                If you think your interview might last more than five minutes or so, it’s
                good to stop and start again with a new subject of set of questions. This
                will make it easier to upload your videos onto Storyvault, and then easier
                to search for them.
                <br />
                <br />
                When you have come to the end of the questions you want to ask, it’s nice
                to say “is there anything else you’d like to tell me?” Sometimes people
                are burning to tell a fascinating story but don’t get the chance!
                <br />
                <br />
              </Text>
              <Text
                fontSize="18px"
                lineHeight="150%"
                fontStyle="italic"
                color="accent.default"
              >
                Adult guidance: Scaffold the questions by modelling some for the children
                and young people first. Perhaps have a clipboard and some prepared
                question sheet masters. For children and young people with SEND, it may be
                useful to scribe their questions or use alternative communication methods
                which they are used to using.
              </Text>
            </Box>
            <Box>
              {renderStaticSubtitle('What to do next')}
              <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                When you have finished asking your questions, do another test to make sure
                you’ve recorded. Say thank you to the person you’ve been speaking to, and
                ask them if there’s anyone they’d like you to inform once the interview is
                on Storyvault. It may be that they have family or friends in another
                country who would love to see them!
                <br />
                <br />
                At the first opportunity, you should upload your recorded videos onto
                Storyvault. You will need to give your video a name, and then a
                description of who is being interviewed and why. Then think of a few words
                which will help anyone searching for the video on the website. These might
                be words like: “Christmas” or “summer holidays” or “immigration” or “The
                Queen”. now.
                <br />
                <br />
                Once your upload is complete, you can inform your own friends and family,
                as well as friends and family of your interviewee, that your interview is
                available to view on-line.
                <br />
                <br />
                You will receive a notification if another Storyvault user comments on
                your video. If that happens, you can choose to ignore the comment, or
                reply, or remove the comment altogether from the Storyvault website.
                <br />
                <br />
              </Text>
              <Text
                fontSize="18px"
                lineHeight="150%"
                fontStyle="italic"
                color="accent.default"
              >
                Adult guidance: It would be useful to make a school or other organisation
                collective decision about whether each video conversation will be uploaded
                by the individual child/young person through their own account and email
                address, or whether there is one school account. It may be more manageable
                to have a school account, as then it is more controlled, and each
                storyvault interview can be checked for quality and accuracy, as well as
                appropriateness, before being uploaded.
              </Text>
            </Box>
            <Box>
              {renderStaticSubtitle('And finally...')}
              <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                Once you have recorded an uploaded a video interview, it’s there as a
                special memory for family and friends of the person you interviewed, and
                also for students studying the times we live in. You will have done a
                great service for the person, whose memories and stories will live on so
                that other people can enjoy and learn from them. Well done!
                <br />
                <br />
              </Text>
              <Text
                fontSize="18px"
                lineHeight="150%"
                fontStyle="italic"
                color="accent.default"
              >
                Adult guidance: It may be useful to share recorded conversations with
                other teachers, through internal email, so they can see the potential of
                using storyvault too. Remember, as the Storyvault library of conversations
                grows, the vault can be used to listen to other people’s memories, as well
                as contribute your own. Good luck!
              </Text>
            </Box>
          </VStack>
        </Container>
      </Box>
    </Container>
  );
};

export default GuidanceTeachersPage;
