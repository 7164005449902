import { Box, Flex, Avatar, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconAvatar } from '../../theme/icons/IconAvatar';
import LeaveCommentForm from './LeaveCommentForm';
import { COMMENT_EDIT, COMMENT_REPLY_EDIT } from '../../util/consts';

const EditComment = ({
  comment,
  handleShowFormForEdit,
  isEditForReply,
  myAvatarSrc,
  commentForReply,
}) => {
  return (
    <Flex ml={isEditForReply && { base: '40px', md: '60px' }}>
      <Link
        // to={`/channel/${cell.owner.slug}`}
        to={comment.channelUrl}
        as={RouterLink}
      >
        <Avatar
          icon={
            <IconAvatar
              fontSize={{ base: '16px', md: '22px' }}
              color="secondary.lighter"
            />
          }
          src={myAvatarSrc}
          bg="secondary.light"
          boxSize={{ base: '30px', md: '40px' }}
        />
      </Link>
      <Box ml={{ base: '10px', md: '20px' }} w="full">
        <LeaveCommentForm
          comment={comment}
          commentFormType={isEditForReply ? COMMENT_REPLY_EDIT : COMMENT_EDIT}
          handleShowFormForEdit={handleShowFormForEdit}
          commentForReply={commentForReply}
        />
      </Box>
    </Flex>
  );
};

export default EditComment;
