import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Box,
  VStack,
  SimpleGrid,
  GridItem,
  useMediaQuery,
  Text,
  Container,
  useToast,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { HttpContext } from '../../context/HttpContext';
import CustomToast from '../UI/CustomToast';
import CommentsContext from '../../context/CommentsContext';

const DeleteCommentModal = ({ onClose, isOpen, data, commentForReply }) => {
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const { removeComment, removeReply } = useContext(CommentsContext);
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  async function handleConfirmDelete(e) {
    try {
      e.preventDefault();
      if (data?.entityId && data?.type && data?.id) {
        let url = null;
        const isReply = data?.type === 'Reply';
        const isComment = data?.type === 'Comment';

        if (isReply) {
          url = `/replies/${data.id}`;
        } else {
          if (isComment) {
            url = `/comments/${data.id}`;
          }
        }

        if (url) {
          // console.log(`This is deletion of the ${type} with id: ${id}`);
          // const url = data.entityId.substring(data.entityId.indexOf('/') + 1);
          await authAxios({
            method: 'DELETE',
            url,
          });

          const dataType = data?.type.toLowerCase() ?? '';

          toast({
            position: 'top',
            render: (props) => (
              <CustomToast
                status="success"
                description={`You have deleted the ${dataType} successfully.`}
                onClose={props.onClose}
                id={props.id}
              />
            ),
          });

          if (isReply && commentForReply?.id) {
            removeReply(data.id, commentForReply.id);
          } else if (isComment) {
            removeComment(data.id);
          }

          onClose();
        }
      }
    } catch (onErr) {
      console.log(onErr);
      console.log(onErr?.response?.status);
      const isAccessDenied = onErr?.response?.status === 403 || false;


      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="error"
            description={isAccessDenied ? "You don't have permission to delete this comment." : "Something went wrong."}
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
      if (isAccessDenied) {
        onClose();
      }
    }
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg" color="primary.default">
            Delete comment
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container p={0} maxW="600px">
            <VStack
              spacing={5}
              mt={4}
              mb={{ base: 4, md: 9 }}
              textAlign="center"
              fontSize={{ md: '20px' }}
            >
              <Text>Deleting comments is permanent and can’t be undone.</Text>
              <Text color="error.default" textStyle="bodySemiBold">
                Are you sure you want to delete this comment?
              </Text>
            </VStack>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Box maxW="600px" w="full">
            <SimpleGrid
              w="full"
              maxW="600px"
              columns={2}
              columnGap={{ md: '30px' }}
              rowGap={7}
            >
              <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                <Button onClick={onClose} isFullWidth variant="outlineError">
                  Cancel
                </Button>
              </GridItem>
              <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                <Button onClick={handleConfirmDelete} variant="solidError" isFullWidth>
                  Delete
                </Button>
              </GridItem>
            </SimpleGrid>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteCommentModal;
