import {
  Container,
  Text,
  Box,
  Image,
  Grid,
  GridItem,
  Flex,
  Show,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import PhotoSrc from '../../assets/img/photos/photo-doea-03.webp';
import PhotoCompressedSrc from '../../assets/img/photos/photo-doea-03.jpg';
import DotsSrc from '../../assets/vectors/purpleLightDottedTriangle.svg';
import PictureOptimizedSingle from '../UI/PictureOptimizedSingle';

const SectionDofeFifth = () => {
  return (
    <Container bg="primary.white" px={0} py={{ base: '60px', '2xl': '140px' }}>
      <Container maxW="1562px">
        <Grid
          templateColumns={{ md: 'repeat(2, 1fr)' }}
          columnGap={{ md: '32px', lg: '100px' }}
          rowGap={10}
          alignItems="center"
        >
          <GridItem order={{ md: 2 }}>
            <Flex pos="relative" justifyContent={{ base: 'center', md: 'left' }}>
              <Show above="2xl">
                <Image
                  src={DotsSrc}
                  pos="absolute"
                  bottom="-60px"
                  right="0px"
                  transform="scaleY(-1)"
                />
              </Show>
              <PictureOptimizedSingle
                src={PhotoSrc}
                fallbackSrc={PhotoCompressedSrc}
                borderRadius="10px"
                width="648px"
                position="relative"
                zIndex="1"
              />
            </Flex>
          </GridItem>
          <GridItem order={{ md: 1 }}>
            <Box maxW="620px" pl={{ '2xl': '67px' }}>
              <Box display="inline-block" textAlign="left">
                <Text as="h1" textStyle="sectionHeadline">
                  The benefits for users of storyvault.com include:
                </Text>
              </Box>
              <UnorderedList mx="0" mt={{ base: '40px', md: '40px' }}>
                <ListItem mt="20px">Improved communication skills</ListItem>
                <ListItem mt="20px">
                  Improved empathy and sense of personal identity
                </ListItem>
                <ListItem mt="20px">Improved understanding of recent history</ListItem>
                <ListItem mt="20px">
                  Developing journalism and media skills - which may be valuable to a
                  future employer
                </ListItem>
                <ListItem mt="20px">
                  A lasting record of your own, and your family’s story
                </ListItem>
              </UnorderedList>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Container>
  );
};

export default SectionDofeFifth;
