import { VStack } from '@chakra-ui/react';
import CommentsContext from '../../context/CommentsContext';
import { useContext } from 'react';
import SingleComment from './SingleComment';

const CommentsList = ({ isVideoOwner, canReply }) => {
  const commentsListCtx = useContext(CommentsContext);
  const { state, totalComments } = commentsListCtx;
  const {
    items: comments,
    // error, status, total
  } = state;

  return totalComments > 0 ? (
    <VStack spacing={{ base: '30px', md: '40px' }} mt={10}>
      {comments.map((comment) => {
        return (
          <SingleComment
            key={comment.id}
            comment={comment}
            isVideoOwner={isVideoOwner}
            avatarSrc={comment.avatarSrc}
            canReply={canReply}
          />
        );
      })}
    </VStack>
  ) : null;
};

export default CommentsList;
