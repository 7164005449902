import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { HttpContext } from '../context/HttpContext';
import { AuthContext } from '../context/AuthContext';
import CommentsContext, { NEWEST, OLDEST } from '../context/CommentsContext';
import { getImageUrlFromUser } from '../util';
import { getDurationAgo } from '../util/date';

export function normalizedComment(comment, loggedUserId) {
  if (comment) {
    return {
      ...comment,
      // id: comment['@id'],
      entityId: comment['@id'],
      type: comment['@type'],
      content: comment.body,
      time: getDurationAgo(comment.createdAt),
      channelUrl: `/channel/` + comment.owner?.slug,
      firstName: comment.owner?.firstName,
      lastName: comment.owner?.lastName,
      isCommentOwner: parseInt(comment.owner.id, 10) === loggedUserId,
      avatarSrc: getImageUrlFromUser(comment.owner),
      replies: comment?.replies ? normalizedReplyData(comment.replies, loggedUserId) : [],
    };
  }

  return null;
}

export function normalizedReply(reply, loggedUserId) {
  if (reply) {
    return {
      ...reply,
      // id: reply['@id'],
      entityId: reply['@id'],
      type: reply['@type'],
      content: reply.body,
      time: getDurationAgo(reply.createdAt),
      channelUrl: `/channel/` + reply.owner?.slug,
      firstName: reply.owner?.firstName,
      lastName: reply.owner?.lastName,
      isCommentOwner: parseInt(reply.owner.id, 10) === loggedUserId,
      avatarSrc: getImageUrlFromUser(reply.owner),
    };
  }

  return null;
}

export function normalizedCommentData(data, loggedUserId) {
  if (data?.length > 0) {
    return data.map((item) => {
      return normalizedComment(item, loggedUserId);
    });
  }

  return [];
}

function normalizedReplyData(data, loggedUserId) {
  if (data?.length > 0) {
    return data.map((item) => {
      return normalizedReply(item, loggedUserId);
    });
  }

  return [];
}

function UseVideoComments({ children }) {
  const { slug: videoSlug } = useParams();
  const { authAxios, publicAxios } = useContext(HttpContext);
  const { updateState, state } = useContext(CommentsContext);
  const { perPage, sortBy } = state;
  const { isAuthenticated, getAuthUser } = useContext(AuthContext);
  const loggedUserId = parseInt(getAuthUser().id, 10);

  useEffect(() => {
    const getData = async () => {
      const itemsPerPage = `?itemsPerPage=${perPage}`;
      try {
        updateState({
          ...state,
          status: 'pending',
        });

        let orderByQuery = '';
        if (sortBy === NEWEST) {
          orderByQuery = '&order[id]=desc';
        } else if (sortBy === OLDEST) {
          orderByQuery = '&order[id]=asc';
        }

        const url = `/videos/${videoSlug}/comments${itemsPerPage}${orderByQuery}`;
        if (isAuthenticated()) {
          const header = { headers: { Accept: 'application/ld+json' } };
          const { data } = await authAxios.get(url,header);

          updateState({
            items: normalizedCommentData(data['hydra:member'], loggedUserId),
            total: data['hydra:totalItems'],
            status: 'resolved',
            error: null,
          });
        } else {
          const { data } = await publicAxios.get(url, {
            headers: { Accept: 'application/ld+json' },
          });
          updateState({
            items: normalizedCommentData(data['hydra:member'], loggedUserId),
            total: data['hydra:totalItems'],
            status: 'resolved',
            error: null,
          });
        }
      } catch (onErr) {
        console.log(onErr);
        updateState({
          items: [],
          total: 0,
          status: 'rejected',
          error: 'Something went wrong',
        });
      }
    };

    getData();
  }, [authAxios, publicAxios, perPage, sortBy, videoSlug, isAuthenticated, loggedUserId]);

  return <>{children}</>;
}

export default UseVideoComments;
