import { Spacer, Image, Box, Flex } from '@chakra-ui/react';

import purpledottedtriangle from '../../assets/img/purpledottedtriangle.svg';
import elipse from '../../assets/img/elipse.svg';

// Layout of signup/login right side without carousel, implemented in LayoutAuth
const LayoutRightSideTemplate = (props) => {
  return (
    <Flex
      direction="column"
      alignItems="flex-end"
      height="100%"
      justifyContent="space-between"
      bgColor="secondary.lighter"
    >
      <Box
        w="50%"
        h="50%"
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Image src={purpledottedtriangle} />
      </Box>
      <Spacer />
      <Box
        w="50%"
        h="50%"
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        // minH="447px" // the size of previously used elipse
        bg="green.100"
        opacity={0}
      >
        <Image src={elipse} />
      </Box>
    </Flex>
  );
};

export default LayoutRightSideTemplate;
