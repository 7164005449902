import React, { useContext, useEffect } from 'react';
import { HttpContext } from '../context/HttpContext';
import { RecommendedVideosContext } from '../context/RecommendedVideosContext';

function UseRecommendedVideos({ children }) {
  const { authAxios } = useContext(HttpContext);
  const { updateState, state } = useContext(RecommendedVideosContext);

  useEffect(() => {
    const getData = async () => {
      try {
        updateState({
          ...state,
          status: 'pending',
        });

        const { data } = await authAxios.get('/recommended_videos?itemsPerPage=20', {
          headers: { Accept: 'application/ld+json' },
        });
        const total = data['hydra:totalItems'];
        const items = data['hydra:member'];

        updateState({
          items,
          total,
          status: 'resolved',
          error: null,
        });
      } catch (onError) {
        console.log(onError);
        updateState({
          items: [],
          total: 0,
          status: 'rejected',
          error: 'Something went wrong',
        });
      }
    };

    getData();
  }, [authAxios]);

  return <>{children}</>;
}

export default UseRecommendedVideos;
