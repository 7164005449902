import { Image } from '@chakra-ui/react';

import { CategoryEarliestIcon } from '../assets/icons/CategoryEarliestIcon'; // 1
import { CategoryHolidaysIcon } from '../assets/icons/CategoryHolidaysIcon'; // 2
import { CategoryLuckIcon } from '../assets/icons/CategoryLuckIcon'; // 3
import { CategoryFamousIcon } from '../assets/icons/CategoryFamousIcon'; // 4
import { CategoryMusicIcon } from '../assets/icons/CategoryMusicIcon'; // 5
import { CategoryMovieIcon } from '../assets/icons/CategoryMovieIcon'; // 6
import { CategoryHeartIcon } from '../assets/icons/CategoryHeartIcon'; // 7
import { CategoryWorkIcon } from '../assets/icons/CategoryWorkIcon'; // 8
import { CategoryFoodIcon } from '../assets/icons/CategoryFoodIcon'; // 9
import { CategorySportsIcon } from '../assets/icons/CategorySportsIcon'; // 10
import { CategoryWarIcon } from '../assets/icons/CategoryWarIcon'; // 11
import { CategoryNarrowIcon } from '../assets/icons/CategoryNarrowIcon'; // 12
import { CategoryChanceIcon } from '../assets/icons/CategoryChanceIcon'; // 13
import { CategoryDeathIcon } from '../assets/icons/CategoryDeathIcon'; // 14
import { CategoryGhostIcon } from '../assets/icons/CategoryGhostIcon'; // 15
import { CategoryLifeIcon } from '../assets/icons/CategoryLifeIcon'; // 16

import CategoryEarliestVector from '../assets/vectors/categories/categoryEarliest.svg';
import CategoryHolidaysVector from '../assets/vectors/categories/categoryHolidays.svg';
import CategoryLuckVector from '../assets/vectors/categories/categoryLuck.svg';
import CategoryFamousVector from '../assets/vectors/categories/categoryFamous.svg';
import CategoryMusicVector from '../assets/vectors/categories/categoryMusic.svg';
import CategoryMoviesVector from '../assets/vectors/categories/categoryMovies.svg';
import CategoryHealthVector from '../assets/vectors/categories/categoryHealth.svg';
import CategoryWorkVector from '../assets/vectors/categories/categoryWork.svg';
import CategoryFoodVector from '../assets/vectors/categories/categoryFood.svg';
import CategorySportsVector from '../assets/vectors/categories/categorySports.svg';
import CategoryWarVector from '../assets/vectors/categories/categoryWar.svg';
import CategoryNarrowVector from '../assets/vectors/categories/categoryNarrow.svg';
import CategoryChanceVector from '../assets/vectors/categories/categoryChance.svg';
import CategoryDeathVector from '../assets/vectors/categories/categoryDeath.svg';
import CategoryGhostVector from '../assets/vectors/categories/categoryGhost.svg';
import CategoryLifeVector from '../assets/vectors/categories/categoryLife.svg';

const ICON_SIZE = '32px';
const VECTOR_SIZE = '60px';

export function getCategoryIcon(id, size) {
  switch (id) {
    case 1:
      return <CategoryEarliestIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 2:
      return <CategoryHolidaysIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 3:
      return <CategoryLuckIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 4:
      return <CategoryFamousIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 5:
      return <CategoryMusicIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 6:
      return <CategoryMovieIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 7:
      return <CategoryHeartIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 8:
      return <CategoryWorkIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 9:
      return <CategoryFoodIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 10:
      return <CategorySportsIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 11:
      return <CategoryWarIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 12:
      return <CategoryNarrowIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 13:
      return <CategoryChanceIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 14:
      return <CategoryDeathIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 15:
      return <CategoryGhostIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    case 16:
      return <CategoryLifeIcon w={size ?? ICON_SIZE} h={size ?? ICON_SIZE} />;
    default:
      return null;
  }
}

export function getCategoryVector(id, size) {
  switch (id) {
    case 1:
      return <Image src={CategoryEarliestVector} h={size ?? VECTOR_SIZE} />;
    case 2:
      return <Image src={CategoryHolidaysVector} h={size ?? VECTOR_SIZE} />;
    case 3:
      return <Image src={CategoryLuckVector} h={size ?? VECTOR_SIZE} />;
    case 4:
      return <Image src={CategoryFamousVector} h={size ?? VECTOR_SIZE} />;
    case 5:
      return <Image src={CategoryMusicVector} h={size ?? VECTOR_SIZE} />;
    case 6:
      return <Image src={CategoryMoviesVector} h={size ?? VECTOR_SIZE} />;
    case 7:
      return <Image src={CategoryHealthVector} h={size ?? VECTOR_SIZE} />;
    case 8:
      return <Image src={CategoryWorkVector} h={size ?? VECTOR_SIZE} />;
    case 9:
      return <Image src={CategoryFoodVector} h={size ?? VECTOR_SIZE} />;
    case 10:
      return <Image src={CategorySportsVector} h={size ?? VECTOR_SIZE} />;
    case 11:
      return <Image src={CategoryWarVector} h={size ?? VECTOR_SIZE} />;
    case 12:
      return <Image src={CategoryNarrowVector} h={size ?? VECTOR_SIZE} />;
    case 13:
      return <Image src={CategoryChanceVector} h={size ?? VECTOR_SIZE} />;
    case 14:
      return <Image src={CategoryDeathVector} h={size ?? VECTOR_SIZE} />;
    case 15:
      return <Image src={CategoryGhostVector} h={size ?? VECTOR_SIZE} />;
    case 16:
      return <Image src={CategoryLifeVector} h={size ?? VECTOR_SIZE} />;
    default:
      return null;
  }
}
