import { Box, Container, Text, Image, Flex, Show, VStack } from '@chakra-ui/react';
import defaultPosterSmallSrc from '../../assets/img/photos/photo-39.webp';
import defaultPosterSrc from '../../assets/img/photos/photo-39.webp';
import defaultPosterCompressedSrc from '../../assets/img/photos/photo-39-compressed.jpg';
import OverlayPhoto from '../UI/ornaments/OverlayPhoto';
import PictureOptimized from '../UI/PictureOptimized';

const HeroAbout = ({ posterSrcSmall }) => {
  return (
    <Flex
      bg="primary.default"
      pos="relative"
      minH={{ base: 'calc(100vh - 80px)', md: 'calc(100vh - 100px)' }}
    >
      <Show below="sm">
        <Image
          src={posterSrcSmall ?? defaultPosterSmallSrc}
          pos="absolute"
          objectFit="cover"
          objectPosition="center center"
          h="full"
          w="full"
        />
      </Show>
      <Show above="sm">
        <PictureOptimized
          src={defaultPosterSrc}
          fallbackSrc={defaultPosterCompressedSrc}
          objectPosition="center top"
        />
      </Show>
      <OverlayPhoto bg="#0F0800" opacity="0.4" mixBlendMode="normal" />

      <Container
        maxW={{ base: 'calc(100vw - 0px)', lg: 'calc(100vw - 84px)', '2xl': '1562px' }}
        py={6}
        pos="relative"
        zIndex={1}
        display="flex"
        justifyContent="center"
      >
        <Flex flexDir="column" alignItems="center" justifyContent="center">
          <VStack
            color="white"
            spacing="25px"
            // alignItems="flex-start"
            py={{ md: '50px' }}
            textAlign="center"
            maxW="1000px"
          >
            <Box>
              <Text as="h1" textStyle="sectionHeadline">
                How many times have you heard someone telling an amazing story and thought{' '}
                <i>“we should be recording this before it’s too late?”</i>
              </Text>
            </Box>
          </VStack>
        </Flex>
      </Container>
    </Flex>
  );
};

export default HeroAbout;
