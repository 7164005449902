import React, { useContext } from 'react';
import {
  Center,
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Button,
  Grid,
  GridItem,
  Spinner,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import CategoryGenericItem from '../components/categories/CategoryGenericItem';
import { CategoriesContext } from '../context/CategoriesContext';
import { AuthContext } from '../context/AuthContext';

const PickCategoriesPage = () => {
  const { state } = useContext(CategoriesContext);
  const { getAuthUserCategories } = useContext(AuthContext);
  const pickedCategories = getAuthUserCategories();
  console.log(pickedCategories);
  const disabledSkipButton = pickedCategories.length > 0;
  const { items, status } = state;
  const loading = status === 'pending';

  // grid items responsive
  const [isLargerThanXS] = useMediaQuery('(min-width: 14em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');

  const templateRender = () => {
    if (isLargerThanLG) {
      return 'repeat(6, 1fr)';
    }
    if (isLargerThanMD) {
      return 'repeat(3, 1fr)';
    }
    if (isLargerThanSM) {
      return 'repeat(3, 1fr)';
    }
    if (isLargerThanXS) {
      return 'repeat(2, 1fr)';
    }
  };

  return (
    <Center minH="94vh" pt={10} pb={5}>
      <Box bgColor="#fff" borderRadius="20px">
        <Flex
          justifyContent="center"
          direction="column"
          alignItems="center"
          p={{ base: '7', sm: '4' }}
        >
          <VStack spacing={10}>
            <Box textAlign="center" pt={5} pb={5}>
              <Heading fontSize={{ sm: '24px', md: '27px' }}>
                Pick one or more topics
              </Heading>
              <Text pt={2} fontSize={{ sm: '20px', md: '23px' }}>
                This will help us recommend stories you'll like
              </Text>
            </Box>
            <Box pl={5} pr={5}>
              <Grid
                templateColumns={templateRender}
                templateRows={isLargerThanLG ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'}
                gap={4}
                fontWeight="500"
                fontSize="14px"
              >
                {items.length > 0 ? (
                  items.map((item) => {
                    const picked = pickedCategories.some(
                      (el) => parseInt(el.id, 10) === parseInt(item.id, 10)
                    );
                    return (
                      <GridItem key={item.id} w="100%">
                        <CategoryGenericItem
                          iconName={item.name}
                          catId={item.id}
                          picked={picked}
                        />
                      </GridItem>
                    );
                  })
                ) : loading ? (
                  <GridItem colSpan={6} rowSpan={6}>
                    <Box textAlign="center">
                      <Spinner size="xl" />
                    </Box>
                  </GridItem>
                ) : null}
              </Grid>
            </Box>
            <Box>
              <Stack spacing={8} direction={isLargerThanMD ? 'row' : 'column'}>
                <Button
                  as={RouterLink}
                  to="/home"
                  variant="outline"
                  width="250px"
                  disabled={disabledSkipButton}
                >
                  Skip
                </Button>
                <Button as={RouterLink} to="/home" width="250px">
                  Done
                </Button>
              </Stack>
            </Box>
          </VStack>
        </Flex>
      </Box>
    </Center>
  );
};

export default PickCategoriesPage;
