import {
  Container,
  Box,
  Tag,
  TagLabel,
  Heading,
  Divider,
  Flex,
  Avatar,
  Text,
  VStack,
  Link,
  HStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import HeroVideoStory from '../../components/videos/HeroVideoStory';
import PhotoCarousels from '../../components/carousels/PhotoCarousels';
import VideoCarousels from '../../components/carousels/VideoCarousels';
import { IconAvatar } from '../../theme/icons/IconAvatar';
import { IconPenPlain } from '../../theme/icons/IconPenPlain';
import { IconTrash } from '../../theme/icons/IconTrash';
import { IconShare } from '../../theme/icons/IconShare';
import { IconFlag } from '../../theme/icons/IconFlag';
import { IconRequestAccess } from '../../theme/icons/IconRequestAccess';
import { useContext } from 'react';
import SingleVideoContext from '../../context/SingleVideoContext';

import CommentsContent from '../../components/comments/CommentsContent';

import { getImageUrlFromUser, getFullName } from '../../util';
import { getDefaultDate } from '../../util/date';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import DeleteVideoModal from '../../components/modals/DeleteVideoModal';
import ShareModal from '../../components/modals/ShareModal';
import ReportModal from '../../components/modals/ReportModal';
import ReportThankYouModal from '../../components/modals/ReportThankYouModal';
import RequestedAccessModal from '../../components/modals/RequestedAccessModal';
import { getPrivacy } from '../../util';
import PrivacyTag from '../../components/videos/privacy/PrivacyTag';
import UpdateSingleVideoViewer from '../../components/trackers/UpdateSingleVideoViewer';
import { HttpContext } from '../../context/HttpContext';
import CustomToast from '../../components/UI/CustomToast';

const SingleVideoPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { slug } = useParams();
  const { authAxios } = useContext(HttpContext);
  const { requestAccessVideo } = useContext(SingleVideoContext);
  // console.log(id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenShare,
    onOpen: onOpenShare,
    onClose: onCloseShare,
  } = useDisclosure();
  const {
    isOpen: isOpenReport,
    onOpen: onOpenReport,
    onClose: onCloseReport,
  } = useDisclosure();
  const {
    isOpen: isOpenReportThankYou,
    onOpen: onOpenReportThankYou,
    onClose: onCloseReportThankYou,
  } = useDisclosure();
  const {
    isOpen: isOpenRequestedAccess,
    onOpen: onOpenRequestedAccess,
    onClose: onCloseRequestedAccess,
  } = useDisclosure();

  const showReportThankYouModal = () => {
    onOpenReportThankYou();
  };

  const showRequestedAccessModal = async (e) => {
    try {
      e.preventDefault();

      await authAxios({
        method: 'POST',
        url: `/access_requests`,
        data: {
          video: `/api/videos/${slug}`,
        },
        headers: {
          Accept: 'application/ld+json',
        },
      });

      requestAccessVideo();
      onOpenRequestedAccess();
    } catch (onErr) {
      console.log(onErr);
      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="error"
            description="Access to this video is already requested"
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
      requestAccessVideo();
    }
  };

  const {
    state: {
      data,
      // error, status
    },
  } = useContext(SingleVideoContext);

  // const loading = status === 'pending';

  // console.log({ data, error, status });

  if (!data) {
    return null;
  }

  const {
    body,
    owner,
    images,
    subject,
    subjectDetails,
    title,
    category,
    createdAt,
    canReply,
    canComment,
    canEdit,
    canReport,
    relatedVideos,
    canRequestPermission,
    isVideoOwner,
  } = data;

  function handleClickCategory(e) {
    e.preventDefault();
    navigate(`/search?category=${category.id}`);
  }

  return (
    <>
      <Box pb="60px">
        <UpdateSingleVideoViewer />
        <HeroVideoStory />

        <Container maxW="1032px" pt={10}>
          {category?.name && (
            <Flex flexDir={{ base: 'column', md: 'row' }} alignItems={{ md: 'center' }}>
              <Tag
                flexShrink={0}
                alignSelf="flex-start"
                mr="15px"
                mb={{ base: 3, md: 0 }}
              >
                <TagLabel onClick={handleClickCategory}>{category?.name}</TagLabel>
              </Tag>
              {data?.tags?.length > 0 && (
                <Box>
                  {data.tags.map((tag, index) => {
                    return (
                      <Box key={index} as="span" display="inline-block" mr={2}>
                        <Link
                          as={RouterLink}
                          to={
                            tag?.title
                              ? `/search?tag=${encodeURIComponent(tag.title.trim())}`
                              : `/search`
                          }
                          variant="secondaryDark"
                          fontSize="14px"
                          textStyle="bodyMedium"
                          alignSelf="center"
                          lineHeight="130%"
                        >
                          #{tag.title}
                        </Link>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Flex>
          )}

          <Heading as="h1" size="lg" color="primary.default" mt={{ base: 4, md: 5 }}>
            {title}
          </Heading>

          {canEdit ? (
            <Flex flexWrap="wrap" mt={{ base: 2, md: 6 }}>
              <HStack
                flexShrink={0}
                spacing={{ base: 3, sm: 5 }}
                mr={{ base: 3, sm: 5 }}
                mt={4}
              >
                <Link
                  variant="secondaryDark"
                  textStyle="bodySemiBold"
                  onClick={onOpenShare}
                >
                  <IconShare boxSize="14px" mr="7px" color="secondary.dark" />
                  Share
                </Link>
                <Link onClick={onOpen} variant="secondaryDark" textStyle="bodySemiBold">
                  <IconTrash boxSize="16px" mr="7px" color="secondary.dark" />
                  Delete
                </Link>
                <Link
                  as={RouterLink}
                  to={`/videos/edit/${slug}`}
                  variant="secondaryDark"
                  textStyle="bodySemiBold"
                >
                  <IconPenPlain boxSize="15px" mr="7px" color="secondary.dark" />
                  Edit
                </Link>
              </HStack>
              <Box mt={4}>
                <PrivacyTag
                  privacy={getPrivacy(data?.privacy)}
                  url={`/videos/edit/${slug}`}
                  emails={data?.sharedMails}
                />
              </Box>
            </Flex>
          ) : (
            <>
              <Flex flexWrap="wrap" mt={{ base: 2, md: 6 }}>
                <HStack
                  flexShrink={0}
                  spacing={{ base: 3, sm: 5 }}
                  mr={{ base: 3, sm: 5 }}
                  mt={4}
                >
                  <Link
                    variant="secondaryDark"
                    textStyle="bodySemiBold"
                    onClick={onOpenShare}
                  >
                    <IconShare boxSize="14px" mr="7px" color="secondary.dark" />
                    Share
                  </Link>
                  {canReport && (
                    <Link
                      variant="secondaryDark"
                      textStyle="bodySemiBold"
                      onClick={onOpenReport}
                    >
                      <IconFlag boxSize="14px" mr="7px" color="secondary.dark" />
                      Report
                    </Link>
                  )}

                  {/* Task scope: User can request access until his request has been declined by the owner. At the same time there can not exist Report and Request - either the user can Report the video story which he can see (he can't report the video story he has no access to) or the user can request access to video story he can't see */}
                  {canRequestPermission && (
                    <Link
                      variant="secondaryDark"
                      textStyle="bodySemiBold"
                      onClick={showRequestedAccessModal}
                    >
                      <IconRequestAccess boxSize="14px" mr="7px" color="secondary.dark" />
                      Request access
                    </Link>
                  )}
                </HStack>
              </Flex>
            </>
          )}
          <Divider my={{ base: '20px', md: '40px' }} />

          <Flex>
            <Avatar
              icon={
                <IconAvatar
                  fontSize={{ base: '16px', md: '22px' }}
                  color="secondary.lighter"
                />
              }
              src={getImageUrlFromUser(owner)}
              bg="secondary.light"
              boxSize="40px"
              as={RouterLink}
              to={`/channel/${owner.slug}`}
            />
            <Box ml="3">
              <Link
                to={`/channel/${owner.slug}`}
                as={RouterLink}
                _hover={{ color: 'primary.default', textDecoration: 'underline' }}
              >
                <Text textStyle="bodySemiBold" fontSize="16px" lineHeight="125%">
                  {getFullName(owner)}
                </Text>
              </Link>
              <Text color="primary.dark" fontSize="13px" lineHeight="130%">
                Published on {getDefaultDate(createdAt)}
              </Text>
            </Box>
          </Flex>

          {body && (
            <Text as="h3" color="primary.dark" lineHeight="26px" mt={5}>
              {body}
            </Text>
          )}

          {(subject || subjectDetails) && (
            <VStack spacing="15px" alignItems="flex-start" mt={10}>
              {subject && (
                <>
                  <Text
                    as="h2"
                    color="primary.dark"
                    textTransform="uppercase"
                    textStyle="formLabels"
                    lineHeight="150%"
                  >
                    protagonist/main subject
                  </Text>
                  <Text textStyle="bodySemiBold" fontSize="16px" lineHeight="125%">
                    {subject}
                  </Text>
                </>
              )}
              {subjectDetails && (
                <Text as="h3" color="primary.dark" lineHeight="26px">
                  {subjectDetails}
                </Text>
              )}
            </VStack>
          )}

          {images.length > 0 && (
            <>
              <Box mt={10}>
                <Text
                  as="h2"
                  color="primary.dark"
                  textTransform="uppercase"
                  textStyle="formLabels"
                  lineHeight="150%"
                  mb={5}
                >
                  Photos
                </Text>
                <PhotoCarousels photos={images} key={data.id} videoId={data.id} />
              </Box>
            </>
          )}

          {relatedVideos?.length > 0 && (
            <>
              <Divider my={{ base: '20px', md: '40px' }} />
              <Box mt={10}>
                <Text
                  as="h2"
                  color="primary.dark"
                  textTransform="uppercase"
                  textStyle="formLabels"
                  lineHeight="150%"
                  mb={5}
                >
                  Watch next
                </Text>
                <VideoCarousels videos={relatedVideos} key={data.id} videoId={data.id} />
              </Box>
            </>
          )}
          <Divider my={{ base: '20px', md: '40px' }} />

          <CommentsContent
            isVideoOwner={isVideoOwner}
            canComment={canComment}
            canReply={canReply}
          />
          {/* <Divider my={{ base: '20px', md: '40px' }} /> */}
        </Container>
      </Box>

      <DeleteVideoModal isOpen={isOpen} onClose={onClose} data={data} />
      <ShareModal
        isOpen={isOpenShare}
        onClose={onCloseShare}
        title="Share video story"
        video={data}
      />
      <ReportModal
        isOpen={isOpenReport}
        onClose={onCloseReport}
        data={data}
        wording="video story"
        onShowReportThankYouModal={showReportThankYouModal}
        videoSlug={slug}
      />
      <ReportThankYouModal
        isOpen={isOpenReportThankYou}
        onClose={onCloseReportThankYou}
      />
      <RequestedAccessModal
        isOpen={isOpenRequestedAccess}
        onClose={onCloseRequestedAccess}
      />
    </>
  );
};

export default SingleVideoPage;
