import React, { createContext, useState } from 'react';
import { getUrlSearchParams } from '../util';
import { useNavigate } from 'react-router-dom';
const PAGE_SIZE = 12;

export const initialState = {
  items: [],
  total: 0,
  pageIndex: 0,
  pageSize: PAGE_SIZE,
  hasMore: false,
  status: 'idle',
  loading: false,
  error: null,
  update: 0,
  query: '',
};

const MyChannelContext = createContext({
  state: initialState,
  updateState: () => {},
  incrementPageIndex: () => {},
  restartPagination: () => {},
  restartPageIndex: () => {},
  clearAll: () => {},
  clearTerm: () => {},
  clearTag: () => {},
  clearCategory: () => {},
  updateTerm: () => {},
  updateTag: () => {},
  updateTagObject: () => {},
  updateCategory: () => {},
  updateQueryString: () => {},
});

export function MyChannelContextProvider({ children }) {
  let query = getUrlSearchParams();
  const navigate = useNavigate();
  const [term, setTerm] = useState(() => query.get('keyword') ?? '');
  const [tag, setTag] = useState(null);
  const [tagId, setTagId] = useState(() => query.get('tag') ?? '');
  const [tagName, setTagName] = useState(() => query.get('tag') ?? '');

  function getCategoryById(categoryId) {
      switch (categoryId) {
        case 1:
          return { id : 1, name : "Earliest memories", slug : "earliest-memories" }
        case 2:
          return { id : 2, name : "Holiday and travel", slug : "holiday-and-travel" }
        case 3:
          return { id : 3, name : "Good luck", slug : "good-luck" }
        case 4:
          return { id : 4, name : "Meeting famous people", slug : "meeting-famous-people" }
        case 6:
          return { id : 6, name : "Arts and Entertainment", slug : "arts-and-entertainment" }
        case 7:
          return { id : 7, name : "Friends, Family and Love", slug : "friends-family-and-love" }
        case 8:
          return { id : 8, name : "Work and business", slug : "work-and-business" }
        case 9:
          return { id : 9, name : "Food and recipes", slug : "food-and-recipes" }
        case 10:
          return { id : 10, name : "Sport and games", slug : "sport-and-games" }
        case 11:
          return { id : 11, name : "Wartime experiences", slug : "wartime-experiences" }
        case 12:
          return { id : 12, name : "Narrow escapes", slug : "narrow-escapes" }
        case 15:
          return { id : 15, name : "Ghost stories", slug : "ghost-stories" }
        case 16:
          return { id : 16, name : "Life stories", slug : "life-stories" }
        case 17:
          return { id : 17, name : "Other", slug : "other" }
        default:
          return null;
      }
  }

  function getInitialCategory() {
    const categoryId = parseInt(query.get('category'), 10);
    if (categoryId) {
      return getCategoryById(categoryId);
    }

    return null
  }

  const [category, setCategory] = useState(getInitialCategory);
  const [categoryId, setCategoryId] = useState(() => query.get('category') ?? '');
  const [clearPageIndex, setClearPageIndex] = useState(0);
  const [state, setState] = useState(initialState);

  function createQueryString() {
    const keyword = query.get('keyword');
    const tag = query.get('tag');
    const category = query.get('category');

    if (keyword && tag && category) {
      return `&keyword=${keyword}&tags=${tag}&category=${category}`
    }
    if (keyword && tag) {
      return `&keyword=${keyword}&tags=${tag}`
    }
    if (keyword && category) {
      return `&keyword=${keyword}&category=${category}`
    }
    if (tag && category) {
      return `&tags=${tag}&category=${category}`
    }
    if (keyword) {
      return `&keyword=${keyword}`
    }
    if (tag) {
      return `&tags=${tag}`
    }
    if (category) {
      return `&category=${category}`
    }

    return '';

  }

  const [queryString, setQueryString] = useState(createQueryString);

  function updateState(state) {
    setState(state);
  }

  function incrementPageIndexHandler() {
    const index = state.pageIndex;
    setState({
      ...state,
      pageIndex: index + 1,
    });
  }

  function restartPaginationHandler() {
    const { update } = state;
    setState({
      ...state,
      update: update + 1,
    });
  }

  function updateQueryHandler(query) {
    setState({
      ...state,
      query,
    });
  }
  function restartPageIndexHandler() {
    setClearPageIndex(clearPageIndex + 1);
  }
  function clearAllHandler() {
    navigate(`/search`, { replace: true });
    restartPageIndexHandler();
    clearTermHandler();
    clearTagHandler();
    clearCategoryHandler();
    updateQueryHandler('')
  }

  const clearTermHandler = () => {
    restartPageIndexHandler();
    setTerm('');
  };

  const clearTagHandler = () => {
    restartPageIndexHandler();
    setTag(null);
    setTagId('');
    setTagName('');
  };

  const clearCategoryHandler = () => {
    restartPageIndexHandler();
    setCategory(null);
    setCategoryId('');
  };

  const updateTermHandler = (term) => {
    restartPageIndexHandler();
    setTerm(term);
  };

  const updateTagHandler = (tag) => {
    restartPageIndexHandler();
    setTag(tag);
  };

  const updateTagObjectHandler = (tag) => {
    restartPageIndexHandler();
    setTag(tag);
  };

  const updateCategoryHandler = ({ id, name }) => {
    restartPageIndexHandler();
    setCategory({ id: id, name: name });
  };

  const updateCategoryForIdHandler = (category) => {
    restartPageIndexHandler();
    setCategory(category);
  };

  const updateQueryStringHandler = (string) => {
    setQueryString(string);
  };

  const updateTagNameHandler = (string) => {
    setTagName(string);
  };

  const value = {
    state,
    updateState,
    incrementPageIndex: incrementPageIndexHandler,
    restartPagination: restartPaginationHandler,
    clearPageIndex,
    updateQuery: updateQueryHandler,
    restartPageIndex: restartPageIndexHandler,
    term,
    category,
    categoryId,
    tag,
    tagId,
    tagName,
    clearAll: clearAllHandler,
    clearTerm: clearTermHandler,
    clearTag: clearTagHandler,
    clearCategory: clearCategoryHandler,
    updateTerm: updateTermHandler,
    updateTag: updateTagHandler,
    updateTagName: updateTagNameHandler,
    updateTagObject: updateTagObjectHandler,
    updateCategory: updateCategoryHandler,
    updateCategoryForId: updateCategoryForIdHandler,
    queryString,
    updateQueryString: updateQueryStringHandler,
  };

  return <MyChannelContext.Provider value={value}>{children}</MyChannelContext.Provider>;
}

export default MyChannelContext;
