import { Flex, Container } from '@chakra-ui/react';
import ScrollToTopForRouter from '../components/common/ScrollToTopForRouter';

import HeaderLogged from './HeaderLogged';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import HeaderLoggedOut from './HeaderLoggedOut';
import Footer from './Footer';
import Guidance from './Guidance';

const LayoutMain = (props) => {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <>
      <ScrollToTopForRouter />
      <Flex
        direction="column"
        minH="100vh"
        bgColor={props.bgColor ?? 'secondary.lighter'}
      >
        {isAuthenticated() ? <HeaderLogged /> : <HeaderLoggedOut />}

        <Container variant="bodyContent" flexDirection="column">
          {props.children}
        </Container>

        <Guidance />

        <Footer />
      </Flex>
    </>
  );
};

export default LayoutMain;
