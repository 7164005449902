import { IconButton } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { PRIVACY_OPTIONS } from '../../../util/consts';
import { IconGlobe } from '../../../theme/icons/IconGlobe';
import { IconLock } from '../../../theme/icons/IconLock';
import { IconUsers } from '../../../theme/icons/IconUsers';

const PrivacyTagIcon = ({ privacy, url }) => {
  const tagIcon =
    privacy === PRIVACY_OPTIONS[0] ? (
      <IconGlobe boxSize="16px" />
    ) : privacy === PRIVACY_OPTIONS[1] ? (
      <IconLock boxSize="16px" />
    ) : (
      <IconUsers boxSize="18px" />
    );

  return (
    <IconButton
      as={RouterLink}
      to={url}
      icon={tagIcon}
      variant="iconicDark"
      bg="secondary.light"
      size="sm"
    />
  );
};

export default PrivacyTagIcon;
