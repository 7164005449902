import { AspectRatio, Text, Box, Flex, Progress, Image } from '@chakra-ui/react';
import defaultPosterSrc from '../../assets/img/carouselimage2.png';

const VideoIsProcessingWithPoster = ({ posterSrc }) => {
  return (
    <AspectRatio ratio={16 / 9}>
      <Flex flexDir="column" borderRadius="10px">
        <Image
          src={posterSrc ?? defaultPosterSrc}
          pos="absolute"
          objectFit="cover"
          h="full"
          w="full"
        />
        <Box
          pos="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          bg="linear-gradient(0deg, rgba(36, 6, 48, 0.6), rgba(36, 6, 48, 0.6))"
        />
        <Text
          textStyle="bodyMedium"
          color="white"
          fontSize={{ base: '20px', md: '24px' }}
          pos="relative"
        >
          Video is being processed.
        </Text>
        <Progress
          className="progress-slow"
          isIndeterminate
          variant="dark"
          w="full"
          pos="absolute"
          bottom="0"
        />
      </Flex>
    </AspectRatio>
  );
};

export default VideoIsProcessingWithPoster;
