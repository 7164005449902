import { useContext } from 'react';
import { HStack, Link, Divider } from '@chakra-ui/react';
import FlickityControlsContext from '../../context/FlickityControlsContext';

const FlickityPhotoGalleryCTA = () => {
  const flickityControlsCtx = useContext(FlickityControlsContext);

  const onClickHandler = () => {
    flickityControlsCtx.updateInitialIndexForGallery(0);
  };

  return (
    <HStack spacing={10} mt="-40px" pr="120px" justifyContent="flex-end">
      <Link
        onClick={onClickHandler}
        variant="secondaryDark"
        fontSize="14px"
        textStyle="bodySemiBold"
      >
        Go to photo gallery
      </Link>
      <Divider orientation="vertical" h="40px" />
    </HStack>
  );
};

export default FlickityPhotoGalleryCTA;
