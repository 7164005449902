import React, { createContext, useState } from 'react';
import { MAX_PHOTOS_FOR_VIDEO_STORY } from '../util/consts';

const initialState = {
  video: {
    file: null,
    vimeo: null,
    status: 'idle',
    uploadPercentage: 0,
  },
  title: '',
  photos: [],
  photosCurrentId: 0,
  tags: [],
  shareEmails: [],
  moreDetails: '',
  subject: '',
  about: '',
  privacy: 0,
  status: 'idle',
  error: null,
};
const VideoFormContext = createContext({
  state: initialState,
  updateState: () => {},
  updateVideo: () => {},
  uploadPercentage: '',
  updateUploadPercentage: () => {},
  posterSrc: null,
  updatePosterSrc: () => {},
  updateStartUploading: () => {},
  startUploadingVideo: () => {},
  finishedUploadingVideo: () => {},
  isNeedUpload: () => {},
  setVideoStatus: () => {},
  setVimeo: () => {},
  setUploadPercentage: () => {},
  getUploadPercentage: () => {},
  getVideoStatus: () => {},
  getVimeoId: () => {},
  getPhotos: () => {},
  setPhotos: () => {},
  addPhoto: () => {},
  updatePhoto: () => {},
  removePhoto: () => {},
  getMaxPhotos: () => {},
  getVideo: () => {},
  updateTags: () => {},
  updateShareEmails: () => {},
  updateAllDataForEdit: () => {},
  updateStatus: () => {},
  getPhotosCurrentId: () => {},
});
const { Provider } = VideoFormContext;

const VideoFormProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [startUploading, setStartUploading] = useState(0);
  const [posterSrc, setPosterSrc] = useState(null);

  function updateStateHandler({ video, title }) {
    setState({
      video,
      title,
    });
  }

  function updateUploadPercentageHandler(percentage) {
    setUploadPercentage(percentage);
  }

  function isNeedUploadHandler() {
    const { video } = state;
    const { status } = video;

    return status === 'uploading';
  }

  function setVideoStatusHandler(status) {
    setState({
      ...state,
      video: {
        ...state.video,
        status: status,
      },
    });
  }

  function setVimeoHandler(vimeo) {
    setState({
      ...state,
      video: {
        ...state.video,
        status: 'uploaded',
        vimeo,
      },
    });
  }

  function setUploadPercentageHandler(uploadPercentage) {
    setState({
      ...state,
      video: {
        ...state.video,
        uploadPercentage,
      },
    });
  }

  function updateVideoHandler(video) {
    setState({
      ...state,
      video,
    });
    // if (video === null) {
    //   setStartUploading(0);
    // } else {
    // updateStartUploadingHandler();
    setStartUploading(startUploading + 1);
    // }
  }

  function startUploadingVideoHandler(file) {
    setState({
      ...state,
      video: {
        ...state.video,
        file: file,
        status: 'uploading',
      },
    });
  }

  function finishedUploadingVideoHandler({
    vimeo,
    id,
    thumb,
    transcodingStatus,
    vimeoUploadLink,
    vimeoVideoUri,
  }) {
    try {
      const vimeoId = vimeoVideoUri.split('/').pop();

      setState({
        ...state,
        video: {
          ...state.video,
          vimeo,
          id,
          thumb,
          transcodingStatus,
          vimeoUploadLink,
          vimeoVideoUri,
          vimeoId,
          status: 'uploaded',
          uploadPercentage: 100,
        },
      });
    } catch (onErr) {
      console.log(onErr);
    }
  }

  function updatePosterSrcHandler(posterSrc) {
    setPosterSrc(posterSrc);
  }

  function updateStartUploadingHandler() {
    setStartUploading(startUploading + 1);
  }

  function getUploadPercentageHandler() {
    if (state.video.uploadPercentage) {
      return parseInt(state.video.uploadPercentage, 10);
    }

    return 0;
  }

  function getVideoStatusHandler() {
    return state.video.status;
  }
  function getVimeoIdHandler() {
    return state.video?.vimeoId ?? null;
  }

  function getPhotosHandler() {
    return state.photos ?? [];
  }

  function setPhotosHandler(photos) {
    setState({
      ...state,
      photos,
    });
  }

  function addPhotoHandler(photo) {
    let { photos } = state;
    photos = photos.concat([photo]);
    setState({
      ...state,
      photos,
    });
  }

  function updatePhotoHandler(photo) {
    let { photos } = state;

    if (photos.length === 0) {
      setState({
        ...state,
        photos: [photo],
      });
      return;
    }

    let recordIndex = photos.findIndex((el) => el.file.preview === photo.file.preview);
    if (recordIndex > -1) {
      photos[recordIndex] = photo;
      setState({
        ...state,
        photos,
      });
    } else {
      setState({
        ...state,
        photos: photos.concat([photo]),
      });
    }
  }

  function removePhotoHandler(photo) {
    let { photos } = state;
    photos = photos.filter((x) => parseInt(x.id, 10) !== parseInt(photo.id));
    setState({
      ...state,
      photos,
    });
  }

  function getMaxPhotosHandler() {
    let { photos } = state;
    return MAX_PHOTOS_FOR_VIDEO_STORY - photos.length;
  }

  function getVideoHandler() {
    let { video } = state;
    return video;
  }

  function updateTagsHandler(tags) {
    setState({
      ...state,
      tags,
    });
  }

  function updateShareEmailsHandler(emails) {
    setState({
      ...state,
      shareEmails: emails,
    });
  }

  function normalizeTagsFromApiResponse(tags) {
    // {value: '/api/tags/Music', label: 'Music'}
    // {title: 'sport'}
    if (tags?.length > 0) {
      let out = [];
      for (let i = 0; i < tags.length; i++) {
        const title = tags[i]?.title ?? null;
        if (title) {
          out.push({ value: `/api/tags/${title}`, label: title });
        }
      }

      return out;
    }
    return [];
  }

  function normalizeSharedEmailsApiResponse(emails) {
    if (emails?.length > 0) {
      let out = [];
      for (let i = 0; i < emails.length; i++) {
        const email = emails[i]?.email ?? null;
        if (email) {
          out.push({ label: email, value: email });
        }
      }

      return out;
    }
    return [];
  }

  function normalizePhotosFromApiResponse(images) {
    if (images?.length > 0) {
      let out = [];
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const { filename, filename_1920_1080, filename_width_440, id } = image;
        const url = filename; // `https://dev.storyvault.etondigital.com/uploads/images/videos/${filename}`;
        const count = i + 1;
        out.push({
          file: null,
          errors: [],
          url,
          id: count,
          filename,
          filename_1920_1080,
          filename_width_440,
          apiId: `/api/video_images/${id}`,
        });
      }

      return out;
    }
    return [];
  }

  function updateAllDataForEditHandler(dataInfo) {
    const { data, status, error } = dataInfo;

    const tags = normalizeTagsFromApiResponse(data?.tags);
    const shareEmails = normalizeSharedEmailsApiResponse(data?.sharedMails);
    const photos = normalizePhotosFromApiResponse(data?.images);

    setState({
      ...state,
      title: data?.title,
      category: data?.category,
      subject: data?.subject,
      privacy: data?.privacy,
      vimeoVideo: data?.vimeoVideo,
      upload: data?.upload,
      owner: data?.owner,
      createdAt: data?.createdAt,
      about: data?.body,
      moreDetails: data?.subjectDetails,
      photos,
      tags,
      shareEmails,
      status,
      error,
      photosCurrentId: photos.length,
    });
  }

  function updateStatusHandler(status) {
    setState({
      ...state,
      status,
    });
  }

  function getPhotosCurrentIdHandler() {
    const { photosCurrentId } = state;
    return photosCurrentId;
  }

  return (
    <Provider
      value={{
        state,
        updateState: (stateInfo) => updateStateHandler(stateInfo),
        uploadPercentage,
        updateUploadPercentage: updateUploadPercentageHandler,
        updateVideo: updateVideoHandler,
        posterSrc,
        updatePosterSrc: updatePosterSrcHandler,
        updateStartUploading: updateStartUploadingHandler,
        startUploading,
        startUploadingVideo: (file) => startUploadingVideoHandler(file),
        finishedUploadingVideo: ({
          vimeo,
          id,
          thumb,
          transcodingStatus,
          vimeoUploadLink,
          vimeoVideoUri,
        }) =>
          finishedUploadingVideoHandler({
            vimeo,
            id,
            thumb,
            transcodingStatus,
            vimeoUploadLink,
            vimeoVideoUri,
          }),
        isNeedUpload: isNeedUploadHandler,
        setVideoStatus: (status) => setVideoStatusHandler(status),
        setVimeo: (vimeo) => setVimeoHandler(vimeo),
        setUploadPercentage: (percentage) => setUploadPercentageHandler(percentage),
        getUploadPercentage: getUploadPercentageHandler,
        getVideoStatus: getVideoStatusHandler,
        getVimeoId: getVimeoIdHandler,
        getPhotos: getPhotosHandler,
        setPhotos: (photos) => setPhotosHandler(photos),
        addPhoto: (photo) => addPhotoHandler(photo),
        updatePhoto: (photo) => updatePhotoHandler(photo),
        removePhoto: (photo) => removePhotoHandler(photo),
        getMaxPhotos: getMaxPhotosHandler,
        getVideo: getVideoHandler,
        updateTags: (tags) => updateTagsHandler(tags),
        updateShareEmails: (emails) => updateShareEmailsHandler(emails),
        updateAllDataForEdit: (dataInfo) => updateAllDataForEditHandler(dataInfo),
        updateStatus: (status) => updateStatusHandler(status),
        getPhotosCurrentId: getPhotosCurrentIdHandler,
      }}
    >
      {children}
    </Provider>
  );
};

export { VideoFormContext, VideoFormProvider };
