import { Box } from '@chakra-ui/react';

const OverlayPhoto = ({ bg, opacity, mixBlendMode, borderRadius }) => {
  return (
    <Box
      pos="absolute"
      top="0"
      left="0"
      h="full"
      w="full"
      bg={bg ?? '#57256A'}
      opacity={opacity ?? '0.5'}
      mixBlendMode={mixBlendMode ?? 'screen'}
      borderRadius={borderRadius}
    ></Box>
  );
};

export default OverlayPhoto;
