import React, { createContext, useState } from 'react';

export const initialCategoriesState = {
  items: [],
  total: 0,
  status: 'idle',
  error: null,
};

const CategoriesContext = createContext({
  state: initialCategoriesState,
  updateState: () => {},
  getCategory: () => {},
});
const { Provider } = CategoriesContext;

const CategoriesProvider = ({ children }) => {
  const [state, setState] = useState({
    items: [],
    total: 0,
    status: 'idle',
    error: null,
  });

  function updateStateHandler({ items, total, status, error }) {
    setState({
      items,
      total,
      status,
      error,
    });
  }

  function getCategoryHandler(id) {
    const { items } = state;
    if (items.length > 0) {
      const intId = parseInt(id, 10)
      for (let i = 0; i < items.length; i++) {
        if (parseInt(items[i].id, 10) ===  intId) {
          return items[i]
        }
      }
    }

    return null;
  }

  return (
    <Provider
      value={{
        state,
        updateState: (stateInfo) => updateStateHandler(stateInfo),
        getCategory:getCategoryHandler,
      }}
    >
      {children}
    </Provider>
  );
};

export { CategoriesContext, CategoriesProvider };
