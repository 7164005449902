import React, { createContext, useState } from 'react';

const initialState = {
  data: null,
  status: 'idle',
  error: null,
};

const UserContext = createContext({
  state: initialState,
  updateState: () => {},
});

export function UserProvider({ children }) {
  const [state, setState] = useState(initialState);

  function updateStateHandler({ data, status, error }) {
    setState({
      data,
      status,
      error,
    });
  }

  const value = {
    state,
    updateState: updateStateHandler,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserContext;
