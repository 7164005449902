import { Icon } from '@chakra-ui/react';

export const PasswordCheckboxCheckedIcon = (props) => (
  <Icon width="19" height="19" viewBox="0 0 19 19" fill="none" >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2587 3.03655C10.8083 2.39027 9.18778 2.23016 7.63891 2.58011C6.09004 2.93006 4.69579 3.7713 3.66409 4.97839C2.63239 6.18548 2.01852 7.69373 1.91405 9.27819C1.80957 10.8627 2.22007 12.4385 3.08434 13.7706C3.9486 15.1027 5.22032 16.1197 6.70983 16.67C8.19934 17.2203 9.82683 17.2744 11.3496 16.8241C12.8723 16.3739 14.2087 15.4435 15.1595 14.1717C16.1103 12.8999 16.6244 11.3548 16.6254 9.76691V9.01465C16.6254 8.56278 16.9917 8.19647 17.4435 8.19647C17.8954 8.19647 18.2617 8.56278 18.2617 9.01465V9.76738C18.2606 11.7082 17.6322 13.597 16.4701 15.1515C15.3081 16.7059 13.6747 17.8431 11.8135 18.3934C9.9524 18.9436 7.96325 18.8776 6.14274 18.205C4.32223 17.5324 2.76791 16.2893 1.71158 14.6612C0.655258 13.0331 0.153531 11.1071 0.281228 9.17053C0.408925 7.23395 1.1592 5.39054 2.42017 3.91521C3.68114 2.43988 5.38522 1.41169 7.27829 0.983978C9.17135 0.556266 11.152 0.75195 12.9247 1.54185C13.3375 1.72576 13.523 2.20945 13.3391 2.6222C13.1552 3.03495 12.6715 3.22046 12.2587 3.03655Z"
      fill="#F4F5F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0271 2.98192C18.3397 3.27442 18.3399 3.7489 18.0277 4.04169L10.0277 11.5429C9.87768 11.6836 9.67416 11.7626 9.46192 11.7627C9.24968 11.7627 9.04611 11.6838 8.89603 11.5432L6.49603 9.29508C6.18361 9.00243 6.18361 8.52795 6.49603 8.2353C6.80845 7.94266 7.31498 7.94266 7.6274 8.2353L9.46144 9.95328L16.8958 2.98245C17.208 2.68965 17.7145 2.68941 18.0271 2.98192Z"
      fill="#FAB9BD"
    />
  </Icon>
);
