import {
  Box,
  Heading,
  Flex,
  Avatar,
  Text,
  LinkBox,
  LinkOverlay,
  Link,
} from '@chakra-ui/react';

import { Link as RouterLink } from 'react-router-dom';
import VideoIsUploaded from '../previews/VideoIsUploaded';
import { IconAvatar } from '../../theme/icons/IconAvatar';

const VideoSliderCard = ({ cell, mr, forDoubleSlider }) => {
  return (
    <>
      <Box role="group" w="360px" maxW="full" mr={mr ?? 5}>
        <LinkBox>
          {/* Recommended size for Poster thumb would be: width 720x, ratio 16:9 */}
          <VideoIsUploaded poster={cell.poster} duration={cell.duration} />

          <Box minH={forDoubleSlider && '104px'}>
            <LinkOverlay as={RouterLink} to={cell.exampleForNestedObject.singlePageUrl}>
              <Heading
                fontSize="18px"
                lineHeight="150%"
                mt={4}
                color="primary.default"
                transition="0.3s"
                noOfLines={2}
              >
                {cell.title}
              </Heading>
            </LinkOverlay>

            <Flex mt="10px" alignItems="center">
              <Avatar
                icon={<IconAvatar fontSize="16px" color="secondary.lighter" />}
                src={cell.avatar}
                // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC9oMJmsng6zbrYeqqIGjKbYdi8lb1HRQw-Q&usqp=CAU"
                bg="secondary.light"
                boxSize="26px"
                alignSelf="flex-start"
                to={`/channel/${cell.owner?.slug}`}
                as={RouterLink}
              />
              <Box ml="10px" flex={1}>
                <Link
                  to={`/channel/${cell.owner?.slug}`}
                  as={RouterLink}
                  _hover={{ color: 'primary.default', textDecoration: 'underline' }}
                >
                  <Text fontSize="14px" lineHeight="130%">
                    {cell.firstName} {cell.lastName}
                  </Text>
                </Link>
              </Box>
            </Flex>
          </Box>
        </LinkBox>
      </Box>
    </>
  );
};

export default VideoSliderCard;
