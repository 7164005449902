import {
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
} from '@chakra-ui/react';
import { IconDeleteThin } from '../../theme/icons/IconDeleteThin';
import { IconSearch } from '../../theme/icons/IconSearch';
import { useContext } from 'react';
import MyChannelContext from '../../context/MyChannelContext';

const SearchByTerm = () => {
  const { term, updateTerm } = useContext(MyChannelContext);
  function handleChange(e) {
    updateTerm(e.target.value);
  }

  function handleClear() {
    updateTerm('');
  }

  return (
    <FormControl maxW={{ md: '600px' }}>
      <FormLabel htmlFor="searchByTerm" textStyle="formLabels">
        &nbsp;
      </FormLabel>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          color={term ? 'primary.default' : 'primary.light'}
          fontSize="26px"
          children={<IconSearch />}
        />
        <Input
          id="searchByTerm"
          type="text"
          name="searchByTerm"
          placeholder="Search"
          value={term}
          onChange={handleChange}
        />
        {term && (
          <InputRightElement
            pointerEvents="auto"
            color="primary.light"
            fontSize="14px"
            children={<IconDeleteThin />}
            transition="0.3s"
            cursor="pointer"
            _hover={{ color: 'accent.default' }}
            onClick={handleClear}
          />
        )}
      </InputGroup>
    </FormControl>
  );
};

export default SearchByTerm;
