import { Link, HStack } from '@chakra-ui/react';
import { IconReply } from '../../theme/icons/IconReply';
import { IconTrash } from '../../theme/icons/IconTrash';
import { IconPenPlain } from '../../theme/icons/IconPenPlain';
import { useContext } from "react"
import { AuthContext } from "../../context/AuthContext"

const SingleCommentActions = ({
  isVideoOwner,
  isCommentOwner,
  isReply,
  handleShowFormForReply,
  handleShowFormForEdit,
  onOpen,
  canReply,
}) => {

  const { getAuthUser } = useContext(AuthContext)
  const user = getAuthUser();
  const isAdmin = user?.admin || false;

  return (
    <HStack
      spacing={5}
      mt={(!isReply || isVideoOwner || isCommentOwner || isAdmin) && { base: 3, md: 5 }}
      ml={isReply ? { base: '80px', md: '120px' } : { base: '40px', md: '60px' }}
      fontSize="14px"
    >
      {!isReply && canReply && (
        <Link
          variant="secondaryDark"
          textStyle="bodySemiBold"
          onClick={handleShowFormForReply}
        >
          <IconReply boxSize="14px" mr="7px" color="secondary.dark" />
          Reply
        </Link>
      )}
      {(isVideoOwner || isCommentOwner || isAdmin) && (
        <Link
          variant="secondaryDark"
          textStyle="bodySemiBold"
          display="flex"
          alignItems="center"
          onClick={onOpen}
        >
          <IconTrash boxSize="14px" mr="7px" color="secondary.dark" />
          Delete
        </Link>
      )}
      {isCommentOwner && (
        <Link
          variant="secondaryDark"
          textStyle="bodySemiBold"
          display="flex"
          alignItems="center"
          onClick={handleShowFormForEdit}
        >
          <IconPenPlain boxSize="14px" mr="7px" color="secondary.dark" />
          Edit
        </Link>
      )}
    </HStack>
  );
};

export default SingleCommentActions;
