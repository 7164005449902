import {
  Flex,
  HStack,
  Image,
  Link,
  Button,
  IconButton,
  useMediaQuery,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Avatar,
} from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import LogoSrc from '../assets/img/logo.svg';
import { IconBlog } from '../theme/icons/IconBlog';
import { IconCrown } from '../theme/icons/IconCrown';
import { IconAvatar } from '../theme/icons/IconAvatar';
import { IconLogo } from '../theme/icons/IconLogo';
import SearchByTermInHeader from '../components/search/SearchByTermInHeader';
import { IconPlay } from '../theme/icons/IconPlay';
import { IconCategories } from '../theme/icons/IconCategories';
import { IconSearch } from '../theme/icons/IconSearch';
import { IconMenu } from '../theme/icons/IconMenu';
import { IconQuestion } from '../theme/icons/IconQuestion';
import { IconEnvelope } from '../theme/icons/IconEnvelope';
import HeaderDropdownCategories from './HeaderDropdownCategories';
import { isStringStartWith } from '../util';

const HeaderLoggedOut = () => {
  const { pathname } = useLocation();
  const redirectTo = isStringStartWith(pathname, '/videos/') ? pathname : '';
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const iconSpacer = '50px';
  const itemMt = '16px';

  return (
    <Flex
      as="header"
      w="full"
      minH="80px"
      position="fixed"
      zIndex="10"
      px={{ base: '16px', '2xl': '50px' }}
      py="20px"
      bg="white"
      boxShadow="0px 10px 30px rgba(0, 0, 0, 0.05)"
      alignItems="center"
      justifyContent="space-between"
    >
      <Link as={RouterLink} to="/" pointerEvents="auto" flexShrink={0} mr={4}>
        <Image src={LogoSrc} maxH={{ base: '32px', md: 'none' }} />
      </Link>
      <HStack spacing={{ base: 'clamp(7px, 2vw, 48px)', '2xl': 'clamp(7px, 3vw, 48px)' }}>
        {isLargerThanMD ? (
          <>
            {/* Easier to maintain one by one - per frequent different requests */}
            {isLargerThanXL && (
              <Link
                as={RouterLink}
                to="/"
                // variant="primaryDefault"
                _hover={{ color: 'primary.default', textDecoration: 'underline' }}
                fontSize="18px"
                textStyle="bodyMedium"
              >
                Home
              </Link>
            )}
            {isLargerThanXL && (
              <Link
                as={RouterLink}
                to="/about"
                // variant="primaryDefault"
                _hover={{ color: 'primary.default', textDecoration: 'underline' }}
                fontSize="18px"
                textStyle="bodyMedium"
              >
                About
              </Link>
            )}
            {isLargerThanXL && <HeaderDropdownCategories />}
            {isLargerThanXL && (
              <Link
                as={RouterLink}
                to="/blog"
                onClick={() => {
                  window.location.href = '/blog';
                }}
                // variant="primaryDefault"
                _hover={{ color: 'primary.default', textDecoration: 'underline' }}
                fontSize="18px"
                textStyle="bodyMedium"
              >
                Blog
              </Link>
            )}
            {isLargerThanXL && (
              <Link
                as={RouterLink}
                to="/dofe"
                // variant="primaryDefault"
                _hover={{ color: 'primary.default', textDecoration: 'underline' }}
                fontSize="18px"
                textStyle="bodyMedium"
              >
                DofE
              </Link>
            )}
            {isLargerThanLG && <SearchByTermInHeader />}
            {isLargerThanLG && <Divider orientation="vertical" h="60px" />}
            <Link
              as={RouterLink}
              to={redirectTo ? `/login?redirect=${redirectTo}` : '/login'}
              color="accent.default"
              fontSize="18px"
              textStyle="bodySemiBold"
              flexShrink={0}
            >
              <IconAvatar boxSize="20px" mr="10px" />
              Sign in
            </Link>
            <Button as={RouterLink} to="/signup" flexShrink={0}>
              <IconLogo boxSize="20px" mr="10px" />
              Join Storyvault
            </Button>
          </>
        ) : (
          <>
            <IconButton
              as={RouterLink}
              to="/login"
              icon={<IconAvatar boxSize="16px" />}
              variant="iconicDark"
              bg="secondary.light"
              size="sm"
            />
            <IconButton
              as={RouterLink}
              to="/signup"
              icon={<IconLogo boxSize="18px" />}
              variant="iconicDark"
              bg="secondary.light"
              size="sm"
            />
          </>
        )}

        {!isLargerThanXL && (
          <Box>
            {/* Box element introduced because Popper doesn't allow it's elements to have margins (which here comes from HStack for Menu) */}
            <Menu>
              <MenuButton>
                <Avatar
                  icon={<IconMenu fontSize="24px" color="secondary.dark" />}
                  bg="secondary.light"
                  boxSize={{ base: '32px', md: '40px' }}
                />
              </MenuButton>
              <MenuList mt={{ base: '28px', md: '34px' }}>
                <IconPlay
                  pos="absolute"
                  top={{ base: '14px', md: '20px' }}
                  right="12px"
                  transform="rotate(-90deg)"
                  color="white"
                  pointerEvents="none"
                />
                <MenuItem as={RouterLink} to="/about">
                  <Box as="span" w={iconSpacer}>
                    <IconLogo color="primary.dark" boxSize="22px" />
                  </Box>
                  About Company
                </MenuItem>
                {!isLargerThanXL && (
                  <MenuItem as={RouterLink} to="/all-categories" mt={itemMt}>
                    <Box as="span" w={iconSpacer}>
                      <IconCategories color="primary.dark" boxSize="24px" />
                    </Box>
                    Browse Categories
                  </MenuItem>
                )}
                {!isLargerThanLG && (
                  <MenuItem as={RouterLink} to="/search" mt={itemMt}>
                    <Box as="span" w={iconSpacer}>
                      <IconSearch color="primary.dark" boxSize="24px" />
                    </Box>
                    Search Storyvault
                  </MenuItem>
                )}
                {!isLargerThanXL && (
                  <MenuItem
                    as={RouterLink}
                    to="/blog"
                    onClick={() => {
                      window.location.href = '/blog';
                    }}
                    mt={itemMt}
                  >
                    <Box as="span" w={iconSpacer}>
                      <IconBlog color="primary.dark" boxSize="24px" />
                    </Box>
                    Blog
                  </MenuItem>
                )}
                {!isLargerThanXL && (
                  <MenuItem as={RouterLink} to="/dofe" mt={itemMt}>
                    <Box as="span" w={iconSpacer}>
                      <IconCrown color="primary.dark" boxSize="24px" />
                    </Box>
                    DofE
                  </MenuItem>
                )}
                <MenuItem as={RouterLink} to="/faq" mt={itemMt}>
                  <Box as="span" w={iconSpacer}>
                    <IconQuestion color="primary.dark" boxSize="24px" />
                  </Box>
                  FAQ
                </MenuItem>
                <MenuItem as={RouterLink} to="/contact-us" mt={itemMt}>
                  <Box as="span" w={iconSpacer}>
                    <IconEnvelope color="primary.dark" boxSize="24px" />
                  </Box>
                  Contact Us
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}
      </HStack>
    </Flex>
  );
};

export default HeaderLoggedOut;
