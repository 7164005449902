import {
  AspectRatio,
  Show,
  Image,
  FormLabel,
  Heading,
  Flex,
  Progress,
} from '@chakra-ui/react';
import VideoIsUploadingSrc from '../../assets/vectors/videoIsUploading.svg';

const VideoIsUploading = ({ percentage }) => {
  return (
    <AspectRatio ratio={16 / 9}>
      <Flex flexDir="column" bg="secondary.lighter" borderRadius="10px">
        <Show above="sm">
          <Image src={VideoIsUploadingSrc} mb={4} />
        </Show>
        <FormLabel textStyle="formLabels" color="primary.dark" mr={0}>
          Uploading video
        </FormLabel>
        <Heading fontSize="24px">{percentage}%</Heading>
        <Progress value={percentage} w="full" pos="absolute" bottom="0" />
      </Flex>
    </AspectRatio>
  );
};

export default VideoIsUploading;
