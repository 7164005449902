import { Container, Box, Text, VStack } from '@chakra-ui/react';
import { renderStaticSubtitle } from '../util/static';

const GuidanceOnlineSafetyPage = () => {
  return (
    <Container
      maxW="1332px"
      mt={{ base: '120px', md: '150px' }}
      mb={{ base: '50px', md: '100px' }}
    >
      <Box
        pt={{ base: '40px', lg: '60px' }}
        pb={{ base: '60px', lg: '100px' }}
        bg="white"
        borderRadius="20px"
      >
        <Container maxW="1032px">
          <Text
            as="h1"
            textStyle="headingSemiBold"
            fontSize={{ base: '32px', lg: '40px' }}
            lineHeight="150%"
            mb={{ base: '40px', lg: '60px' }}
          >
            Storyvault.com user’s on-line safety policy
          </Text>
          <VStack spacing={10} alignItems="flex-start">
            <Box>
              {renderStaticSubtitle('We believe that:')}
              <VStack as="ul" spacing={6} ml={6} alignItems="flex-start">
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  children and young people should never experience abuse of any kind.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  children should be able to use the internet for education and personal
                  development, but safeguards need to be in place to ensure they are kept
                  safe at all times.
                </Text>
              </VStack>
            </Box>
            <Box>
              {renderStaticSubtitle('We recognise that:')}
              <VStack as="ul" spacing={6} ml={6} alignItems="flex-start">
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  the online world provides everyone with many opportunities; however it
                  can also present risks and challenges.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  we have a duty to ensure that all children, young people and adults
                  involved in our organisation are protected from potential harm online.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  we have a responsibility to help keep children and young people safe
                  online, whether or not they are using storyvault.com’s website.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  working in partnership with children, young people, their parents,
                  carers and other agencies is essential in promoting young people’s
                  welfare and in helping young people to be responsible in their approach
                  to online safety.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  all children, regardless of age, disability, gender reassignment, race,
                  religion or belief, sex or sexual orientation, have the right to equal
                  protection from all types of harm or abuse.
                </Text>
              </VStack>
            </Box>
            <Box>
              {renderStaticSubtitle(
                'We will seek to keep children and young people safe by:'
              )}
              <VStack as="ul" spacing={6} ml={6} alignItems="flex-start">
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  providing clear and specific directions to staff and volunteers on how
                  to behave online.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  supporting and encouraging the young people using our service to use the
                  internet, social media and mobile phones in a way that keeps them safe
                  and shows respect for others.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  supporting and encouraging parents and carers to do what they can to
                  keep their children safe online.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  adopting clear and robust procedures to enable us to respond
                  appropriately to any incidents of inappropriate online behaviour,
                  whether by an adult or a child or young person.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  reviewing and updating the security of our information systems
                  regularly.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  ensuring that user-names, logins, email accounts and passwords are used
                  effectively.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  ensuring personal information about the adults and children who are
                  involved in our organisation is held securely and shared only as
                  appropriate.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  ensuring that images of children, young people and families are used
                  only after their permission has been obtained, and only for the purpose
                  for which consent has been given.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  providing supervision, support and training for staff and volunteers
                  about online safety.
                </Text>
                <Text as="li" fontSize="18px" lineHeight="150%" color="primary.dark">
                  examining and risk assessing any social media platforms and new
                  technologies before they are used within the organisation.
                </Text>
              </VStack>
            </Box>
            <Box>
              {renderStaticSubtitle('If in doubt…')}
              <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                If you are involved in a Storyvault project, or any other on-line
                activity, and you feel in any way uncomfortable or in danger, you should
                immediately alert your carer or your teacher. If you are concerned or
                uncomfortable about anything you see on Storyvault.com, please let us know
                on{' '}
                <a
                  href="mailto:info@storyvault.com"
                  style={{ textDecoration: 'underline' }}
                >
                  info@storyvault.com
                </a>
                , where your worries will receive a supportive and speedy response.
              </Text>
            </Box>
          </VStack>
        </Container>
      </Box>
    </Container>
  );
};

export default GuidanceOnlineSafetyPage;
