import { Icon } from '@chakra-ui/react';

export const IconPlay = (props) => (
  <Icon viewBox="0 0 20 23" {...props}>
    <path
      fill="currentColor"
      d="M19.1836 11.4492L0.433593 22.2745L0.433594 0.623901L19.1836 11.4492Z"
    />
  </Icon>
);
