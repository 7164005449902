import {
  Box,
  Text,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Grid,
  GridItem,
  Flex,
  HStack,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconChevronLeft } from '../theme/icons/IconChevronLeft';
import { IconSearch } from '../theme/icons/IconSearch';
import { IconCategories } from '../theme/icons/IconCategories';
import { CategoriesProvider } from '../context/CategoriesContext';
import UseCategories from '../hooks/useCategories';
import DropdownCategoriesList from './DropdownCategoriesList';

const HeaderDropdownCategories = () => {
  const iconSpacer = '32px';

  return (
    <CategoriesProvider>
      <UseCategories>
        <Box flexShrink={0}>
          {/* Box element introduced because Popper doesn't allow it's elements to have margins (which here comes from HStack for Menu) */}
          <Menu closeOnSelect="true">
            {({ isOpen }) => (
              <>
                <MenuButton
                  _hover={{ color: 'primary.default', textDecoration: 'underline' }}
                >
                  <Text fontSize="18px" textStyle="bodyMedium">
                    Browse
                    <IconChevronLeft
                      boxSize="20px"
                      ml={1}
                      transition="0.3s"
                      transform={isOpen ? 'rotate(90deg)' : 'rotate(-90deg)'}
                    />
                  </Text>
                </MenuButton>
                <MenuList mt="10px" p="30px 0" overflow="auto" maxH="calc(100vh - 100px)">
                  <Grid
                    px="60px"
                    templateColumns="repeat(2, 1fr)"
                    columnGap="70px"
                    rowGap="18px"
                  >
                    <GridItem>
                      <MenuItem
                        as={RouterLink}
                        to="/search"
                        p={0}
                        _hover={{ bg: 'transparent' }}
                      >
                        <Flex flexDirection="column" alignItems="flex-start" role="group">
                          <HStack spacing={0} alignItems="center">
                            <Box w={iconSpacer}>
                              <IconSearch
                                color="accent.default"
                                boxSize="20px"
                                verticalAlign="sub"
                              />
                            </Box>
                            <Text _groupHover={{ textDecoration: 'underline' }}>
                              Video stories
                            </Text>
                          </HStack>
                          <Text
                            textStyle="bodyRegular"
                            fontSize="14px"
                            color="primary.dark"
                            mt="8px"
                            maxW="210px"
                          >
                            Discover Storyvault video stories
                          </Text>
                        </Flex>
                      </MenuItem>
                    </GridItem>
                    <GridItem>
                      <MenuItem
                        as={RouterLink}
                        to="/all-categories"
                        p={0}
                        _hover={{ bg: 'transparent' }}
                      >
                        <Flex flexDirection="column" alignItems="flex-start" role="group">
                          <HStack spacing={0} alignItems="center">
                            <Box w={iconSpacer}>
                              <IconCategories
                                color="accent.default"
                                boxSize="20px"
                                verticalAlign="sub"
                              />
                            </Box>
                            <Text _groupHover={{ textDecoration: 'underline' }}>
                              All categories
                            </Text>
                          </HStack>
                          <Text
                            textStyle="bodyRegular"
                            fontSize="14px"
                            color="primary.dark"
                            mt="8px"
                            maxW="210px"
                          >
                            View all categories with popular tags
                          </Text>
                        </Flex>
                      </MenuItem>
                    </GridItem>
                  </Grid>
                  <Text
                    px="60px"
                    mt="30px"
                    mb="18px"
                    textStyle="bodyMedium"
                    textTransform="uppercase"
                  >
                    Categories
                  </Text>
                  <Grid
                    px="60px"
                    templateColumns="repeat(2, 1fr)"
                    columnGap="70px"
                    rowGap="18px"
                  >
                    <DropdownCategoriesList />
                  </Grid>
                </MenuList>
              </>
            )}
          </Menu>
        </Box>
      </UseCategories>
    </CategoriesProvider>
  );
};

export default HeaderDropdownCategories;
