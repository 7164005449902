import {
  Container,
  Text,
  Box,
  Image,
  Grid,
  GridItem,
  Flex,
  Show,
  Button,
} from '@chakra-ui/react';
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PhotoSrc from '../../assets/img/photos/photo-26.webp';
import PhotoCompressedSrc from '../../assets/img/photos/photo-26-compressed.jpg';
import DotsSrc from '../../assets/vectors/purpleLightDottedTriangle.svg';
import PictureOptimizedSingle from '../UI/PictureOptimizedSingle';
import { IconLogo } from '../../theme/icons/IconLogo';

const SectionCollageReversed4 = () => {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <Container bg="secondary.lighter" px={0} py={{ base: '60px', '2xl': '88px' }}>
      <Container maxW="1562px">
        <Grid
          templateColumns={{ md: 'repeat(2, 1fr)' }}
          columnGap={{ md: '32px', lg: '100px' }}
          rowGap={10}
          alignItems="center"
        >
          <GridItem order={{ md: 2 }}>
            <Flex pos="relative" justifyContent={{ base: 'center', md: 'left' }}>
              <Show above="2xl">
                <Image src={DotsSrc} pos="absolute" top="-60px" right="0px" />
              </Show>
              <PictureOptimizedSingle
                src={PhotoSrc}
                fallbackSrc={PhotoCompressedSrc}
                borderRadius="10px"
                width="648px"
                position="relative"
                zIndex="1"
                filter="drop-shadow(0px 4px 30px rgba(36, 6, 48, 0.3))"
              />
            </Flex>
          </GridItem>
          <GridItem order={{ md: 1 }}>
            <Box maxW="600px" pl={{ '2xl': '67px' }}>
              <Box display="inline-block">
                <Text textStyle="sectionEyebrow">Preserve your memories</Text>
                <Text as="h2" textStyle="sectionHeadline" mt={6}>
                  Upload noteworthy video interviews to My Channel
                </Text>
                <Box w="120px" h="3px" bg="accent.default" mt="30px"></Box>
              </Box>
              <Text textStyle="sectionText" mt="40px">
                Sharing stories has never been easier. Just upload a video clip or still
                straight from your mobile or tablet and preserve your family member’s,
                friend’s or neighbour’s life story alongside other witness accounts of
                remarkable events from around the world.
              </Text>
              <Box w={{ base: '100%', sm: 'auto' }}>
                <Button
                  as={RouterLink}
                  to={!isAuthenticated() ? '/login' : '/add-video'}
                  w={{ base: '100%', md: 'auto' }}
                  mt="40px"
                >
                  <IconLogo boxSize="20px" mr="10px" />
                  Upload a video
                </Button>
              </Box>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Container>
  );
};

export default SectionCollageReversed4;
