import FlickityHorizontalDoubleVideos from './FlickityHorizontalDoubleVideos';
import { FlickityControlsContextProvider } from '../../context/FlickityControlsContext';

const DoubleVideosCarousels = (props) => {
  return (
    // FlickityControlsContextProvider - just for FE purposes, we can use another Context for BE-RE-FE flow
    <FlickityControlsContextProvider>
      <FlickityHorizontalDoubleVideos videos={props.data} key={Math.random()} />

      {props.children}
    </FlickityControlsContextProvider>
  );
};

export default DoubleVideosCarousels;
