// /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-7])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
// /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-7])(?=.*?[^\w\s]).{6,}$/,
// /^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)” + “(?=.*[-+_!@#$%^&*., ?]).+$/,
// export const REGEX_HAS_SYMBOL = /^(?=.*[-+_!@#$%^&*., ?]).+$/
export const REGEX_HAS_SYMBOL = /(\W)+/;
export const REGEX_HAS_AT_LEAST_ONE_NUMBER = /[0-9]/;
export const REGEX_HAS_LOWER_AND_UPPERCASE_LETTERS = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
export const REGEX_IS_CURRENCY_NUMBER = /^\d+(\.\d{1,2})?$/; //positive numbers and allow empty string
export const REGEX_LETTERS_AND_NUMBERS = /^[a-zA-Z0-9]*$/;
export const REGEX_ONLY_NUMBERS = /^[0-9]*$/;
export const REGEX_EMAIL =
  // /^\S+@\S+\.\S+$//^[0-9]*$/;
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
