import { Box, Text } from '@chakra-ui/react';
import DoubleVideosCarousels from '../../../components/carousels/DoubleVideosCarousels';
import VideosListLoading from '../VideosListLoading';

import { useMemo, useContext } from 'react';
import { FeaturedVideosContext } from '../../../context/FeaturedVideosContext';
import { normalizedVideoData } from '../VideosList';
import { isFetching } from '../../../util';

const FeaturedVideos = () => {
  const { state } = useContext(FeaturedVideosContext);
  const { status, items } = state;
  const isLoading = isFetching(status);

  const data = useMemo(() => normalizedVideoData(items), [items]);

  return (
    <>
      <Box mb={{ base: '40px', '2xl': '80px' }}>
        <Text as="h2" textStyle="sectionHeadline" textAlign="center">
          Editor’s Picks
        </Text>
        <Text textStyle="sectionText" mt="30px" textAlign="center">
          Here’s just a few of the videos we think you’d like to see
        </Text>
      </Box>

      {isLoading ? (
        <VideosListLoading />
      ) : (
        <DoubleVideosCarousels data={data} key={Math.random()} />
      )}
    </>
  );
};

export default FeaturedVideos;
