import {
  Button,
  Box,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  VStack,
  SimpleGrid,
  GridItem,
  InputGroup,
  InputLeftElement,
  Flex,
  Spacer,
  Text,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { NameIcon } from '../../assets/icons/NameIcon';
import { EmailIcon } from '../../assets/icons/EmailIcon';
import { TextareaAutosize } from '../common/TextareaAutosize';
import { TEXTAREA_LENGTH_MD } from '../../util/consts';
import CustomToast from '../UI/CustomToast';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { HttpContext } from '../../context/HttpContext';

const ContactUsForm = () => {
  const { authAxios, publicAxios } = useContext(HttpContext);
  const {
    isAuthenticated,
    // getAuthUser
  } = useContext(AuthContext);
  // const user = getAuthUser();

  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const toast = useToast();

  const schema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup
      .string()
      .required('Please enter a valid email address')
      .email('Please enter a valid email address'),
    message: yup.string().required('Please enter your message'),
  });

  const formOptions = {
    resolver: yupResolver(schema),
    mode: 'onChange',
  };

  const { register, handleSubmit, formState, watch, reset } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const watchMessage = watch('message');
  let messageLength = watchMessage?.length ?? 0;

  const submitForm = async (values) => {
    try {
      let axiosInstance = isAuthenticated() ? authAxios : publicAxios;

      await axiosInstance({
        method: 'POST',
        url: `/contact_uses`,
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phone,
          message: values.message,
        },
        headers: {
          Accept: 'application/ld+json',
        },
      });
      reset();

      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="success"
            description={`Thank you!\nWe've received your message. Someone from our team will contact you soon.`}
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    } catch (onErr) {
      console.log(onErr);
      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="success"
            description="Something went wrong."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <VStack spacing="40px">
        <SimpleGrid w="full" maxW="600px" columns={2} columnGap={5} rowGap={10}>
          <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
            <FormControl isInvalid={errors.firstName}>
              <FormLabel htmlFor="firstName" textStyle="formLabels">
                First name
              </FormLabel>
              <InputGroup>
                <Input
                  id="firstName"
                  type="text"
                  name="firstName"
                  placeholder="Julie"
                  // defaultValue={user?.firstName}
                  {...register('firstName')}
                />
                <InputLeftElement
                  pointerEvents="none"
                  color={errors.firstName ? 'error.default' : 'primary.light'}
                  fontSize="22px"
                  children={<NameIcon />}
                />
              </InputGroup>
              <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
            <FormControl isInvalid={errors.lastName}>
              <FormLabel htmlFor="lastName" textStyle="formLabels">
                Last name
              </FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color={errors.lastName ? 'error.default' : 'primary.light'}
                  fontSize="22px"
                  children={<NameIcon />}
                />
                <Input
                  id="lastName"
                  type="text"
                  name="lastName"
                  placeholder="Barett"
                  // defaultValue={user?.lastName}
                  {...register('lastName')}
                />
              </InputGroup>
              <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </SimpleGrid>
        <FormControl isInvalid={errors.email} maxW="600px">
          <FormLabel htmlFor="email" textStyle="formLabels">
            Email
          </FormLabel>
          <InputGroup>
            <Input
              id="email"
              type="email"
              placeholder="example@mail.com"
              // defaultValue={user?.email}
              {...register('email')}
            />
            <InputLeftElement
              pointerEvents="none"
              color={errors.email ? 'error.default' : 'primary.light'}
              fontSize="22px"
              children={<EmailIcon />}
            />
          </InputGroup>
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.phone} maxW="600px">
          <FormLabel htmlFor="phone" textStyle="formLabels">
            Phone number{' '}
            <Text as="span" color="primary.light">
              (optional)
            </Text>
          </FormLabel>
          <Input
            id="phone"
            type="text"
            placeholder="Enter your phone number"
            {...register('phone')}
          />
          <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
        </FormControl>
        <Box maxW="600px" w="full">
          <FormControl isInvalid={errors.message}>
            <FormLabel htmlFor="category" textStyle="formLabels">
              Your message
            </FormLabel>
            <TextareaAutosize
              id="message"
              type="text"
              placeholder="Enter your message "
              maxLength={TEXTAREA_LENGTH_MD}
              {...register('message')}
            />
            <Flex justifyContent="space-between">
              <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
              <Spacer />
              <FormHelperText
                color={
                  TEXTAREA_LENGTH_MD >= messageLength ? 'primary.light' : 'error.default'
                }
                mx={5}
                flexShrink={0}
              >
                {messageLength} / {TEXTAREA_LENGTH_MD}
              </FormHelperText>
            </Flex>
          </FormControl>
        </Box>
        <Box maxW="600px" w="full">
          <Button isFullWidth type="submit" isLoading={isSubmitting}>
            Send message
          </Button>
        </Box>
      </VStack>
    </form>
  );
};

export default ContactUsForm;
