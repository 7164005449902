import { useContext, useState, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Heading,
  Input,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  SimpleGrid,
  GridItem,
  Button,
  Box,
  Link,
  Text,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Checkbox,
  HStack,
  Stack,
  Image,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Logo from '../assets/img/logo.svg';
import { NameIcon } from '../assets/icons/NameIcon';
import { EmailIcon } from '../assets/icons/EmailIcon';
import { EyeOnIcon } from '../assets/icons/EyeOnIcon';
import { EyeOffIcon } from '../assets/icons/EyeOffIcon';
import { PasswordIcon } from '../assets/icons/PasswordIcon';
import { PasswordCheckboxEmptyIcon } from '../assets/icons/PasswordCheckboxEmptyIcon';
import { PasswordCheckboxCheckedIcon } from '../assets/icons/PasswordCheckboxCheckedIcon';
import { passwordLength } from '../helper/validations';
import '../helper/passwordTooltip.css';

import { HttpContext } from '../context/HttpContext';

const SignupPage = () => {
  const navigate = useNavigate();
  const { publicAxios } = useContext(HttpContext);

  // password visibility handle
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const [showPasswordTooltip, setShowPassordTooltip] = useState(false);
  const onFocusHandler = () => {
    setShowPassordTooltip(true);
  };
  const onBlurHandler = () => {
    setShowPassordTooltip(false);
  };
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: (e) => {
      setShowPassordTooltip(false);
    },
  });

  // yup schema and hook form setup
  const schema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup
      .string()
      .required('Please enter a valid email address')
      .email('Please enter a valid email address'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Please enter at least 8 characters'),
    terms: yup
      .boolean()
      .isTrue('You must accept the Terms and Conditions in order to sign up.')
      .required('You must accept the Terms and Conditions in order to sign up.'),
  });

  const formOptions = {
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  };

  const {
    register,
    handleSubmit,
    formState,
    watch,
    // getFieldState,
    reset,
    setError,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  // password watch for tooltip rendering
  const watchPassword = watch('password');
  // const fieldStatePassword = getFieldState('password');

  // firstname and lastname inputs responsive
  const [isLargerThanMD] = useMediaQuery('(min-width: 68em)');

  // submit handle
  const submitForm = async (values) => {
    try {
      // console.log(values);
      await publicAxios.post(`/users`, values);

      // post-success UI events
      reset();
      navigate('/account-activation');
    } catch (onError) {
      console.log(onError);
      if (onError.response?.status === 422) {
        setError('email', {
          type: 'server',
          message: 'Email is already in use.',
        });
      }
    }
  };

  return (
    <Center minH="96vh">
      <Box textAlign="left" p={4} maxW="532px">
        <form onSubmit={handleSubmit(submitForm)} noValidate>
          <VStack spacing="30px">
            <VStack
              spacing={7}
              alignItems="flex-start"
              w="full"
              mb={{ base: 4, '2xl': 16 }}
            >
              <Link as={RouterLink} to="/">
                <Image src={Logo} />
              </Link>
              <Heading size="lg" color="primary.default">
                Create your account
              </Heading>
            </VStack>
            <SimpleGrid w="full" columns={2} columnGap={4} rowGap={[5, 5]}>
              <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                <FormControl isInvalid={errors.firstName}>
                  <FormLabel htmlFor="firstName" textStyle="formLabels">
                    First name
                  </FormLabel>
                  <InputGroup>
                    <Input
                      id="firstName"
                      type="text"
                      name="firstName"
                      placeholder="John"
                      {...register('firstName')}
                    />
                    <InputLeftElement
                      pointerEvents="none"
                      color={errors.firstName ? 'error.default' : 'primary.light'}
                      fontSize="22px"
                      children={<NameIcon />}
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                <FormControl isInvalid={errors.lastName}>
                  <FormLabel htmlFor="lastName" textStyle="formLabels">
                    Last name
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color={errors.lastName ? 'error.default' : 'primary.light'}
                      fontSize="22px"
                      children={<NameIcon />}
                    />
                    <Input
                      id="lastName"
                      type="text"
                      name="lastName"
                      placeholder="Doe"
                      {...register('lastName')}
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </SimpleGrid>
            <FormControl isInvalid={errors.email}>
              <FormLabel htmlFor="email" textStyle="formLabels">
                Email
              </FormLabel>
              <InputGroup>
                <Input
                  id="email"
                  type="email"
                  placeholder="example@mail.com"
                  {...register('email')}
                />
                <InputLeftElement
                  pointerEvents="none"
                  color={errors.email ? 'error.default' : 'primary.light'}
                  fontSize="22px"
                  children={<EmailIcon />}
                />
              </InputGroup>
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.password}>
              <FormLabel htmlFor="password" textStyle="formLabels">
                Password
              </FormLabel>
              <InputGroup ref={ref}>
                <InputLeftElement
                  pointerEvents="none"
                  color={errors.password ? 'error.default' : 'primary.light'}
                  fontSize="22px"
                  children={<PasswordIcon />}
                />
                <Input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="* * * * * * * *"
                  {...register('password')}
                  onFocus={onFocusHandler}
                  onBlur={onBlurHandler}
                />
                <InputRightElement
                  children={
                    <Button
                      h="40px"
                      size="lg"
                      mr={3}
                      variant="eyeButton"
                      alignItems="center"
                      onClick={handlePasswordVisibility}
                      fontSize="22px"
                      children={showPassword ? <EyeOnIcon /> : <EyeOffIcon />}
                    />
                  }
                />
                {showPasswordTooltip && (
                  <Box
                    position="absolute"
                    bottom="calc(100% - 4px)"
                    right="0"
                    zIndex="10"
                    textStyle="bodyMedium"
                    fontSize="14px"
                    w="244px"
                    p="16px"
                    backgroundColor="primary.default"
                    borderRadius="10px"
                    color="white"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <div className="passwordBoxTriangle" />
                    <VStack alignItems="flex-start" spacing={4}>
                      <Text>Your password needs to have:</Text>
                      <HStack spacing="10px">
                        {passwordLength(watchPassword, 8, 100) ? (
                          <PasswordCheckboxCheckedIcon />
                        ) : (
                          <PasswordCheckboxEmptyIcon />
                        )}
                        <Text
                          color={
                            passwordLength(watchPassword, 8, 100)
                              ? 'accent.light'
                              : 'white'
                          }
                        >
                          8 characters minimum
                        </Text>
                      </HStack>
                    </VStack>
                    <Box className="passwordTriangle" />
                  </Box>
                )}
              </InputGroup>
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.terms}>
              <Box mb={{ base: 4, '2xl': 9 }}>
                <Checkbox id="terms" {...register('terms')} alignItems="flex-start">
                  <Text fontSize="16px">
                    I agree with Storyvault &nbsp;
                    <Link
                      as={RouterLink}
                      to="/terms-and-conditions"
                      color="accent.default"
                      textDecoration="underline"
                    >
                      Terms&nbsp;&amp;&nbsp;Conditions
                    </Link>
                    &nbsp; and &nbsp;
                    <Link
                      as={RouterLink}
                      to="/privacy-policy"
                      color="accent.default"
                      textDecoration="underline"
                    >
                      Privacy&nbsp;Policy
                    </Link>
                  </Text>
                </Checkbox>
                <FormErrorMessage>{errors.terms?.message}</FormErrorMessage>
              </Box>
            </FormControl>
            <Box w="full">
              <Button
                isFullWidth
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Create account
              </Button>
            </Box>
          </VStack>
          <Stack
            direction={isLargerThanMD ? 'row' : 'column'}
            justifyContent="center"
            alignItems="center"
            mt="20px"
          >
            <Text>Already a member?</Text>
            <Link
              as={RouterLink}
              to="/login"
              color="accent.default"
              textDecoration="underline"
              textStyle="bodySemiBold"
            >
              Log In
            </Link>
          </Stack>
        </form>
      </Box>
    </Center>
  );
};

export default SignupPage;
