import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';

const usePageTracking = () => {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.page();
    // track pageview with gtag / react-ga / react-ga4, for example:
    // window.gtag("event", "page_view", {
    //   page_path: location.pathname + location.search,
    // });
  }, [location]);
};

export default usePageTracking;
