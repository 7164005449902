import { useState, useRef } from 'react';
import {
  Container,
  Input,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  SimpleGrid,
  GridItem,
  Button,
  Box,
  Link,
  Text,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Checkbox,
  HStack,
  Radio,
  Select,
  Flex,
  Spacer,
  RadioGroup,
  FormHelperText,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { IconChevronLeft } from '../../theme/icons/IconChevronLeft';
import { IconDeleteThin } from '../../theme/icons/IconDeleteThin';
import { NameIcon } from '../../assets/icons/NameIcon';
import { EmailIcon } from '../../assets/icons/EmailIcon';
import { EyeOnIcon } from '../../assets/icons/EyeOnIcon';
import { EyeOffIcon } from '../../assets/icons/EyeOffIcon';
import { PasswordIcon } from '../../assets/icons/PasswordIcon';
import { PasswordCheckboxEmptyIcon } from '../../assets/icons/PasswordCheckboxEmptyIcon';
import { PasswordCheckboxCheckedIcon } from '../../assets/icons/PasswordCheckboxCheckedIcon';
import { passwordLength } from '../../helper/validations';
import '../../helper/passwordTooltip.css';

import { TextareaAutosize } from '../common/TextareaAutosize';
import { TEXTAREA_LENGTH_MD } from '../../util/consts';

import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import {
  dummyTags,
  TAGS_LIMIT,
  stylesReactSelect,
  stylesReactSelectIndicators,
} from '../videos/reactSelectHelpers';

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <IconDeleteThin fontSize="12px" />
    </components.MultiValueRemove>
  );
};

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <IconDeleteThin fontSize="12px" />
    </components.ClearIndicator>
  );
};

const FormControls = () => {
  const [tagsLimitReached, setTagsLimitReached] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const [showPasswordTooltip, setShowPassordTooltip] = useState(false);
  const onFocusHandler = () => {
    setShowPassordTooltip(true);
  };
  const onBlurHandler = () => {
    setShowPassordTooltip(false);
  };
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: (e) => {
      setShowPassordTooltip(false);
    },
  });

  const schema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    email: yup
      .string()
      .required('Please enter a valid email address')
      .email('Please enter a valid email address'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Please enter at least 8 characters'),
    terms: yup
      .boolean()
      .isTrue('You must accept the Terms and Conditions in order to sign up.')
      .required('You must accept the Terms and Conditions in order to sign up.'),
  });

  const formOptions = {
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  };

  const { register, handleSubmit, formState, watch, control } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const watchPassword = watch('password');
  const watchAbout = watch('about');
  let aboutLength = watchAbout?.length ?? 0;

  const [isLargerThanMD] = useMediaQuery('(min-width: 68em)');

  const handleTagChange = (newValue, actionMeta) => {
    console.group('Value Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    setTagsLimitReached(newValue.length >= TAGS_LIMIT);
  };

  const submitForm = async (values) => {
    console.log('Submitted values:');
    console.log(values);
  };

  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Center>
        <Box textAlign="left" p={4} maxW="600px">
          <form onSubmit={handleSubmit(submitForm)} noValidate>
            <VStack spacing="30px">
              <SimpleGrid w="full" columns={2} columnGap={4} rowGap={[5, 5]}>
                <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                  <FormControl isInvalid={errors.firstName}>
                    <FormLabel htmlFor="firstName" textStyle="formLabels">
                      First name{' '}
                      <Text as="span" color="primary.light">
                        (required)
                      </Text>
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="firstName"
                        type="text"
                        name="firstName"
                        placeholder="John"
                        {...register('firstName')}
                      />
                      <InputLeftElement
                        pointerEvents="none"
                        color={errors.firstName ? 'error.default' : 'primary.light'}
                        fontSize="22px"
                        children={<NameIcon />}
                      />
                    </InputGroup>
                    <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                  <FormControl isInvalid={errors.lastName}>
                    <FormLabel htmlFor="lastName" textStyle="formLabels">
                      Last name
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color={errors.lastName ? 'error.default' : 'primary.light'}
                        fontSize="22px"
                        children={<NameIcon />}
                      />
                      <Input
                        id="lastName"
                        type="text"
                        name="lastName"
                        placeholder="Doe"
                        {...register('lastName')}
                      />
                    </InputGroup>
                    <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              <FormControl isInvalid={errors.email}>
                <FormLabel htmlFor="email" textStyle="formLabels">
                  Email
                </FormLabel>
                <InputGroup>
                  <Input
                    id="email"
                    type="email"
                    placeholder="example@mail.com"
                    {...register('email')}
                  />
                  <InputLeftElement
                    pointerEvents="none"
                    color={errors.email ? 'error.default' : 'primary.light'}
                    fontSize="22px"
                    children={<EmailIcon />}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.password}>
                <FormLabel htmlFor="password" textStyle="formLabels">
                  Password
                </FormLabel>
                <InputGroup ref={ref}>
                  <InputLeftElement
                    pointerEvents="none"
                    color={errors.password ? 'error.default' : 'primary.light'}
                    fontSize="22px"
                    children={<PasswordIcon />}
                  />
                  <Input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    placeholder="* * * * * * * *"
                    {...register('password')}
                    onFocus={onFocusHandler}
                    onBlur={onBlurHandler}
                  />
                  <InputRightElement
                    children={
                      <Button
                        h="40px"
                        size="lg"
                        mr={3}
                        variant="eyeButton"
                        alignItems="center"
                        onClick={handlePasswordVisibility}
                        fontSize="22px"
                        children={showPassword ? <EyeOnIcon /> : <EyeOffIcon />}
                      />
                    }
                  />
                  {showPasswordTooltip && (
                    <Box
                      position="absolute"
                      bottom="calc(100% - 4px)"
                      right="0"
                      zIndex="10"
                      textStyle="bodyMedium"
                      fontSize="14px"
                      w="244px"
                      p="16px"
                      backgroundColor="primary.default"
                      borderRadius="10px"
                      color="white"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <div className="passwordBoxTriangle" />
                      <VStack alignItems="flex-start" spacing={4}>
                        <Text>Your password needs to have:</Text>
                        <HStack spacing="10px">
                          {passwordLength(watchPassword, 8, 100) ? (
                            <PasswordCheckboxCheckedIcon />
                          ) : (
                            <PasswordCheckboxEmptyIcon />
                          )}
                          <Text
                            color={
                              passwordLength(watchPassword, 8, 100)
                                ? 'accent.light'
                                : 'white'
                            }
                          >
                            8 characters minimum
                          </Text>
                        </HStack>
                      </VStack>
                      <Box className="passwordTriangle" />
                    </Box>
                  )}
                </InputGroup>
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.title}>
                <FormLabel htmlFor="title" textStyle="formLabels">
                  Story title
                </FormLabel>
                <Input
                  id="title"
                  type="text"
                  placeholder="Enter story title"
                  {...register('title')}
                />
                <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.category}>
                <FormLabel htmlFor="category" textStyle="formLabels">
                  Choose category
                </FormLabel>
                <Select
                  icon={<IconChevronLeft transform="rotate(-90deg)" />}
                  placeholder="Select category"
                  {...register('category')}
                >
                  <option value="music">Music</option>
                  <option value="movies">Movies</option>
                </Select>
                <FormErrorMessage>{errors.category?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.about}>
                <FormLabel htmlFor="about" textStyle="formLabels">
                  About this story
                </FormLabel>
                <TextareaAutosize
                  id="about"
                  type="text"
                  placeholder="Describe what your story is about"
                  maxLength={TEXTAREA_LENGTH_MD}
                  {...register('about')}
                />
                <Flex justifyContent="space-between">
                  <FormErrorMessage>{errors.about?.message}</FormErrorMessage>
                  <Spacer />
                  <FormHelperText
                    color={
                      TEXTAREA_LENGTH_MD >= aboutLength
                        ? 'primary.light'
                        : 'error.default'
                    }
                    mx={5}
                    flexShrink={0}
                  >
                    {aboutLength} / {TEXTAREA_LENGTH_MD}
                  </FormHelperText>
                </Flex>
              </FormControl>

              <FormControl isInvalid={errors.aboutSmall}>
                <FormLabel htmlFor="aboutSmall" textStyle="formLabels">
                  About this story - Small
                </FormLabel>
                <TextareaAutosize
                  id="aboutSmall"
                  type="text"
                  placeholder="Describe what your story is about"
                  maxLength={TEXTAREA_LENGTH_MD}
                  sizesmall="true"
                  {...register('aboutSmall')}
                />
                <Flex justifyContent="space-between">
                  <FormErrorMessage>{errors.aboutSmall?.message}</FormErrorMessage>
                </Flex>
              </FormControl>

              <FormControl mt="20px !important" isInvalid={errors.tags}>
                <FormLabel htmlFor="tags" textStyle="formLabels">
                  Add tags
                </FormLabel>
                <Text color="primary.dark" fontSize="14px" lineHeight="150%" mb={4}>
                  Add up to {TAGS_LIMIT} tags that define what your video story is about.
                </Text>
                <CreatableSelect
                  isLoading={false}
                  isOptionDisabled={() => tagsLimitReached}
                  onChange={handleTagChange}
                  options={dummyTags}
                  // onCreateOption={handleCreateOption}
                  // defaultValue={
                  //   [
                  //     // tagOptions[3],
                  //     // tagOptions[4],
                  //     // tagOptions[13],
                  //     // tagOptions[15],
                  //   ]
                  // }
                  components={{ MultiValueRemove, ClearIndicator }}
                  placeholder="Enter tags"
                  isMulti
                  // closeMenuOnSelect={false}
                  styles={{ ...stylesReactSelect, ...stylesReactSelectIndicators }}
                />
                <FormErrorMessage>{errors.tags?.message}</FormErrorMessage>
                {tagsLimitReached && (
                  <FormHelperText color="primary.light" textAlign="right">
                    You have entered {TAGS_LIMIT} tags
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl isInvalid={errors.sport}>
                <FormLabel htmlFor="sport" textStyle="formLabels" mb={5}>
                  Radio choice
                </FormLabel>
                <Controller
                  control={control}
                  name="sport"
                  defaultValue="1"
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <VStack spacing={5} alignItems="flex-start">
                        <Radio value="1">
                          Basketball
                          <Text className="label-helper">
                            Basketball is a team sport in which two teams, most commonly
                            of five players each, opposing one another on a rectangular
                            court, compete with the primary objective of shooting a
                            basketball through the defender's hoop
                          </Text>
                        </Radio>
                        <Radio value="2">
                          Association football
                          <Text className="label-helper">
                            Association football, more commonly known as simply football
                            or soccer
                          </Text>
                        </Radio>
                        <Radio value="3">Hiking</Radio>
                      </VStack>
                    </RadioGroup>
                  )}
                />
                <FormErrorMessage>{errors.sport?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.terms}>
                <FormLabel htmlFor="sport" textStyle="formLabels" mb={5}>
                  Checkbox choice
                </FormLabel>
                <Box mb={{ base: 4, '2xl': 9 }}>
                  <Checkbox id="terms" {...register('terms')} alignItems="flex-start">
                    <Text fontSize="16px">
                      *I agree with Storyvault &nbsp;
                      <Link color="accent.default" textDecoration="underline">
                        Terms&nbsp;&amp;&nbsp;Conditions
                      </Link>
                      &nbsp; and &nbsp;
                      <Link color="accent.default" textDecoration="underline">
                        Privacy&nbsp;Policy
                      </Link>
                    </Text>
                  </Checkbox>
                  <FormErrorMessage>{errors.terms?.message}</FormErrorMessage>
                </Box>
              </FormControl>

              <Box w="full">
                <Button
                  isFullWidth
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Box>

              <Box textAlign="left" w="full">
                <Text color="primary.dark" fontSize="14px" lineHeight="150%" mb={4}>
                  We also use following controls:
                  <br />
                  <Box as="span" textStyle="headingSemiBold">
                    Controlled Radio Group from Chakra UI
                  </Box>{' '}
                  on page Create video story
                  <br />
                  <Box as="span" textStyle="headingSemiBold">
                    Dropzone
                  </Box>{' '}
                  to upload videos and photos
                  <br />
                  <Box as="span" textStyle="headingSemiBold">
                    input type="file"
                  </Box>{' '}
                  to upload profile image
                </Text>
              </Box>
            </VStack>
          </form>
        </Box>
      </Center>
    </Container>
  );
};

export default FormControls;
