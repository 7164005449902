import { Box, Grid, GridItem, useMediaQuery } from '@chakra-ui/react';
import VideoListCardSkeleton from './VideoListCardSkeleton';

const VideosListLoading = ({ startColor, endColor }) => {
  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');

  const numberOfSkeletons = isLargerThan2XL
    ? 4
    : isLargerThanXL
    ? 3
    : isLargerThanLG
    ? 2
    : isLargerThanMD
    ? 1
    : isLargerThanSM
    ? 2
    : 1;

  return (
    <Box mb="50px">
      <Grid
        templateColumns={{
          base: '1fr',
          sm: 'repeat(2, 1fr)',
          md: '1fr',
          lg: 'repeat(2, 1fr)',
          xl: 'repeat(3, 1fr)',
          '2xl': 'repeat(4, 1fr)',
        }}
        columnGap={{ base: '16px', lg: '30px' }}
        rowGap={{ base: '32px', lg: '50px' }}
      >
        {[...Array(numberOfSkeletons).keys()].map((item, index) => {
          return (
            <GridItem key={index}>
              <VideoListCardSkeleton startColor={startColor} endColor={endColor} />
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};

export default VideosListLoading;
