import {
  SimpleGrid,
  GridItem,
  Container,
  Tag,
  TagLabel,
  useDisclosure,
} from '@chakra-ui/react';

import WithFormModal from '../modals/WithFormModal';
import WithConfirmationModal from '../modals/WithConfirmationModal';
import ShareModal from '../modals/ShareModal';
import ReportThankYouModal from '../modals/ReportThankYouModal';

function Modals() {
  const {
    isOpen: isOpenWithForm,
    onOpen: onOpenWithForm,
    onClose: onCloseWithForm,
  } = useDisclosure();
  const {
    isOpen: isOpenWithConfirmation,
    onOpen: onOpenWithConfirmation,
    onClose: onCloseWithConfirmation,
  } = useDisclosure();
  const {
    isOpen: isOpenShare,
    onOpen: onOpenShare,
    onClose: onCloseShare,
  } = useDisclosure();
  const {
    isOpen: isOpenReportThankYou,
    onOpen: onOpenReportThankYou,
    onClose: onCloseReportThankYou,
  } = useDisclosure();
  const items = [
    { name: 'With form', f: onOpenWithForm },
    { name: 'With confirmation', f: onOpenWithConfirmation },
    { name: 'Share & Copy link', f: onOpenShare },
    { name: 'Report Thank You', f: onOpenReportThankYou },
  ];

  return (
    <>
      <Container bg="white" borderBottom="solid 1px #DDD">
        <Container maxW="1180px" px="0" py={6}>
          <SimpleGrid
            w="full"
            columns={{ base: 1, sm: 2, md: 4 }}
            columnGap={4}
            rowGap={4}
          >
            {items.map((item, index) => {
              return (
                <GridItem key={index}>
                  <Tag onClick={item.f}>
                    <TagLabel textTransform="capitalize">{item.name}</TagLabel>
                  </Tag>
                </GridItem>
              );
            })}
          </SimpleGrid>
        </Container>
      </Container>

      <WithFormModal isOpen={isOpenWithForm} onClose={onCloseWithForm} user={null} />
      <WithConfirmationModal
        isOpen={isOpenWithConfirmation}
        onClose={onCloseWithConfirmation}
      />
      <ShareModal
        isOpen={isOpenShare}
        onClose={onCloseShare}
        title="Share Storyvault page"
      />
      <ReportThankYouModal
        isOpen={isOpenReportThankYou}
        onClose={onCloseReportThankYou}
      />
    </>
  );
}

export default Modals;
