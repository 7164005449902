import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { IconPlus } from '../../theme/icons/IconPlus';
import { IconMinus } from '../../theme/icons/IconMinus';

const AccordionComponent = ({ items }) => {
  return (
    <Accordion allowMultiple>
      {items.map((item, index) => {
        return (
          <AccordionItem key={index}>
            {({ isExpanded }) => (
              <>
                <AccordionButton>
                  <Box as="h2" flex="1" textAlign="left">
                    {item.title}
                  </Box>
                  {isExpanded ? (
                    <IconMinus fontSize="30px" color="accent.default" ml={4} />
                  ) : (
                    <IconPlus fontSize="30px" color="accent.default" ml={4} />
                  )}
                </AccordionButton>
                <AccordionPanel as="h3">{item.text}</AccordionPanel>
              </>
            )}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default AccordionComponent;
