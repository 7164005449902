import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Box,
  VStack,
  SimpleGrid,
  GridItem,
  useMediaQuery,
  Text,
  Container,
  useToast,
} from '@chakra-ui/react';
import CustomToast from '../UI/CustomToast';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { HttpContext } from '../../context/HttpContext';
import MyChannelContext from '../../context/MyChannelContext';
import { AuthContext } from '../../context/AuthContext';

const DeleteVideoModal = ({ onClose, isOpen, data, parent }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { authAxios } = useContext(HttpContext);
  const { restartPagination } = useContext(MyChannelContext);
  const { getAuthUser } = useContext(AuthContext);
  const { slug } = getAuthUser();

  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  async function consoleLogConfirmDelete(e) {
    try {
      e.preventDefault();

      await authAxios({
        method: 'DELETE',
        url: `/videos/${data.slug}`,
      });
      onClose();

      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="success"
            description="You have successfully deleted your video story."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
      if (parent !== 'video_list_card') {
        navigate(`/channel/${slug}`, { replace: true });
      } else {
        restartPagination();
      }
    } catch (onErr) {
      console.log(onErr);
      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="error"
            description="Something went wrong."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    }
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg" color="primary.default">
            Delete video story
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container p={0} maxW="600px">
            <VStack
              mt={4}
              mb={{ base: 4, md: 9 }}
              textAlign="center"
              fontSize={{ md: '20px' }}
            >
              <Text>
                Deleting{' '}
                <Text as="span" textStyle="bodySemiBold">
                  {data?.title}
                </Text>{' '}
                video story and its associated data is permanent and can’t be undone.
              </Text>
              <Text color="error.default" textStyle="bodySemiBold">
                Are you sure you want to delete this video story?
              </Text>
            </VStack>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Box maxW="600px" w="full">
            <SimpleGrid
              w="full"
              maxW="600px"
              columns={2}
              columnGap={{ md: '30px' }}
              rowGap={7}
            >
              <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                <Button onClick={onClose} isFullWidth variant="outlineError">
                  Cancel
                </Button>
              </GridItem>
              <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                <Button
                  onClick={consoleLogConfirmDelete}
                  variant="solidError"
                  isFullWidth
                >
                  Delete
                </Button>
              </GridItem>
            </SimpleGrid>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteVideoModal;
