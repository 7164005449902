import {
  Box,
  Container,
  Text,
  Image,
  Flex,
  Show,
  VStack,
  Button,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import defaultPosterSmallSrc from '../../assets/img/heroes/soonXS.webp';
import defaultPosterSrc from '../../assets/img/heroes/soon.webp';
import defaultPosterCompressedSrc from '../../assets/img/heroes/soonCompressed.jpg';
import { IconVideoAdd } from '../../theme/icons/IconVideoAdd';
import PictureOptimized from '../UI/PictureOptimized';

const HeroLoggedHome = ({ posterSrcSmall }) => {
  const breakWord = {
    wordBreak: 'break-all',
  };

  return (
    <Flex
      bg="primary.default"
      pos="relative"
      minH={{ base: 'calc(100vh - 80px)', md: 'calc(100vh - 100px)' }}
    >
      <Show below="sm">
        <Image
          src={posterSrcSmall ?? defaultPosterSmallSrc}
          pos="absolute"
          objectFit="cover"
          objectPosition="right center"
          h="full"
          w="full"
        />
      </Show>
      <Show above="sm">
        <PictureOptimized
          src={defaultPosterSrc}
          fallbackSrc={defaultPosterCompressedSrc}
          objectPosition="right center"
        />
      </Show>

      <Container
        maxW={{ base: 'calc(100vw - 0px)', lg: 'calc(100vw - 84px)', '2xl': '1562px' }}
        py="25px"
        pos="relative"
        zIndex={1}
        display="flex"
      >
        <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
          <VStack
            color="white"
            spacing="25px"
            alignItems="flex-start"
            py={{ md: '50px' }}
            textAlign={{ base: 'center', sm: 'left' }}
          >
            <Box maxW={{ md: '765px', lg: '840px' }}>
              <Text
                as="h1"
                textStyle="heroHeadline"
                lineHeight="1.25"
                fontSize={{ base: '32px', md: '40px' }}
              >
                Help us to create a living record of the times we live in – a video
                archive of stories and memories from extraordinary
                <span style={breakWord}>/</span>ordinary people
              </Text>
            </Box>
            <Text textStyle="heroText" maxW={{ md: '600px', lg: '660px' }}>
              Preserve your and your family’s most-treasured memories for future
              generations.
            </Text>
            <Box w={{ base: '100%', sm: 'auto' }}>
              <Button
                as={RouterLink}
                to="/add-video"
                w={{ base: '100%', md: 'auto' }}
                mt="25px"
              >
                <IconVideoAdd boxSize="24px" mr="10px" />
                Add video story
              </Button>
            </Box>
          </VStack>
        </Flex>
      </Container>
    </Flex>
  );
};

export default HeroLoggedHome;
