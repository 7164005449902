import { Icon } from '@chakra-ui/react';

export const IconCopy = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1538 16C16.9643 16 16 16.9643 16 18.1538V34.7692C16 35.9588 16.9643 36.9231 18.1538 36.9231H34.7692C35.9588 36.9231 36.9231 35.9588 36.9231 34.7692V18.1538C36.9231 16.9643 35.9588 16 34.7692 16H18.1538ZM12.9231 18.1538C12.9231 15.265 15.265 12.9231 18.1538 12.9231H34.7692C37.6581 12.9231 40 15.265 40 18.1538V34.7692C40 37.6581 37.6581 40 34.7692 40H18.1538C15.265 40 12.9231 37.6581 12.9231 34.7692V18.1538Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.23077 3.07692C4.65953 3.07692 4.11169 3.30385 3.70777 3.70777C3.30385 4.11169 3.07692 4.65953 3.07692 5.23077V21.8462C3.07692 22.4174 3.30385 22.9652 3.70777 23.3692C4.11169 23.7731 4.65953 24 5.23077 24H7.07692C7.92659 24 8.61539 24.6888 8.61539 25.5385C8.61539 26.3881 7.92659 27.0769 7.07692 27.0769H5.23077C3.84348 27.0769 2.51302 26.5258 1.53206 25.5449C0.551097 24.5639 0 23.2334 0 21.8462V5.23077C0 3.84348 0.551097 2.51302 1.53206 1.53206C2.51302 0.551097 3.84348 0 5.23077 0H21.8462C23.2334 0 24.5639 0.551097 25.5449 1.53206C26.5258 2.51302 27.0769 3.84348 27.0769 5.23077V7.07692C27.0769 7.92659 26.3881 8.61539 25.5385 8.61539C24.6888 8.61539 24 7.92659 24 7.07692V5.23077C24 4.65953 23.7731 4.11169 23.3692 3.70777C22.9652 3.30385 22.4174 3.07692 21.8462 3.07692H5.23077Z"
    />
  </Icon>
);
