import { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';

import {
  Heading,
  Input,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  Button,
  Box,
  Link,
  Text,
  InputGroup,
  InputRightElement,
  useMediaQuery,
  Stack,
  Flex,
  Image,
  InputLeftElement,
  useToast,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { EmailIcon } from '../assets/icons/EmailIcon';
import { PasswordIcon } from '../assets/icons/PasswordIcon';
import { EyeOffIcon } from '../assets/icons/EyeOffIcon';
import { EyeOnIcon } from '../assets/icons/EyeOnIcon';
import Logo from '../assets/img/logo.svg';
import CustomToast from '../components/UI/CustomToast';
import { HttpContext } from '../context/HttpContext';
import { AuthContext, KEYS } from '../context/AuthContext';
import { getUrlSearchParams } from '../util';

const Login = () => {
  const toast = useToast();
  let query = getUrlSearchParams();
  let redirect = query.get('redirect');
  const section = query.get('section');
  const { publicAxios, authAxios } = useContext(HttpContext);
  const { setAuthState } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();

  let from = location.state?.from?.pathname || '/';
  if (from === '/logout') {
    from = '/';
  }

  let search = location.state?.from?.search || '';
  from = from + search;

  // password visibility handle
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  // get-started btn and login btn responsive
  const [isLargerThanMD] = useMediaQuery('(min-width: 68em)');

  // yup schema and hook form setup
  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Please enter a valid email address')
      .email('Please enter a valid email address'),
    password: yup.string().required('Please enter the password you used to sign in'),
  });

  const formOptions = {
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  };

  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  // submit handle
  const submitForm = async (values) => {
    try {
      const { data: responseData } = await publicAxios.post(
        `authentication_token`,
        values
      );

      // console.log({responseData});
      const { token, refresh_token: refreshToken } = responseData;
      // console.log({ token, refreshToken });
      if (token) {
        localStorage.setItem(KEYS.token, token);
        const { data: users } = await authAxios.get(`logged_user_data`);
        const user = users.length > 0 ? users[0] : null;
        if (user) {
          setAuthState({
            token,
            user,
            refreshToken,
          });

          // post-success UI events
          reset();
          if (redirect) {
            if (section) {
              redirect = `${redirect}?section=${section}`;
            }
            navigate(redirect, { replace: true });
          } else {
            navigate(from, { replace: true });
          }
        }
      }
    } catch (onError) {
      console.log(onError);
      // post-error UI events
      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="error"
            description="Sorry, we don’t recognize that email address and password. Please check your login details and try again."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    }
  };

  return (
    <Center minH="96vh">
      <Box textAlign="left" p={4} maxW="full" w="532px">
        <form onSubmit={handleSubmit(submitForm)} noValidate>
          <VStack spacing={9}>
            <VStack
              spacing={7}
              alignItems="flex-start"
              w="full"
              mb={{ base: 4, '2xl': 16 }}
            >
              <Link as={RouterLink} to="/">
                <Image src={Logo} />
              </Link>
              <Heading size="lg" color="primary.default">
                Log in
              </Heading>
            </VStack>
            <FormControl isInvalid={errors.email}>
              <FormLabel htmlFor="email" textStyle="formLabels">
                Email
              </FormLabel>
              <InputGroup>
                <Input
                  id="email"
                  type="email"
                  placeholder="example@mail.com"
                  {...register('email', { required: true })}
                />
                <InputLeftElement
                  pointerEvents="none"
                  color={errors.email ? 'error.default' : 'primary.light'}
                  fontSize="22px"
                  children={<EmailIcon />}
                />
              </InputGroup>
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.password}>
              <Flex justifyContent="space-between" alignItems="flex-end">
                <FormLabel htmlFor="password" textStyle="formLabels">
                  Password
                </FormLabel>
                <Link
                  as={RouterLink}
                  to="/forgot-password"
                  color="accent.default"
                  textDecoration="underline"
                  pb={2}
                  fontSize="14px"
                  textStyle="bodyMedium"
                >
                  Forgot password?
                </Link>
              </Flex>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color={errors.password ? 'error.default' : 'primary.light'}
                  fontSize="22px"
                  children={<PasswordIcon />}
                />
                <Input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="* * * * * * * *"
                  {...register('password', { required: true })}
                />
                <InputRightElement
                  children={
                    <Button
                      h="40px"
                      size="lg"
                      mr={3}
                      variant="eyeButton"
                      alignItems="center"
                      onClick={handlePasswordVisibility}
                      fontSize="22px"
                      children={showPassword ? <EyeOnIcon /> : <EyeOffIcon />}
                    />
                  }
                />
              </InputGroup>
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
            <Box w="full">
              <Button
                mt={{ base: 4, '2xl': 6 }}
                isFullWidth
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Log in
              </Button>
            </Box>
          </VStack>
          <Stack
            direction={isLargerThanMD ? 'row' : 'column'}
            justifyContent="center"
            alignItems="center"
            mt="20px"
          >
            <Text fontSize={{ sm: '14px', md: '16px' }}>Don't have an account?</Text>
            <Link
              as={RouterLink}
              to="/signup"
              textDecoration="underline"
              color="accent.default"
              textStyle="bodySemiBold"
            >
              Get started!
            </Link>
          </Stack>
        </form>
      </Box>
    </Center>
  );
};

export default Login;
