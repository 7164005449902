import { Box } from '@chakra-ui/react';
import PersonalInfo from './PersonalInfo';

const sidebarWidth = '300px';

const Sidebar = ({user}) => {
  return (
    <>
      <Box bg="secondary.lighter" pos="fixed" w={sidebarWidth} h="100vh"></Box>
      <Box
        flexShrink={0}
        bg="secondary.lighter"
        maxH="100vh"
        w={sidebarWidth}
        pos="sticky"
        top="0"
        alignSelf="flex-start"
        overflow="auto"
        pt={{ base: '80px', md: '100px' }}
      >
        <Box p={4} bg="secondary.lighter" overflow="auto">
          <PersonalInfo user={user} />
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
