import { Container, Box, Flex, Text, Grid, GridItem } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { IconArrowRight } from '../theme/icons/IconArrowRight';

const guidanceLinks = [
  {
    title: 'Guidance for young people',
    segment: [
      { wording: 'Guidance for young people', url: '/guidance-for-young-people' },
    ],
  },
  {
    title: 'Guidance for teachers',
    segment: [{ wording: 'Guidance for teachers', url: '/guidance-for-teachers' }],
  },
  {
    title: 'On-line safety policy',
    segment: [{ wording: 'On-line safety policy', url: '/on-line-safety-policy' }],
  },
  {
    title: 'Approaching care homes',
    segment: [{ wording: 'Approaching care homes', url: '/approaching-care-homes' }],
  },
];

const Guidance = (props) => {
  return (
    <Box
      bgColor={props.bgColor ?? 'white'}
      py={{ base: '50px', '2xl': '80px' }}
      pos="relative"
      overflow="hidden"
      borderTop="solid 1px"
      borderTopColor="secondary.light"
    >
      <Container maxW={props.fullWidth ? 'full' : '1562px'}>
        <Flex
          flexDirection={{ base: 'column', xl: 'row' }}
          align="center"
          justifyContent="space-between"
        >
          <Text
            align={{ base: 'center', xl: 'left' }}
            as="h2"
            textStyle="sectionHeadline"
            maxW={'16ch'}
            marginBottom={{ base: 10, xl: 0 }}
          >
            Our guidance for Storyvault users
          </Text>
          <Grid
            as="nav"
            className="footerNav"
            templateColumns={{ base: null, md: 'repeat(2, 1fr)' }}
            rowGap={{ base: 6, lg: 9 }}
            columnGap={{ base: 6, md: 12, '2xl': '250px' }}
            width={{ base: '100%', md: 'auto' }}
          >
            {guidanceLinks.map((guidanceSegment, index) => {
              return (
                <GridItem key={index} whiteSpace={'nowrap'}>
                  {guidanceSegment.segment.map((link, index) => {
                    return (
                      <NavLink
                        key={index}
                        to={link.url}
                        className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                      >
                        <Flex justifyContent="flex-start" alignItems="center">
                          <Text fontSize={{ base: '18px', md: '20px' }} lineHeight="150%">
                            {link.wording}
                          </Text>
                          <Box color="accent.default">
                            <IconArrowRight
                              fontSize="24px"
                              ml={{ base: '8px', lg: '18px' }}
                            />
                          </Box>
                        </Flex>
                      </NavLink>
                    );
                  })}
                </GridItem>
              );
            })}
          </Grid>
        </Flex>
      </Container>
    </Box>
  );
};

export default Guidance;
