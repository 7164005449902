import React from 'react';
import { Box } from '@chakra-ui/react';
import CategoryGenericIcon from './CategoryGenericIcon';

const CategoryGenericItem = (props) => {
  return (
    <Box textAlign="center">
      <CategoryGenericIcon
        icon={props.iconName}
        catId={props.catId}
        elipse={props.elipse}
        picked={props.picked}
      />
    </Box>
  );
};

export default CategoryGenericItem;
