import {
  Container,
  Text,
  Box,
  Image,
  Grid,
  GridItem,
  Flex,
  Show,
} from '@chakra-ui/react';
import PhotoSrc from '../../assets/img/photos/photo-36.webp';
import PhotoCompressedSrc from '../../assets/img/photos/photo-36-compressed.jpg';
import DotsSrc from '../../assets/vectors/purpleLightDottedTriangle.svg';
import PictureOptimizedSingle from '../UI/PictureOptimizedSingle';

const SectionCollage7 = () => {
  return (
    <Container px={0} py={{ base: '60px', '2xl': '88px' }}>
      <Container maxW="1562px">
        <Grid
          templateColumns={{ md: 'repeat(2, 1fr)' }}
          columnGap={{ md: '32px', lg: '100px' }}
          rowGap={10}
          alignItems="center"
        >
          <GridItem>
            <Flex pos="relative" justifyContent={{ base: 'center', md: 'right' }}>
              <Show above="2xl">
                <Image
                  src={DotsSrc}
                  pos="absolute"
                  top="-60px"
                  left="0px"
                  transform="scaleX(-1)"
                />
              </Show>
              <PictureOptimizedSingle
                src={PhotoSrc}
                fallbackSrc={PhotoCompressedSrc}
                borderRadius="10px"
                width="648px"
                position="relative"
                zIndex="1"
              />
            </Flex>
          </GridItem>
          <GridItem>
            <Box maxW="620px">
              <Box display="inline-block" textAlign="left">
                <Text as="h2" textStyle="sectionHeadline">
                  Hear and learn from{' '}
                  <Text as="span" display="inline-block">
                    first-hand
                  </Text>{' '}
                  witnesses
                </Text>
                <Box w="120px" h="3px" bg="accent.default" mt="30px"></Box>
              </Box>
              <Text textStyle="sectionText" mt="40px">
                For students of history, media or I.T. in particular, Storyvault is a
                unique resource offering first-hand witness accounts of many of the
                projects you might be studying. It might be about wartime evacuees or the
                history of fashion. Or “I was there” stories of the British Empire and
                immigration. Simply search under a key-word and we’ll do the rest. History
                teachers in particular are unanimous about the importance of original
                eye-witness accounts in essays and projects.
              </Text>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Container>
  );
};

export default SectionCollage7;
