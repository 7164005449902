import { Container, Box, Flex, Text, Grid, GridItem, Link } from '@chakra-ui/react';
import { IconDownload } from '../../theme/icons/IconDownload';

const downloadLinks = [
  {
    title: 'Download the Bronze Level Logbook',
    segment: [
      {
        wording: 'Download the Bronze Level Logbook',
        url: '/documents/Storyvault DofE Logbook – Bronze.pdf',
      },
    ],
  },
  {
    title: 'Download the Silver Level Logbook',
    segment: [
      {
        wording: 'Download the Silver Level Logbook',
        url: '/documents/Storyvault DofE Logbook – Silver.pdf',
      },
    ],
  },
  {
    title: 'Download the Gold Level Logbook',
    segment: [
      {
        wording: 'Download the Gold Level Logbook',
        url: '/documents/Storyvault DofE Logbook – Gold.pdf',
      },
    ],
  },
  {
    title: 'Download Guidance for Assessors',
    segment: [
      {
        wording: 'Download Guidance for Assessors',
        url: '/documents/Storyvault DofE – Guidance for Assessors.pdf',
      },
    ],
  },
];

const SectionDocDownloadLinks = (props) => {
  return (
    <Box
      bgColor={props.bgColor ?? 'white'}
      py={{ base: '50px', '2xl': '80px' }}
      pos="relative"
      overflow="hidden"
      borderTop="solid 1px"
      borderTopColor="secondary.light"
    >
      <Container maxW={props.fullWidth ? 'full' : '1562px'}>
        <Flex
          flexDirection={{ base: 'column', xl: 'row' }}
          align="center"
          justifyContent="space-between"
        >
          <Text
            align={{ base: 'center', xl: 'left' }}
            as="h2"
            textStyle="sectionHeadline"
            maxW={'16ch'}
            marginBottom={{ base: 10, xl: 0 }}
          >
            Download documents
          </Text>
          <Grid
            as="nav"
            className="footerNav"
            templateColumns={{ base: null, lg: 'repeat(2, 1fr)' }}
            rowGap={{ base: 6, lg: 9 }}
            columnGap={{ base: 6, md: 12, '2xl': '85px' }}
            width={{ base: '100%', md: 'auto' }}
          >
            {downloadLinks.map((guidanceSegment, index) => {
              return (
                <GridItem key={index} whiteSpace={'nowrap'}>
                  {guidanceSegment.segment.map((link, index) => {
                    return (
                      <Link key={index} href={link.url} isExternal>
                        <Flex justifyContent="flex-start" alignItems="center">
                          <Text
                            fontSize={{ base: '18px', md: '20px' }}
                            lineHeight="150%"
                            decoration="underline"
                          >
                            {link.wording}
                          </Text>
                          <Box color="accent.default">
                            <IconDownload
                              fontSize="24px"
                              ml={{ base: '8px', lg: '18px' }}
                            />
                          </Box>
                        </Flex>
                      </Link>
                    );
                  })}
                </GridItem>
              );
            })}
          </Grid>
        </Flex>
      </Container>
    </Box>
  );
};

export default SectionDocDownloadLinks;
