import { useParams } from 'react-router-dom';
import { useContext } from 'react';

import { FormControl, HStack, Link, Tag, TagLabel, useToast } from '@chakra-ui/react';
import { TextareaAutosize } from '../common/TextareaAutosize';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  COMMENT_EDIT,
  COMMENT_NEW,
  COMMENT_REPLY,
  COMMENT_REPLY_EDIT,
} from '../../util/consts';

import { HttpContext } from '../../context/HttpContext';
import CommentsContext from '../../context/CommentsContext';
import { AuthContext } from '../../context/AuthContext';
import { normalizedComment, normalizedReply } from '../../hooks/useVideoComments';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomToast from '../UI/CustomToast';

const LeaveCommentForm = ({
  comment,
  commentFormType,
  handleShowFormForReply,
  handleShowFormForEdit,
  commentForReply,
}) => {
  const toast = useToast();
  const { slug: videoSlug } = useParams();
  const { authAxios } = useContext(HttpContext);
  const { getAuthUser } = useContext(AuthContext);
  const loggedUserId = parseInt(getAuthUser()?.id, 10);

  const { addComment, addReply, updateComment, updateReply } =
    useContext(CommentsContext);
  const isEdit = [COMMENT_EDIT, COMMENT_REPLY_EDIT].includes(commentFormType);
  const isReply = commentFormType === COMMENT_REPLY;

  const schema = yup.object().shape({
    content: yup
      .string()
      .strict(false)
      .trim()
      .required('Please enter a text...')
      .min(1, 'Please enter a text...'),
  });

  const formOptions = {
    resolver: yupResolver(schema),
    mode: 'onChange',
  };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { isSubmitting, isDirty, isValid } = formState;

  const cancelComment = () => {
    isEdit && handleShowFormForEdit();
    isReply && handleShowFormForReply();
    reset({ content: '' });
  };

  const renderBtnWording = () => {
    switch (commentFormType) {
      case COMMENT_EDIT:
      case COMMENT_REPLY_EDIT:
        return 'Save';
      case COMMENT_REPLY:
        return 'Reply';
      default:
        return 'Comment';
    }
  };

  function showError(message) {
    toast({
      position: 'top',
      render: (props) => (
        <CustomToast
          status="error"
          description={message}
          onClose={props.onClose}
          id={props.id}
        />
      ),
    });
  }

  const submitForm = async (values) => {
    // isEdit && console.log(`This is edit of the comment with id: ${comment?.id}`);
    // isReply && console.log(`This is reply to comment with id: ${comment?.id}`);
    // console.log(`Submitted values for: ${commentFormType}`);
    // console.log(values);

    try {
      isReply && handleShowFormForReply();
      isEdit && handleShowFormForEdit();
      if (commentFormType === COMMENT_NEW) {
        const { data: responseData } = await authAxios({
          method: 'POST',
          url: `/comments`,
          data: {
            body: values.content,
            video: `api/videos/${videoSlug}`,
          },
          headers: {
            Accept: 'application/ld+json',
          },
        });
        const normalized = normalizedComment(responseData, loggedUserId);
        addComment(normalized);
      }

      if (commentFormType === COMMENT_EDIT && comment?.entityId && comment?.id) {
        const { data: responseData } = await authAxios({
          method: 'PATCH',
          url: `/comments/${comment.id}`,
          data: {
            body: values.content,
            video: `api/videos/${videoSlug}`,
          },
          headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/merge-patch+json',
          },
        });
        const normalized = normalizedComment(responseData, loggedUserId);
        updateComment(normalized);
        // showNotification('You have successfully edit your comment.');
      }

      if (commentFormType === COMMENT_REPLY && comment?.entityId) {
        const { data: responseData } = await authAxios({
          method: 'POST',
          url: `/replies`,
          data: {
            body: values.content,
            comment: comment.entityId,
          },
          headers: {
            Accept: 'application/ld+json',
          },
        });
        const normalized = normalizedReply(responseData, loggedUserId);
        addReply(normalized, comment.id);
      }

      if (commentFormType === COMMENT_REPLY_EDIT && comment?.id) {
        const { data: responseData } = await authAxios({
          method: 'PATCH',
          url: `/replies/${comment.id}`,
          data: {
            body: values.content,
          },
          headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/merge-patch+json',
          },
        });
        const normalized = normalizedReply(responseData, loggedUserId);
        updateReply(normalized, commentForReply.id);
      }

      reset();
    } catch (onErr) {
      console.log(onErr);
      showError('Something went wrong.');
    }
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} noValidate>
      <FormControl>
        <TextareaAutosize
          defaultValue={isEdit ? comment.content : ''}
          id="comment"
          type="text"
          placeholder={isReply ? 'Type your reply here...' : 'Type your comment here...'}
          sizesmall="true"
          {...register('content')}
        />
      </FormControl>

      {isValid && (
        <HStack flexShrink={0} spacing="25px" justifyContent="flex-end" mt="15px">
          <Link
            variant="secondaryDark"
            fontSize="14px"
            textStyle="bodyMedium"
            onClick={cancelComment}
          >
            Cancel
          </Link>
          <Tag
            variant="solidDark"
            as="button"
            type="submit"
            opacity={isSubmitting && '0.5'}
            pointerEvents={isSubmitting && 'none'}
            disabled={isSubmitting || !isDirty || !isValid}
          >
            <TagLabel>{renderBtnWording()}</TagLabel>
          </Tag>
        </HStack>
      )}
    </form>
  );
};

export default LeaveCommentForm;
