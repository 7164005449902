import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Box,
  VStack,
  SimpleGrid,
  GridItem,
  useMediaQuery,
  Text,
  Container,
} from '@chakra-ui/react';

const WithConfirmationModal = ({ onClose, isOpen, data }) => {
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  function consoleLogConfirmDelete() {
    console.log('consoleLogConfirmDelete');
    onClose();
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg" color="primary.default">
            Modal with confirmation
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container p={0} maxW="600px">
            <VStack
              mt={4}
              mb={{ base: 4, md: 9 }}
              textAlign="center"
              fontSize={{ md: '20px' }}
            >
              <Text>
                Deleting{' '}
                <Text as="span" textStyle="bodySemiBold">
                  {data?.title}
                </Text>{' '}
                video story and its associated data is permanent and can’t be undone.
              </Text>
              <Text color="error.default" textStyle="bodySemiBold">
                Are you sure you want to delete this video story?
              </Text>
            </VStack>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Box maxW="600px" w="full">
            <SimpleGrid
              w="full"
              maxW="600px"
              columns={2}
              columnGap={{ md: '30px' }}
              rowGap={7}
            >
              <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                <Button onClick={onClose} isFullWidth variant="outlineError">
                  Cancel
                </Button>
              </GridItem>
              <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                <Button
                  onClick={consoleLogConfirmDelete}
                  variant="solidError"
                  isFullWidth
                >
                  Delete
                </Button>
              </GridItem>
            </SimpleGrid>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WithConfirmationModal;
