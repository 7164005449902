import { Container, Text, Box, Grid, GridItem, Flex, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import PhotoSrc from '../../assets/img/photos/photo-33b.webp';
import PhotoCompressedSrc from '../../assets/img/photos/photo-33b.png';
import PictureOptimizedSingle from '../UI/PictureOptimizedSingle';
import { IconLogo } from '../../theme/icons/IconLogo';

const SectionCollageReversed2 = () => {
  return (
    <Container bg="secondary.lighter" px={0} py={{ base: '60px', '2xl': '88px' }}>
      <Container maxW="1562px">
        <Grid
          templateColumns={{ md: 'repeat(2, 1fr)' }}
          columnGap={{ md: '32px', lg: '100px' }}
          rowGap={10}
          alignItems="center"
        >
          <GridItem order={{ md: 2 }}>
            <Flex pos="relative" justifyContent={{ base: 'center', md: 'left' }}>
              <PictureOptimizedSingle
                src={PhotoSrc}
                fallbackSrc={PhotoCompressedSrc}
                borderRadius="10px"
                width="765px"
                position="relative"
                zIndex="1"
              />
            </Flex>
          </GridItem>
          <GridItem order={{ md: 1 }}>
            <Box maxW="640px" pl={{ '2xl': '67px' }}>
              <Box display="inline-block">
                <Text textStyle="sectionEyebrow">Explore videos</Text>
                <Text as="h2" textStyle="sectionHeadline" mt={6}>
                  Numerous ways to discover stories you never knew
                </Text>
                <Box w="120px" h="3px" bg="accent.default" mt="30px"></Box>
              </Box>
              <Text textStyle="sectionText" mt="40px">
                Browse thousands of stories from around the world and discover the videos
                based on categories you have chosen: stunning heroic deeds, incredible
                near-death experiences, amazing survival tales… All videos at your
                fingertips - easily searchable by category tags.
              </Text>
              <Box w={{ base: '100%', sm: 'auto' }}>
                <Button
                  as={RouterLink}
                  to="/all-categories"
                  w={{ base: '100%', md: 'auto' }}
                  mt="40px"
                >
                  <IconLogo boxSize="20px" mr="10px" />
                  Browse videos
                </Button>
              </Box>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Container>
  );
};

export default SectionCollageReversed2;
