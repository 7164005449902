import { Container, Box, Text, VStack } from '@chakra-ui/react';
import { renderStaticSubtitle } from '../util/static';

const PrivacyPage = () => {
  return (
    <Container
      maxW="1332px"
      mt={{ base: '120px', md: '150px' }}
      mb={{ base: '50px', md: '100px' }}
    >
      <Box
        pt={{ base: '40px', lg: '60px' }}
        pb={{ base: '60px', lg: '100px' }}
        bg="white"
        borderRadius="20px"
      >
        <Container maxW="1032px">
          <Text
            as="h1"
            textStyle="headingSemiBold"
            fontSize={{ base: '32px', lg: '40px' }}
            lineHeight="150%"
            mb="40px"
          >
            Storyvault Privacy Policy
          </Text>

          <VStack spacing={10} alignItems="flex-start">
            <Box>
              {renderStaticSubtitle('Thank you for visiting Storyvault!')}
              <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                We appreciate your visit to our website and thank you for your interest in
                our company, our products, and our web pages. Protecting your privacy when
                using our web pages is important to us.
                <br />
                <br />
                We wrote this policy to help you understand what information we collect,
                how we use it and what choices you have about it. Therefore, please take
                note of the following information:
              </Text>
            </Box>

            <Box>
              {renderStaticSubtitle('Who processes information')}
              <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                Storyvault Ltd. processes all the necessary information of the persons who
                have given consent for their data to be processed, with a clearly stated
                purpose in a manner allowed by the law, so that the person whose
                information is being processed cannot be defined or definable after the
                purpose of processing has been met, proportionately with the purpose of
                processing.
                <br />
                <br />
                Storyvault Ltd. gathers personal information from its customers, i.e.
                information about the company in cases of legal persons. The purpose, i.e.
                the obligation to collect information is defined by the The Data
                Protection Act 1998 (c 29). The purpose of collecting information is also
                to prevent the misuse in advertising as well as internal processing of
                information with the purpose of compiling demographic reports.
                <br />
                <br />
                People who use the information are persons who are employed or hired by
                Storyvault Ltd., persons with whom a member establishes communication (to
                an extent necessary for proper communication), and all the state
                institutions who have the right to use the information, whether legally or
                by arbitration.
              </Text>
            </Box>

            <Box>
              {/*<Text*/}
              {/*  textStyle="headingSemiBold"*/}
              {/*  fontSize="18px"*/}
              {/*  lineHeight="150%"*/}
              {/*  mb="20px"*/}
              {/*>*/}
              {/*  We collect information in a few different ways:*/}
              {/*</Text>*/}
              {renderStaticSubtitle(
                'We collect information when you give it to us or give us permission to obtain it'
              )}
              <Text fontSize="18px" lineHeight="150%" color="primary.dark">
                Storyvault Ltd. processes all the necessary information of the persons who
                have given consent for their data to be processed, with a clearly stated
                purpose in a manner allowed by the law, so that the person whose
                information is being processed cannot be defined or definable after the
                purpose of processing has been met, proportionately with the purpose of
                processing.
              </Text>
            </Box>

            {/*<Box>*/}
            {/*  {renderStaticSubtitle(*/}
            {/*    'We also get technical information when you visit Storyvault'*/}
            {/*  )}*/}
            {/*  <Text fontSize="18px" lineHeight="150%" color="primary.dark" mb={5}>*/}
            {/*    Whenever you use any website, mobile application or other internet*/}
            {/*    services, certain information gets created and logged automatically. The*/}
            {/*    same is true when you visit Storyvault website. Here are some of the*/}
            {/*    types of information we collect:*/}
            {/*  </Text>*/}
            {/*  <List spacing={0} mb={5}>*/}
            {/*    <ListItem>*/}
            {/*      Lorem ipsum dolor sit amet, consectetur adipisicing elit Lorem ipsum*/}
            {/*      dolor sit amet, consectetur adipisicing elit Lorem ipsum dolor sit amet,*/}
            {/*      consectetur adipisicing elit Lorem ipsum dolor sit amet, consectetur*/}
            {/*      adipisicing elit Lorem ipsum dolor sit amet{' '}*/}
            {/*      <Link*/}
            {/*        as={RouterLink}*/}
            {/*        to="/contact-us"*/}
            {/*        variant="secondaryDark"*/}
            {/*        textStyle="bodySemiBold"*/}
            {/*      >*/}
            {/*        Contact us*/}
            {/*      </Link>*/}
            {/*      , consectetur adipisicing elit*/}
            {/*    </ListItem>*/}
            {/*    <ListItem>*/}
            {/*      Lorem ipsum dolor sit amet, consectetur adipisicing elit*/}
            {/*    </ListItem>*/}
            {/*  </List>*/}
            {/*  <List spacing={5}>*/}
            {/*    <ListItem>*/}
            {/*      <Text as="span" color="primary.default" textStyle="bodySemiBold">*/}
            {/*        Lorem ipsum*/}
            {/*      </Text>{' '}*/}
            {/*      um dolor sit amet, consectetur adipisicing elit elit*/}
            {/*    </ListItem>*/}
            {/*    <ListItem>*/}
            {/*      <Text as="span" color="primary.default" textStyle="bodySemiBold">*/}
            {/*        Lorem ipsum*/}
            {/*      </Text>{' '}*/}
            {/*      dolor sit amet, consectetur adipisicing elit*/}
            {/*    </ListItem>*/}
            {/*  </List>*/}
            {/*</Box>*/}
          </VStack>
        </Container>
      </Box>
    </Container>
  );
};

export default PrivacyPage;
