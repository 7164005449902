import { useRadio, Box, Flex, Text } from '@chakra-ui/react';
import { IconGlobe } from '../../../theme/icons/IconGlobe';
import { IconLock } from '../../../theme/icons/IconLock';
import { IconUsers } from '../../../theme/icons/IconUsers';
import { PRIVACY_OPTIONS } from '../../../util/consts';
import EmailAddressesControl from '../EmailAddressesControl';

const RadioCardPrivacy = (props) => {
  const { getInputProps, getCheckboxProps, state } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const cardTitle =
    props.children === PRIVACY_OPTIONS[0]
      ? 'Public'
      : props.children === PRIVACY_OPTIONS[1]
      ? 'Only me'
      : 'Specific people';
  const cardDescription =
    props.children === PRIVACY_OPTIONS[0]
      ? 'Your video will be visible to everyone, login/registration is not required to watch the video.'
      : props.children === PRIVACY_OPTIONS[1]
      ? 'Your video will be visible only to you.'
      : props.children === PRIVACY_OPTIONS[2] && state.isChecked
      ? 'Your video will be visible only to users that log in with email address that you have added to the field below.'
      : 'Your video will be visible only to users that log in with email address that you have added. Choose this option to start adding the email addresses.';
  const cardIcon =
    props.children === PRIVACY_OPTIONS[0] ? (
      <IconGlobe boxSize="18px" />
    ) : props.children === PRIVACY_OPTIONS[1] ? (
      <IconLock boxSize="18px" />
    ) : (
      <IconUsers boxSize="18px" />
    );

  return (
    <Box
      borderWidth="1px"
      borderRadius="10px"
      transition="0.3s"
      bg={state.isChecked ? 'secondary.lighter' : 'transparent'}
      color={state.isChecked ? 'secondary.dark' : 'inherit'}
      borderColor={state.isChecked ? 'secondary.light' : 'transparent'}
      _hover={{
        color: 'secondary.dark',
      }}
    >
      <Box as="label">
        <input {...input} />
        <Box
          {...checkbox}
          w="full"
          px={4}
          py="13px"
          cursor="pointer"
          // borderWidth="1px"
          // borderColor="transparent"
          // borderRadius="10px"
          // transition="0.3s"
          // bg={state.isChecked ? 'secondary.lighter' : 'transparent'}
          // _checked={{
          //   color: 'secondary.dark',
          //   borderColor: 'secondary.light',
          // }}
          // _hover={{
          //   color: 'secondary.dark',
          // }}
        >
          <Flex>
            <Box
              flexShrink={0}
              transition="0.3s"
              color={!state.isChecked ? 'primary.light' : 'secondary.dark'}
            >
              {cardIcon}
            </Box>
            <Box ml={4}>
              <Text textStyle="formLabels">
                {/* {props.children} */}
                {cardTitle}
              </Text>
              <Text fontSize="14px" color="primary.dark" mt="8px">
                {cardDescription}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>

      {props.children === PRIVACY_OPTIONS[2] && state.isChecked && (
        <EmailAddressesControl />
      )}
    </Box>
  );
};

export default RadioCardPrivacy;
