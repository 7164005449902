import {
  Text,
  SimpleGrid,
  GridItem,
  Box,
  Container,
  AspectRatio,
} from '@chakra-ui/react';

function Colors() {
  const colors = [
    { codeName: 'primary.default', name: 'primary.default', code: '#2D2D2D' },
    { codeName: 'primary.dark', name: 'primary.dark', code: '#62606E' },
    { codeName: 'primary.light', name: 'primary.light', code: '#CCCCCC' },
    { codeName: 'primary.active', name: 'primary.active', code: '#000000' },
    {
      codeName: 'secondary.lighter',
      name: 'secondary.lighter',
      code: '#F7F6FC',
      color: 'primary.default',
      // border: true,
    },
    {
      codeName: 'secondary.light',
      name: 'secondary.light',
      code: '#E3E0FB',
      color: 'primary.default',
    },
    {
      codeName: 'secondary.alt',
      name: 'secondary.alt',
      code: '#D8CDEE',
      color: 'primary.default',
    },
    {
      codeName: 'secondary.mid',
      name: 'secondary.mid',
      code: '#C8C1FF',
      color: 'primary.default',
    },
    { codeName: 'secondary.dark', name: 'secondary.dark', code: '#5649B7' },
    { codeName: 'accent.default', name: 'accent.default', code: '#E81E42' },
    { codeName: 'accent.light', name: 'accent.light', code: '#FAB9BD' },
    { codeName: 'accent.active', name: 'accent.active', code: '#C81A39' },
    { codeName: 'error.default', name: 'error.default', code: '#CA3B1C' },
    { codeName: 'elipse', name: 'elipse', code: '#FFE0DC', color: 'primary.default' },
  ];

  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 2, md: 4, lg: 6 }} columnGap={6} rowGap={6}>
          {colors.map((color, index) => {
            return (
              <GridItem
                key={index}
                bg={color.codeName}
                color={color.color ? color.color : 'white'}
                border={color.border ? 'solid 1px #DDD' : ''}
              >
                <AspectRatio ratio={1}>
                  <Box
                    p={3}
                    style={{
                      alignItems: 'flex-start',
                      justifyContent: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <Text w="full" fontSize="16px">
                      {color.name}
                    </Text>
                    <Text as="div" fontSize="14px">
                      {color.code}
                    </Text>
                  </Box>
                </AspectRatio>
              </GridItem>
            );
          })}
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default Colors;
