import {
  Container,
  Text,
  Box,
  Image,
  Grid,
  GridItem,
  Flex,
  Show,
  Circle,
} from '@chakra-ui/react';
import PhotoSrc from '../../assets/img/photos/photo-32.webp';
import PhotoCompressedSrc from '../../assets/img/photos/photo-32-compressed.jpg';
import DotsSrc from '../../assets/vectors/purpleLightDottedTriangle.svg';
import PictureOptimizedSingle from '../UI/PictureOptimizedSingle';

const SectionCollageReversed6 = () => {
  return (
    <Container bg="secondary.lighter" px={0} py={{ base: '60px', '2xl': '88px' }}>
      <Container maxW="1562px">
        <Grid
          templateColumns={{ md: 'repeat(2, 1fr)' }}
          columnGap={{ md: '32px', lg: '100px' }}
          rowGap={10}
          alignItems="center"
        >
          <GridItem order={{ md: 2 }}>
            <Flex pos="relative" justifyContent={{ base: 'center', md: 'left' }}>
              <Show above="2xl">
                <Image src={DotsSrc} pos="absolute" top="-60px" right="0px" />
                <Circle
                  pos="absolute"
                  bottom="-120px"
                  left="-120px"
                  bg="#E7E6EB"
                  size="400px"
                ></Circle>
              </Show>
              <PictureOptimizedSingle
                src={PhotoSrc}
                fallbackSrc={PhotoCompressedSrc}
                borderRadius="10px"
                width="648px"
                position="relative"
                zIndex="1"
              />
            </Flex>
          </GridItem>
          <GridItem order={{ md: 1 }}>
            <Box maxW="600px" pl={{ '2xl': '67px' }}>
              <Box display="inline-block" textAlign="left">
                <Text as="h2" textStyle="sectionHeadline">
                  Learning tool for history students
                </Text>
                <Box w="120px" h="3px" bg="accent.default" mt="30px"></Box>
              </Box>
              <Text textStyle="sectionText" mt="40px">
                Help us to create an easily searchable archive of living memories,
                bringing our past to life for use by historians, students and anyone
                interested in how we came to be who we are today. Stories from the sinking
                of the Titanic to the fall of Berlin Wall – or just what we used to do on
                our holidays!
              </Text>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Container>
  );
};

export default SectionCollageReversed6;
