import { Icon } from '@chakra-ui/react';

export const IconRequestAccess = (props) => (
  <Icon viewBox="0 0 37 40" {...props}>
    <path
      fill="currentColor"
      d="M31.45 40H5.55C2.405 40 0 37.6364 0 34.5455V21.8182C0 18.7273 2.405 16.3637 5.55 16.3637H31.45C34.595 16.3637 37 18.7273 37 21.8182V34.5455C37 37.6364 34.595 40 31.45 40ZM5.55 20C4.44 20 3.7 20.7273 3.7 21.8182V34.5455C3.7 35.6364 4.44 36.3636 5.55 36.3636H31.45C32.56 36.3636 33.3 35.6364 33.3 34.5455V21.8182C33.3 20.7273 32.56 20 31.45 20H5.55Z"
    />
    <path
      fill="currentColor"
      d="M9.24995 20C8.13995 20 7.39995 19.2727 7.39995 18.1818V10.9091C7.39995 7.99999 8.50995 5.27272 10.7299 3.27272C12.7649 1.27273 15.5399 0 18.4999 0C23.6799 0 28.3049 3.63636 29.4149 8.72727C29.5999 9.63636 29.0449 10.7273 27.9349 10.9091C27.0099 11.0909 25.9 10.5454 25.7149 9.45454C24.9749 5.99999 22.0149 3.63636 18.4999 3.63636C16.4649 3.63636 14.6149 4.36363 13.3199 5.81818C11.8399 7.27272 11.0999 9.0909 11.0999 10.9091V18.1818C11.0999 19.2727 10.3599 20 9.24995 20Z"
    />
  </Icon>
);
