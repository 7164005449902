import { Container, Grid, GridItem, VStack, Text } from '@chakra-ui/react';
import PhotoSrc from '../../assets/img/photos/photo-3.webp';
import PhotoCompressedSrc from '../../assets/img/photos/photo-3-compressed.jpg';
// import OverlayPhoto from '../UI/ornaments/OverlayPhoto';
import PictureOptimized from '../UI/PictureOptimized';

const HeroFAQ = () => {
  return (
    <Container
      mt={{ base: '80px', md: '100px' }}
      p={0}
      overflowX="hidden"
      bg="secondary.lighter"
    >
      <Container maxW="1562px">
        <Grid templateColumns={{ md: 'repeat(2, 1fr)' }} columnGap={0}>
          <GridItem>
            <VStack
              spacing="25px"
              alignItems={{ base: 'center', sm: 'flex-start' }}
              pt={{ base: '60px', sm: '100px', '2xl': '216px' }}
              pb={{ base: '60px', sm: '140px', '2xl': '216px' }}
              textAlign={{ base: 'center', sm: 'left' }}
            >
              <Text as="h1" textStyle="heroHeadline">Frequently Asked Questions</Text>
              <Text as="h2" textStyle="heroText" maxW={{ md: '538px' }}>
                Find answers to the most frequently asked questions about Storyvault
              </Text>
            </VStack>
          </GridItem>
          <GridItem mr="calc((200% - 100vw)/2)" pos="relative">
            <PictureOptimized
              src={PhotoSrc}
              fallbackSrc={PhotoCompressedSrc}
              objectPosition="left center"
            />
            {/* <OverlayPhoto /> */}
          </GridItem>
        </Grid>
      </Container>
    </Container>
  );
};

export default HeroFAQ;
