import { useContext, useEffect } from 'react';
import VideoIsUploading from './VideoIsUploading';
import VideoIsProcessing from './VideoIsProcessing';
import { HttpContext } from '../../context/HttpContext';
import { VideoFormContext } from '../../context/VideoFormContext';
import * as tus from 'tus-js-client';
import { getExtensionFromFile } from '../../util';

const VideoUploadPreviews = ({title}) => {
  const { authAxios } = useContext(HttpContext);
  const {
    // posterSrc,
    state,
    getUploadPercentage,
    setUploadPercentage,
    isNeedUpload,
    finishedUploadingVideo,
  } = useContext(VideoFormContext);
  const { video } = state;
  const { file } = video;
  const fileSize = file?.size ?? null;
  const filename = file?.name ?? null;
  const filetype = getExtensionFromFile(file);


  const isNeed = isNeedUpload();

  console.log({file, isNeed, fileSize, filename, filetype});

  useEffect(() => {
    const getData = async () => {
      if (!isNeed || !fileSize || !filename || !filetype) return;
      // if (state.video === undefined || state.video === null) return;
      try {
        const { data: responseData } = await authAxios({
          method: 'post',
          url: `vimeo_uploads`,
          data: { fileSize, filename },
        });
        const { id, thumb, transcodingStatus, vimeoUploadLink, vimeoVideoUri } =
          responseData;

        // Create a new tus upload
        const upload = new tus.Upload(file, {
          endPoint: 'https://api.vimeo.com/me/videos',
          uploadUrl: vimeoUploadLink,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename,
            filetype,
          },
          headers: {},
          onError: function (error) {
            console.log('Failed because: ' + error);
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            console.log(bytesUploaded, bytesTotal, percentage + '%');
            setUploadPercentage(percentage);
          },
          onSuccess: function (res) {
            console.log(upload);
            console.log(res);
            console.log('Download %s from %s', upload.file.name, upload.url);
            // setVideoStatus('uploaded')
            finishedUploadingVideo({
              vimeo: upload,
              id,
              thumb,
              transcodingStatus,
              vimeoUploadLink,
              vimeoVideoUri,
            });
          },
          onAfterResponse: function (req, res) {
            console.log(req);
            console.log(res);
            // const url = req.getURL()
            // const value = res.getHeader("X-My-Header")
            // console.log(`Request for ${url} responded with ${value}`)
          },
        });

        // Start the upload
        upload.start();
      } catch (onError) {
        console.log(onError);
      }
    };

    getData();
  }, [authAxios, isNeed]);

  const uploadPercentage = getUploadPercentage();
  return (
    <>
      {uploadPercentage < 100 ? (
        <VideoIsUploading percentage={uploadPercentage} />
      ) : uploadPercentage === 100 ? (
        <VideoIsProcessing componentSize="large" borderRadius="10px" title={title}/>
      ) : null}
    </>
  );
};

export default VideoUploadPreviews;
