import { Icon } from '@chakra-ui/react';

export const IconLinkedIn = (props) => (
  <Icon viewBox="0 0 31 30" {...props}>
    <path
      fill="currentColor"
      d="M2.11168 0.374035C1.17226 0.825858 0.749523 1.27768 0.326785 2.24587C-0.268179 3.5852 -0.0333245 5.03749 0.96872 6.19932C2.59704 8.10342 5.63449 7.73228 6.76179 5.48931C7.57595 3.89179 7.26281 2.06837 5.96329 0.938813C4.83599 -0.0777869 3.44252 -0.271425 2.11168 0.374035Z"
    />
    <path
      fill="currentColor"
      d="M0 19.6726V30H3.6011H7.20219V19.6726V9.34527H3.6011H0V19.6726Z"
    />
    <path
      fill="currentColor"
      d="M10.9587 19.6726V30H14.5442H18.1453L18.1922 23.8197C18.2392 17.9783 18.2549 17.6233 18.568 16.9294C19.3978 15.0737 21.6211 14.3637 22.7328 15.6062C23.5782 16.526 23.5626 16.3969 23.6096 23.4163L23.6565 30H27.3359H30.9996V24.3684C30.9996 18.301 30.8587 16.0742 30.3577 14.3799C29.7001 12.1692 28.3379 10.491 26.6313 9.79709C25.9268 9.50663 25.4571 9.44209 24.1889 9.45823C21.2923 9.47436 19.5074 10.3135 18.3645 12.1853C18.2079 12.4435 18.1766 12.2014 18.1766 10.9105L18.1609 9.34527H14.5598H10.9587V19.6726Z"
    />
  </Icon>
);
