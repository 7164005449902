import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';

import LayoutAuth from './layout/LayoutAuth';
import LayoutPlain from './layout/LayoutPlain';
import LayoutPlainDark from './layout/LayoutPlainDark';
import LayoutMain from './layout/LayoutMain';
import StyleguidePage from './pages/StyleguidePage';

import SignupPage from './pages/SignupPage';
import SignupConfirmationPage from './pages/SignupConfirmationPage';
import SignupCheckEmailPage from './pages/SignupCheckEmailPage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import PasswordCheckEmailPage from './pages/PasswordCheckEmailPage';
import HomePage from './pages/HomePage';
import PickCategoriesPage from './pages/PickCategoriesPage';
import { useContext } from 'react';
import { AuthContext } from './context/AuthContext';
import LogoutPage from './pages/LogoutPage';
import RedirectPage from './pages/RedirectPage';
import { CategoriesProvider } from './context/CategoriesContext';
import UseCategories from './hooks/useCategories';
import ChannelPage from './pages/ChannelPage';
import AllCategoriesPage from './pages/AllCategoriesPage';
import SettingsProfilePage from './pages/settings/SettingsProfilePage';
import SettingsPasswordPage from './pages/settings/SettingsPasswordPage';
import SettingsInterestsPage from './pages/settings/SettingsInterestsPage';
import SearchPage from './pages/SearchPage';
import SingleVideoPage from './pages/videos/SingleVideoPage';
import CreateVideoPage from './pages/videos/CreateVideoPage';
import RequestAccessPage from './pages/RequestAccessPage';
import { SingleVideoProvider } from './context/SingleVideoContext';
import UseSingleVideo from './hooks/useSingleVideo';
import EditVideoPage from './pages/videos/EditVideoPage';
import UseEditVideo from './hooks/useEditVideo';
import { VideoFormProvider } from './context/VideoFormContext';
import { TagsProvider } from './context/TagsContext';
import UseTags from './hooks/useTags';
import { UserProvider } from './context/UserContext';
import UseUser from './hooks/useUser';
import ComingSoonPage from './pages/ComingSoonPage';
import PublicHomePage from './pages/PublicHomePage';
import SettingsDeleteAccount from './pages/settings/SettingsDeleteAccount';
import ContactUsPage from './pages/ContactUsPage';
import FAQPage from './pages/FAQPage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import WhoAreWePage from './pages/WhoAreWePage';
import { FeaturedVideosProvider } from './context/FeaturedVideosContext';
import UseFeaturedVideos from './hooks/useFeaturedVideos';
import AboutPage from './pages/AboutPage';
import ResetUserPasswordPage from './pages/ResetUserPasswordPage';

import GuidanceYoungPeoplePage from './pages/GuidanceYoungPeoplePage';
import GuidanceTeachersPage from './pages/GuidanceTeachersPage';
import GuidanceOnlineSafetyPage from './pages/GuidanceOnlineSafetyPage';
import GuidanceApproachingCareHomesPage from './pages/GuidanceApproachingCareHomesPage';

import DukeOfEdinburgAwardPage from './pages/DukeOfEdinburgAwardPage';

import { TaglinesProvider } from './context/TaglinesContext';
import UseTaglines from './hooks/useTaglines';
import GoogleTagManagerTracker from './pages/analytics/GoogleTagManagerTracker';
// import HowItWorksPage from './pages/HowItWorksPage';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <GoogleTagManagerTracker />
      <Routes>
        {/* Access anyone */}
        <Route
          path="/confirm/:confirmationtoken"
          element={
            <LayoutPlain>
              <SignupConfirmationPage />
            </LayoutPlain>
          }
        />
        <Route
          path="/videos/:slug"
          element={
            <LayoutMain bgColor="white">
              <SingleVideoProvider>
                <UseSingleVideo>
                  <SingleVideoPage />
                </UseSingleVideo>
              </SingleVideoProvider>
            </LayoutMain>
          }
        />
        <Route
          path="/request-confirm"
          element={
            <LayoutPlain>
              <RequestAccessPage />
            </LayoutPlain>
          }
        />
        <Route
          path="/request-decline"
          element={
            <LayoutPlain>
              <RequestAccessPage />
            </LayoutPlain>
          }
        />
        <Route
          path="/contact-us"
          element={
            <LayoutMain bgColor="white">
              <ContactUsPage />
            </LayoutMain>
          }
        />
        <Route
          path="/faq"
          element={
            <LayoutMain bgColor="white">
              <FAQPage />
            </LayoutMain>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <LayoutMain>
              <TermsPage />
            </LayoutMain>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <LayoutMain>
              <PrivacyPage />
            </LayoutMain>
          }
        />
        <Route
          path="/who-are-we"
          element={
            <LayoutMain>
              <WhoAreWePage />
            </LayoutMain>
          }
        />
        <Route
          path="/about"
          element={
            <LayoutMain bgColor="white">
              <AboutPage />
            </LayoutMain>
          }
        />
        {/* guidance pages */}
        <Route
          path="/guidance-for-young-people"
          element={
            <LayoutMain>
              <GuidanceYoungPeoplePage />
            </LayoutMain>
          }
        />
        <Route
          path="/guidance-for-teachers"
          element={
            <LayoutMain>
              <GuidanceTeachersPage />
            </LayoutMain>
          }
        />
        <Route
          path="/on-line-safety-policy"
          element={
            <LayoutMain>
              <GuidanceOnlineSafetyPage />
            </LayoutMain>
          }
        />
        <Route
          path="/approaching-care-homes"
          element={
            <LayoutMain>
              <GuidanceApproachingCareHomesPage />
            </LayoutMain>
          }
        />
        {/* END - guidance pages */}
        <Route
          path="/dofe"
          element={
            <LayoutMain>
              <DukeOfEdinburgAwardPage />
            </LayoutMain>
          }
        />
        <Route
          path="/all-categories"
          element={
            <LayoutMain bgColor="white">
              <AllCategoriesPage />
            </LayoutMain>
          }
        />
        <Route
          path="/search"
          element={
            <LayoutMain bgColor="white">
              <SearchPage />
            </LayoutMain>
          }
        />
        <Route
          path="/channel/:slug"
          element={
            <LayoutMain bgColor="white">
              <UserProvider>
                <UseUser>
                  <ChannelPage />
                </UseUser>
              </UserProvider>
            </LayoutMain>
          }
        />
        {/* <Route
          path="/how-it-works"
          element={
            <LayoutMain bgColor="white">
              <HowItWorksPage />
            </LayoutMain>
          }
        /> */}
        {/* Access only public users (logged out users) */}
        <Route element={<AuthRoutes />}>
          <Route
            path="/"
            element={
              <LayoutMain bgColor="white">
                <FeaturedVideosProvider>
                  <UseFeaturedVideos>
                    <PublicHomePage />
                  </UseFeaturedVideos>
                </FeaturedVideosProvider>
              </LayoutMain>
            }
          />
          <Route
            path="/signup"
            element={
              <LayoutAuth>
                <SignupPage />
              </LayoutAuth>
            }
          />
          <Route
            path="/account-activation"
            element={
              <LayoutPlain>
                <SignupCheckEmailPage />
              </LayoutPlain>
            }
          />
          <Route
            path="/login"
            element={
              <LayoutAuth>
                <LoginPage />
              </LayoutAuth>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <LayoutPlain>
                <ForgotPasswordPage />
              </LayoutPlain>
            }
          />
          <Route
            path="/check-email"
            element={
              <LayoutPlain>
                <PasswordCheckEmailPage />
              </LayoutPlain>
            }
          />
          <Route
            path="/reset-password/:uuid"
            element={
              <LayoutPlain>
                <ResetPasswordPage />
              </LayoutPlain>
            }
          />
          <Route
            path="/reset-user-password/:uuid"
            element={
              <LayoutPlain>
                <ResetUserPasswordPage />
              </LayoutPlain>
            }
          />
          <Route
            path="/coming-soon"
            element={
              <LayoutPlainDark>
                <ComingSoonPage />
              </LayoutPlainDark>
            }
          />
          <Route
            path="/"
            element={
              <LayoutMain bgColor="white">
                <PublicHomePage />
              </LayoutMain>
            }
          />
        </Route>

        {/* PRIVATE ROUTES */}
        <Route element={<PrivateRoutes />}>
          <Route
            path="/home"
            element={
              <LayoutMain bgColor="white">
                <TaglinesProvider>
                  <UseTaglines>
                    <HomePage />
                  </UseTaglines>
                </TaglinesProvider>
              </LayoutMain>
            }
          />
          <Route path="/styleguide" element={<StyleguidePage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route
            path="/pick-categories"
            element={
              <LayoutPlain>
                <CategoriesProvider>
                  <UseCategories>
                    <PickCategoriesPage />
                  </UseCategories>
                </CategoriesProvider>
              </LayoutPlain>
            }
          />
          <Route
            path="/add-video"
            element={
              <LayoutMain>
                <CategoriesProvider>
                  <UseCategories>
                    <CreateVideoPage />
                  </UseCategories>
                </CategoriesProvider>
              </LayoutMain>
            }
          />
          <Route
            path="/all-categories"
            element={
              <LayoutMain bgColor="white">
                <AllCategoriesPage />
              </LayoutMain>
            }
          />
          <Route
            path="/settings/profile"
            element={
              <LayoutMain>
                <SettingsProfilePage />
              </LayoutMain>
            }
          />
          <Route
            path="/settings/password"
            element={
              <LayoutMain>
                <SettingsPasswordPage />
              </LayoutMain>
            }
          />
          <Route
            path="/settings/interests"
            element={
              <LayoutMain>
                <SettingsInterestsPage />
              </LayoutMain>
            }
          />
          <Route
            path="/settings/delete-account"
            element={
              <LayoutMain>
                <SettingsDeleteAccount />
              </LayoutMain>
            }
          />
          {/*<Route*/}
          {/*  path="/search"*/}
          {/*  element={*/}
          {/*    <LayoutMain bgColor="white">*/}
          {/*      <SearchPage />*/}
          {/*    </LayoutMain>*/}
          {/*  }*/}
          {/*/>*/}
          <Route
            path="/videos/edit/:slug"
            element={
              <LayoutMain bgColor="white">
                <VideoFormProvider>
                  <CategoriesProvider>
                    <TagsProvider>
                      <UseCategories>
                        <UseTags>
                          <UseEditVideo>
                            <EditVideoPage />
                          </UseEditVideo>
                        </UseTags>
                      </UseCategories>
                    </TagsProvider>
                  </CategoriesProvider>
                </VideoFormProvider>
              </LayoutMain>
            }
          />
          {/*<Route*/}
          {/*  path="/videos/:id"*/}
          {/*  element={*/}
          {/*    <LayoutMain bgColor="white">*/}
          {/*      <SingleVideoProvider>*/}
          {/*        <UseSingleVideo>*/}
          {/*          <SingleVideoPage />*/}
          {/*        </UseSingleVideo>*/}
          {/*      </SingleVideoProvider>*/}
          {/*    </LayoutMain>*/}
          {/*  }*/}
          {/*/>*/}
          <Route path="*" element={<RedirectPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

function AuthRoutes() {
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated() === undefined) {
    return null; // or loading spinner, etc...
  }

  return isAuthenticated() ? <Navigate to={'/home'} replace /> : <Outlet />;
}

function PrivateRoutes() {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  return isAuthenticated() ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default AppRoutes;
