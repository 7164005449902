import { Icon } from '@chakra-ui/react';

export const CategoryFoodIcon = (props) => (
  <Icon viewBox="0 0 59 51" fill="none" {...props}>
    <path
      d="M10.8351 42.9844C23.3054 42.9844 35.7019 42.9844 48.1722 42.9844C48.1722 43.6016 48.1722 44.1947 48.1722 44.7998C48.1722 46.0222 48.1845 47.2567 48.1722 48.4791C48.1599 49.5563 47.4828 50.2462 46.3872 50.2946C46.2764 50.2946 46.1533 50.2946 46.0425 50.2946C35.0248 50.2946 23.9948 50.2946 12.9771 50.2946C12.3247 50.2946 11.6845 50.222 11.266 49.6652C11.0567 49.3868 10.8721 49.0238 10.8598 48.697C10.8105 46.821 10.8351 44.933 10.8351 42.9844Z"
      fill="#BC8C79"
    />
    <path
      d="M0 25.8545C0 25.0194 0 24.1964 0 23.3613C0.0369308 23.2403 0.0861719 23.1193 0.110792 22.9861C0.824789 17.0194 5.99511 12.2266 12.0887 11.9119C12.5442 11.8877 12.7411 11.7425 12.9135 11.331C15.6464 4.66224 22.1708 0.317275 29.4708 0.293069C36.7585 0.268863 43.3445 4.63803 46.0897 11.331C46.262 11.7425 46.459 11.8877 46.9145 11.9119C53.3897 12.2387 58.757 17.6366 58.9909 24.0028C59.2248 30.3568 54.9408 35.7548 48.6133 37.0619C48.244 37.1345 48.1578 37.2797 48.1578 37.6186C48.1701 39.3372 48.1701 41.0559 48.1701 42.7745C48.1701 42.9802 48.1701 43.1739 48.1701 43.3796C35.6875 43.3796 23.2787 43.3796 10.833 43.3796C10.833 41.4432 10.8207 39.543 10.8454 37.6307C10.8454 37.2555 10.7346 37.1345 10.3529 37.0498C5.23187 35.8637 1.9204 32.7895 0.43086 27.8273C0.246206 27.1859 0.135413 26.5202 0 25.8545Z"
      fill="#DFB2A0"
    />
  </Icon>
);
