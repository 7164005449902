import { Icon } from '@chakra-ui/react';

export const IconLock = (props) => (
  <Icon viewBox="0 0 38 41" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.20156 20.1738C5.12879 20.1738 4.25914 21.0435 4.25914 22.1163V34.9147C4.25914 35.9875 5.12879 36.8571 6.20156 36.8571H31.7985C32.8712 36.8571 33.7409 35.9875 33.7409 34.9147V22.1163C33.7409 21.0435 32.8712 20.1738 31.7985 20.1738H6.20156ZM0.830566 22.1163C0.830566 19.1499 3.23524 16.7453 6.20156 16.7453H31.7985C34.7648 16.7453 37.1695 19.1499 37.1695 22.1163V34.9147C37.1695 37.881 34.7648 40.2857 31.7985 40.2857H6.20156C3.23524 40.2857 0.830566 37.881 0.830566 34.9147V22.1163Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0044 3.71428C17.0345 3.71428 15.1453 4.49681 13.7524 5.88973C12.3595 7.28266 11.5769 9.17186 11.5769 11.1418V18.4552C11.5769 19.4019 10.8094 20.1694 9.86266 20.1694C8.91589 20.1694 8.14838 19.4019 8.14838 18.4552V11.1418C8.14838 8.26255 9.29214 5.50127 11.328 3.46537C13.3639 1.42946 16.1252 0.285706 19.0044 0.285706C21.8836 0.285706 24.6449 1.42946 26.6808 3.46537C28.7167 5.50127 29.8605 8.26255 29.8605 11.1418V18.4552C29.8605 19.4019 29.093 20.1694 28.1462 20.1694C27.1994 20.1694 26.4319 19.4019 26.4319 18.4552V11.1418C26.4319 9.17186 25.6494 7.28266 24.2564 5.88973C22.8635 4.49681 20.9743 3.71428 19.0044 3.71428Z"
    />
  </Icon>
);
