import { Text, VStack } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

const SettingsSidebar = () => {
  return (
    <VStack
      minW="216px"
      spacing={10}
      alignItems="flex-start"
      className="sideNav"
      pos="sticky"
      top="140px"
      alignSelf="flex-start"
    >
      <NavLink
        to="/settings/profile"
        className={({ isActive }) => (isActive ? 'active' : 'inactive')}
      >
        <Text textStyle="formLabels">Edit Profile</Text>
      </NavLink>
      <NavLink
        to="/settings/password"
        className={({ isActive }) => (isActive ? 'active' : 'inactive')}
      >
        <Text textStyle="formLabels">Change Password</Text>
      </NavLink>
      <NavLink
        to="/settings/interests"
        className={({ isActive }) => (isActive ? 'active' : 'inactive')}
      >
        <Text textStyle="formLabels">Edit Interests</Text>
      </NavLink>
      <NavLink
        to="/settings/delete-account"
        className={({ isActive }) => (isActive ? 'active' : 'inactive')}
      >
        <Text textStyle="formLabels">Delete Account</Text>
      </NavLink>
    </VStack>
  );
};

export default SettingsSidebar;
