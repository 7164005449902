import { MenuItem, GridItem } from '@chakra-ui/react';
import { useContext } from 'react';
import { CategoriesContext } from '../context/CategoriesContext';
import { Link as RouterLink } from 'react-router-dom';

const DropdownCategoriesList = () => {
  const { state } = useContext(CategoriesContext);
  const { items } = state;
  return items.map((item, index) => {
    if (item.name === 'Other') {
      return null;
    }
    return (
      <GridItem key={index}>
        <MenuItem
          as={RouterLink}
          to={item?.id ? `/search?category=${item.id}` : `/search`}
          fontSize="14px"
          textStyle="bodyRegular"
          p={0}
          _hover={{ bg: 'transparent', textDecoration: 'underline' }}
          reloadDocument={true}
        >
          {item.name}
        </MenuItem>
      </GridItem>
    );
  });
};

export default DropdownCategoriesList;
