import React, { createContext, useState } from 'react';

export const initialRecommendedVideosState = {
  items: [],
  total: 0,
  status: 'idle',
  error: null,
};

const RecommendedVideosContext = createContext({
  state: initialRecommendedVideosState,
  updateState: () => {},
});
const { Provider } = RecommendedVideosContext;

const RecommendedVideosProvider = ({ children }) => {
  const [state, setState] = useState(initialRecommendedVideosState);

  function updateStateHandler({ items, total, status, error }) {
    setState({
      items,
      total,
      status,
      error,
    });
  }

  return (
    <Provider
      value={{
        state,
        updateState: (stateInfo) => updateStateHandler(stateInfo),
      }}
    >
      {children}
    </Provider>
  );
};

export { RecommendedVideosContext, RecommendedVideosProvider };
