import { Img, Center, Box, Container } from '@chakra-ui/react';
import { useEffect } from 'react';
import Flickity from 'react-flickity-component';
import './flickity.css';
import debounce from 'lodash/debounce';
import { getImageUrlFromVideo } from '../../util';

const FlickityPhotoGallery = ({ photosForFullscreen, photosForNav, initialIndex }) => {
  const flickityOptions = {
    initialIndex: initialIndex,
    pageDots: false,
    contain: true,
    arrowShape:
      'M24.9845 28.66C25.2788 28.9543 25.6816 29.1092 26.0689 29.1092L26.0534 29.0937C26.4562 29.0937 26.8435 28.9388 27.1533 28.629L39.5468 16.0651C40.1355 15.4764 40.1355 14.5004 39.5468 13.8962L27.1533 1.33231C26.5647 0.743615 25.5577 0.743615 24.969 1.33231C24.3803 1.921 24.3648 2.91248 24.969 3.51666L34.7653 13.4469H1.56078C0.708731 13.4469 0.0115967 14.1441 0.0115967 14.9961C0.0115967 15.8482 0.708731 16.5453 1.56078 16.5453H34.7674L24.9845 26.4756C24.3648 27.0798 24.3803 28.0558 24.9845 28.66Z',
    lazyLoad: 1,
  };

  const flickityNavOptions = {
    initialIndex: initialIndex,
    pageDots: false,
    contain: true,
    prevNextButtons: false,
  };

  let flktyMain = null;
  let flktyNav = null;
  let flktyNavCellElements = [];

  const removeInitialStyleForSlide = () => {
    flktyNavCellElements.map((el) => {
      el.classList.remove('initial-style');
      return null;
    });
  };

  const addCustomClassToSlide = (activeIndex) => {
    flktyNavCellElements.map((el, index) => {
      el.classList.remove('active-index');
      if (index === activeIndex) {
        el.classList.add('active-index');
      }
      return null;
    });
  };

  useEffect(() => {
    flktyMain?.on('select', () => {
      flktyNav?.select(flktyMain.selectedIndex);
      addCustomClassToSlide(flktyMain.selectedIndex);
    });

    flktyNav?.on('ready', () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      flktyNavCellElements = flktyNav.getCellElements();
      removeInitialStyleForSlide();
    });
    flktyNav?.on('staticClick', (event, pointer, cellElement, cellIndex) => {
      flktyNav.select(cellIndex);
      flktyMain?.select(cellIndex);
      addCustomClassToSlide(cellIndex);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flktyMain, flktyNav]);

  useEffect(() => {
    function resizeFlickities() {
      flktyNav?.resize();
      flktyMain?.resize();
    }
    window.addEventListener('resize', debounce(resizeFlickities, 1000));
    return () => {
      window.removeEventListener('resize', debounce(resizeFlickities, 1000));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Flickity
        flickityRef={(c) => (flktyMain = c)}
        className={'flickity-gallery'} // default ''
        elementType={'div'} // default 'div'
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        {photosForFullscreen.map((photo, index) => {
          return (
            <Center key={index} w="full" h="full" className="flickity-gallery__cell">
              <Img
                // src={`https://placeimg.com${photo}`}
                data-flickity-lazyload={getImageUrlFromVideo(photo, '1920_1080')}
                alt=""
                maxH="full"
                p={5}
              />
            </Center>
          );
        })}
      </Flickity>

      <Container px={{ base: '16px', md: '20px' }}>
        <Flickity
          flickityRef={(c) => (flktyNav = c)}
          className={'flickity-nav'} // default ''
          elementType={'div'} // default 'div'
          options={flickityNavOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
          static // default false
        >
          {photosForNav.map((photo, index) => {
            return (
              <Box
                key={index}
                mr={5}
                className={[
                  'flickity-nav__cell initial-style',
                  index === parseInt(initialIndex) ? 'active-index' : '',
                ].join(' ')}
              >
                <Img
                  src={getImageUrlFromVideo(photo, 'width_440')}
                  alt=""
                  h="100px"
                  maxW="none"
                  cursor="pointer"
                />
              </Box>
            );
          })}
        </Flickity>
      </Container>
    </>
  );
};

export default FlickityPhotoGallery;
