import { Icon } from '@chakra-ui/react';

export const IconSearch = (props) => (
  <Icon viewBox="0 0 25 27" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7921 3.87532C6.63984 3.87532 3.27376 7.2414 3.27376 11.3937C3.27376 15.5459 6.63984 18.912 10.7921 18.912C14.9444 18.912 18.3105 15.5459 18.3105 11.3937C18.3105 7.2414 14.9444 3.87532 10.7921 3.87532ZM0.382812 11.3937C0.382812 5.64478 5.04321 0.984375 10.7921 0.984375C16.541 0.984375 21.2014 5.64478 21.2014 11.3937C21.2014 17.1426 16.541 21.803 10.7921 21.803C5.04321 21.803 0.382812 17.1426 0.382812 11.3937Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3286 17.3819C15.8931 16.8174 16.8084 16.8174 17.3728 17.3819L23.6607 23.6697C24.2251 24.2342 24.2251 25.1494 23.6607 25.7139C23.0962 26.2784 22.1809 26.2784 21.6164 25.7139L15.3286 19.4261C14.7642 18.8616 14.7642 17.9464 15.3286 17.3819Z"
    />
  </Icon>
);
