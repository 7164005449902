const PictureOptimized = (props) => {
  return (
    <picture
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        inset: '0',
      }}
    >
      <source srcSet={props?.src} type="image/webp" media="screen" />
      <img
        style={{
          objectFit: 'cover',
          objectPosition: props?.objectPosition ?? 'center center',
          height: '100%',
          width: '100%',
        }}
        loading="lazy"
        src={props?.fallbackSrc}
        alt=""
      />
    </picture>
  );
};

export default PictureOptimized;
