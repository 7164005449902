import { Container, Box, Flex, Image, Link, Wrap, WrapItem } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import LogoSrc from '../assets/img/logo.svg';

const footerLinks = [
  {
    title: 'Who are we',
    segment: [{ wording: 'Who Are We', url: '/who-are-we' }],
  },
  {
    title: 'Contact',
    segment: [{ wording: 'Contact us', url: '/contact-us' }],
  },
  {
    title: 'Help',
    segment: [{ wording: 'FAQ', url: '/faq' }],
  },
  {
    title: 'Privacy',
    segment: [{ wording: 'Privacy', url: '/privacy-policy' }],
  },
  {
    title: 'Terms',
    segment: [{ wording: 'Terms and Conditions', url: '/terms-and-conditions' }],
  },
];

const Footer = (props) => {
  return (
    <Box
      as="footer"
      bgColor={props.bgColor ?? 'white'}
      pt={{ base: '0px', lg: '50px' }}
      pb="50px"
      pos="relative"
      borderTop="solid 1px"
      borderTopColor="primary.light"
      overflow="hidden"
    >
      <Container maxW={props.fullWidth ? 'full' : '1562px'}>
        <Flex
          flexDirection={{ base: 'column-reverse', '2xl': 'row' }}
          align="center"
          justifyContent="space-between"
          py={{ base: '0px', '2xl': '50px' }}
        >
          <Link
            as={RouterLink}
            to="/"
            pointerEvents="auto"
            flexShrink={0}
            mt={{ base: '50px', '2xl': '0' }}
          >
            {/* © {new Date().getFullYear()},&nbsp;Storyvault™ */}
            <Image src={LogoSrc} h={{ base: '50px', lg: '70px' }} />
          </Link>

          <Wrap
            spacing={{ base: '0px', lg: '80px' }}
            pt={{ base: '50px', lg: '0' }}
            justify="center"
            className="footerNav"
          >
            {footerLinks.map((footerSegment, index) => {
              return (
                <WrapItem
                  key={index}
                  w={{ base: '100%', md: '50%', lg: 'auto' }}
                  pt={{ base: '16px', lg: '0' }}
                  textAlign={{ base: 'center', lg: 'left' }}
                  justifyContent="center"
                >
                  {/*<Text*/}
                  {/*  as="h3"*/}
                  {/*  textStyle="headingSemiBold"*/}
                  {/*  fontSize={{ base: '20px', md: '25px' }}*/}
                  {/*  lineHeight="150%"*/}
                  {/*  color="primary.default"*/}
                  {/*>*/}
                  {/*  {footerSegment.title}*/}
                  {/*</Text>*/}
                  {footerSegment.segment.map((link, index) => {
                    return (
                      <NavLink
                        key={index}
                        to={link.url}
                        className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                        onClick={
                          link.url === '/blog'
                            ? () => {
                                window.location.href = '/blog';
                              }
                            : () => {}
                        }
                      >
                        <Box fontSize={{ base: '18px', md: '20px' }} lineHeight="150%">
                          {link.wording}
                        </Box>
                      </NavLink>
                    );
                  })}
                </WrapItem>
              );
            })}
          </Wrap>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
