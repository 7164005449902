import { CategoriesProvider } from '../context/CategoriesContext';
import UseCategories from '../hooks/useCategories';
import AllCategoriesContent from '../components/categories/AllCategoriesContent';

const AllCategoriesPage = () => {
  return (
    <CategoriesProvider>
      <UseCategories>
        <AllCategoriesContent />
      </UseCategories>
    </CategoriesProvider>
  );
};

export default AllCategoriesPage;
