import { Flex, Box, Tag, TagLabel, Text } from '@chakra-ui/react';
import { IconDeleteThin } from '../../theme/icons/IconDeleteThin';

const ClearSearchControls = ({
  term,
  clearTerm,
  tag,
  clearTag,
  category,
  clearCategory,
  clearAll,
}) => {
  return (
    <Flex flexWrap="wrap" mb="20px">
      {term || tag?.id > 0 || category?.id > 0 ? (
        <Box mr={5} mb={4}>
          <Tag variant="solidDark" onClick={clearAll}>
            <TagLabel>Clear all</TagLabel>
          </Tag>
        </Box>
      ) : (
        <Flex mb={4} h="30px" alignItems="center">
          <Text
            color="primary.light"
            fontSize="16px"
            userSelect="none"
            textStyle="bodyMedium"
          >
            No searches entered
          </Text>
        </Flex>
      )}
      {term && (
        <Tag variant="solidLight" mr={5} mb={4}>
          <TagLabel cursor="default">
            {term}
            <IconDeleteThin
              boxSize="12px"
              ml="10px"
              onClick={clearTerm}
              cursor="pointer"
              transition="0.3s"
              _hover={{ color: 'accent.default' }}
            />
          </TagLabel>
        </Tag>
      )}
      {tag?.id > 0 && (
        <Tag variant="solidLight" mr={5} mb={4}>
          <TagLabel cursor="default">
            #{tag.label}
            <IconDeleteThin
              boxSize="12px"
              ml="10px"
              onClick={clearTag}
              cursor="pointer"
              transition="0.3s"
              _hover={{ color: 'accent.default' }}
            />
          </TagLabel>
        </Tag>
      )}
      {category?.id > 0 && (
        <Tag variant="solidLight" mr={5} mb={4}>
          <TagLabel cursor="default">
            {category?.name}
            <IconDeleteThin
              boxSize="12px"
              ml="10px"
              onClick={clearCategory}
              cursor="pointer"
              transition="0.3s"
              _hover={{ color: 'accent.default' }}
            />
          </TagLabel>
        </Tag>
      )}
    </Flex>
  );
};

export default ClearSearchControls;
