import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import FlickityPhotoGallery from './FlickityPhotoGallery';
import { useContext, useEffect } from 'react';
import FlickityControlsContext from '../../context/FlickityControlsContext';

const FlickityPhotoGalleryDrawer = ({ photosForFullscreen, photosForNav }) => {
  const flickityControlsCtx = useContext(FlickityControlsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openHandler = () => {
    flickityControlsCtx.initialIndexForGallery > -1 && onOpen();
  };

  const onCloseHandler = () => {
    flickityControlsCtx.updateInitialIndexForGallery(-1);
    onClose();
  };

  useEffect(() => {
    openHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flickityControlsCtx.initialIndexForGallery]);

  return (
    <Drawer isOpen={isOpen} onClose={onCloseHandler} size="full">
      <DrawerOverlay backdropFilter="blur(8px)" />
      <DrawerContent bg="transparent">
        <DrawerCloseButton zIndex="1" color="white" />
        <DrawerBody p={0} display="flex" alignItems="flex-end" pb="20px !important">
          <FlickityPhotoGallery
            photosForFullscreen={photosForFullscreen}
            photosForNav={photosForNav}
            initialIndex={flickityControlsCtx.initialIndexForGallery}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default FlickityPhotoGalleryDrawer;
