import { Icon } from '@chakra-ui/react';

export const NameIcon = (props) => (
  <Icon viewBox="0 0 23 23" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9102 2C6.93959 2 2.91016 6.02944 2.91016 11C2.91016 15.9706 6.93959 20 11.9102 20C16.8807 20 20.9102 15.9706 20.9102 11C20.9102 6.02944 16.8807 2 11.9102 2ZM0.910156 11C0.910156 4.92487 5.83502 0 11.9102 0C17.9853 0 22.9102 4.92487 22.9102 11C22.9102 17.0751 17.9853 22 11.9102 22C5.83502 22 0.910156 17.0751 0.910156 11Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.70707 12.3959C8.3749 11.9577 7.75065 11.8697 7.31022 12.2001C6.86839 12.5314 6.77884 13.1582 7.11022 13.6001L7.91022 13.0001C7.11022 13.6001 7.11046 13.6004 7.1107 13.6007L7.11122 13.6014L7.11238 13.6029L7.11512 13.6066L7.12233 13.616L7.14363 13.6432C7.16087 13.665 7.18422 13.6939 7.21358 13.7289C7.27222 13.7987 7.35521 13.8934 7.46166 14.0045C7.67374 14.2258 7.98365 14.5172 8.38454 14.8088C9.18248 15.3891 10.3847 16.0001 11.9102 16.0001C13.4357 16.0001 14.6379 15.3891 15.4359 14.8088C15.8368 14.5172 16.1467 14.2258 16.3588 14.0045C16.4652 13.8934 16.5482 13.7987 16.6069 13.7289C16.6362 13.6939 16.6596 13.665 16.6768 13.6432L16.6981 13.616L16.7053 13.6066L16.7081 13.6029L16.7092 13.6014L16.7097 13.6007C16.71 13.6004 16.7102 13.6001 15.9102 13.0001L16.7102 13.6001C17.0416 13.1582 16.952 12.5314 16.5102 12.2001C16.0698 11.8697 15.4455 11.9577 15.1134 12.3959L15.1087 12.4018C15.1028 12.4093 15.0914 12.4234 15.0749 12.4431C15.0418 12.4826 14.988 12.5442 14.9148 12.6207C14.7675 12.7744 14.5461 12.9829 14.2595 13.1913C13.6825 13.611 12.8847 14.0001 11.9102 14.0001C10.9357 14.0001 10.1379 13.611 9.56089 13.1913C9.27428 12.9829 9.05294 12.7744 8.90564 12.6207C8.8324 12.5442 8.77868 12.4826 8.74553 12.4431C8.72898 12.4234 8.71767 12.4093 8.71172 12.4018L8.70707 12.3959Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.91016 8C7.91016 7.44772 8.35787 7 8.91016 7H8.92016C9.47244 7 9.92016 7.44772 9.92016 8C9.92016 8.55228 9.47244 9 8.92016 9H8.91016C8.35787 9 7.91016 8.55228 7.91016 8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9102 8C13.9102 7.44772 14.3579 7 14.9102 7H14.9202C15.4724 7 15.9202 7.44772 15.9202 8C15.9202 8.55228 15.4724 9 14.9202 9H14.9102C14.3579 9 13.9102 8.55228 13.9102 8Z"
      fill="currentColor"
    />
  </Icon>
);
