import { Heading, Text } from '@chakra-ui/react';
import ChangePasswordForm from '../../components/settings/ChangePasswordForm';
import SettingsLayout from '../../components/settings/SettingsLayout';

const SettingsPasswordPage = () => {
  return (
    <SettingsLayout>
      <Heading size="lg" color="primary.default">
        Change Password
      </Heading>
      <Text textStyle="bodyMedium" mt="15px" mb={10}>
        To change your password you must provide your current password and then enter a
        new password below. It's a good idea to choose a strong password that you don't
        use on other accounts.
      </Text>

      <ChangePasswordForm />
    </SettingsLayout>
  );
};

export default SettingsPasswordPage;
