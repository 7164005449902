import { Global } from '@emotion/react';
import poppinsRegularUrl from '../assets/fonts/Poppins-Regular.ttf';
import poppinsMediumUrl from '../assets/fonts/Poppins-Medium.ttf';
import poppinsSemiBoldUrl from '../assets/fonts/Poppins-SemiBold.ttf';
import poppinsBoldUrl from '../assets/fonts/Poppins-Bold.ttf';
const Fonts = () => (
  <Global
    styles={`
        /* latin */
        @font-face {
          font-family: 'Poppins Bold';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
                  src: local(''), url(${poppinsBoldUrl}) format('truetype');
        }
        /* latin */
        @font-face {
          font-family: 'Poppins Medium';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
                  src: local(''), url(${poppinsMediumUrl}) format('truetype');
        }
        /* latin */
        @font-face {
          font-family: 'Poppins SemiBold';
          font-style: normal;
          font-weight: 600;
          font-display: swap;
                  src: local(''), url(${poppinsSemiBoldUrl}) format('truetype');
        }
        /* latin */
        @font-face {
          font-family: 'Poppins Regular';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
                  src: local(''), url(${poppinsRegularUrl}) format('truetype');
        }
        `}
  />
);

export default Fonts;
