import { IconButton, Box, Text } from '@chakra-ui/react';
import { IconLinkedIn } from '../../theme/icons/IconLinkedIn';
import { IconFacebook } from '../../theme/icons/IconFacebook';
import { IconTwitter } from '../../theme/icons/IconTwitter';
import { SOCIAL_MEDIA_BRAND_COLORS } from '../../util/consts';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';

export const GENERAL_VIDEO_STORY_DESCRIPTION = `Remarkable stories about events from eyewitnesses testimonies.`;

const SocialMediaShareBox = ({ media, video }) => {
  const title = `${video.title} | Storyvault`;
  const description = video?.description
    ? video.description
    : GENERAL_VIDEO_STORY_DESCRIPTION;
  const urlToShare = window.location.href;

  const handleClickOnSocialMedia = (e) => {
    console.log(media);
    console.log(urlToShare);
    e.preventDefault();
  };

  const renderIcon = () => {
    const iconComponents = {
      LinkedIn: IconLinkedIn,
      Facebook: IconFacebook,
      Twitter: IconTwitter,
    };
    const IconName = iconComponents[media];
    return <IconName boxSize="30px" color="white" />;
  };

  if (media === 'Facebook') {
    return (
      <FacebookShareButton
        url={urlToShare}
        description={description}
        // hashtag={"#hashtag"}
      >
        <Box
          onClick={handleClickOnSocialMedia}
          role="group"
          cursor="pointer"
          textAlign="center"
        >
          <IconButton
            icon={renderIcon()}
            bg={`${SOCIAL_MEDIA_BRAND_COLORS[media]} !important`}
            boxSize="60px"
            aria-label={`${media}-share-icon-${urlToShare}`}
            as="div"
          />
          <Text
            fontSize="14px"
            lineHeight="150%"
            mt="10px"
            _groupHover={{ textDecoration: 'underline' }}
          >
            {media}
          </Text>
        </Box>
      </FacebookShareButton>
    );
  }

  if (media === 'Twitter') {
    return (
      <TwitterShareButton
        url={urlToShare}
        title={title}
        description={description}
        // hashtags={["hashtag1", "hashtag2"]}
      >
        <Box
          onClick={handleClickOnSocialMedia}
          role="group"
          cursor="pointer"
          textAlign="center"
        >
          <IconButton
            icon={renderIcon()}
            bg={`${SOCIAL_MEDIA_BRAND_COLORS[media]} !important`}
            boxSize="60px"
            aria-label={`${media}-share-icon-${urlToShare}`}
            as="div"
          />
          <Text
            fontSize="14px"
            lineHeight="150%"
            mt="10px"
            _groupHover={{ textDecoration: 'underline' }}
          >
            {media}
          </Text>
        </Box>
      </TwitterShareButton>
    );
  }

  if (media === 'LinkedIn') {
    return (
      <LinkedinShareButton
        url={urlToShare}
        title={title}
        description={description}
        // hashtags={["hashtag1", "hashtag2"]}
      >
        <Box
          onClick={handleClickOnSocialMedia}
          role="group"
          cursor="pointer"
          textAlign="center"
        >
          <IconButton
            icon={renderIcon()}
            bg={`${SOCIAL_MEDIA_BRAND_COLORS[media]} !important`}
            boxSize="60px"
            aria-label={`${media}-share-icon-${urlToShare}`}
            as="div"
          />
          <Text
            fontSize="14px"
            lineHeight="150%"
            mt="10px"
            _groupHover={{ textDecoration: 'underline' }}
          >
            {media}
          </Text>
        </Box>
      </LinkedinShareButton>
    );
  }

  return null;

  // return (
  //   <Box
  //     onClick={handleClickOnSocialMedia}
  //     role="group"
  //     cursor="pointer"
  //     textAlign="center"
  //   >
  //     <IconButton
  //       icon={renderIcon()}
  //       bg={`${SOCIAL_MEDIA_BRAND_COLORS[media]} !important`}
  //       boxSize="60px"
  //     />
  //     <Text
  //       fontSize="14px"
  //       lineHeight="150%"
  //       mt="10px"
  //       _groupHover={{ textDecoration: 'underline' }}
  //     >
  //       {media}
  //     </Text>
  //   </Box>
  // );
};

export default SocialMediaShareBox;
