import { useContext, useEffect } from 'react';
import { HttpContext } from '../../context/HttpContext';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

function UpdateSingleVideoViewer() {
  const { slug } = useParams();

  const { authAxios, publicAxios } = useContext(HttpContext);
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    async function updateView() {
      try {
        if (isAuthenticated()) {
          await authAxios.patch(
            `/videos/${slug}/view`,
            { slug },
            {
              headers: { 'Content-Type': 'application/merge-patch+json' },
            }
          );
        } else {
          await publicAxios.patch(
            `videos/${slug}/view`,
            { slug },
            {
              headers: { 'Content-Type': 'application/merge-patch+json' },
            }
          );
        }
      } catch (onErr) {
        console.log(onErr);
      }
    }

    updateView();
  }, [authAxios, slug]);

  return null;
}

export default UpdateSingleVideoViewer;
