import React, { createContext, useState } from 'react';

export const initialTagsState = {
  items: [],
  total: 0,
  status: 'idle',
  error: null,
  query: '',
};

const TagsContext = createContext({
  state: initialTagsState,
  updateState: () => {},
  updateQuery: () => {},
  getTag: () => {},
});
const { Provider } = TagsContext;

const TagsProvider = ({ children }) => {
  const [state, setState] = useState({
    items: [],
    total: 0,
    status: 'idle',
    error: null,
    query: '',
  });

  function updateStateHandler({ items, total, status, error }) {
    setState({
      ...state,
      items,
      total,
      status,
      error,
    });
  }

  function updateQueryHandler(query) {
    setState({
      ...state,
      query,
    });
  }

  function getTagHandler(id) {
    const { items } = state;
    if (items.length > 0) {
      const intId = parseInt(id, 10);
      for (let i = 0; i < items.length; i++) {
        if (parseInt(items[i].id, 10) === intId) {
          return items[i];
        }
      }
    }

    return null;
  }

  return (
    <Provider
      value={{
        state,
        updateState: (stateInfo) => updateStateHandler(stateInfo),
        updateQuery: (query) => updateQueryHandler(query),
        getTag: getTagHandler,
      }}
    >
      {children}
    </Provider>
  );
};

export { TagsContext, TagsProvider };
