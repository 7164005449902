import Intro from '../components/styleguide/Intro';
import SectionTitle from '../components/styleguide/SectionTitle';
import Colors from '../components/styleguide/Colors';
import Typography from '../components/styleguide/Typography';
import MonocolorIcons from '../components/styleguide/MonocolorIcons';
import Buttons from '../components/styleguide/Buttons';
import FormControls from '../components/styleguide/FormControls';
import Previews from '../components/styleguide/Previews';
import FlashMessages from '../components/styleguide/FlashMessages';
import Modals from '../components/styleguide/Modals';
import Other from '../components/styleguide/Other';
import EmailExemplars from '../components/styleguide/EmailExemplars';

const StyleguidePage = () => {
  return (
    <>
      <Intro />

      <SectionTitle title="Colors" id="destination_colors" />
      <Colors />

      <SectionTitle title="Typography" id="destination_typography" />
      <Typography />

      <SectionTitle title="Monocolor icons" id="destination_monocolor" />
      <MonocolorIcons />

      <SectionTitle title="Buttons, Links &amp; Tags" id="destination_buttons" />
      <Buttons />

      <SectionTitle title="Form Controls" id="destination_form" />
      <FormControls />

      <SectionTitle title="Flash Messages" id="destination_flash" />
      <FlashMessages />

      <SectionTitle title="Modals" id="destination_modals" />
      <Modals />

      <SectionTitle title="Other" id="destination_other" />
      <Other />

      <SectionTitle title="Video previews" id="destination_previews" />
      <Previews />

      <SectionTitle title="Email exemplars" id="destination_emails" />
      <EmailExemplars />
    </>
  );
};

export default StyleguidePage;
