import { Icon } from '@chakra-ui/react';

export const IconAvatar = (props) => (
  <Icon viewBox="0 0 36 40" {...props}>
    <path
      fill="currentColor"
      d="M8.35676 23.5462C4.65791 24 1.69129 26.4489 0.574082 29.9932C0.106065 31.4562 -0.0373602 33.1619 0.00793179 36.487C0.0381265 38.7325 0.0683212 38.8968 0.543887 39.4054C0.943967 39.8435 1.31385 40 1.91775 40C2.52164 40 2.89152 39.8435 3.2916 39.4054C3.78982 38.8655 3.79737 38.8577 3.85021 35.3134C3.90305 31.6831 3.91814 31.558 4.54468 30.3061C4.91457 29.5707 5.74492 28.6553 6.4243 28.2406C7.02065 27.8807 7.97933 27.5521 8.74929 27.4504C9.19467 27.3878 12.1688 27.3721 18.4493 27.3878L27.5077 27.4113L28.1116 27.5912C30.0969 28.1702 31.4331 29.5159 32.0068 31.5188C32.1653 32.0665 32.1804 32.3012 32.2181 35.3134C32.271 38.8577 32.2785 38.8655 32.7767 39.4054C33.1768 39.8435 33.5467 40 34.1506 40C34.7696 40 35.1621 39.8279 35.5471 39.3741C36 38.8342 36 38.8499 36 35.5325C36 32.2465 35.9472 31.6127 35.5848 30.3061C35.3206 29.3438 34.679 28.0215 34.0826 27.2078C32.7541 25.3927 30.9726 24.2504 28.5419 23.6792L27.8097 23.5071L18.3739 23.4915C13.1879 23.4836 8.68136 23.5071 8.35676 23.5462Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 16C21.3137 16 24 13.3137 24 10C24 6.68629 21.3137 4 18 4C14.6863 4 12 6.68629 12 10C12 13.3137 14.6863 16 18 16ZM18 20C23.5228 20 28 15.5228 28 10C28 4.47715 23.5228 0 18 0C12.4772 0 8 4.47715 8 10C8 15.5228 12.4772 20 18 20Z"
    />
  </Icon>
);
