import { Icon } from '@chakra-ui/react';

export const IconEnvelope = (props) => (
  <Icon viewBox="0 0 49 40" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.51695 4.04494C5.16202 4.04494 4.04504 5.16192 4.04504 6.51685V33.4831C4.04504 34.8381 5.16202 35.9551 6.51695 35.9551H42.472C43.8269 35.9551 44.9439 34.8381 44.9439 33.4831V6.51685C44.9439 5.16192 43.8269 4.04494 42.472 4.04494H6.51695ZM9.15527e-05 6.51685C9.15527e-05 2.92796 2.92806 0 6.51695 0H42.472C46.0609 0 48.9889 2.92796 48.9889 6.51685V33.4831C48.9889 37.072 46.0609 40 42.472 40H6.51695C2.92806 40 9.15527e-05 37.072 9.15527e-05 33.4831V6.51685Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.365772 5.35705C1.00632 4.44198 2.26739 4.21944 3.18246 4.85999L24.4946 19.7785L45.8067 4.85999C46.7217 4.21944 47.9828 4.44198 48.6233 5.35705C49.2639 6.27212 49.0413 7.53319 48.1263 8.17374L25.6544 23.9041C24.958 24.3915 24.0311 24.3915 23.3347 23.9041L0.862834 8.17374C-0.052232 7.53319 -0.274774 6.27212 0.365772 5.35705Z"
    />
  </Icon>
);
