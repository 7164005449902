import { useLocation as ReactRouterLocation } from 'react-router-dom';
import moment from 'moment';
import { REGEX_EMAIL } from './regExr';
import { PRIVACY_ONLY_ME, PRIVACY_PUBLIC, PRIVACY_SHARED } from './consts';
import defaultPoster from '../assets/img/carouselimage2.png';

export function getUrlSearchParams() {
  return new URLSearchParams(ReactRouterLocation().search);
}

export const WINDOW_LOCATION_ORIGIN = window.location.origin; // 'http://localhost' "https://dev.storyvault.etondigital.com"
// export const WINDOW_LOCATION_ORIGIN = 'https://dev.storyvault.etondigital.com';
export const VIDEOS_IMAGES_PATH = WINDOW_LOCATION_ORIGIN + '/uploads/images/videos/'; // 'http://localhost/uploads' "https://dev.storyvault.etondigital.com/uploads/images/videos/"
export const AUTHORS_IMAGES_PATH = WINDOW_LOCATION_ORIGIN + '/uploads/images/authors/'; // 'http://localhost/uploads' "https://dev.storyvault.etondigital.com/uploads/images/videos/"

export function getFullName(user) {
  if (user?.firstName && user?.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  return null;
}

export function isStringStartWith(text, needle = '') {
  if (typeof text === 'string' && typeof needle === 'string') {
    const str1 = text.toLowerCase().trim();
    const str2 = needle.toLowerCase().trim();
    return str1.startsWith(str2);
  }

  return false;
}

export function getImageUrlFromUser(user) {
  const filename = user?.image?.filename_200_200 ?? user?.image?.filename ?? null;

  return filename;
}

export function getImageUrlFromVideo(image, crop) {
  if (!image) return null;

  switch (crop) {
    case 'width_440':
      return image.filename_width_440;
    case '1920_1080':
      return image.filename_1920_1080;
    default:
      return image.filename;
  }
}

export function getVideoImageUrlForFilename(filename) {
  return VIDEOS_IMAGES_PATH + filename;
}

export function createVimeoThumb(url, cropSize, ext) {
  if (!url) return defaultPoster;

  const thumb = url + (cropSize ?? '') + (ext ?? '');
  return thumb;
}

export function getDefaultDuration(secs) {
  if (!secs) return secs;

  if (secs > 3600) {
    return moment.utc(secs * 1000).format('HH:mm:ss');
  }

  return moment.utc(secs * 1000).format('mm:ss');
}

export function getLowestNumberInArray(arr) {
  const sorted = arr.sort(function (a, b) {
    return a === b ? 0 : a < b ? -1 : 1;
  });
  if (sorted?.length > 0) {
    return sorted[0];
  }

  return null;
}

export function validateEmail(email) {
  return String(email).toLowerCase().trim().match(REGEX_EMAIL);
}

export function getPrivacy(privacy) {
  if ([0, 1, 2].includes(privacy)) {
    if (privacy === 0) {
      return PRIVACY_PUBLIC;
    }
    if (privacy === 1) {
      return PRIVACY_SHARED;
    }
    if (privacy === 2) {
      return PRIVACY_ONLY_ME;
    }
  }

  return null;
}

export function getVimeoIdFromVimeoVideo(vimeoVideo) {
  return vimeoVideo?.substring(vimeoVideo.lastIndexOf('/') + 1);
}

export async function normalizeShareEmails(emails) {
  if (emails?.length > 0) {
    let out = [];

    for (let i = 0; i < emails.length; i++) {
      if (emails[i]) {
        out.push({ email: emails[i].value.toLowerCase().trim() });
      }
    }

    return out;
  }

  return [];
}

export function newLineToBr(text) {
  if (text === null || text === undefined) return true;
  let result = '';
  result = text.split('\n').map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });
  return result;
}

export function isEmptyObject(obj) {
  if (obj === null || obj === undefined) return true;
  return Object.keys(obj).length === 0;
}

export function getExtension(filename) {
  const parts = filename.split('.');
  const count = parts.length;
  if (count > 1) {
    return parts[count - 1];
  }

  return '';
}

export function getExtensionFromFile(file) {
  if (file?.type) {
    console.log({ fileTypeCore: file.type });
    return file.type;
  }

  if (file?.name) {
    const ext = getExtension(file.name);
    switch (ext?.toLowerCase()) {
      case 'wmv':
        return 'video/x-ms-wmv';
      case 'avi':
        return 'video/x-msvideo';
      case 'divx':
        return 'video/divx';
      case 'mkv':
        return 'video/x-matroska';
      case 'flv':
        return 'video/x-flv';
      case 'm3u8':
        return 'video/x-mpegURL';
      case 'm4u':
        return 'video/vnd.mpegurl';
      case 'm4v':
        return 'video/x-m4v';
      case 'mov':
      case 'qt':
        return 'video/quicktime';
      case 'movie':
        return 'video/x-sgi-movie';
      case 'ts':
        return 'video/MP2T';
      case '3gp':
        return 'video/3gpp';
      case 'mpe':
      case 'mpg':
      case 'mpeg':
        return 'video/mpeg';
      case 'ogv':
        return 'video/ogg';
      case 'rm':
        return 'application/vnd.rn-realmedia';
      case 'vob':
        return 'video/x-ms-vob';
      default:
        return '';
    }
  }

  return '';
}

export function isVideo(file) {
  if (file) {
    const extension = getExtensionFromFile(file);
    console.log({ extension });
    if (extension) {
      return (
        extension.includes('video') || extension.includes('application/vnd.rn-realmedia')
      );
    }
  }

  return false;
}

export function isFetching(status) {
  return !['resolved', 'rejected'].includes(status);
}

export function isFetchingDone(status) {
  return ['resolved', 'rejected'].includes(status);
}

const PUBLIC_ROUTES = [
  '/confirm/',
  '/videos/',
  '/request-confirm',
  '/request-decline',
  '/contact-us',
  '/faq',
  '/terms-and-conditions',
  '/privacy-policy',
  '/about',
  '/all-categories',
  '/search',
  '/channel/',
  '/blog',
];

export function isPublicRoute(route) {
  if (typeof route === 'string') {
    const routeToLower = route.toLowerCase().trim();
    let count = PUBLIC_ROUTES.length;
    let i = 0;
    let exist = false;
    while (i < count && !exist) {
      if (routeToLower.indexOf(PUBLIC_ROUTES[i]) !== -1) {
        exist = true;
      } else {
        i++;
      }
    }
    return exist;
  }

  return false;
}

export function isLoggedUserChannel(user, loggedUser) {
  if (!isEmptyObject(loggedUser)) {
    const slug1 = loggedUser?.slug ?? null;
    const slug2 = user?.slug ?? null;
    if (isString(slug1)) {
      if (isString(slug2)) {
        if (slug1.trim() !== '' && slug2.trim() !== '') {
          if (isEqualString(slug1, slug2)) return true;
        }
      }
    }
  }

  return false;
}

export function isString(str) {
  return typeof str === 'string';
}

export function isEqualString(str1, str2) {
  if (isString(str1)) {
    if (isString(str2)) {
      if (str1.trim().toLowerCase() === str2.trim().toLowerCase()) return true;
    }
  }

  return false;
}

function isEmpty(str) {
  return (!str || str.length === 0 );
}
