import { Icon } from '@chakra-ui/react';

export const IconArrowRight = (props) => (
  <Icon viewBox="0 0 40 30" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.9845 28.66C25.2788 28.9543 25.6816 29.1092 26.0689 29.1092L26.0534 29.0937C26.4562 29.0937 26.8435 28.9388 27.1533 28.629L39.5468 16.0651C40.1355 15.4764 40.1355 14.5004 39.5468 13.8962L27.1533 1.33231C26.5647 0.743615 25.5577 0.743615 24.969 1.33231C24.3803 1.921 24.3648 2.91248 24.969 3.51666L34.7653 13.4469H1.56078C0.708731 13.4469 0.0115967 14.1441 0.0115967 14.9961C0.0115967 15.8482 0.708731 16.5453 1.56078 16.5453H34.7674L24.9845 26.4756C24.3648 27.0798 24.3803 28.0558 24.9845 28.66Z"
    />
  </Icon>
);
