import { Container, Box, Text, VStack } from '@chakra-ui/react';
import { TERMS_LIST } from '../util/static';

const TermsPage = () => {
  return (
    <Container
      maxW="1332px"
      mt={{ base: '120px', md: '150px' }}
      mb={{ base: '50px', md: '100px' }}
    >
      <Box
        pt={{ base: '40px', lg: '60px' }}
        pb={{ base: '60px', lg: '100px' }}
        bg="white"
        borderRadius="20px"
      >
        <Container maxW="1032px">
          <Text
            as="h1"
            textStyle="headingSemiBold"
            fontSize={{ base: '32px', lg: '40px' }}
            lineHeight="150%"
            mb={{ base: '40px', lg: '60px' }}
          >
            Storyvault Terms and Conditions
          </Text>
          <VStack spacing="30px" alignItems="flex-start">
            {TERMS_LIST.map((item, index) => {
              return (
                <Text key={index} fontSize="18px" lineHeight="150%" color="primary.dark">
                  <Text
                    as="span"
                    textStyle="bodyMedium"
                    fontSize="30px"
                    lineHeight="27px"
                    color="secondary.dark"
                  >
                    {index + 1}.&nbsp;
                  </Text>
                  {item.text}
                </Text>
              );
            })}
          </VStack>
        </Container>
      </Box>
    </Container>
  );
};

export default TermsPage;
