import { usePagination, useTable } from 'react-table';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import VideosListEmpty from './VideosListEmpty';
import VideosListLoading from './VideosListLoading';
import VideoListCard from './VideoListCard';
import ReactTablePagination from '../common/ReactTablePagination';
import { useContext, useEffect } from 'react';
import MyChannelContext from '../../context/MyChannelContext';
import { PAGE_SIZE_OPTIONS } from '../../util/consts';
import { getLowestNumberInArray, isLoggedUserChannel } from '../../util';
import VideosListEmptyForOthers from './VideosListEmptyForOthers';
import { AuthContext } from '../../context/AuthContext';

const handleItemClick = (row) => {
  // history.push(`/video-story/${id}`);
  // console.log(row.original.exampleForNestedObject.singlePageUrl);
};

// Create a default prop getter (per documentation https://react-table.tanstack.com/docs/examples/data-driven-classes-and-styles)
const defaultPropGetter = () => ({});

export default function CustomTable({
  user,
  columns,
  data,
  total,
  pageCount: controlledPageCount,
  fetchData,
  // getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  isLoading,
}) {
  const {
    clearPageIndex,
    state: { update },
  } = useContext(MyChannelContext);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    // headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 12 },
      // initialState: { pageIndex: 2, hiddenColumns: hiddenTableColumns },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  );

  const { getAuthUser } = useContext(AuthContext);
  const loggedUser = getAuthUser();

  const isMyChannel = isLoggedUserChannel(user, loggedUser);

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData({ pageIndex, pageSize });
    window.scrollTo(0, 0);
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth"
    // });
  }, [fetchData, pageIndex, pageSize, update]);

  // reset the page index to 0 when the table data updates due to something other than internal table state changes
  useEffect(() => {
    gotoPage(0);
  }, [gotoPage, clearPageIndex]);

  // Render the UI for your table
  return (
    <>
      <Box mb="50px">
        {data.length === 0 && !isLoading && isMyChannel && <VideosListEmpty />}
        {data.length === 0 && !isLoading && !isMyChannel && <VideosListEmptyForOthers />}
        {isLoading && <VideosListLoading />}

        <div {...getTableProps()}>
          {data.length > 0 && !isLoading && (
            <Grid
              {...getTableBodyProps()}
              templateColumns={{
                base: '1fr',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(3, 1fr)',
                xl: 'repeat(4, 1fr)',
                // '2xl': 'repeat(4, 1fr)',
              }}
              columnGap={{ base: '16px', lg: '30px' }}
              rowGap={{ base: '32px', lg: '50px' }}
            >
              {page.map((row) => {
                // console.log(row);
                prepareRow(row);
                return (
                  <GridItem {...row.getRowProps()} onClick={() => handleItemClick(row)}>
                    {row.cells.map((cell) => {
                      // console.log(cell);
                      // console.log(cell.row.original.customUrl);
                      return (
                        <div
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                        >
                          <VideoListCard cell={cell.row.original} />
                        </div>
                      );
                    })}
                  </GridItem>
                );
              })}
            </Grid>
          )}
        </div>

        {total > getLowestNumberInArray(PAGE_SIZE_OPTIONS) && (
          <Flex
            mt={{ base: '50px', lg: '80px' }}
            flexDirection={{ base: 'column-reverse', '2xl': 'row' }}
            justifyContent={{ base: 'none', '2xl': 'center' }}
          >
            <ReactTablePagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </Flex>
        )}
      </Box>

      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
            },
            null,
            2
          )}
        </code>
      </pre> */}
    </>
  );
}
