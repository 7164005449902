import { Icon } from '@chakra-ui/react';

export const IconGlobe = (props) => (
  <Icon viewBox="0 0 40 41" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 4.40584C10.8787 4.40584 3.47673 11.8209 3.47673 20.9773C3.47673 30.1337 10.8787 37.5487 20 37.5487C29.1213 37.5487 36.5233 30.1337 36.5233 20.9773C36.5233 11.8209 29.1213 4.40584 20 4.40584ZM0.0481567 20.9773C0.0481567 9.93583 8.97665 0.977264 20 0.977264C31.0234 0.977264 39.9519 9.93583 39.9519 20.9773C39.9519 32.0187 31.0234 40.9773 20 40.9773C8.97665 40.9773 0.0481567 32.0187 0.0481567 20.9773Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0481567 20.9773C0.0481567 20.0305 0.815669 19.263 1.76244 19.263H38.2376C39.1843 19.263 39.9519 20.0305 39.9519 20.9773C39.9519 21.924 39.1843 22.6916 38.2376 22.6916H1.76244C0.815669 22.6916 0.0481567 21.924 0.0481567 20.9773Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.417 20.9773C14.543 26.673 16.5116 32.1544 19.9973 36.6084C23.4831 32.1544 25.4516 26.673 25.5777 20.9773C25.4516 15.2815 23.4831 9.80009 19.9973 5.3461C16.5116 9.80009 14.543 15.2815 14.417 20.9773ZM19.9973 2.69154L18.7301 1.53705C13.8886 6.85136 11.1379 13.7472 10.9884 20.9416C10.9879 20.9654 10.9879 20.9891 10.9884 21.0129C11.1379 28.2073 13.8886 35.1031 18.7301 40.4175C19.0549 40.774 19.515 40.9773 19.9973 40.9773C20.4797 40.9773 20.9398 40.774 21.2646 40.4175C26.106 35.1031 28.8568 28.2073 29.0063 21.0129C29.0068 20.9891 29.0068 20.9654 29.0063 20.9416C28.8568 13.7472 26.106 6.85136 21.2646 1.53705L19.9973 2.69154Z"
    />
  </Icon>
);
