import { Icon } from '@chakra-ui/react';

export const EyeOffIcon = (props) => (
  <Icon viewBox="0 0 23 22" fill="none">
    <g clipPath="url(#clip0_221_2615)">
      <path
        d="M13.8255 13.3026C13.5737 13.5728 13.2701 13.7895 12.9328 13.9398C12.5955 14.0901 12.2313 14.1709 11.8621 14.1774C11.4928 14.1839 11.1261 14.116 10.7836 13.9777C10.4412 13.8394 10.1302 13.6335 9.86902 13.3724C9.60788 13.1112 9.40202 12.8002 9.26371 12.4578C9.1254 12.1153 9.05748 11.7486 9.06399 11.3793C9.07051 11.0101 9.15133 10.6459 9.30163 10.3086C9.45194 9.97126 9.66864 9.66766 9.93883 9.4159M17.3272 16.8042C15.7602 17.9987 13.8522 18.6604 11.8822 18.6926C5.46549 18.6926 1.79883 11.3592 1.79883 11.3592C2.93906 9.23431 4.52054 7.37779 6.43716 5.91424L17.3272 16.8042ZM9.95716 4.2459C10.5881 4.09821 11.2341 4.02438 11.8822 4.0259C18.2988 4.0259 21.9655 11.3592 21.9655 11.3592C21.4091 12.4002 20.7455 13.3802 19.9855 14.2834L9.95716 4.2459Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.79883 1.27588L21.9655 21.4425"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_221_2615">
        <rect
          width="22"
          height="22"
          fill="white"
          transform="translate(0.882812 0.359375)"
        />
      </clipPath>
    </defs>
  </Icon>
);
