import {
  Container,
  Text,
  Box,
  Image,
  Grid,
  GridItem,
  Flex,
  Show,
} from '@chakra-ui/react';
import PhotoSrc from '../../assets/img/photos/photo-37.webp';
import PhotoCompressedSrc from '../../assets/img/photos/photo-37-compressed.jpg';
import DotsSrc from '../../assets/vectors/purpleLightDottedTriangle.svg';
import PictureOptimizedSingle from '../UI/PictureOptimizedSingle';

const SectionCollageReversed6 = () => {
  return (
    <Container bg="white" px={0} py={{ base: '60px', '2xl': '88px' }}>
      <Container maxW="1562px">
        <Grid
          templateColumns={{ md: 'repeat(2, 1fr)' }}
          columnGap={{ md: '32px', lg: '100px' }}
          rowGap={10}
          alignItems="center"
        >
          <GridItem order={{ md: 2 }}>
            <Flex pos="relative" justifyContent={{ base: 'center', md: 'left' }}>
              <Show above="2xl">
                <Image src={DotsSrc} pos="absolute" top="-60px" right="0px" />
              </Show>
              <PictureOptimizedSingle
                src={PhotoSrc}
                fallbackSrc={PhotoCompressedSrc}
                borderRadius="10px"
                width="648px"
                position="relative"
                zIndex="1"
              />
            </Flex>
          </GridItem>
          <GridItem order={{ md: 1 }}>
            <Box maxW="620px" pl={{ '2xl': '67px' }}>
              <Box display="inline-block" textAlign="left">
                <Text as="h2" textStyle="sectionHeadline">
                  It doesn’t have to be an interview
                </Text>
                <Box w="120px" h="3px" bg="accent.default" mt="30px"></Box>
              </Box>
              <Text textStyle="sectionText" mt="40px">
                Maybe you have a story of your own which you’d like to preserve and pass
                on to your family or other storyvault users? Simply record yourself
                speaking to the camera, upload onto Storyvault, and share your experience
                with the rest of us.
              </Text>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Container>
  );
};

export default SectionCollageReversed6;
