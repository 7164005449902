import { AspectRatio, Text, Flex, Image, Circle } from '@chakra-ui/react';
import { IconPlay } from '../../theme/icons/IconPlay';
import { createVimeoThumb } from '../../util';

const VideoIsUploaded = ({ poster, duration }) => {
  return (
    <AspectRatio ratio={16 / 9}>
      <Flex
        flexDir="column"
        borderRadius="10px"
        bg="secondary.lighter"
        willChange="transform"
      >
        <Image
          src={createVimeoThumb(poster, '_720x405')}
          pos="absolute"
          objectFit="cover"
          h="full"
          w="full"
          transform="scale(1)"
          transition="0.4s ease-in-out"
          _groupHover={{ transform: 'scale(1.2)' }}
        />
        <Circle
          pos="relative"
          w="50px"
          h="50px"
          bg="rgba(36, 6, 48, 0.4)"
          color="white"
          top="0px"
          opacity="1"
          transition="0.4s ease-in-out"
          // _groupHover={{ w: '58px', h: '58px' }}
          _groupHover={{ bg: 'accent.default' }}
        >
          <IconPlay
            fontSize="22px"
            pos="relative"
            left="2px"
            transition="0.3s ease-in-out"
            // _groupHover={{ fontSize: '26px' }}
          />
        </Circle>

        <Text
          textStyle="bodySemiBold"
          color="white"
          fontSize="14px"
          lineHeight="18px"
          borderRadius="2px"
          bg="rgba(36, 6, 48, 0.5)"
          p="4px"
          minW="56px"
          textAlign="center"
          pos="absolute"
          bottom="10px"
          right="10px"
          opacity="1"
          transition="0.3s ease-in-out"
          // _groupHover={{ bottom: '20px', opacity: '0' }}
        >
          {duration}
        </Text>
      </Flex>
    </AspectRatio>
  );
};

export default VideoIsUploaded;
