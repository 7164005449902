import { Icon } from '@chakra-ui/react';

export const IconTwitter = (props) => (
  <Icon viewBox="0 0 37 30" {...props}>
    <path
      fill="currentColor"
      d="M24.7642 0.0575562C23.2064 0.23547 21.5683 1.06034 20.3317 2.32192C18.8381 3.8261 18.1154 5.73463 18.2118 7.90195L18.26 9.19587L17.0715 9.09883C12.3821 8.66213 7.69263 6.44629 4.07918 2.93653C3.22801 2.09548 2.48926 1.44852 2.42502 1.49704C2.16807 1.65878 1.62204 3.4541 1.52568 4.40836C1.33296 6.54333 2.08777 8.62978 3.64557 10.2472L4.62521 11.2661H4.12736C3.8704 11.2661 3.16377 11.0882 2.56956 10.878L1.4775 10.506V11.0882C1.4775 12.6248 2.37685 14.598 3.67769 15.9243C4.51279 16.7653 6.5042 17.8975 7.17871 17.8975C7.43567 17.8975 7.45173 17.9298 7.25901 18.0592C7.13054 18.1401 6.37573 18.221 5.60486 18.221C4.20766 18.221 4.1916 18.221 4.30402 18.5768C4.65733 19.7737 5.82969 21.2132 7.09842 22.0704C8.12624 22.7659 9.81252 23.3967 10.6958 23.3967L11.1937 23.4128L10.7119 23.801C10.0213 24.3833 7.67657 25.564 6.45603 25.9521C4.75369 26.5021 3.59739 26.68 1.75051 26.6638H0L0.497853 26.9711C2.60168 28.2489 5.86181 29.3972 8.54379 29.8016C10.76 30.125 14.2129 30.0442 16.1722 29.6236C24.3466 27.8769 30.4493 22.1998 32.7137 14.2583C33.1795 12.6248 33.3882 11.3147 33.5328 9.16352L33.6452 7.46525L34.8657 6.3169C36.2629 4.99063 37.3871 3.55114 36.8732 3.71288C35.9257 4.02019 33.6452 4.6348 33.4204 4.6348C33.2598 4.6348 33.597 4.18193 34.3037 3.4541C35.2351 2.516 36.1666 1.12504 36.1666 0.688341C36.1666 0.63982 35.6366 0.866257 34.9942 1.15739C34.3518 1.46469 33.2919 1.85287 32.6495 2.03078L31.4771 2.35427L30.6581 1.73965C28.7791 0.332514 26.9804 -0.185055 24.7642 0.0575562Z"
    />
  </Icon>
);
