import { FormControl, FormLabel, InputLeftElement } from '@chakra-ui/react';
import { IconDeleteThin } from '../../theme/icons/IconDeleteThin';
import Select from 'react-select';
import { components } from 'react-select';
import {
  // dummyTags,
  stylesReactSelect,
  stylesReactSelectSingle,
  stylesReactSelectIndicatorsForEmails,
} from '../videos/reactSelectHelpers';
import { useEffect, useState, useRef, useContext } from 'react';
import { TagsContext } from '../../context/TagsContext';
import useTagsCache from '../../hooks/useTagsCache';
import { useAsync } from '../../hooks/useAsync';
import { HttpContext } from '../../context/HttpContext';
import { formatDate } from '../../util/date';

// function fetchTag(id, axiosInstance) {
//   return axiosInstance({
//     method: 'GET',
//     url: `tags_all/${id}`,
//     data: { id },
//     headers: {
//       Accept: 'application/ld+json',
//     },
//   })
//     .then(async (response) => {
//       const { data: tag } = await response;
//       if (tag) {
//         tag.fetchedAt = formatDate(new Date());
//         tag.label = tag.title.toLowerCase();
//         tag.value = tag['@id'];
//         return tag;
//       }
//     })
//     .catch((error) => {
//       return Promise.reject(error);
//     });
// }

function fetchTagByName(name, axiosInstance) {
  return axiosInstance({
    method: 'GET',
    url: `/tags/${name}`,
    data: { title: name },
    headers: {
      Accept: 'application/ld+json',
    },
  })
    .then(async (response) => {
      const { data: tag } = await response;
      if (tag) {
        tag.fetchedAt = formatDate(new Date());
        tag.label = tag.title.toLowerCase();
        tag.value = tag['@id'];
        return tag;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <IconDeleteThin fontSize="12px" />
    </components.MultiValueRemove>
  );
};

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <IconDeleteThin fontSize="14px" />
    </components.ClearIndicator>
  );
};

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <InputLeftElement
        pointerEvents="none"
        transition="0.3s ease-in-out"
        color={props.selectProps?.color}
        left="12px"
        top="6px"
        fontSize="26px"
        children={'#'}
      />
      {children}
    </components.Control>
  );
};

const SearchByTagWithSelect = ({
  updateTag,
  tag,
  // tagId: id,
  tagName: name,
}) => {
  const [
    // cache,
    // dispatch,
  ] = useTagsCache();
  const { publicAxios: axiosInstance } = useContext(HttpContext);

  const {
    run,
    // setData
  } = useAsync({
    status: name ? 'pending' : 'idle',
  });

  const [selectedOption, setSelectedOption] = useState(tag);
  const { state: tagsState, updateQuery } = useContext(TagsContext);
  const { items: tagsOptions } = tagsState;

  const ref = useRef();

  function handleChange(option, { action }) {
    ref.current.blur();
    if (option !== null) {
      setSelectedOption(option);
      updateTag(option);
    } else {
      setSelectedOption(null);
      updateTag(null);
    }
  }

  function handleInputChange(input) {
    if (input === '') {
      updateQuery('');
    } else {
      updateQuery(`&title=${input}`);
    }
  }

  useEffect(() => {
    let timer;
    if (!tag) {
      setSelectedOption(null);
      timer = setTimeout(() => ref.current.blur(), 100);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [tag]);

  // useEffect(() => {
  //   if (!id) {
  //     return;
  //   } else if (cache[id]) {
  //     setData(cache[id]);
  //   } else {
  //     run(
  //       fetchTag(id, axiosInstance).then((data) => {
  //         dispatch({ type: 'ADD_TAG', id, data });
  //         updateTag(data);
  //         return data;
  //       })
  //     );
  //   }
  // }, [id]);

  useEffect(() => {
    if (!name) {
      return;
    }
    run(
      fetchTagByName(name, axiosInstance).then((data) => {
        // dispatch({ type: 'ADD_TAG', id: data.id, data });
        updateTag(data);
        return data;
      })
    );
  }, [name]);

  return (
    <>
      <FormControl maxW={{ md: '340px' }}>
        <FormLabel textStyle="formLabels">Search by popular tag</FormLabel>
        <Select
          ref={ref}
          // defaultValue={tag}
          value={tag}
          onChange={handleChange}
          onInputChange={handleInputChange}
          options={tagsOptions}
          placeholder="Enter tag here"
          isClearable={true}
          color={selectedOption ? 'primary.default' : 'primary.light'}
          components={{ Control, MultiValueRemove, ClearIndicator }}
          styles={{
            ...stylesReactSelect,
            ...stylesReactSelectSingle,
            ...stylesReactSelectIndicatorsForEmails,
          }}
          // isMulti
          // isOptionDisabled={() => selectedOption}
          // isDisabled={selectedOption}
        />
      </FormControl>
    </>
  );
};

export default SearchByTagWithSelect;
