import { Center, VStack, Box, Image, Text } from '@chakra-ui/react';
import progressBarSrc from '../assets/vectors/progressBar.svg';
import purpleDottedTriangleSrc from '../assets/vectors/purpleDottedTriangle.svg';

const ComingSoonPage = () => {
  return (
    <Center h={{ base: 'calc(100vh - 74px)', '2xl': 'calc(100vh - 108px)' }} mx={4}>
      <Box maxW="full" pos="relative" pb={{ lg: '80px' }}>
        <Box
          textAlign="center"
          position="relative"
          zIndex="1"
          bgColor="white"
          py={{ base: '64px', xl: '100px', '2xl': '110px' }}
          px="16px"
          borderRadius="20px"
          w={{ base: '658px', xl: '976px', '2xl': '1076px' }}
          maxW="full"
        >
          <VStack spacing="35px">
            <Text
              textStyle="headingSemiBold"
              fontSize={{ base: '48px', xl: '70px' }}
              lineHeight="1"
              textTransform="uppercase"
            >
              Coming soon
            </Text>
            <Text
              textStyle="bodyMedium"
              fontSize={{ base: '20px', xl: '25px' }}
              lineHeight="150%"
            >
              Be prepared for a new experience. See you soon!
            </Text>
          </VStack>
          <VStack spacing="30px" mt={{ base: '50px', '2xl': '80px' }}>
            <Image
              src={progressBarSrc}
              alt="StoryVault - Coming soon"
              maxW={{ sm: '436px', md: '536px', xl: 'none' }}
            />
            <Text
              textStyle="headingSemiBold"
              fontSize="21px"
              lineHeight="150%"
              color="accent.active"
            >
              Please wait (99%)
            </Text>
          </VStack>
        </Box>
        <Image
          src={purpleDottedTriangleSrc}
          display={{ base: 'none', lg: 'block' }}
          pos="absolute"
          bottom="20px"
          right="-130px"
          w="400px"
        />
      </Box>
    </Center>
  );
};

export default ComingSoonPage;
