import { Container, Button, Box, Text, IconButton, Show, Flex } from '@chakra-ui/react';
import Flickity from 'react-flickity-component';
import { Link as RouterLink } from 'react-router-dom';
import { IconLogo } from '../../theme/icons/IconLogo';
import { IconBigNext } from '../../theme/icons/IconBigNext';
import { useEffect, useRef, useState } from 'react';

const flickityOptions = {
  draggable: true,
  adaptiveHeight: true,
  prevNextButtons: false,
  pageDots: false,
  // wrapAround: true,
};

const FlickityTaglines = ({ items, oneItem }) => {
  const [setIndex] = useState({
    selected: 0,
    initial: -1,
  });

  let flkty = useRef(null);

  useEffect(() => {
    flkty?.current?.on('select', () => {
      // console.log(flkty.current)
      const value = flkty.current.selectedIndex;
      setIndex({ selected: value, initial: value });
    });
    flkty?.current?.on('staticClick', (event, pointer, cellElement, cellIndex) => {
      // console.log({ cellIndex})
      setIndex({ selected: cellIndex, initial: cellIndex });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flkty?.current]);

  const onClickPrev = () => {
    flkty.previous();
  };
  const onClickNext = () => {
    flkty.next();
  };

  return (
    <Container px={0} mt={{ base: '48px', md: '80px' }} mb={{ base: '0', md: '30px' }}>
      <Container maxW="1200px" pos="relative">
        <Box
          textAlign="center"
          bg="secondary.lighter"
          borderRadius="10px"
          px={{ base: '24px', md: '32px' }}
          py={{ base: '24px', md: '48px' }}
        >
          <Text textStyle="sectionEyebrow" align="center">
            Share your story with us
          </Text>
          <Flex my={{ base: '24px', md: '40px' }}>
            <Show above="lg">
              <Box
                w="7.8vw"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                {(!oneItem && items.length > 1) && (
                      <IconButton
                        onClick={onClickPrev}
                        icon={<IconBigNext boxSize="44px" />}
                        variant="iconicBlack"
                        transform="rotate(-180deg)"
                      />
                    )}
              </Box>
            </Show>
            <Box flex="1">
              <Flickity
                flickityRef={(c) => (flkty = c)}
                options={flickityOptions}
                reloadOnUpdate // default false
                static // default false
              >
                {items?.length > 0 ? (
                  items.map((item) => {
                    return (
                      <Box px="10px" key={item.id}>
                        <Text
                          as="h2"
                          fontSize={{ base: '24px', md: '30px' }}
                          fontWeight="500"
                          lineHeight="1.2"
                          mb="0.9em"
                        >
                          {item.title}
                        </Text>
                        <Text as="p" fontSize="18px">
                          {item.description}
                        </Text>
                      </Box>
                    );
                  })
                ) : oneItem ? (
                  <Box px="10px" key={oneItem.id}>
                    <Text
                      as="h2"
                      fontSize={{ base: '24px', md: '30px' }}
                      fontWeight="500"
                      lineHeight="1.2"
                      mb="0.9em"
                    >
                      {oneItem.title}
                    </Text>
                    <Text as="p" fontSize="18px">
                      {oneItem.description}
                    </Text>
                  </Box>
                ) : null}
              </Flickity>
            </Box>
            <Show above="lg">
              <Box w="7.8vw" display="flex" justifyContent="flex-end" alignItems="center">
                {(!oneItem && items.length > 1) && (
                      <IconButton
                        onClick={onClickNext}
                        icon={<IconBigNext boxSize="44px" />}
                        variant="iconicBlack"
                      />
                    )}
              </Box>
            </Show>
          </Flex>
          <Button as={RouterLink} to="/add-video" w={{ base: '100%', md: 'auto' }}>
            <IconLogo boxSize="24px" mr="10px" />
            Share your story
          </Button>
        </Box>
      </Container>
    </Container>
  );
};

export default FlickityTaglines;
