import {
  Hide,
  Flex,
  IconButton,
  Text,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { IconChevronLeftPe } from '../../theme/icons/IconChevronLeftPe';
import { IconChevronLeftDouble } from '../../theme/icons/IconChevronLeftDouble';
import { PAGE_SIZE_OPTIONS } from '../../util/consts';
import debounce from 'lodash/debounce';
import { createRef } from 'react';

const ReactTablePagination = ({
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}) => {
  const ref = createRef();
  const handleGoToPage = debounce((value) => {
    const page = value ? value - 1 : 0;
    gotoPage(page);
    ref.current.blur();
  }, 1000);

  return (
    <Flex
      alignItems="center"
      justifyContent={{ base: 'space-between', lg: 'none' }}
      flexWrap={{ base: 'wrap', lg: 'no-wrap' }}
    >
      <Flex order={{ base: 2, lg: 1 }} mr={{ xl: '20px' }}>
        <IconButton
          onClick={() => gotoPage(0)}
          isDisabled={!canPreviousPage}
          icon={<IconChevronLeftDouble w="24px" h="24px" color="secondary.dark" />}
          mr={3}
          variant="iconicDark"
          size="sm"
          w="36px"
          _disabled={{ opacity: 0.4, cursor: 'default' }}
        />
        <IconButton
          onClick={() => previousPage()}
          isDisabled={!canPreviousPage}
          icon={<IconChevronLeftPe w="24px" h="24px" color="secondary.dark" />}
          variant="iconicDark"
          size="sm"
          w="36px"
          _disabled={{ opacity: 0.4, cursor: 'default' }}
        />
      </Flex>

      <Flex
        order={{ base: 1, lg: 2 }}
        width={{ base: '100%', lg: 'auto' }}
        justifyContent={{ base: 'center', lg: 'none' }}
        mb={{ base: '12px', lg: '0' }}
        alignItems="center"
        fontSize="12px"
      >
        <Text flexShrink="0">
          Page <Text as="span">{pageIndex + 1}</Text> of{' '}
          <Text as="span">{pageOptions.length}</Text>
        </Text>
        <Hide below="sm">
          <Text flexShrink="0" ml="30px">
            Go to <Hide below="xl">page</Hide>:
          </Text>{' '}
        </Hide>
        <NumberInput
          size="sm"
          ml={2}
          mr={5}
          w="72px"
          min={1}
          max={pageOptions.length}
          onChange={(value) => {
            handleGoToPage(value);
            // const page = value ? value - 1 : 0;
            // gotoPage(page);
          }}
          // value={pageIndex + 1}
          defaultValue={pageIndex + 1}
        >
          <NumberInputField ref={ref} />
          <NumberInputStepper>
            <NumberIncrementStepper
              children={
                <IconChevronLeftPe
                  transform="rotate(90deg)"
                  w="16px"
                  h="16px"
                  color="primary.dark"
                />
              }
            />
            <NumberDecrementStepper
              children={
                <IconChevronLeftPe
                  transform="rotate(270deg)"
                  w="16px"
                  h="16px"
                  color="primary.dark"
                />
              }
            />
          </NumberInputStepper>
        </NumberInput>
        <Select
          w={32}
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          size="sm"
        >
          {PAGE_SIZE_OPTIONS.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex order={{ base: 3, lg: 3 }} ml={{ xl: '20px' }}>
        <IconButton
          onClick={() => nextPage()}
          isDisabled={!canNextPage}
          icon={
            <IconChevronLeftPe
              transform="rotate(180deg)"
              w="24px"
              h="24px"
              color="secondary.dark"
            />
          }
          variant="iconicDark"
          size="sm"
          w="36px"
          _disabled={{ opacity: 0.4, cursor: 'default' }}
        />
        <IconButton
          onClick={() => gotoPage(pageCount - 1)}
          isDisabled={!canNextPage}
          icon={
            <IconChevronLeftDouble
              transform="rotate(180deg)"
              w="24px"
              h="24px"
              color="secondary.dark"
            />
          }
          ml={3}
          variant="iconicDark"
          size="sm"
          w="36px"
          _disabled={{ opacity: 0.4, cursor: 'default' }}
        />
      </Flex>
    </Flex>
  );
};

export default ReactTablePagination;
