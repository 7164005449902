import { Container, Box } from '@chakra-ui/react';
import FormCreateVideo from '../../components/videos/FormCreateVideo';
import { VideoFormProvider } from '../../context/VideoFormContext';
import { TagsProvider } from '../../context/TagsContext';
import UseTags from '../../hooks/useTags';

const CreateVideoPage = () => {
  return (
    <VideoFormProvider>
      <TagsProvider>
        <UseTags>
          <Container maxW="832px" mt={{ base: '120px', md: '140px' }} mb={10}>
            <Box
              bg="white"
              borderRadius="20px"
              p={{ base: '32px 16px', md: '60px 100px' }}
            >
              <FormCreateVideo
                // video={{
                //   title: 'Sunny day',
                //   category: 'goodLuck',
                //   about:
                //     'The anniversary of the 1969 Woodstock Music Festival prompts many to reminisce about the extraordinary gathering of musical talent and idealism associated with that event. It more than lived up to its promotional billing as “three days of peace and music.” Woodstock has established a high point of outdoor concert music by assembling more talent in one place in front of more people than any prior or subsequent event.',
                //   subject: 'Hana Jones',
                //   moreDetails:
                //     'Hana Jones is a British author and journalist whose work focuses on women in music. She was 19 when she attended Woodstock where she got the inspiration for her future profession. She has written many articles about the greatest world festivals and has been awarded the most prestigious awards.',
                // }}
                video={null}
              />
            </Box>
          </Container>
        </UseTags>
      </TagsProvider>
    </VideoFormProvider>
  );
};

export default CreateVideoPage;
