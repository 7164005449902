import { Box, useToast } from '@chakra-ui/react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import {
  stylesReactSelect,
  stylesReactSelectIndicatorsForEmails,
} from './reactSelectHelpers';

import { IconDeleteThin } from '../../theme/icons/IconDeleteThin';
import { validateEmail } from '../../util';
import CustomToast from '../UI/CustomToast';
import { useContext } from 'react';
import { VideoFormContext } from '../../context/VideoFormContext';

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <IconDeleteThin fontSize="12px" />
    </components.MultiValueRemove>
  );
};

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <IconDeleteThin fontSize="12px" />
    </components.ClearIndicator>
  );
};

const EmailAddressesControl = () => {
  const toast = useToast();
  const { updateShareEmails, state } = useContext(VideoFormContext);
  const { shareEmails } = state;

  function showError(message) {
    toast({
      position: 'top',
      render: ({ onClose, id }) => (
        <CustomToast status="error" description={message} onClose={onClose} id={id} />
      ),
    });
  }

  function checkLastEmail(emails) {
    if (emails.length > 0) {
      const lastIndex = emails.length - 1;
      const email = emails[lastIndex]?.value;
      if (email) {
        if (!validateEmail(email)) {
          showError(`Email ${email} is not valid email address`);
          emails.length = lastIndex;
          return emails;
        }

        return emails;
      }
    }

    return [];
  }

  const handleOnChange = (newValue, actionMeta) => {
    const emails = checkLastEmail(newValue);
    updateShareEmails(emails);
  };

  // const handleOnFocus = (e) => {
  //   e.stopPropagation();
  //   console.group('Emails Focused');
  //   console.log(e);
  //   console.groupEnd();
  // };

  return (
    <Box p="0 16px 16px 47px" color="primary.default">
      <CreatableSelect
        defaultValue={shareEmails}
        onChange={handleOnChange}
        // onCreateOption={handleCreate}
        options={[]}
        components={{ MultiValueRemove, ClearIndicator }}
        placeholder="Add email address"
        isMulti
        formatCreateLabel={(userInput) => `Add email "${userInput}"`}
        openMenuOnClick={false}
        styles={{ ...stylesReactSelect, ...stylesReactSelectIndicatorsForEmails }}
      />
    </Box>
  );
};

export default EmailAddressesControl;
