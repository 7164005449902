import React, { createContext, useState } from 'react';
import { getVimeoIdFromVimeoVideo } from '../util';

const initialState = {
  data: null,
  status: 'idle',
  error: null,
};

const SingleVideoContext = createContext({
  state: initialState,
  updateState: () => {},
  isCompleted: () => {},
  reportVideo: () => {},
  requestAccessVideo: () => {},
  isPrivate: () => {},
});

export function SingleVideoProvider({ children }) {
  const [state, setState] = useState(initialState);

  function updateStateHandler({ data, status, error }) {
    setState({
      data,
      status,
      error,
    });
  }

  function getVimeoIdHandler() {
    const { data } = state;
    const vimeoVideoUri = data?.vimeoVideo ?? null;
    if (vimeoVideoUri) {
      return getVimeoIdFromVimeoVideo(vimeoVideoUri);
    }
    return null;
  }

  function isCompletedHandler() {
    const { data } = state;
    return data?.upload?.transcodingStatus === 'complete' ?? false;
  }

  function isPrivateHandler() {
    const { data } = state;
    return parseInt(data?.privacy, 0) === 2 ?? false;
  }

  function reportVideoHandler() {
    const { data } = state;
    setState({
      ...state,
      data: { ...data, canReport: false },
    });
  }

  function requestAccessVideoHandler() {
    const { data } = state;
    setState({
      ...state,
      data: { ...data, canRequestPermission: false },
    });
  }

  const value = {
    state,
    updateState: updateStateHandler,
    getVimeoId: getVimeoIdHandler,
    isCompleted: isCompletedHandler,
    reportVideo: reportVideoHandler,
    requestAccessVideo: requestAccessVideoHandler,
    isPrivate: isPrivateHandler,
  };

  return (
    <SingleVideoContext.Provider value={value}>{children}</SingleVideoContext.Provider>
  );
}

export default SingleVideoContext;
