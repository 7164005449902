import React, { createContext, useState } from 'react';
export const INITIAL_TRENDING_VIDEOS_PERP_AGE = 50;
export const INITIAL_ORDER_VIEW_COUNTER = 'desc';

export const initialTrendingVideosState = {
  items: [],
  total: 0,
  status: 'idle',
  error: null,
  perPage: INITIAL_TRENDING_VIDEOS_PERP_AGE,
  orderViewCounter: INITIAL_ORDER_VIEW_COUNTER
};

const TrendingVideosContext = createContext({
  state: initialTrendingVideosState,
  updateState: () => {},
});
const { Provider } = TrendingVideosContext;

const TrendingVideosProvider = ({ children }) => {
  const [state, setState] = useState(initialTrendingVideosState);

  function updateStateHandler({ items, total, status, error }) {
    setState({
      items,
      total,
      status,
      error,
    });
  }

  return (
    <Provider
      value={{
        state,
        updateState: (stateInfo) => updateStateHandler(stateInfo),
      }}
    >
      {children}
    </Provider>
  );
};

export { TrendingVideosContext, TrendingVideosProvider };
