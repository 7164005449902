import { Center, Heading, Text, Box, Button, Image } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconVideoAdd } from '../../theme/icons/IconVideoAdd';
import VideoIsUploadingSrc from '../../assets/vectors/videoIsUploading.svg';

const verticalSpace = { base: '24px', '2xl': '50px' };

const VideosListEmpty = () => {
  return (
    <Center>
      <Box maxW="600px" textAlign="center">
        <Image
          src={VideoIsUploadingSrc}
          mb={verticalSpace}
          w={{ md: '314px' }}
          display="inline-block"
        />
        <Heading size="lg" mb={5}>
          Create video story to get started
        </Heading>
        <Text lineHeight="28px" mb={verticalSpace}>
          Start sharing your video stories alongside other eyewitnesses of remarkable
          events. Video stories you upload will show up here.
        </Text>
        <Button
          as={RouterLink}
          to="/add-video"
          variant="solid"
          px={{ md: '62px' }}
          mb="24px"
        >
          Upload your first video
        </Button>
      </Box>
    </Center>
  );
};

export default VideosListEmpty;
