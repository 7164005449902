import { Box, Container, Flex, Link, Image } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import logoForDarkSrc from '../assets/vectors/logoForDark.svg';
import soonSrc from '../assets/img/heroes/soon.webp';

const LayoutPlainDark = (props) => {
  return (
    <Container bgColor="primary.default" p={0} pos="relative">
      <Image
        src={soonSrc}
        pos="absolute"
        objectFit="cover"
        // objectPosition="right top"
        h="full"
        w="full"
      />
      <Box
        pos="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
        bg="rgba(36, 6, 48, 0.6)"
      />
      <Box
        as="header"
        pt={{ base: '16px', '2xl': '50px' }}
        pl={{ base: '16px', '2xl': '100px' }}
        pos="relative"
        zIndex={1}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Link as={RouterLink} to="/" pointerEvents="auto">
            <Image src={logoForDarkSrc} />
          </Link>
        </Flex>
      </Box>

      {props.children}
    </Container>
  );
};

export default LayoutPlainDark;
