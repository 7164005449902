import {
  SimpleGrid,
  GridItem,
  Container,
  Text,
  useMediaQuery,
  Tooltip,
  Tag,
  TagLabel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  MenuItemOption,
  Link,
  Box,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconChevronLeft } from '../../theme/icons/IconChevronLeft';

function Other() {
  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const itemMt = '8px';

  const handleSortBy = (e, value) => {
    console.log('Sort by: ', value);
  };

  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 1, sm: 2, md: 4 }} columnGap={4} rowGap={4}>
          <GridItem>
            <Tooltip
              hasArrow
              arrowSize={12}
              placement="top"
              bg="primary.default"
              label="julie.barnett@gmail.com, craig.james@gmail.com, danka.maksimovic@etondigital.com, goran@etondigital.com, johnny@nba.com, novica.stevic@etondigital.com, vladan.petrovic@etondigital.com, milan.kitanovic@etondigital.com, nenad.jovanovic@etondigital.com"
            >
              <Tag>
                <TagLabel>Tooltip</TagLabel>
              </Tag>
            </Tooltip>
          </GridItem>

          <GridItem>
            <Text color="brandDark">
              {isLargerThan2XL
                ? 'isLargerThan2XL 96em'
                : isLargerThanXL
                ? 'isLargerThanXL 80em'
                : isLargerThanLG
                ? 'isLargerThanLG 62em'
                : isLargerThanMD
                ? 'isLargerThanMD 48em'
                : isLargerThanSM
                ? 'isLargerThanSM 30em'
                : 'useMediaQuery:none'}
            </Text>
          </GridItem>

          <GridItem>
            {/* Box element introduced because Popper doesn't allow it's elements to have margins (which here comes from HStack for Menu) */}
            <Box>
              <Menu variant="small">
                <MenuButton>
                  <Link variant="secondaryDark" fontSize="14px" textStyle="bodySemiBold">
                    Menu small
                    <IconChevronLeft
                      fontSize="24px"
                      transform="rotate(-90deg)"
                      pos="relative"
                      top="-1px"
                    />
                  </Link>
                </MenuButton>
                <MenuList>
                  <MenuItem as={RouterLink} to="/">
                    Link one
                  </MenuItem>
                  <MenuItem as={RouterLink} to="/" mt={itemMt}>
                    Link two
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </GridItem>

          <GridItem>
            {/* Box element introduced because Popper doesn't allow it's elements to have margins (which here comes from HStack for Menu) */}
            <Box>
              <Menu variant="small">
                <MenuButton>
                  <Link variant="secondaryDark" fontSize="14px" textStyle="bodySemiBold">
                    Sort by
                    <IconChevronLeft
                      fontSize="24px"
                      transform="rotate(-90deg)"
                      pos="relative"
                      top="-1px"
                    />
                  </Link>
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup
                    defaultValue="newest"
                    // title="Order"
                    type="radio"
                  >
                    <MenuItemOption
                      value="newest"
                      onClick={(e) => handleSortBy(e, 'newest')}
                    >
                      Newest first
                    </MenuItemOption>
                    <MenuItemOption
                      value="oldest"
                      onClick={(e) => handleSortBy(e, 'oldest')}
                      mt={itemMt}
                    >
                      Oldest first
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </Box>
          </GridItem>
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default Other;
