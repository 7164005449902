import { AspectRatio, Flex, Image, FormLabel, Box } from '@chakra-ui/react';
import CautionTapeSrc from '../../assets/vectors/cautionTape.svg';
import NoAccessSrc from '../../assets/vectors/noAccess.svg';

const VideoNoAccess = ({ imgW, isPrivate }) => {
  return (
    <AspectRatio ratio={16 / 9}>
      <Flex
        flexDir="column"
        bg="secondary.lighter"
        justifyContent="space-between !important"
      >
        <Image src={CautionTapeSrc} alt="" />
        <Box>
          <Image
            src={NoAccessSrc}
            mb={{ base: 2, sm: 6 }}
            mr="auto"
            ml="auto"
            w={imgW ?? { base: '140px', md: 'auto' }}
            alt=""
          />
          <FormLabel
            textStyle="formLabels"
            color="primary.dark"
            mr={0}
            textAlign="center"
          >
            {isPrivate ? "This video is private": "You don't have permission to access this video"}
          </FormLabel>
        </Box>
        <Image src={CautionTapeSrc} alt="" />
      </Flex>
    </AspectRatio>
  );
};

export default VideoNoAccess;
