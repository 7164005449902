import { HStack, Link, Divider } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const FlickityViewAllCategoriesCTA = () => {
  return (
    <HStack spacing={10} mt="-40px" pr="120px" justifyContent="flex-end">
      <Link
        as={RouterLink}
        to="/all-categories"
        variant="secondaryDark"
        fontSize="14px"
        textStyle="bodySemiBold"
      >
        View All Categories
      </Link>
      <Divider orientation="vertical" h="40px" />
    </HStack>
  );
};

export default FlickityViewAllCategoriesCTA;
