import { createContext, useState } from 'react';
export const COMMENTS_PER_PAGE = 5;
export const NEWEST = 'newest';
export const OLDEST = 'oldest';

const initialCommentsState = {
  items: [],
  total: 0,
  status: 'idle',
  error: null,
  perPage: COMMENTS_PER_PAGE,
  sortBy: NEWEST,
};

const CommentsContext = createContext({
  state: initialCommentsState,
  updateState: () => {},
  updatePerPage: (total) => {},
  updateSortBy: (sortBy) => {},
  addComment: () => {},
  updateComment: () => {},
  removeComment: () => {},
  addReply: () => {},
  updateReply: () => {},
  removeReply: () => {},
});

export function CommentsContextProvider(props) {
  const [state, setState] = useState(initialCommentsState);

  function updateStateHandler({ items, total, status, error }) {
    setState({
      ...state,
      items,
      total,
      status,
      error,
    });
  }

  function getTotalComments() {
    return state?.total ?? 0;
  }

  function updatePerPageHandler(perPage) {
    setState({
      ...state,
      perPage,
    });
  }

  function updateSortByHandler(sortBy) {
    setState({
      ...state,
      sortBy,
    });
  }

  function addCommentHandler(comment) {
    try {
      let { items, total } = state;
      if (state.sortBy === NEWEST) {
        items = [comment].concat(items);
        setState({
          ...state,
          items,
        });
      } else {
        items = items.concat([comment]);
      }

      setState({
        ...state,
        items,
        total: total + 1,
      });
    } catch (onErr) {
      console.log(onErr);
    }
  }
  function updateCommentHandler(comment) {
    try {
      let { items } = state;
      let recordIndex = items.findIndex(
        (el) => parseInt(el.id, 10) === parseInt(comment.id)
      );

      if (recordIndex > -1) {
        items[recordIndex] = comment;
        setState({
          ...state,
          items,
        });
      }
    } catch (onErr) {
      console.log(onErr);
    }
  }

  function removeCommentHandler(commentId) {
    try {
      let { items, total } = state;
      items = items.filter((x) => parseInt(x.id, 10) !== parseInt(commentId));
      setState({
        ...state,
        items,
        total: total > 1 ? total - 1 : 0,
      });
    } catch (onErr) {
      console.log(onErr);
    }
  }

  function addReplyHandler(reply, commentId) {
    try {
      let { items } = state;

      let recordIndex = items.findIndex(
        (el) => parseInt(el.id, 10) === parseInt(commentId)
      );

      if (recordIndex > -1) {
        let replies = items[recordIndex].replies;
        if (state.sortBy === NEWEST) {
          replies = [reply].concat(replies);
        } else {
          replies = replies.concat([reply]);
        }

        items[recordIndex].replies = replies;
        setState({
          ...state,
          items,
        });
      }
    } catch (onErr) {
      console.log(onErr);
    }
  }

  function updateReplyHandler(reply, commentId) {
    try {
      let { items } = state;
      let recordIndex = items.findIndex(
        (el) => parseInt(el.id, 10) === parseInt(commentId)
      );

      if (recordIndex > -1) {
        let replies = items[recordIndex].replies;
        let replyRecordIndex = replies.findIndex(
          (el) => parseInt(el.id, 10) === parseInt(reply.id)
        );
        if (replyRecordIndex > -1) {
          replies[replyRecordIndex] = reply;
          items[recordIndex].replies = replies;
          setState({
            ...state,
            items,
          });
        }
      }
    } catch (onErr) {
      console.log(onErr);
    }
  }

  function removeReplyHandler(replyId, commentId) {
    try {
      let { items } = state;
      let recordIndex = items.findIndex(
        (el) => parseInt(el.id, 10) === parseInt(commentId)
      );

      if (recordIndex > -1) {
        let replies = items[recordIndex].replies;
        replies = replies.filter((x) => parseInt(x.id, 10) !== parseInt(replyId));
        items[recordIndex].replies = replies;
        setState({
          ...state,
          items,
        });
      }
    } catch (onErr) {
      console.log(onErr);
    }
  }

  const context = {
    state: state,
    updateState: (stateInfo) => updateStateHandler(stateInfo),
    totalComments: getTotalComments(),
    updatePerPage: updatePerPageHandler,
    updateSortBy: updateSortByHandler,
    addComment: addCommentHandler,
    updateComment: updateCommentHandler,
    removeComment: removeCommentHandler,
    addReply: addReplyHandler,
    updateReply: updateReplyHandler,
    removeReply: removeReplyHandler,
  };

  return (
    <CommentsContext.Provider value={context}>{props.children}</CommentsContext.Provider>
  );
}

export default CommentsContext;
