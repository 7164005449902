import { Icon } from '@chakra-ui/react';

export const CategoryMusicIcon = (props) => (
  <Icon viewBox="0 0 51 51" fill="none" {...props}>
    <path
      d="M22.6631 50.5519C21.7244 50.2919 20.7971 50.0205 19.9942 49.4212C17.5515 47.612 16.8617 44.2989 18.3997 41.7208C19.9716 39.0862 23.1946 38.1137 25.9539 39.4254C26.067 39.4819 26.1801 39.5385 26.3723 39.6289C26.3723 39.3915 26.3723 39.2105 26.3723 39.0183C26.3723 32.7201 26.3723 26.4218 26.3723 20.1348C26.3723 19.0041 26.6777 18.6083 27.7746 18.2917C34.4581 16.3921 41.1529 14.5037 47.8363 12.6041C49.1821 12.2196 49.9963 12.8415 49.9963 14.2323C49.9963 22.4189 50.0076 30.5942 49.9963 38.7809C49.9963 42.1505 47.2822 44.7625 43.9122 44.6721C40.9945 44.5929 38.4953 42.1844 38.2918 39.2558C38.0769 36.2706 40.1916 33.5455 43.0867 33.0593C44.4324 32.8331 45.1562 32.9462 47.0334 33.7151C47.0334 29.7236 47.0334 25.7546 47.0334 21.7405C41.1189 23.414 35.2271 25.0762 29.29 26.7497C29.29 26.9645 29.29 27.1568 29.29 27.349C29.29 32.9914 29.2334 38.6452 29.3126 44.2876C29.3578 47.8381 26.8021 50.2014 24.2463 50.4841C24.201 50.4841 24.1558 50.5293 24.1219 50.5519C23.6356 50.5519 23.1493 50.5519 22.6631 50.5519Z"
      fill="#DB4049"
    />
    <path
      d="M0 29.2707C0.36188 28.4114 0.588055 27.4615 1.09695 26.7039C2.72541 24.2841 5.76746 23.5378 8.4024 24.793C8.51549 24.8495 8.62858 24.8947 8.79821 24.9739C8.79821 24.7251 8.79821 24.5329 8.79821 24.352C8.79821 17.0813 8.79821 9.81056 8.79821 2.52854C8.79821 2.38155 8.79821 2.23455 8.79821 2.08755C8.80952 1.35257 9.20532 0.821115 9.86123 0.61758C10.4832 0.425353 11.0939 0.651503 11.5123 1.2508C11.8629 1.75964 12.2135 2.26847 12.5414 2.78862C14.43 5.83033 16.9292 8.30666 19.7903 10.4212C21.1361 11.4162 22.2895 12.5357 22.9228 14.13C24.8114 18.8452 21.3509 24.1032 16.3185 24.1711C15.3686 24.1824 14.6901 23.6057 14.6675 22.7576C14.6448 21.9096 15.2781 21.2876 16.2281 21.2424C18.1505 21.1633 19.7677 19.9421 20.3218 18.1668C20.8873 16.3463 20.2766 14.4692 18.7273 13.3046C16.2054 11.4275 13.8306 9.39218 11.7385 6.81408C11.7385 7.13069 11.7385 7.31161 11.7385 7.50383C11.7385 14.9894 11.7385 22.4749 11.7385 29.9605C11.7385 33.364 9.02438 35.9874 5.64307 35.8856C2.98551 35.8064 0.667216 33.8163 0.124396 31.1251C0.101779 31.0234 0.0565438 30.9103 0.0226176 30.8085C8.59419e-08 30.311 0 29.7909 0 29.2707Z"
      fill="#DB4049"
    />
  </Icon>
);
