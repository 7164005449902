import {
  SimpleGrid,
  GridItem,
  Container,
  useToast,
  Tag,
  TagLabel,
} from '@chakra-ui/react';

import CustomToast from '../UI/CustomToast';

function FlashMessages() {
  const toast = useToast();
  // const toastStatuses = ['success', 'error', 'warning', 'info'];
  const toastStatuses = ['success', 'error'];

  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 1, sm: 2, md: 4 }} columnGap={4} rowGap={4}>
          {toastStatuses.map((status, index) => {
            return (
              <GridItem key={index}>
                <Tag
                  onClick={() =>
                    toast({
                      position: 'top',
                      // duration: '9000',
                      render: (props) => (
                        <CustomToast
                          status={status}
                          title={`${status} - Title goes here\nNew line!`}
                          description={`${status} - Description goes here\nNew line!`}
                          onClose={props.onClose}
                          id={props.id}
                        />
                      ),
                    })
                  }
                >
                  <TagLabel textTransform="capitalize">{status}</TagLabel>
                </Tag>
              </GridItem>
            );
          })}
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default FlashMessages;
