import { HStack, Link, Divider } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const FlickityViewMoreVideosCTA = () => {
  return (
    <HStack spacing={10} mt="-40px" pr="120px" justifyContent="flex-end">
      <Link
        as={RouterLink}
        to="/search"
        variant="secondaryDark"
        fontSize="14px"
        textStyle="bodySemiBold"
      >
        View More Videos
      </Link>
      <Divider orientation="vertical" h="40px" />
    </HStack>
  );
};

export default FlickityViewMoreVideosCTA;
