import { Icon } from '@chakra-ui/react';

export const CategorySportsIcon = (props) => (
  <Icon viewBox="0 0 50 51" fill="none" {...props}>
    <path
      d="M24.197 50.6285C26.8821 44.1899 28.1893 37.3629 29.8733 30.63C30.4151 28.4641 31.051 26.3101 31.7694 24.1796C32.2287 22.8142 33.0177 21.6135 34.2778 20.6719C34.5722 21.2369 34.8784 21.7666 35.1493 22.308C37.1277 26.2748 38.3996 30.4769 39.2475 34.8204C39.8717 37.9985 40.2368 41.2119 40.4134 44.4489C40.4487 45.0492 40.2839 45.4023 39.7893 45.7672C36.5742 48.1096 33.0177 49.6045 29.1079 50.2754C28.1304 50.4402 27.1412 50.5108 26.1637 50.6285C25.5042 50.6285 24.8565 50.6285 24.197 50.6285Z"
      fill="#E37712"
    />
    <path
      d="M49.9885 27.1944C49.859 28.1361 49.7647 29.0895 49.5999 30.0312C48.7048 34.9867 46.5026 39.2712 43.0403 42.9202C42.9932 42.9673 42.9461 42.9908 42.8519 43.0614C42.2042 34.8337 40.579 26.9119 36.5278 19.5199C37.4817 19.3433 38.3768 19.155 39.2836 19.0019C41.0618 18.6841 42.5339 19.4021 43.9118 20.3909C45.8431 21.7798 47.3976 23.5572 48.9286 25.3582C49.2819 25.7819 49.647 26.1939 50.0003 26.6176C49.9885 26.806 49.9885 26.9943 49.9885 27.1944Z"
      fill="#E37712"
    />
    <path
      d="M29.638 14.6211C30.7568 15.9159 31.852 17.1871 32.8177 18.3054C32.2524 18.9763 31.746 19.5413 31.2632 20.1298C30.062 21.5894 29.273 23.2726 28.7901 25.0618C27.9186 28.2399 27.1414 31.4533 26.3524 34.655C25.2218 39.2456 24.1501 43.848 22.4543 48.2856C22.2305 48.8741 21.9361 49.4391 21.7124 50.0394C21.6064 50.3337 21.4297 50.4043 21.1589 50.3337C19.8163 50.0041 18.4503 49.7804 17.1431 49.3449C13.139 48.0266 9.68846 45.8137 6.80319 42.7415C6.67365 42.6003 6.57943 42.3413 6.60299 42.1647C6.95628 39.3986 8.08684 36.9267 9.48825 34.5608C11.926 30.441 15.0939 26.898 18.5327 23.6022C21.889 20.3888 25.5044 17.4814 29.3201 14.8447C29.379 14.7859 29.4496 14.7388 29.638 14.6211Z"
      fill="#E37712"
    />
    <path
      d="M24.5976 10.3477C25.6222 11.1834 26.6232 12.0073 27.6831 12.8784C27.4947 13.0196 27.3416 13.1373 27.2003 13.2433C22.4779 16.5626 18.0616 20.2704 14.1047 24.4726C10.9603 27.8155 8.08684 31.3703 6.12014 35.5489C5.55486 36.7378 5.15446 37.9972 4.6834 39.2214C4.61274 39.398 4.56563 39.5863 4.48319 39.857C4.35365 39.6687 4.25944 39.551 4.177 39.4333C2.17498 36.4317 0.87955 33.1359 0.314273 29.5694C-0.251005 26.0381 -0.0508026 22.5304 0.891326 19.0816C0.938433 18.8933 1.13864 18.7167 1.31528 18.6108C3.41152 17.4808 5.67263 16.8334 7.99262 16.3625C10.3479 15.8799 12.715 15.4562 15.0704 14.95C18.2972 14.2556 21.1824 12.7842 23.8675 10.8891C24.0913 10.7125 24.3268 10.5478 24.5976 10.3477Z"
      fill="#E37712"
    />
    <path
      d="M22.4311 8.81793C19.7814 10.7013 16.9786 12.0314 13.846 12.6552C10.5721 13.3144 7.30995 14.0677 4.03606 14.7975C3.43545 14.927 2.87017 15.1859 2.16357 15.4213C2.52865 14.7033 2.82306 14.0677 3.16459 13.4556C5.15483 9.92438 7.8399 7.02877 11.2198 4.76877C11.7615 4.40388 12.3268 4.0743 12.8921 3.76826C13.0216 3.69763 13.2454 3.68586 13.3749 3.74471C16.5428 5.1219 19.5223 6.82866 22.4311 8.81793Z"
      fill="#E37712"
    />
    <path
      d="M49.7295 22.4383C49.4234 22.097 49.1525 21.7556 48.8227 21.4614C47.5509 20.3196 46.3379 19.119 44.9718 18.0949C42.852 16.4941 40.4378 16.0703 37.8469 16.6824C37.0579 16.8708 36.2807 17.1062 35.4916 17.2827C35.3385 17.318 35.103 17.271 35.0088 17.165C33.9371 15.8585 32.8772 14.5401 31.8055 13.21C34.2669 11.6681 36.8342 10.1849 39.8254 9.61994C40.9795 9.40807 42.1336 9.20797 43.2877 9.01963C43.4526 8.99609 43.7117 9.07849 43.8177 9.20797C47.1034 12.9982 49.0818 17.3769 49.7649 22.3442C49.7767 22.3559 49.7649 22.3795 49.7295 22.4383Z"
      fill="#E37712"
    />
    <path
      d="M29.7676 11.3966C28.7666 10.5491 27.6949 9.65455 26.5408 8.67758C29.061 6.80602 30.4977 4.44009 29.8618 1.20312C32.7588 1.32083 39.2948 4.52249 41.2969 6.78248C39.2477 7.20623 37.1868 7.54758 35.3143 8.4657C33.4536 9.37206 31.64 10.3961 29.7676 11.3966Z"
      fill="#E37712"
    />
    <path
      d="M16.3195 2.18036C19.8407 0.88557 23.5267 0.379426 27.3895 0.744321C27.4484 1.93317 27.6486 3.13379 27.201 4.25202C26.9655 4.82879 26.4591 5.31139 26.0234 5.78222C25.5759 6.27659 25.0577 6.70034 24.622 7.11232C21.8309 5.46441 19.0752 3.8165 16.3195 2.18036Z"
      fill="#E37712"
    />
  </Icon>
);
