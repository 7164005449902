import {
  SimpleGrid,
  GridItem,
  Box,
  Container,
  Button,
  Stack,
  VStack,
  Link,
  Divider,
  IconButton,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconVideoAdd } from '../../theme/icons/IconVideoAdd';
import { IconPen } from '../../theme/icons/IconPen';
import { IconGlobe } from '../../theme/icons/IconGlobe';
import { IconDeleteThin } from '../../theme/icons/IconDeleteThin';
import { IconCopy } from '../../theme/icons/IconCopy';
import { IconJustPlus } from '../../theme/icons/IconJustPlus';

function Buttons() {
  const handleTagOnClick = () => {
    console.log('tag solidLight clicked!');
  };

  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 1, lg: 2 }} columnGap={4} rowGap={4}>
          <GridItem border="solid 1px #DDD">
            <Box p={3}>
              <VStack alignItems="flex-start" w="full">
                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button>Log in</Button>
                  <Button disabled>Log in</Button>
                  <Button variant="solidLightGrey">
                    Advanced search
                    <IconJustPlus boxSize="14px" ml="10px" />
                  </Button>
                </Stack>

                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button alt="Save" isLoading>
                    Save
                  </Button>
                  <Button isLoading loadingText="Saving">
                    Save
                  </Button>
                </Stack>

                <Divider />

                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button as={RouterLink} to="/styleguide" py="25px">
                    <IconCopy boxSize="24px" mr="10px" />
                    Copy
                  </Button>
                </Stack>

                <Divider />

                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button as={RouterLink} to="/styleguide" variant="solidError">
                    Delete
                  </Button>
                  <Button as={RouterLink} to="/styleguide" variant="solidError" disabled>
                    Delete
                  </Button>
                </Stack>
              </VStack>
            </Box>
          </GridItem>

          <GridItem border="solid 1px #DDD">
            <Box p={3}>
              <VStack alignItems="flex-start" w="full">
                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button as={RouterLink} to="/styleguide" variant="solidLight">
                    <IconVideoAdd boxSize="24px" mr="10px" />
                    Add video story
                  </Button>
                  <Button as={RouterLink} to="/styleguide" variant="solidLight" disabled>
                    <IconVideoAdd boxSize="24px" mr="10px" />
                    Add video story
                  </Button>
                </Stack>

                <Divider />

                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button as={RouterLink} to="/styleguide" variant="outline">
                    Skip
                  </Button>
                  <Button as={RouterLink} to="/styleguide" variant="outline" disabled>
                    Skip
                  </Button>
                </Stack>

                <Divider />

                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button as={RouterLink} to="/styleguide" variant="outlineError">
                    Cancel
                  </Button>
                  <Button
                    as={RouterLink}
                    to="/styleguide"
                    variant="outlineError"
                    disabled
                  >
                    Cancel
                  </Button>
                </Stack>
              </VStack>
            </Box>
          </GridItem>

          <GridItem border="solid 1px #DDD">
            <Box p={3}>
              {/* <VStack alignItems="flex-start" w="full"> */}
              {/* <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button as={RouterLink} to="/styleguide" alt="Router Link">
                    Router Link
                  </Button>
                </Stack>
                <Divider /> */}

              <Stack
                spacing={5}
                direction={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                alignItems="center"
              >
                <Link
                  as={RouterLink}
                  to="/styleguide"
                  textDecoration="underline"
                  color="accent.default"
                  textStyle="bodySemiBold"
                >
                  Log in
                </Link>
                <Link as={RouterLink} to="/styleguide" variant="primaryDefault">
                  <IconPen mr="7px" />
                  Edit
                </Link>
                <Link
                  as={RouterLink}
                  to="/styleguide"
                  variant="secondaryDark"
                  fontSize="14px"
                  textStyle="bodyMedium"
                >
                  Photo gallery
                </Link>
                <IconButton
                  as={RouterLink}
                  to="/styleguide"
                  icon={<IconGlobe boxSize="16px" />}
                  variant="iconicDark"
                  bg="secondary.light"
                  size="sm"
                />
                <IconButton
                  as={RouterLink}
                  to="/styleguide"
                  icon={<IconVideoAdd boxSize="24px" />}
                  variant="iconicDark"
                  size="md"
                />
                <IconButton
                  icon={<IconPen boxSize="20px" />}
                  variant="iconicDark"
                  size="md"
                  _hover={{ bg: 'transparent', color: 'primary.default' }}
                />
              </Stack>
              {/* </VStack> */}
            </Box>
          </GridItem>

          <GridItem border="solid 1px #DDD">
            <Box p={3}>
              <Stack
                spacing={5}
                direction={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                alignItems="center"
              >
                <Tag>
                  <TagLabel>Music</TagLabel>
                </Tag>
                <Tag variant="solidDark">
                  <TagLabel>
                    <IconGlobe boxSize="14px" mr="10px" />
                    Public
                  </TagLabel>
                </Tag>
                <Tag variant="solidLight">
                  <TagLabel>
                    Festival
                    <IconDeleteThin
                      boxSize="12px"
                      ml="10px"
                      onClick={handleTagOnClick}
                      transition="0.3s"
                      _hover={{ color: 'accent.default' }}
                    />
                  </TagLabel>
                </Tag>
              </Stack>
            </Box>
          </GridItem>
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default Buttons;
