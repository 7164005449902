import {
  Text,
  Heading,
  Box,
  Container,
  Link,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconChevronLeft } from '../../theme/icons/IconChevronLeft';

function Intro() {
  const items = [
    { destination: 'destination_colors', name: 'Colors' },
    { destination: 'destination_typography', name: 'Typography' },
    { destination: 'destination_monocolor', name: 'Monocolor icons' },
    { destination: 'destination_buttons', name: 'Buttons & Links' },
    { destination: 'destination_form', name: 'Form Controls' },
    { destination: 'destination_flash', name: 'Flash Messages' },
    { destination: 'destination_modals', name: 'Modals' },
    { destination: 'destination_other', name: 'Other' },
    { destination: 'destination_previews', name: 'Video previews' },
    { destination: 'destination_emails', name: 'Email exemplars' },
  ];

  return (
    <>
      <Container bg="secondary.dark" id="destination_intro">
        <Container maxW="1180px" color="white" px="0">
          <Heading fontSize={{ base: '36px', lg: '6vw' }} py="4vw">
            UI Components
          </Heading>
          <Box maxW="880px" pb="4vw">
            <Text
              textStyle="bodyMedium"
              fontSize={{ base: '18px', lg: '24px' }}
              lineHeight="normal"
              mb={6}
            >
              This UI Kit is a live inventory of UI components, design patterns, brand
              assets and guidelines for the purpose of the designers and developers to
              build a cohensive and consistent experience.
            </Text>
            <HStack flexWrap="wrap" spacing={6} fontSize="14px">
              <Link
                as={RouterLink}
                to="/home"
                color="secondary.light"
                _hover={{ color: 'white', textDecoration: 'underline' }}
                mb="4px !important"
              >
                {'< '}Home
              </Link>
              {items.map((item, index) => {
                return (
                  <Link
                    key={index}
                    href={`#${item.destination}`}
                    color="secondary.light"
                    _hover={{ color: 'white', textDecoration: 'underline' }}
                    mb="4px !important"
                  >
                    #{item.name}
                  </Link>
                );
              })}
            </HStack>
          </Box>
        </Container>
      </Container>

      <Link href="#destination_intro" pos="fixed" bottom="32px" right="32px" zIndex={1}>
        <IconButton
          icon={<IconChevronLeft boxSize="26px" transform="rotate(90deg)" />}
          variant="iconicDark"
          bg="secondary.light"
          size="sm"
        />
      </Link>
    </>
  );
}

export default Intro;
