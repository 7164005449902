import FlickityHorizontalVideos from './FlickityHorizontalVideos';
import { FlickityControlsContextProvider } from '../../context/FlickityControlsContext';
// import makeData from '../videos/makeData';
// import { useMemo } from 'react';

const VideoCarousels = ({ videos, videoId }) => {
  if (videos?.length > 0) {
    return (
      // FlickityControlsContextProvider - just for FE purposes, we can use another Context for BE-RE-FE flow
      <FlickityControlsContextProvider>
        <FlickityHorizontalVideos videos={videos} key={videoId} />
      </FlickityControlsContextProvider>
    );
  }

  return null;
};

export default VideoCarousels;
