import { Tag, TagLabel, Tooltip, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { PRIVACY_OPTIONS } from '../../../util/consts';
import { IconGlobe } from '../../../theme/icons/IconGlobe';
import { IconLock } from '../../../theme/icons/IconLock';
import { IconUsers } from '../../../theme/icons/IconUsers';

const emailAddressList = (list) => {
  return <Text noOfLines={6}>{list}</Text>;
};

function getEmailsAsString(emails) {
  const count = emails?.length ?? 0;

  if (count === 0) {
    return '';
  }

  let outString = '';
  emails.forEach((value, index) => {
    if (index === count - 1) {
      outString = outString + value.email;
    } else {
      outString = outString + value.email + ', ';
    }
  });

  return outString;
}

const PrivacyTag = ({ privacy, url, emails }) => {
  const list = getEmailsAsString(emails);
  const tagContent =
    privacy === PRIVACY_OPTIONS[0] ? (
      <>
        <IconGlobe boxSize="18px" mr="10px" />
        Public
      </>
    ) : privacy === PRIVACY_OPTIONS[1] ? (
      <>
        <IconLock boxSize="18px" mr="10px" />
        Only me
      </>
    ) : (
      <>
        <IconUsers boxSize="18px" mr="10px" />
        Specific people
      </>
    );

  return (privacy !== PRIVACY_OPTIONS[2] || !list) ? (
    <Tag as={RouterLink} to={url} variant="solidDark">
      <TagLabel>{tagContent}</TagLabel>
    </Tag>
  ) : (
    <Tooltip
      hasArrow
      arrowSize={12}
      placement="top"
      bg="primary.default"
      label={emailAddressList(list)}
      // isTruncated
      // noOfLines={2}
    >
      <Tag as={RouterLink} to={url} variant="solidDark">
        <TagLabel>{tagContent}</TagLabel>
      </Tag>
    </Tooltip>
  );
};

export default PrivacyTag;
