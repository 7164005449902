import { Text, Container } from '@chakra-ui/react';

function SectionTitle(props) {
  return (
    <Container bg="secondary.lighter" borderBottom="solid 1px #DDD" id={props.id}>
      <Container maxW="1180px" px="0">
        <Text
          textStyle="headingFamilyMedium"
          fontSize={{ base: '24px', lg: '3vw' }}
          py="3vw"
        >
          {props.title}
        </Text>
      </Container>
    </Container>
  );
}

export default SectionTitle;
