import { Icon } from '@chakra-ui/react';

export const CategoryLifeIcon = (props) => (
  <Icon viewBox="0 0 45 51" fill="none" {...props}>
    <path
      d="M25.052 41.2642C26.5139 39.7503 26.8087 37.4854 25.6417 35.7569C25.0274 34.8271 24.1675 34.0642 23.3812 33.194C27.3984 30.4285 31.8578 28.4854 36.8209 27.6033C30.1134 21.0947 23.4427 14.6219 16.7474 8.11328C15.6049 14.0616 12.9268 19.2709 9.0202 23.7887C6.44037 26.292 4.15538 29.0218 1.55098 31.5728C-0.525166 33.5993 -0.512881 36.4364 1.56327 38.4748C2.98831 39.8695 4.42565 41.2642 5.87527 42.6589C7.88999 44.6019 10.6295 44.6973 12.718 42.9212C15.1258 45.1741 17.5582 47.4748 20.0152 49.7635C20.568 50.2761 21.256 50.5264 22.0176 50.5503C23.3444 50.598 24.536 49.8946 25.0888 48.7264C25.654 47.5344 25.4205 46.1993 24.4746 45.2337C23.9218 44.6615 23.369 44.1013 22.7179 43.4457C23.4672 42.7304 24.3026 42.039 25.052 41.2642ZM12.9391 33.802C12.1406 34.6245 11.3175 35.4351 10.4575 36.2099C9.81872 36.7821 8.8605 36.6986 8.27082 36.1026C7.69343 35.5185 7.65658 34.5649 8.24625 33.9689C9.0202 33.1702 9.81872 32.3834 10.6541 31.6444C10.9244 31.4059 11.3298 31.3106 11.5263 31.2271C12.3985 31.2748 12.9022 31.5609 13.1848 32.1569C13.455 32.741 13.3936 33.3251 12.9391 33.802ZM22.7301 39.047C21.9808 39.8457 21.1454 40.5609 20.3346 41.3238C19.3027 40.3582 18.2707 39.3927 17.2879 38.4748C17.8776 37.9026 18.4673 37.3304 19.0692 36.7463C19.6221 36.2099 20.1749 35.6973 20.7154 35.1967C21.3911 35.8285 22.0053 36.3768 22.595 36.9609C23.2093 37.5927 23.3075 38.439 22.7301 39.047Z"
      fill="#616161"
    />
    <path
      d="M16.2632 3.62267C16.3246 2.43062 16.902 1.44122 18.1182 0.880952C19.3344 0.320688 20.4892 0.487575 21.558 1.27433C21.6685 1.35777 21.7791 1.45314 21.8774 1.56042C28.9903 8.46239 36.8299 16.0588 43.9428 22.9727C44.7905 23.7952 45.1713 24.7608 44.9256 25.9171C44.4465 28.2058 41.6701 29.1952 39.7782 27.7647C39.5694 27.6098 39.3728 27.419 39.1886 27.2283C32.1862 20.4336 24.4695 12.9445 17.4671 6.16173C16.7546 5.47034 16.3 4.70743 16.2632 3.62267Z"
      fill="#616161"
    />
  </Icon>
);
