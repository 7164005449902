import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Box } from '@chakra-ui/react';
import { TagsContext } from '../../context/TagsContext';
import { VideoFormContext } from '../../context/VideoFormContext';
import { CategoriesContext } from '../../context/CategoriesContext';
import { AuthContext } from '../../context/AuthContext';

import FormEditVideo from '../../components/videos/FormEditVideo';

const EditVideoPage = () => {
  const navigate = useNavigate();
  const { getAuthUser } = useContext(AuthContext);
  const videoFormCtx = useContext(VideoFormContext);
  const { state: categoriesState } = useContext(CategoriesContext);
  const { state: tagsState, updateQuery } = useContext(TagsContext);
  const { slug } = getAuthUser();

  const { items: tagsOptions } = tagsState;
  const { items: categories, status: statusCategories } = categoriesState;
  const { state } = videoFormCtx;
  const { status, error } = state;

  const isLoadedCategories = statusCategories === 'resolved';

  if (!isLoadedCategories) {
    return null;
  }

  if (error) {
    return <h1>{error}</h1>;
  }

  if (status === 'pending') {
    return null;
    // <h1>Loading...</h1>
  }

  if (status === 'idle') {
    return null;
    // <h1>Idle...</h1>
  }

  const canEdit = state.owner.id === getAuthUser().id ?? false;

  if (!canEdit) {
    navigate(`/channel/${slug}`, { replace: true });
  }

  return (
    <Container maxW="832px" mt={{ base: '120px', md: '140px' }} mb={10}>
      <Box bg="white" borderRadius="20px" p={{ base: '32px 16px', md: '60px 100px' }}>
        <FormEditVideo
          video={state}
          tagsOptions={tagsOptions}
          categories={categories}
          updateTagQuery={updateQuery}
        />
      </Box>
    </Container>
  );
};

export default EditVideoPage;
