import { useCallback, useContext, useMemo, useRef } from 'react';
import MyChannelContext from '../../context/MyChannelContext';
import { HttpContext } from '../../context/HttpContext';
import SearchCustomTable from './SearchCustomTable';
import { getDefaultDuration, getImageUrlFromUser } from '../../util';

function SearchVideosList({ queryString}) {
  const { state, updateState } = useContext(MyChannelContext);
  const { publicAxios:  axiosInstance } = useContext(HttpContext);
  const { status, items, total, pageCount } = state;

  const fetchIdRef = useRef(0);

  const columns = useMemo(
    () => [
      {
        Header: 'title',
        accessor: 'title',
      },
    ],
    // eslint-disable-next-line
    []
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;
      updateState({
        ...state,
        loading: true,
        status: 'pending',
      });

      // We'll even set a delay to simulate a server here
      setTimeout(async () => {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          try {
            const { data } = await axiosInstance.get(
              `/search_results?page=${pageIndex + 1}&itemsPerPage=${pageSize}${queryString}`,
              {
                headers: { Accept: 'application/ld+json' },
              }
            );

            const items = data['hydra:member'];
            const total = data['hydra:totalItems'];

            const pageCount = Math.ceil(total / pageSize);
            const hasMore =
              pageCount === -1 ? pageIndex >= pageSize : pageIndex < pageCount - 1;

            updateState({
              ...state,
              items,
              total,
              pageCount,
              hasMore,
              loading: false,
              status: 'resolved',
            });
          } catch (onError) {
            console.log(onError);
            updateState({
              ...state,
              items: [],
              total: 0,
              pageCount: 0,
              loading: false,
              error: 'Something went wrong',
              status: 'rejected',
            });
          }
        }
      }, 0);
    },
    [axiosInstance, queryString]
  );

  function normalizedData(data) {
    if (data?.length > 0) {
      // return [];
      return data.map((item) => {
        return {
          ...item,
          poster:
            item.upload?.thumb ??
            'https://placeimg.com/720/405/' + Math.floor(Math.random() * 100),
          firstName: item.owner?.firstName,
          lastName: item.owner?.lastName,
          avatar: getImageUrlFromUser(item.owner),
          duration: getDefaultDuration(item.upload?.duration),
          status:
            item.upload?.transcodingStatus === 'complete' ? 'uploaded' : 'processing',
          exampleForNestedObject: {
            singlePageUrl: '/videos/' + item.slug,
          },
        };
      });
    }

    return [];
  }

  const data = useMemo(() => normalizedData(items), [items]);
  // console.log(data);

  return (
    <SearchCustomTable
      columns={columns}
      data={data}
      isLoading={status === 'pending'}
      fetchData={fetchData}
      pageCount={pageCount}
      total={total}
    />
  );
}

export default SearchVideosList;
