import { Container, Text, Grid, GridItem } from '@chakra-ui/react';

import { IconBronzeMedal } from '../../theme/icons/IconBronzeMedal';
import { IconSilverMedal } from '../../theme/icons/IconSilverMedal';
import { IconGoldMedal } from '../../theme/icons/IconGoldMedal';
const SectionCourses = () => {
  return (
    <Container bg="white">
      <Container maxW="1562px" py={0} pb={{ base: '40px', '2xl': '120px' }}>
        <Text
          as="h2"
          textStyle="sectionHeadline"
          textAlign="center"
          pb={{ base: '24px', md: '48px', '2xl': '120px' }}
        >
          The storyvault.com courses
        </Text>
        <Grid templateColumns={{ base: null, md: 'repeat(3, 1fr)' }} gap="48px">
          <GridItem textAlign="center">
            <IconBronzeMedal />
            <Text as="h3" textAlign="center" fontSize="22px" fontWeight="500" my="24px">
              Bronze medal
            </Text>
            <Text fontSize="18px" textAlign="center" mx="auto" my="0" maxWidth="320px">
              Participants for the BRONZE MEDAL will be required to record and upload
              THREE videos onto Storyvault
            </Text>
          </GridItem>
          <GridItem textAlign="center">
            <IconSilverMedal />
            <Text as="h3" textAlign="center" fontSize="22px" fontWeight="500" my="24px">
              Silver medal
            </Text>
            <Text fontSize="18px" textAlign="center" mx="auto" my="0" maxWidth="320px">
              SILVER MEDAL participants will be required to record and upload SIX videos,
              and to demonstrate development in several aspects of digital storytelling
            </Text>
          </GridItem>
          <GridItem textAlign="center">
            <IconGoldMedal />
            <Text as="h3" textAlign="center" fontSize="22px" fontWeight="500" my="24px">
              Gold medal
            </Text>
            <Text fontSize="18px" textAlign="center" mx="auto" my="0" maxWidth="320px">
              GOLD MEDAL participants will be required to record and upload TWELVE videos,
              and to show further progression in interviewing techniques, video
              production, and storytelling
            </Text>
          </GridItem>
        </Grid>
      </Container>
    </Container>
  );
};

export default SectionCourses;
