import { Icon } from '@chakra-ui/react';

export const CategoryWarIcon = (props) => (
  <Icon viewBox="0 0 67 51" fill="none" {...props}>
    <path
      d="M0.0309256 33.9671C0.463882 32.8339 0.726748 31.5935 1.34526 30.5674C2.93792 27.8722 5.42742 26.5093 8.56635 26.3561C9.958 26.2949 11.3651 26.3255 12.7568 26.3408C13.1897 26.3408 13.468 26.2183 13.7464 25.8814C15.2617 24.0131 16.808 22.1601 18.3079 20.2919C18.6017 19.9396 18.88 19.7865 19.3439 19.7865C22.3127 19.8018 25.2816 19.8018 28.2349 19.8018C28.4669 19.8018 28.7143 19.8018 29.0545 19.8018C29.0545 19.4649 29.0235 19.1586 29.0545 18.883C29.1472 17.9182 29.1936 16.9534 29.3946 16.004C29.5029 15.468 29.3792 15.2995 28.9308 15.0852C21.2613 11.5323 13.5917 7.97954 5.92223 4.41142C5.69028 4.30422 5.45834 4.19703 5.14909 4.04389C5.69028 2.89535 6.20055 1.79276 6.75721 0.628906C7.02008 0.736103 7.25202 0.827985 7.4685 0.919868C15.4009 4.59519 23.3178 8.2705 31.2502 11.9458C31.5749 12.099 31.8378 12.2368 32.1934 11.9458C33.7087 10.7513 35.5024 10.3532 37.3889 10.3685C38.0383 10.3685 38.6877 10.3685 39.4145 10.3685C39.4145 9.09745 39.4145 7.87234 39.4145 6.6013C40.7288 6.6013 41.9813 6.6013 43.2956 6.6013C43.2956 7.84172 43.2956 9.06682 43.2956 10.3685C44.2234 10.3685 45.0893 10.3532 45.9552 10.3685C49.8673 10.4298 53.0217 12.9718 53.7175 16.6931C53.9031 17.6579 53.8721 18.6686 53.9495 19.7406C55.1246 19.7406 56.3307 19.7406 57.6141 19.7406C57.6141 21.9457 57.6141 24.0744 57.6141 26.2796C60.8304 26.2796 63.459 27.3975 65.3455 29.9702C66.4279 31.4403 66.9536 33.0942 67 34.9778C65.7166 34.9778 64.4641 34.9778 63.258 34.9778C63.057 34.2887 62.9642 33.5843 62.6704 32.987C61.7427 31.0575 60.1191 30.1693 58.0007 30.1386C57.6451 30.1386 57.3049 30.1386 56.9492 30.1386C41.0999 30.1386 25.2352 30.1386 9.38588 30.1386C7.5922 30.1386 5.98408 30.5674 4.91715 32.0835C4.4378 32.7726 4.23679 33.6302 3.91207 34.4265C3.85022 34.595 3.85022 34.794 3.81929 35.0084C2.52043 35.0084 1.25248 35.0084 0 35.0084C0.0309255 34.6562 0.0309256 34.3193 0.0309256 33.9671Z"
      fill="#457A41"
    />
    <path
      d="M33.4545 50.6074C27.022 50.6074 20.5895 50.6687 14.157 50.5921C9.78105 50.5462 6.30194 47.2078 5.76074 42.7515C5.26594 38.7239 8.06469 34.6504 12.1314 33.5632C12.9664 33.3488 13.8478 33.2416 14.7137 33.2263C27.2076 33.2109 39.7014 33.2109 52.1953 33.2109C57.3444 33.2109 61.3338 37.2078 61.1792 42.1542C61.04 46.7637 57.3444 50.5156 52.6901 50.5768C46.2731 50.6687 39.8561 50.6074 33.4545 50.6074ZM29.8826 44.39C31.2588 44.39 32.3876 43.2721 32.3876 41.9245C32.3876 40.5769 31.2433 39.459 29.8826 39.459C28.5064 39.459 27.4086 40.5616 27.4086 41.9398C27.3931 43.3027 28.491 44.39 29.8826 44.39ZM39.5004 41.9398C39.5004 40.5616 38.4026 39.459 37.0264 39.459C35.6657 39.459 34.5214 40.5769 34.5214 41.9245C34.5214 43.2721 35.6502 44.39 37.0109 44.39C38.4026 44.39 39.5004 43.3181 39.5004 41.9398ZM25.2129 41.9092C25.2129 40.5463 24.0995 39.4437 22.7234 39.4437C21.3626 39.4437 20.2339 40.5769 20.2339 41.9245C20.2339 43.2874 21.3626 44.3747 22.7388 44.3747C24.115 44.3747 25.2129 43.2874 25.2129 41.9092ZM44.1392 44.39C45.5154 44.4053 46.6442 43.3027 46.6597 41.9551C46.6751 40.6075 45.5463 39.4743 44.1856 39.459C42.8094 39.4437 41.6807 40.5463 41.6807 41.9092C41.6652 43.2721 42.7631 44.3747 44.1392 44.39ZM15.5487 44.39C16.9403 44.4053 18.0536 43.3334 18.0845 41.9704C18.1155 40.6228 17.0021 39.4743 15.626 39.459C14.2498 39.4437 13.121 40.5309 13.0901 41.8939C13.0592 43.2415 14.1725 44.3747 15.5487 44.39ZM51.2676 44.39C52.6592 44.4053 53.7725 43.3487 53.8034 41.9704C53.8344 40.6228 52.721 39.4743 51.3603 39.459C49.9841 39.4284 48.8554 40.5156 48.8244 41.8786C48.7781 43.2415 49.8914 44.3594 51.2676 44.39Z"
      fill="#457A41"
    />
  </Icon>
);
