import { Icon } from '@chakra-ui/react';

export const EmailIcon = (props) => (
  <Icon viewBox="0 0 23 23" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9102 8.6792C10.2533 8.6792 8.91016 10.0223 8.91016 11.6792C8.91016 13.3361 10.2533 14.6792 11.9102 14.6792C13.567 14.6792 14.9102 13.3361 14.9102 11.6792C14.9102 10.0223 13.567 8.6792 11.9102 8.6792ZM6.91016 11.6792C6.91016 8.91778 9.14873 6.6792 11.9102 6.6792C14.6716 6.6792 16.9102 8.91778 16.9102 11.6792C16.9102 14.4406 14.6716 16.6792 11.9102 16.6792C9.14873 16.6792 6.91016 14.4406 6.91016 11.6792Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.21025 1.73431C9.45489 0.673551 11.9924 0.403312 14.4101 0.967532C16.8278 1.53175 18.9835 2.89725 20.5268 4.84199C22.07 6.78673 22.91 9.19634 22.9102 11.679V12.6791C22.9102 13.7399 22.4887 14.7573 21.7386 15.5075C20.9885 16.2576 19.971 16.6791 18.9102 16.6791C17.8493 16.6791 16.8319 16.2576 16.0817 15.5075C15.3316 14.7573 14.9102 13.7399 14.9102 12.6791V7.67907C14.9102 7.12678 15.3579 6.67907 15.9102 6.67907C16.4625 6.67907 16.9102 7.12678 16.9102 7.67907V12.6791C16.9102 13.2095 17.1209 13.7182 17.496 14.0933C17.871 14.4684 18.3797 14.6791 18.9102 14.6791C19.4406 14.6791 19.9493 14.4684 20.3244 14.0933C20.6995 13.7182 20.9102 13.2095 20.9102 12.6791V11.6791C20.9102 11.6791 20.9102 11.6792 20.9102 11.6791C20.91 9.64788 20.2228 7.67634 18.9601 6.08521C17.6975 4.49406 15.9337 3.37683 13.9555 2.9152C11.9774 2.45356 9.90131 2.67467 8.06478 3.54256C6.22825 4.41045 4.73932 5.87409 3.8401 7.69548C2.94087 9.51687 2.68424 11.5889 3.11192 13.5746C3.5396 15.5604 4.62645 17.343 6.19573 18.6328C7.76502 19.9225 9.72446 20.6434 11.7554 20.6784C13.7864 20.7133 15.7695 20.0601 17.3822 18.8251C17.8206 18.4893 18.4483 18.5726 18.7841 19.0111C19.1199 19.4495 19.0366 20.0772 18.5982 20.413C16.6271 21.9225 14.2033 22.7208 11.721 22.6781C9.23874 22.6354 6.84388 21.7542 4.92586 20.1779C3.00784 18.6016 1.67947 16.4227 1.15675 13.9957C0.634029 11.5687 0.947696 9.03625 2.04675 6.8101C3.1458 4.58396 4.9656 2.79507 7.21025 1.73431Z"
      fill="currentColor"
    />
  </Icon>
);
