import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Box,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  VStack,
  SimpleGrid,
  GridItem,
  InputGroup,
  InputLeftElement,
  Flex,
  Spacer,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { NameIcon } from '../../assets/icons/NameIcon';
import { TextareaAutosize } from '../common/TextareaAutosize';
import { TEXTAREA_LENGTH_MD } from '../../util/consts';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { HttpContext } from '../../context/HttpContext';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const EditPersonalInfoModal = ({ user, isOpen, onClose }) => {
  const { authAxios } = useContext(HttpContext);
  const { setAuthUser } = useContext(AuthContext);
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  const schema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
  });

  const formOptions = {
    resolver: yupResolver(schema),
    mode: 'onChange',
  };

  const { register, handleSubmit, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const watchBio = watch('biography');
  let bioLength = watchBio?.length ?? user.biography?.length ?? 0;

  const submitForm = async (data) => {
    try {
      const response = await authAxios.patch(`/users/${user.slug}`, data, {
        headers: { 'Content-Type': 'application/merge-patch+json' },
      });

      setAuthUser(response.data);

      onClose();
    } catch (onErr) {
      console.log(onErr);
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(submitForm)}>
          <ModalHeader>
            <Heading size="lg" color="primary.default">
              Edit personal info
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing="40px" mt={4} mb={2}>
              <SimpleGrid w="full" maxW="600px" columns={2} columnGap={5} rowGap={10}>
                <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                  <FormControl isInvalid={errors.firstName}>
                    <FormLabel htmlFor="firstName" textStyle="formLabels">
                      First name
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="firstName"
                        type="text"
                        name="firstName"
                        placeholder="John"
                        defaultValue={user.firstName}
                        {...register('firstName')}
                      />
                      <InputLeftElement
                        pointerEvents="none"
                        color={errors.firstName ? 'error.default' : 'primary.light'}
                        fontSize="22px"
                        children={<NameIcon />}
                      />
                    </InputGroup>
                    <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                  <FormControl isInvalid={errors.lastName}>
                    <FormLabel htmlFor="lastName" textStyle="formLabels">
                      Last name
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color={errors.lastName ? 'error.default' : 'primary.light'}
                        fontSize="22px"
                        children={<NameIcon />}
                      />
                      <Input
                        id="lastName"
                        type="text"
                        name="lastName"
                        placeholder="Doe"
                        defaultValue={user.lastName}
                        {...register('lastName')}
                      />
                    </InputGroup>
                    <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              </SimpleGrid>
              <Box maxW="600px" w="full">
                <FormControl isInvalid={errors.biography}>
                  <FormLabel htmlFor="category" textStyle="formLabels">
                    My biography{' '}
                    <Text as="span" color="primary.light">
                      (optional)
                    </Text>
                  </FormLabel>
                  <TextareaAutosize
                    defaultValue={user.biography}
                    id="biography"
                    type="text"
                    placeholder="Tell more about yourself"
                    maxLength={TEXTAREA_LENGTH_MD}
                    {...register('biography')}
                  />
                  <Flex justifyContent="space-between">
                    <FormErrorMessage>{errors.biography?.message}</FormErrorMessage>
                    <Spacer />
                    <FormHelperText
                      color={
                        TEXTAREA_LENGTH_MD >= bioLength
                          ? 'primary.light'
                          : 'error.default'
                      }
                      mx={5}
                      flexShrink={0}
                    >
                      {bioLength} / {TEXTAREA_LENGTH_MD}
                    </FormHelperText>
                  </Flex>
                </FormControl>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Box maxW="600px" w="full">
              <Button isFullWidth type="submit" isLoading={isSubmitting}>
                Save
              </Button>
            </Box>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default EditPersonalInfoModal;
