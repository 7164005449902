import { useContext } from 'react';
import { Box, Grid, GridItem, Spinner, useMediaQuery } from '@chakra-ui/react';
import CategoryGenericItem from '../../components/categories/CategoryGenericItem';
import { CategoriesContext } from '../../context/CategoriesContext';
import { AuthContext } from '../../context/AuthContext';

const PickCategories = () => {
  const { state } = useContext(CategoriesContext);
  const { getAuthUserCategories } = useContext(AuthContext);
  const pickedCategories = getAuthUserCategories();
  console.log(pickedCategories);
  const { items, status } = state;
  const loading = status === 'pending';

  const [isLargerThanXS] = useMediaQuery('(min-width: 14em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  const templateRender = () => {
    if (isLargerThanMD) {
      return 'repeat(4, 1fr)';
    }
    if (isLargerThanSM) {
      return 'repeat(3, 1fr)';
    }
    if (isLargerThanXS) {
      return 'repeat(2, 1fr)';
    }
  };

  return (
    <Grid templateColumns={templateRender} gap={5} fontWeight="500" fontSize="14px">
      {items.length > 0 ? (
        items.map((item) => {
          const picked = pickedCategories.some(
            (el) => parseInt(el.id, 10) === parseInt(item.id, 10)
          );
          return (
            <GridItem key={item.id} w="100%">
              <CategoryGenericItem iconName={item.name} catId={item.id} picked={picked} />
            </GridItem>
          );
        })
      ) : loading ? (
        <GridItem colSpan={6} rowSpan={6}>
          <Box textAlign="center">
            <Spinner size="xl" />
          </Box>
        </GridItem>
      ) : null}
    </Grid>
  );
};

export default PickCategories;
