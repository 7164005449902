import { VStack, Image, Text, Box, Avatar, Flex, Link } from '@chakra-ui/react';
import { AuthContext } from '../../context/AuthContext';
import CommentsContext from '../../context/CommentsContext';
import { useContext } from 'react';
import exclamationMarkSrc from '../../assets/vectors/exclamationMark.svg';
import { Link as RouterLink, useParams } from 'react-router-dom';
import plusSrc from '../../assets/vectors/plus.svg';
import { IconAvatar } from '../../theme/icons/IconAvatar';
import LeaveCommentForm from './LeaveCommentForm';
import { COMMENT_NEW } from '../../util/consts';
import { getImageUrlFromUser, isEmptyObject } from '../../util';

const LeaveComment = () => {
  const { slug } = useParams();
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();
  const commentsContextCtx = useContext(CommentsContext);
  const myAvatarSrc = getImageUrlFromUser(user);

  return (
    <>
      {!isEmptyObject(user) ? (
        <>
          {commentsContextCtx.totalComments === 0 && (
            <VStack spacing="30px" mt={10}>
              <Image src={plusSrc} />
              <Text
                color="primary.dark"
                textStyle="formLabels"
                lineHeight="150%"
                textAlign="center"
              >
                No comments yet. Be the first to comment
              </Text>
            </VStack>
          )}
          <Flex mt={10}>
            <Avatar
              icon={
                <IconAvatar
                  fontSize={{ base: '16px', md: '22px' }}
                  color="secondary.lighter"
                />
              }
              src={myAvatarSrc}
              bg="secondary.light"
              boxSize={{ base: '30px', md: '40px' }}
            />
            <Box flex="1" ml={4}>
              <LeaveCommentForm commentFormType={COMMENT_NEW} />
            </Box>
          </Flex>
        </>
      ) : (
        <VStack spacing="30px" mt={10}>
          <Image src={exclamationMarkSrc} />
          <Text
            color="primary.dark"
            textStyle="formLabels"
            lineHeight="150%"
            textAlign="center"
          >
            You must{' '}
            <Link
              as={RouterLink}
              to={`/login?redirect=/videos/${slug}&section=leave-comment`}
              color="accent.default"
            >
              log in
            </Link>{' '}
            to leave a comment
          </Text>
        </VStack>
      )}
    </>
  );
};

export default LeaveComment;
