import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Box,
  VStack,
  SimpleGrid,
  GridItem,
  useMediaQuery,
  Text,
  Container,
  Flex,
  Spacer,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { TextareaAutosize } from '../common/TextareaAutosize';
import { TEXTAREA_LENGTH_MD, REPORT_REASONS } from '../../util/consts';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useContext } from 'react';
import { HttpContext } from '../../context/HttpContext';
import SingleVideoContext from '../../context/SingleVideoContext';

const ReportModal = ({
  onClose,
  isOpen,
  // data,
  wording,
  onShowReportThankYouModal,
  videoSlug,
}) => {
  const { authAxios } = useContext(HttpContext);
  const { reportVideo } = useContext(SingleVideoContext);
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  const schema = yup.object().shape({
    reason: yup.string().required('Please select one reason'),
  });

  const formOptions = {
    resolver: yupResolver(schema),
    mode: 'onChange',
  };

  const { register, handleSubmit, formState, watch, control } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const watchExplanation = watch('explanation');
  let explanationLength = watchExplanation?.length ?? 0;

  function prepareDataForSubmit(values, videoSlug) {
    let submittedData = {
      video: `/api/videos/${videoSlug}`,
      explanation: values.explanation,
    };

    if (values?.reason === '1') {
      submittedData = {
        ...submittedData,
        sexuallyExplicit: true,
      };
    }
    if (values?.reason === '2') {
      submittedData = {
        ...submittedData,
        hateSpeech: true,
      };
    }
    if (values?.reason === '3') {
      submittedData = {
        ...submittedData,
        violent: true,
      };
    }
    if (values?.reason === '4') {
      submittedData = {
        ...submittedData,
        disinformation: true,
      };
    }

    return submittedData;
  }

  const submitForm = async (values) => {
    try {
      let submittedData = prepareDataForSubmit(values, videoSlug);
      await authAxios({
        method: 'POST',
        url: `/report_videos`,
        data: submittedData,
        headers: {
          Accept: 'application/ld+json',
        },
      });

      reportVideo();
      onClose();
      onShowReportThankYouModal();
    } catch (onErr) {
      console.log(onErr);
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(submitForm)}>
          <ModalHeader>
            <Heading size="lg" color="primary.default">
              Report {wording}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Container p={0} maxW="600px">
              <VStack
                mt={4}
                mb={{ base: 4, md: 4 }}
                textAlign="center"
                fontSize={{ md: '20px' }}
                spacing={10}
              >
                <FormControl isInvalid={errors.reason}>
                  <FormLabel htmlFor="reason" textStyle="formLabels" mb={5}>
                    Select the main reason you're reporting this {wording}:
                  </FormLabel>
                  <Controller
                    control={control}
                    name="reason"
                    // defaultValue="4"
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <VStack spacing={5} alignItems="flex-start" textAlign="left">
                          {REPORT_REASONS.map((reason) => {
                            return (
                              <Radio key={reason.value} value={reason.value}>
                                {reason.main}
                                <Text className="label-helper">{reason.sub}</Text>
                              </Radio>
                            );
                          })}
                        </VStack>
                      </RadioGroup>
                    )}
                  />
                  <FormErrorMessage>{errors.reason?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.explanation}>
                  <FormLabel htmlFor="category" textStyle="formLabels">
                    Explain why are you reporting this {wording}
                  </FormLabel>
                  <TextareaAutosize
                    id="explanation"
                    type="text"
                    placeholder="Write your explanation here..."
                    maxLength={TEXTAREA_LENGTH_MD}
                    {...register('explanation')}
                  />
                  <Flex justifyContent="space-between">
                    <FormErrorMessage>{errors.explanation?.message}</FormErrorMessage>
                    <Spacer />
                    <FormHelperText
                      color={
                        TEXTAREA_LENGTH_MD >= explanationLength
                          ? 'primary.light'
                          : 'error.default'
                      }
                      mx={5}
                      flexShrink={0}
                    >
                      {explanationLength} / {TEXTAREA_LENGTH_MD}
                    </FormHelperText>
                  </Flex>
                </FormControl>
              </VStack>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Box maxW="600px" w="full">
              <SimpleGrid
                w="full"
                maxW="600px"
                columns={2}
                columnGap={{ md: '30px' }}
                rowGap={7}
              >
                <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                  <Button onClick={onClose} isFullWidth variant="outline">
                    Cancel
                  </Button>
                </GridItem>
                <GridItem colSpan={isLargerThanMD ? '1' : '2'}>
                  <Button isFullWidth type="submit" isLoading={isSubmitting}>
                    Report
                  </Button>
                </GridItem>
              </SimpleGrid>
            </Box>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ReportModal;
