import React from 'react';
import { Box, VStack, Text, Circle } from '@chakra-ui/react';
import { CategoryEarliestIcon } from '../../assets/icons/CategoryEarliestIcon';
import { CategoryHolidaysIcon } from '../../assets/icons/CategoryHolidaysIcon';
import { CategoryLuckIcon } from '../../assets/icons/CategoryLuckIcon';
import { CategoryFamousIcon } from '../../assets/icons/CategoryFamousIcon';
import { CategoryMusicIcon } from '../../assets/icons/CategoryMusicIcon';
import { CategoryMovieIcon } from '../../assets/icons/CategoryMovieIcon';
import { CategoryHeartIcon } from '../../assets/icons/CategoryHeartIcon';
import { CategoryWorkIcon } from '../../assets/icons/CategoryWorkIcon';
import { CategoryFoodIcon } from '../../assets/icons/CategoryFoodIcon';
import { CategorySportsIcon } from '../../assets/icons/CategorySportsIcon';
import { CategoryWarIcon } from '../../assets/icons/CategoryWarIcon';
import { CategoryNarrowIcon } from '../../assets/icons/CategoryNarrowIcon';
import { CategoryChanceIcon } from '../../assets/icons/CategoryChanceIcon';
import { CategoryDeathIcon } from '../../assets/icons/CategoryDeathIcon';
import { CategoryGhostIcon } from '../../assets/icons/CategoryGhostIcon';
import { CategoryLifeIcon } from '../../assets/icons/CategoryLifeIcon';

import { useState, useContext } from 'react';
import { HttpContext } from '../../context/HttpContext';
import { AuthContext } from '../../context/AuthContext';

const CategoryGenericIcon = (props) => {
  const { updateAuthUserCategory } = useContext(AuthContext);
  const EARLIEST_MEMORIES = 'Earliest memories';
  const HOLIDAY_AND_TRAVEL = 'Holiday and travel';
  const GOOD_LUCK = 'Good luck';
  const MEETING_FAMOUS_PEOPLE = 'Meeting famous people';
  const MUSIC = 'Music';
  const ARTS_AND_ENTERTAINMENT = 'Arts and Entertainment';
  const FRIENDS_FAMILY_AND_LOVE = 'Friends, Family and Love';
  const WORK_AND_BUSINESS = 'Work and business';
  const FOOD_AND_RECIPES = 'Food and recipes';
  const SPORTS_ANG_GAMES = 'Sport and games';
  const WARTIME_EXPERIENCES = 'Wartime experiences';
  const NARROW_ESCAPES = 'Narrow escapes';
  const CHANCE_AND_COINCIDENCE = 'Chance and coincidence';
  const NEAR_DEATH_EXPERIENCE = 'Near death experiences';
  const GHOST_STORIES = 'Ghost stories';
  const LIFE_STORIES = 'Life stories';

  const [clickCat, setClickCat] = useState(props.picked);
  const { authAxios } = useContext(HttpContext);

  const pickCatHandler = async () => {
    setClickCat((prevState) => !prevState);
    const id = props.catId;
    if (!clickCat) {
      // mark
      try {
        const response = await authAxios.patch(
          `/categories/${id}/mark`,
          {
            id: id,
          },
          {
            headers: { 'Content-Type': 'application/merge-patch+json' },
          }
        );
        updateAuthUserCategory(response.data, 'add');
      } catch (error) {
        console.log(error);
      }
    }
    if (clickCat) {
      // unmark
      try {
        const response = await authAxios.patch(
          `/categories/${id}/unmark`,
          {
            id: id,
          },
          {
            headers: { 'Content-Type': 'application/merge-patch+json' },
          }
        );
        updateAuthUserCategory(response.data, 'remove');
      } catch (error) {
        console.log(error);
      }
    }
  };

  const renderSingleIcon = (IconComponent, bg, nameWidth) => {
    return (
      <VStack align="center">
        <Circle
          onClick={pickCatHandler}
          className="categoryCircle"
          bg={clickCat && bg}
          // size="80px" // In case we need to override global CustomTheme css class
        >
          <IconComponent
            width="auto"
            height="50px"
            className={!clickCat && 'categoryNotSelected'}
          />
        </Circle>
        <Box textAlign="center" maxW="120px" wordBreak="break-word">
          <Text textStyle="bodyMedium">{props.icon}</Text>
        </Box>
      </VStack>
    );
  };

  const renderIcons = (name) => {
    switch (name) {
      case EARLIEST_MEMORIES:
        return renderSingleIcon(CategoryEarliestIcon, '#E5F4FF');
      case HOLIDAY_AND_TRAVEL:
        return renderSingleIcon(CategoryHolidaysIcon, '#E5F4FF');
      case GOOD_LUCK:
        return renderSingleIcon(CategoryLuckIcon, '#DBF4CD');
      case MEETING_FAMOUS_PEOPLE:
        return renderSingleIcon(CategoryFamousIcon, '#FFE8E8');
      case MUSIC:
        return renderSingleIcon(CategoryMusicIcon, '#FFE8E8');
      case ARTS_AND_ENTERTAINMENT:
        return renderSingleIcon(CategoryMovieIcon, '#E5F4FF');
      case FRIENDS_FAMILY_AND_LOVE:
        return renderSingleIcon(CategoryHeartIcon, '#FFE8E8');
      case WORK_AND_BUSINESS:
        return renderSingleIcon(CategoryWorkIcon, '#F1E8DE');
      case FOOD_AND_RECIPES:
        return renderSingleIcon(CategoryFoodIcon, '#FFEDCC');
      case SPORTS_ANG_GAMES:
        return renderSingleIcon(CategorySportsIcon, '#FFEDCC');
      case WARTIME_EXPERIENCES:
        return renderSingleIcon(CategoryWarIcon, '#DBF4CD');
      case NARROW_ESCAPES:
        return renderSingleIcon(CategoryNarrowIcon, '#FFEDCC');
      case CHANCE_AND_COINCIDENCE:
        return renderSingleIcon(CategoryChanceIcon, '#E5F4FF');
      case NEAR_DEATH_EXPERIENCE:
        return renderSingleIcon(CategoryDeathIcon, '#FFEDCC');
      case GHOST_STORIES:
        return renderSingleIcon(CategoryGhostIcon, '#F1E8DE');
      case LIFE_STORIES:
        return renderSingleIcon(CategoryLifeIcon, '#F1E8DE');
      default:
        return null;
      // return 'no icon to display';
    }
  };
  return <div>{renderIcons(props.icon)}</div>;
};

export default CategoryGenericIcon;
