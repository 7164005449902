import SocialMediaShareBox from './SocialMediaShareBox';

const SocialMediaShareBoxes = ({video}) => {
  return (
    <>
      <SocialMediaShareBox media="LinkedIn" video={video} />
      <SocialMediaShareBox media="Facebook" video={video} />
      <SocialMediaShareBox media="Twitter" video={video} />
    </>
  );
};

export default SocialMediaShareBoxes;
