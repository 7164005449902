import {
  Center,
  VStack,
  Box,
  Image,
  Text,
  Heading,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PadlockSrc from '../assets/vectors/padlock.svg';
import PadlockApprovedSrc from '../assets/vectors/padlockApproved.svg';
import PadlockDeclinedSrc from '../assets/vectors/padlockDeclined.svg';
import elipseSrc from '../assets/img/elipse.svg';
import DotsSrc from '../assets/vectors/9dots.svg';
import { getUrlSearchParams } from '../util';
import { HttpContext } from '../context/HttpContext';
import CustomToast from '../components/UI/CustomToast';

const RequestAccessPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { publicAxios } = useContext(HttpContext);
  let query = getUrlSearchParams();
  const id = query.get('id');
  const confirmToken = query.get('confirmToken');

  const requestApproved = pathname.includes('/request-confirm');
  const requestDeclined = pathname.includes('/request-decline');

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);

        const url = requestApproved
          ? `/access_requests/${id}/confirm`
          : requestDeclined
          ? `/access_requests/${id}/decline`
          : null;

        await publicAxios({
          method: 'PATCH',
          url,
          data: {
            confirmToken: confirmToken,
          },
          headers: { 'Content-Type': 'application/merge-patch+json' },
        });

        setIsLoading(false);
      } catch (onErr) {
        console.log(onErr);
        setIsLoading(false);
        toast({
          position: 'top',
          render: (props) => (
            <CustomToast
              status="error"
              description={`Something went wrong.\nThe data you have provided is not valid.`}
              onClose={props.onClose}
              id={props.id}
            />
          ),
        });

        navigate(`/home`, { replace: true });
      }
    };

    getData();
  }, [confirmToken, id, navigate, publicAxios, requestApproved, requestDeclined, toast]);

  return (
    <Center minH="94vh" pt={10} pb={{ base: '40px', lg: '0' }}>
      <Box maxW="full" pos="relative" pb={{ lg: '120px' }}>
        <Box
          textAlign="center"
          position="relative"
          zIndex="1"
          bgColor="white"
          pt={{ base: '48px', '2xl': '100px' }}
          pb={{ base: '64px', '2xl': '90px' }}
          px="16px"
          borderRadius="20px"
          w="658px"
          maxW="full"
        >
          <VStack spacing="10px">
            <Box pos="relative" py="50px" w="full" textAlign="center">
              <Image
                src={DotsSrc}
                // display={{ base: 'none', sm: 'block' }}
                pos="absolute"
                top="0px"
                left={{ base: 0, md: '104px' }}
              />
              <Image
                src={DotsSrc}
                // display={{ base: 'none', sm: 'block' }}
                pos="absolute"
                bottom="0px"
                right={{ base: 0, md: '104px' }}
                transform="rotate(180deg)"
              />
              <Box display="inline-block" verticalAlign="top">
                {isLoading ? (
                  <Image src={PadlockSrc} />
                ) : requestApproved ? (
                  <Image src={PadlockApprovedSrc} />
                ) : (
                  <Image src={PadlockDeclinedSrc} />
                )}
              </Box>
            </Box>

            <Heading size="lg" color="primary.default" pt="30px">
              {isLoading
                ? 'This will only take a moment'
                : requestApproved
                ? 'You approved this request'
                : 'You declined this request'}
            </Heading>
            <Box maxW="500px" w="full">
              <Text fontSize="20px" lineHeight={{ md: '40px' }} textStyle="bodyMedium">
                {isLoading
                  ? 'Please wait while we are processing your action'
                  : requestApproved
                  ? 'The person who asked for permission to watch your video will receive an email notification.'
                  : 'The person who asked for permission to watch your video will receive an email notification.'}
              </Text>
            </Box>
          </VStack>
          {isLoading && (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="elipse"
              color="accent.active"
              boxSize="60px"
              mt="50px"
            />
          )}
        </Box>
        <Image
          src={elipseSrc}
          display={{ base: 'none', lg: 'block' }}
          pos="absolute"
          bottom="20px"
          right="-100px"
          w="400px"
        />
      </Box>
    </Center>
  );
};

export default RequestAccessPage;
