import React, { useContext, useEffect } from 'react';
import { HttpContext } from '../context/HttpContext';
import {
  // useNavigate,
  useParams,
} from 'react-router-dom';
// import { AuthContext } from '../context/AuthContext';
import UserContext from '../context/UserContext';
import { AuthContext } from '../context/AuthContext';

function UseUser({ children }) {
  // const navigate = useNavigate();
  const { slug } = useParams();

  const { authAxios: axiosInstance, publicAxios } = useContext(HttpContext);
  const { updateState } = useContext(UserContext);
  const { isAuthenticated } = useContext(AuthContext);
  // const { slug: loggedUserSlug } = getAuthUser();

  useEffect(() => {
    const getData = async () => {
      try {
        if (isAuthenticated()) {
          const { data } = await axiosInstance.get(`/users/${slug}`);
          const { data: channelData } = await axiosInstance.get(`/users/${slug}/channel`);
          updateState({
            data: { ...data, channelData },
            status: 'resolved',
            error: null,
          });
        } else {
          const { data: channelData } = await publicAxios.get(`/users/${slug}/channel`);
          updateState({
            data: { channelData },
            status: 'resolved',
            error: null,
          });
        }
      } catch (onError) {
        console.log(onError);
        updateState({
          data: null,
          status: 'rejected',
          error: 'Something went wrong',
        });
        // navigate(`/channel/${loggedUserSlug}`, { replace: true });
      }
    };

    getData();
  }, [slug, isAuthenticated]);

  return <>{children}</>;
}

export default UseUser;
