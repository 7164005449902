import { Icon } from '@chakra-ui/react';

export const IconBigNext = (props) => (
  <Icon viewBox="0 0 23 43" {...props}>
    <path
      fill="currentColor"
      d="M23 21.555c0 .397-.064.761-.193 1.092-.129.331-.354.629-.676.894L3.778 42.404c-.386.397-.902.596-1.546.596s-1.159-.199-1.545-.596c-.515-.53-.74-1.075-.676-1.638.064-.562.29-1.075.676-1.539L17.88 21.555.687 3.883C.3 3.486.09 2.956.059 2.295.027 1.633.236 1.103.687.706c.45-.53.982-.761 1.594-.695a2.81 2.81 0 0 1 1.593.695l9.129 9.432 9.128 9.432c.322.264.547.562.676.893.129.331.193.695.193 1.092Z"
    />
  </Icon>
);
