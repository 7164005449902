import { Icon } from '@chakra-ui/react';

export const IconTriangle = (props) => (
  <Icon viewBox="0 0 46 41" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.247 0.931792C21.0874 0.458648 22.0356 0.210083 23 0.210083C23.9644 0.210083 24.9126 0.458648 25.753 0.931792C26.5933 1.40494 27.2976 2.08669 27.7978 2.91128L27.8022 2.91854L45.098 31.7926C45.588 32.6411 45.8471 33.6033 45.8498 34.5831C45.8526 35.5629 45.5987 36.5263 45.1135 37.3776C44.6283 38.2288 43.9286 38.9382 43.0842 39.4351C42.2397 39.932 41.2798 40.1991 40.3001 40.2099L40.2832 40.2101L5.69992 40.21C4.72015 40.1992 3.76029 39.932 2.91582 39.4351C2.07135 38.9382 1.3717 38.2288 0.886496 37.3776C0.401286 36.5263 0.147425 35.5629 0.150168 34.5831C0.152912 33.6033 0.412165 32.6412 0.902133 31.7927L0.914396 31.7715L18.1978 2.91856L19.5107 3.70498L18.2022 2.91128C18.7024 2.08669 19.4066 1.40494 20.247 0.931792ZM20.8211 4.49549C20.8205 4.49655 20.8198 4.49762 20.8192 4.49869L3.54733 33.3327C3.32812 33.7161 3.21218 34.1499 3.21095 34.5916C3.2097 35.037 3.32509 35.4749 3.54564 35.8619C3.76619 36.2488 4.08421 36.5712 4.46806 36.7971C4.84976 37.0217 5.28333 37.143 5.72606 37.1492H40.2739C40.7167 37.143 41.1502 37.0217 41.5319 36.7971C41.9158 36.5712 42.2338 36.2488 42.4543 35.8619C42.6749 35.4749 42.7903 35.037 42.789 34.5916C42.7878 34.1499 42.6719 33.7161 42.4527 33.3327L25.1808 4.49869C25.1802 4.49762 25.1795 4.49655 25.1789 4.49549C24.9516 4.12211 24.6322 3.81337 24.2513 3.59892C23.8693 3.38386 23.4384 3.27087 23 3.27087C22.5616 3.27087 22.1306 3.38386 21.7486 3.59892C21.3677 3.81337 21.0484 4.12211 20.8211 4.49549Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9999 12.6628C23.8452 12.6628 24.5303 13.348 24.5303 14.1932V22.3553C24.5303 23.2005 23.8452 23.8857 22.9999 23.8857C22.1547 23.8857 21.4695 23.2005 21.4695 22.3553V14.1932C21.4695 13.348 22.1547 12.6628 22.9999 12.6628Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9594 30.5174C20.9594 29.3904 21.873 28.4768 22.9999 28.4768H23.0203C24.1473 28.4768 25.0609 29.3904 25.0609 30.5174C25.0609 31.6443 24.1473 32.5579 23.0203 32.5579H22.9999C21.873 32.5579 20.9594 31.6443 20.9594 30.5174Z"
    />
  </Icon>
);
