import { extendTheme, withDefaultSize, withDefaultVariant } from '@chakra-ui/react';

const stylesControls = {
  // height: '50px',
  // fontSize: '14px',
  // lineHeight: '50px',
  // backgroundColor: 'rgba(221, 221, 221, 0.5)',
  padding: '0 22px',
  borderRadius: '25px',
  border: '1px solid',
  borderColor: 'primary.light',
  color: 'primary.default',
  boxShadow: 'none',
  _hover: {
    borderColor: 'primary.active',
    boxShadow: 'none',
  },
  _focus: {
    borderColor: 'primary.active',
    boxShadow: 'none',
  },
  _invalid: {
    borderColor: 'error.default',
    boxShadow: 'none',
  },
  _placeholder: {
    color: 'primary.light',
  },
  _disabled: {
    opacity: 1,
    color: 'rgba(221, 221, 221, 0.4)',
  },
};

const sizesControls = {
  md: {
    field: {
      height: '50px',
      fontSize: '16px',
      lineHeight: '50px',
    },
  },
};

export const customTheme = extendTheme(
  {
    // semanticTokens: {
    //   colors: {},
    // },
    fonts: {
      heading: 'Poppins SemiBold, sans-serif',
      body: 'Poppins Regular, sans-serif',
    },
    textStyles: {
      headingSemiBold: {
        fontFamily: 'Poppins SemiBold, sans-serif',
      },
      bodyRegular: {
        fontFamily: 'Poppins Regular, sans-serif',
        fontSize: '16px',
        lineHeight: '24px',
      },
      bodyMedium: {
        fontFamily: 'Poppins Medium, sans-serif',
      },
      bodyMediumSmall: {
        fontFamily: 'Poppins Medium, sans-serif',
        fontSize: '14px',
        lineHeight: '18px',
      },
      bodySemiBold: {
        fontFamily: 'Poppins SemiBold, sans-serif',
      },
      formLabels: {
        fontFamily: 'Poppins Medium, sans-serif',
        fontSize: '18px',
        lineHeight: '23px',
      },
      heroHeadline: {
        fontFamily: 'Poppins Medium, sans-serif',
        fontSize: { base: '32px', lg: '45px' },
        lineHeight: '150%',
      },
      heroText: {
        fontSize: { base: '20px', lg: '22px' },
        lineHeight: '150%',
      },
      sectionEyebrow: {
        fontFamily: 'Poppins SemiBold, sans-serif',
        fontSize: '16px',
        lineHeight: '150%',
        textTransform: 'uppercase',
        color: '#838383',
      },
      sectionHeadline: {
        fontFamily: 'Poppins Medium, sans-serif',
        fontSize: { base: '32px', lg: '40px' },
        lineHeight: '150%',
      },
      sectionHeadlineSm: {
        fontFamily: 'Poppins Medium, sans-serif',
        fontSize: { base: '26px', lg: '30px' },
        lineHeight: '150%',
      },
      sectionText: {
        fontSize: '18px',
        lineHeight: '150%',
      },
    },
    // colors
    colors: {
      primary: {
        default: '#2D2D2D',
        dark: '#62606E',
        light: '#CCCCCC',
        white: '#ffff',
        active: 'black',
      },
      secondary: {
        lighter: '#F7F6FC',
        light: '#E3E0FB',
        alt: '#D8CDEE',
        mid: '#C8C1FF',
        dark: '#5649B7',
      },
      accent: {
        default: '#E81E42',
        light: '#FAB9BD',
        active: '#C81A39',
      },
      error: {
        default: '#CA3B1C',
      },
      elipse: '#FFE0DC',
    },

    // global
    styles: {
      global: {
        'html, body': {
          color: 'primary.default',
          scrollbarColor: '#a5a5a5 transparent' /* thumb and track color */,
          scrollbarWidth: 'thin',
        },

        'input:-webkit-autofill': {
          boxShadow: 'inset 0 0 0px 1000px #FFFFFF !important',
          WebkitBoxShadow: 'inset 0 0 0px 1000px #FFFFFF !important',
          borderColor: 'primary.light',
          fontFamily: 'Poppins Regular, sans-serif !important',
        },
        'input:autofill': {
          boxShadow: 'inset 0 0 0px 1000px #FFFFFF !important',
          WebkitBoxShadow: 'inset 0 0 0px 1000px #FFFFFF !important',
          borderColor: 'primary.light',
          fontFamily: 'Poppins Regular, sans-serif !important',
        },
        'input:-webkit-autofill::first-line': {
          fontFamily: 'Poppins Regular, sans-serif !important',
        },

        'input.autofillPlain:-webkit-autofill': {
          boxShadow: 'inset 0 0 0px 1000px #F7F6FC !important',
          WebkitBoxShadow: 'inset 0 0 0px 1000px #F7F6FC !important',
          borderColor: 'white !important',
        },
        'input.autofillPlain:autofill': {
          boxShadow: 'inset 0 0 0px 1000px #F7F6FC !important',
          WebkitBoxShadow: 'inset 0 0 0px 1000px #F7F6FC !important',
          borderColor: 'white !important',
        },

        '::-webkit-scrollbar': {
          width: '8px',
          background: 'lightgray',
        },
        '::-webkit-scrollbar-track': {
          // '-webkit-box-shadow': 'inset 0 0 6px rgb(195, 195, 195)',
          WebkitBoxShadow: 'inset 0 0 6px rgb(195, 195, 195)',
          borderRadius: '15px',
        },
        '::-webkit-scrollbar-thumb': {
          borderRadius: '15px',
          // '-webkit-box-shadow': 'inset 0 0 6px rgba(219, 219, 219, 0.5)',
          WebkitBoxShadow: 'inset 0 0 6px rgba(219, 219, 219, 0.5)',
          background: '#a5a5a5',
        },

        '*::selection': {
          background: 'secondary.light',
        },

        '.chakra-input__group .chakra-input': {
          paddingLeft: '50px',
        },
        '.chakra-input__group .chakra-input__left-element svg': {
          transition: '0.3s',
        },
        '[data-focus] + .chakra-input__group .chakra-input__left-element svg': {
          color: 'black !important',
        },

        '.progress-slow [data-indeterminate]': { animationDuration: '1.25s' },

        '.sideNav': {
          '.inactive': {
            transition: '0.3s',
            color: 'primary.dark',
            _hover: {
              color: 'primary.default',
            },
          },
          '.active': {
            color: 'primary.default',
          },
        },
        '.footerNav': {
          '.inactive': {
            transition: '0.3s',
            color: 'primary.default',
            _hover: {
              color: 'primary.dark',
            },
          },
          '.active': {
            color: 'primary.dark',
          },
        },

        '.categoryCircle': {
          width: '100px',
          height: '100px',
          cursor: 'pointer',
          transition: '0.3s',
          bg: 'secondary.lighter',
          _hover: {
            filter: 'brightness(0.98)',
          },
        },
        '.categoryNotSelected': {
          '*': { fill: '#AEAEAE' },
        },
        '.categoryForSlider': {
          '*': { fill: '#2F2F2F' },
        },
      },
    },

    // components
    components: {
      Container: {
        baseStyle: {
          // "maxW" should be used, instead of "maxWidth", so we can override it with props inside the code
          maxW: '100%',
        },
        variants: {
          bodyContent: {
            flex: '1 0 auto',
            padding: 0,
            display: 'flex',
          },
        },
      },

      Button: {
        sizes: {
          md: {
            fontSize: '18px',
          },
        },
        baseStyle: {
          fontFamily: 'Poppins Bold, sans-serif',
          _focus: {
            boxShadow: '0',
          },
          _disabled: {
            cursor: 'default',
          },
        },
        variants: {
          solid: {
            fontWeight: '700',
            bg: 'accent.default',
            color: 'white',
            borderRadius: '50px',
            p: '30px',
            _active: {
              bg: 'accent.default',
            },
            _hover: {
              bg: 'accent.active',
              _disabled: {
                bg: 'accent.light',
              },
            },
            _disabled: {
              bg: 'accent.light',
            },
          },
          solidLight: {
            ring: 0,
            color: 'accent.default',
            borderRadius: '50px',
            backgroundColor: 'elipse',
            fontFamily: 'Poppins SemiBold, sans-serif',
            fontWeight: '600',
            p: '30px',
            _focus: {
              ring: 0,
            },
            _hover: {
              color: 'accent.active',
              backgroundColor: 'elipse',
              _disabled: {
                color: 'accent.default',
                backgroundColor: 'elipse',
              },
            },
            _active: {
              backgroundColor: 'elipse',
            },
            _disabled: {
              // opacity: 0.6,
            },
          },
          solidLightGrey: {
            ring: 0,
            borderRadius: '50px',
            backgroundColor: 'secondary.lighter',
            fontFamily: 'Poppins SemiBold, sans-serif',
            fontWeight: '500',
            p: '30px',
            _focus: {
              ring: 0,
            },
            _hover: {
              backgroundColor: 'secondary.light',
              _disabled: {
                color: 'secondary.light',
                backgroundColor: 'secondary.lighter',
              },
            },
            _active: {
              backgroundColor: 'secondary.mid',
            },
            _disabled: {
              opacity: 1,
              cursor: 'not-allowed',
            },
          },
          solidError: {
            fontWeight: '700',
            bg: 'error.default',
            color: 'white',
            borderRadius: '50px',
            p: '30px',
            _active: {
              bg: 'error.default',
            },
            _hover: {
              bg: 'accent.active',
              _disabled: {
                bg: 'accent.light',
              },
            },
            _disabled: {
              bg: 'accent.light',
            },
          },
          outline: {
            color: 'accent.default',
            borderRadius: '50px',
            borderWidth: '2px',
            borderColor: 'accent.default',
            fontWeight: '700',
            p: { base: '28px 30px', lg: '28px 62px' },
            _active: {
              backgroundColor: 'transparent',
            },
            _hover: {
              backgroundColor: 'transparent',
              color: 'accent.active',
              borderColor: 'accent.active',
            },
          },
          outlineError: {
            color: 'error.default',
            borderRadius: '50px',
            borderWidth: '2px',
            borderColor: 'error.default',
            fontWeight: '700',
            p: { base: '28px 30px', lg: '28px 62px' },
            _active: {
              backgroundColor: 'transparent',
            },
            _hover: {
              backgroundColor: 'transparent',
              color: 'accent.active',
              borderColor: 'accent.active',
            },
          },

          iconicDark: {
            ring: 0,
            color: 'secondary.dark',
            borderRadius: '50px',
            backgroundColor: 'transparent',
            p: '30px',
            _focus: {
              ring: 0,
            },
            _hover: {
              color: 'secondary.dark',
              backgroundColor: 'secondary.light',
              _disabled: {
                color: 'secondary.light',
                backgroundColor: 'transparent',
              },
            },
            _active: {
              backgroundColor: 'transparent',
            },
            _disabled: {
              color: 'secondary.light',
              opacity: 1,
            },
          },
          iconicBlack: {
            ring: 0,
            color: 'primary.default',
            borderRadius: '50px',
            backgroundColor: 'transparent',
            p: '30px',
            _focus: {
              ring: 0,
            },
            _hover: {
              color: 'primary.dark',
              backgroundColor: 'transparent',
              _disabled: {
                color: 'primary.light',
                backgroundColor: 'transparent',
              },
            },
            _active: {
              backgroundColor: 'transparent',
            },
            _disabled: {
              color: 'primary.light',
              opacity: 1,
            },
          },

          category: {
            padding: '0px',
            margin: '0',
            width: '20px',
          },
          eyeButton: {
            bg: 'transparent',
            boxShadow: 0,
            borderRadius: '100%',
            _focus: {
              boxShadow: 0,
            },
            _active: {
              bg: 'transparent',
            },
          },
        },
      },

      Link: {
        baseStyle: {
          // cursor: 'crosshair',
          _hover: {
            // textDecoration: 'none',
            // color: 'secondaryDark'
            color: 'accent.active',
          },
          _focus: {
            boxShadow: 'none',
          },
        },
        variants: {
          secondaryDark: {
            color: 'secondary.dark',
            _hover: {
              color: 'secondary.dark',
            },
          },
          primaryDefault: {
            // color: 'primary.default',
            fontFamily: 'Poppins SemiBold, sans-serif',
            _hover: {
              color: 'primary.default',
            },
          },
        },
      },

      Input: {
        variants: {
          outline: {
            field: {
              ...stylesControls,
            },
          },
        },
        sizes: {
          ...sizesControls,
        },
      },

      Textarea: {
        variants: {
          outline: {
            ...stylesControls,
            pt: '15px !important',
            pb: '7px !important',
          },
        },
      },

      Select: {
        baseStyle: {
          field: {
            '> option, > optgroup': {
              bg: 'secondary.lighter',
            },
          },
          icon: { fontSize: '36px', color: 'primary.dark', right: '20px' },
        },
        variants: {
          outline: {
            field: {
              ...stylesControls,
            },
          },
        },
        sizes: {
          ...sizesControls,
        },
      },

      FormLabel: {
        baseStyle: {
          fontSize: '18px',
          color: 'primary.default',
          _invalid: {
            color: 'error.default',
          },
        },
      },

      FormError: {
        baseStyle: {
          text: {
            color: 'error.default',
          },
        },
      },

      Checkbox: {
        baseStyle: {
          control: {
            borderRadius: '4px',
            borderColor: 'primary.default',
            padding: '8px',
            _checked: {
              bg: 'transparent',
              borderColor: 'primary.default',
              _hover: {
                bg: 'transparent',
                borderColor: 'primary.default',
              },
            },
            _hover: {
              bg: 'transparent',
            },
            _focus: {
              boxShadow: '0',
            },
          },
          icon: {
            color: 'accent.default',
          },
          label: {
            fontFamily: 'Poppins Regular, sans-serif',
            ml: '16px',
          },
        },
        sizes: {
          md: {
            // label: { fontSize: '16px' },
          },
        },
      },

      Progress: {
        baseStyle: {
          filledTrack: { bg: 'secondary.light' },
          track: { bg: 'transparent' },
        },
        variants: {
          dark: {
            filledTrack: {
              bg: 'secondary.dark',
            },
          },
        },
        sizes: {
          sm: {
            track: {
              h: '6px',
            },
          },
          md: {
            track: {
              h: '10px',
            },
          },
        },
      },

      Tag: {
        baseStyle: {
          label: { lineHeight: '130%', display: 'flex', alignItems: 'center' },
          container: { transition: '0.3s', cursor: 'pointer' },
        },
        variants: {
          outline: {
            container: {
              boxShadow: 'inset 0 0 0px 1px #5649B7',
              color: 'secondary.dark',
              _focus: {
                boxShadow: 'inset 0 0 0px 1px #5649B7',
              },
              _hover: {
                boxShadow: 'inset 0 0 0px 1px #E3E0FB',
                backgroundColor: 'secondary.light',
                color: 'secondary.dark',
              },
            },
          },
          solidDark: {
            container: {
              backgroundColor: 'secondary.light',
              color: 'secondary.dark',
              _focus: {
                boxShadow: 'none',
                backgroundColor: 'secondary.light',
              },
            },
            label: { fontFamily: 'Poppins SemiBold, sans-serif' },
          },
          solidLight: {
            container: {
              backgroundColor: 'secondary.lighter',
              color: 'primary.default',
              _focus: {
                boxShadow: 'none',
                backgroundColor: 'secondary.lighter',
              },
            },
            label: { fontFamily: 'Poppins Regular, sans-serif' },
          },
        },
        sizes: {
          md: {
            container: {
              fontFamily: 'Poppins Medium, sans-serif',
              fontSize: '14px',
              borderRadius: '30px',
              p: '6px 15px',
            },
          },
        },
      },

      Divider: {
        baseStyle: {
          borderColor: 'primary.light',
          opacity: 1,
        },
      },

      Menu: {
        baseStyle: {
          list: {
            p: '20px 0',
            borderRadius: '10px',
            boxShadow: '0px 4px 19px rgba(0, 0, 0, 0.13)',
            fontFamily: 'Poppins Medium, sans-serif',
            fontSize: '16px',
            lineHeight: '150%',
            minW: '240px',
            border: 'none',
          },
          item: {
            px: '30px',
            _focus: {
              bg: 'transparent',
            },
            _active: {
              bg: 'secondary.lighter',
            },
            _hover: {
              bg: 'transparent',
              textDecoration: 'underline',
            },
          },
          divider: {
            opacity: 1,
            borderColor: 'primary.light',
            mx: '30px',
            mt: '20px',
            mb: '0px',
          },
        },
        variants: {
          small: {
            list: {
              p: '10px 0',
              boxShadow: '0px 4px 25px rgba(36, 6, 48, 0.2)',
              fontSize: '14px',
              minW: 'auto',
            },
            item: {
              px: '20px',
            },
          },
        },
      },

      CloseButton: {
        baseStyle: {
          _focus: {
            boxShadow: 'none',
          },
        },
      },

      Modal: {
        baseStyle: {
          overlay: { bg: 'rgba(36, 6, 48, 0.8)' },
          closeButton: {
            fontSize: '20px',
            color: 'primary.dark',
            top: { base: '16px', md: '30px' },
            right: { base: '16px', md: '30px' },
          },
          dialog: { mx: '8px', maxW: '800px', borderRadius: '20px' },
          header: {
            padding: { base: '48px 24px 0 24px', md: '60px 24px 0 24px' },
            textAlign: 'center',
          },
          body: {
            padding: '24px',
          },
          footer: {
            padding: { base: '0 24px 48px', md: '0 24px 60px' },
            justifyContent: 'center',
          },
        },
        sizes: {
          xl: {
            dialog: {
              maxW: '800px',
            },
          },
          lg: {
            dialog: {
              maxW: '650px',
            },
          },
        },
      },

      Drawer: {
        baseStyle: {
          overlay: { bg: 'rgba(36, 6, 48, 0.8)' },
          closeButton: {
            fontSize: '20px',
            color: 'primary.dark',
            top: { base: '16px', md: '30px' },
            right: { base: '16px', md: '30px' },
          },
        },
      },

      NumberInput: {
        baseStyle: {
          // root: {
          //   color: 'error',
          // },
          // field: {
          //   color: 'success',
          // },
          stepper: {
            _first: {
              border: 'none',
              mr: '10px',
              _active: {
                bg: 'primary.light',
              },
            },
            _last: {
              borderLeft: 'none',
              borderTop: 'solid 1px rgba(59, 72, 81, 0.4)',
              mr: '10px',
              _active: {
                bg: 'primary.light',
              },
            },
          },
        },
        variants: {
          outline: {
            field: {
              ...stylesControls,
              pl: '16px',
              _selection: {
                bg: 'primary.light',
              },
            },
          },
        },
        sizes: {
          ...sizesControls,
        },
      },

      Tooltip: {
        baseStyle: {
          borderRadius: '10px',
          backgroundColor: 'primary.default',
          color: '#F4F5F7',
          fontFamily: 'Poppins Medium, sans-serif',
          fontSize: '14px',
          lineHeight: '150%',
          boxShadow: 'none',
          maxW: { base: '320px', sm: '392px' },
          px: 4,
          py: 3,
        },
      },

      Radio: {
        baseStyle: {
          control: {
            alignSelf: 'flex-start',
            mt: '4px',
            borderColor: 'primary.dark',
            ring: 0,
            _focus: {
              ring: 0,
            },
            _checked: {
              backgroundColor: 'white',
              borderColor: 'accent.default',
              _before: {
                w: '10px',
                h: '10px',
                bg: 'accent.default',
              },
              _hover: {
                backgroundColor: 'white',
                borderColor: 'accent.default',
              },
            },
          },
          label: {
            fontFamily: 'Poppins Medium, sans-serif',
            fontSize: '18px',
            lineHeight: '27px',
            ml: '10px',
            '.label-helper': {
              fontFamily: 'Poppins Regular, sans-serif',
              fontSize: '14px',
              color: 'primary.dark',
            },
          },
        },
      },

      Accordion: {
        baseStyle: {
          container: {
            borderColor: 'primary.light',
            _first: {
              borderTopWidth: '0px',
            },
          },
          button: {
            fontSize: { base: '18px', lg: '22px' },
            lineHeight: '150%',
            py: { base: '20px', lg: '25px' },
            px: 0,
            _focus: {
              boxShadow: 'none',
            },
            _focusVisible: {
              boxShadow: 'none',
            },
            _hover: {
              bg: 'transparent',
            },
          },
          panel: {
            fontSize: { base: '16px', lg: '18px' },
            lineHeight: '150%',
            color: 'primary.dark',
            p: { base: '0px 30px 25px 0px', lg: '0px 100px 25px 0px' },
          },
        },
      },

      List: {
        baseStyle: {
          item: {
            fontSize: '18px',
            lineHeight: '150%',
            color: 'primary.default',
            pos: 'relative',
            pl: '27px',
            listStyle: 'none',
            _before: {
              content: '""',
              w: '8px',
              h: '8px',
              borderRadius: '100%',
              bg: 'secondary.light',
              pos: 'absolute',
              top: '10px',
              left: 0,
            },
          },
        },
      },
    },
  },
  withDefaultVariant({
    variant: 'outline',
    components: ['Input', 'Select', 'Textarea', 'NumberInput', 'Tag'], // colide with components array from other withDef...
  }),
  withDefaultVariant({
    variant: 'solid',
    components: ['Button'], // colide with components array from other withDef...
  }),
  withDefaultSize({
    size: 'lg',
    components: ['Radio'], // colide with components array from other withDef...
  })
);
