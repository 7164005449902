import React, { createContext, useState } from 'react';
export const INITIAL_FEATURED_VIDEOS_PERP_AGE = 50;

export const initialFeaturedVideosState = {
  items: [],
  total: 0,
  status: 'idle',
  error: null,
  perPage: INITIAL_FEATURED_VIDEOS_PERP_AGE,
};

const FeaturedVideosContext = createContext({
  state: initialFeaturedVideosState,
  updateState: () => {},
});
const { Provider } = FeaturedVideosContext;

const FeaturedVideosProvider = ({ children }) => {
  const [state, setState] = useState(initialFeaturedVideosState);

  function updateStateHandler({ items, total, status, error }) {
    setState({
      items,
      total,
      status,
      error,
    });
  }

  return (
    <Provider
      value={{
        state,
        updateState: (stateInfo) => updateStateHandler(stateInfo),
      }}
    >
      {children}
    </Provider>
  );
};

export { FeaturedVideosContext, FeaturedVideosProvider };
