import {
  FormControl,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
} from '@chakra-ui/react';
import { IconDeleteThin } from '../../theme/icons/IconDeleteThin';
import { IconSearch } from '../../theme/icons/IconSearch';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SearchByTermInHeader = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const showSearchInHeader = !pathname.includes('/search');
  const [termInHeader, setTermInHeader] = useState('');

  const handleChange = (e) => {
    setTermInHeader(e.target.value);
  };

  const handleKeyDown = (event) => {
    // console.log('User pressed: ', event.key);
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleClearTermInHeader = () => {
    setTermInHeader('');
  };

  const handleSubmit = async () => {
    // console.log('Submit search term from header: ', termInHeader);
    const termInHeaderTrimmed = termInHeader.trim();
    if (termInHeaderTrimmed === '') {
      navigate(`/search`);
    } else {
      navigate(`/search?keyword=${termInHeaderTrimmed}`, { replace: true });
    }
    setTermInHeader('');
  };

  return (
    <>
      {showSearchInHeader && (
        <FormControl maxW={{ base: '220px', '2xl': '260px' }}>
          <InputGroup>
            <InputLeftElement
              // pointerEvents="none"
              onClick={handleSubmit}
              cursor="pointer"
              color="primary.default"
              fontSize="18px"
              children={<IconSearch />}
              _hover={{ color: 'secondary.dark' }}
            />
            <Input
              id="searchByTerm"
              type="text"
              name="searchByTerm"
              placeholder="Search Storyvault..."
              value={termInHeader}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              _placeholder={{ color: 'primary.dark' }}
              _groupHover={{ bg: 'secondary.lighter', borderColor: 'transparent' }}
              _focus={{ bg: 'secondary.lighter', borderColor: 'transparent' }}
              pl="42px !important"
              borderColor="transparent"
              className="autofillPlain"
              backgroundColor={termInHeader && 'secondary.lighter'}
            />
            {termInHeader && (
              <InputRightElement
                pointerEvents="auto"
                color="primary.light"
                fontSize="15px"
                children={<IconDeleteThin />}
                transition="0.3s"
                cursor="pointer"
                _hover={{ color: 'accent.default' }}
                onClick={handleClearTermInHeader}
              />
            )}
          </InputGroup>
        </FormControl>
      )}
    </>
  );
};

export default SearchByTermInHeader;
