import { Container, Box } from '@chakra-ui/react';
import HeroPublicHome from '../components/heroes/HeroPublicHome';
// import SectionTemplate from '../components/sections/SectionTemplate';
import SectionCollage7 from '../components/sections/SectionCollage7';
import SectionCollage8 from '../components/sections/SectionCollage8';
import SectionCollageReversed6 from '../components/sections/SectionCollageReversed6';
import SectionConclusion3 from '../components/sections/SectionConclusion3';
import SectionEditorsPickWide from '../components/sections/SectionEditorsPickWide';
import SectionConclusion from '../components/sections/SectionConclusion';

const PublicHomePage = () => {
  return (
    <Container mt={{ base: '80px', md: '100px' }} p={0}>
      <HeroPublicHome />

      <Box bg="secondary.lighter" py={{ base: '60px', '2xl': '52px' }}>
        <SectionCollage7 />
        <SectionCollageReversed6 />
        <SectionCollage8 />
        <SectionConclusion3 />
      </Box>

      <SectionEditorsPickWide />
      <SectionConclusion />
    </Container>
  );
};

export default PublicHomePage;
