import { Icon } from '@chakra-ui/react';

export const PasswordIcon = (props) => (
  <Icon viewBox="0 0 23 22" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.06756 11.2674C3.24306 11.2674 2.74349 11.8484 2.74349 12.3674V19.4257C2.74349 19.9448 3.24306 20.5257 4.06756 20.5257H19.7527C20.5772 20.5257 21.0768 19.9448 21.0768 19.4257V12.3674C21.0768 11.8484 20.5772 11.2674 19.7527 11.2674H4.06756ZM0.910156 12.3674C0.910156 10.6589 2.41701 9.43408 4.06756 9.43408H19.7527C21.4033 9.43408 22.9102 10.6589 22.9102 12.3674V19.4257C22.9102 21.1342 21.4033 22.3591 19.7527 22.3591H4.06756C2.41701 22.3591 0.910156 21.1342 0.910156 19.4257V12.3674Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9091 2.19271C10.6372 2.19271 9.43428 2.64834 8.56125 3.43406C7.69141 4.21692 7.22396 5.25672 7.22396 6.31771V10.351C7.22396 10.8573 6.81355 11.2677 6.30729 11.2677C5.80103 11.2677 5.39062 10.8573 5.39062 10.351V6.31771C5.39062 4.70443 6.10356 3.17949 7.33482 2.07136C8.56289 0.966093 10.2097 0.359375 11.9091 0.359375C13.6086 0.359375 15.2554 0.966093 16.4835 2.07136C17.7147 3.17949 18.4277 4.70443 18.4277 6.31771V10.351C18.4277 10.8573 18.0173 11.2677 17.511 11.2677C17.0047 11.2677 16.5943 10.8573 16.5943 10.351V6.31771C16.5943 5.25672 16.1269 4.21692 15.257 3.43406C14.384 2.64834 13.1811 2.19271 11.9091 2.19271Z"
      fill="currentColor"
    />
  </Icon>
);
