import { Heading, Text, FormLabel } from '@chakra-ui/react';
import SettingsLayout from '../../components/settings/SettingsLayout';
import AvatarUpload from '../../components/user/AvatarUpload';
import EditProfileForm from '../../components/settings/EditProfileForm';
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';

const SettingsProfilePage = () => {
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();

  return (
    <SettingsLayout>
      <Heading size="lg" color="primary.default">
        Edit Profile
      </Heading>
      <Text textStyle="bodyMedium" mt="15px" mb={10}>
        Here you can edit public information about yourself.
        <br />
        People visiting your profile can see your biography.
      </Text>

      <FormLabel textStyle="formLabels" mb="10px">
        Profile photo
      </FormLabel>
      <AvatarUpload />

      <EditProfileForm user={user} />
    </SettingsLayout>
  );
};

export default SettingsProfilePage;
