import { useState, useRef, useContext } from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  Button,
  Box,
  Text,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  HStack,
  useOutsideClick,
  useToast,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { EyeOnIcon } from '../../assets/icons/EyeOnIcon';
import { EyeOffIcon } from '../../assets/icons/EyeOffIcon';
import { PasswordIcon } from '../../assets/icons/PasswordIcon';
import { PasswordCheckboxEmptyIcon } from '../../assets/icons/PasswordCheckboxEmptyIcon';
import { PasswordCheckboxCheckedIcon } from '../../assets/icons/PasswordCheckboxCheckedIcon';
import { passwordLength } from '../../helper/validations';
import '../../helper/passwordTooltip.css';
import CustomToast from '../UI/CustomToast';
import { HttpContext } from '../../context/HttpContext';
import { AuthContext } from '../../context/AuthContext';

const ChangePasswordForm = () => {
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const { getAuthUser, setAuthTokens } = useContext(AuthContext);
  const { slug } = getAuthUser();
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const [showPasswordTooltip, setShowPassordTooltip] = useState(false);
  const onFocusHandler = () => {
    setShowPassordTooltip(true);
  };
  const onBlurHandler = () => {
    setShowPassordTooltip(false);
  };
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: (e) => {
      setShowPassordTooltip(false);
    },
  });

  const schema = yup.object().shape({
    currentPassword: yup.string().required('Password is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Please enter at least 8 characters'),
  });

  const formOptions = {
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  };

  const { register, handleSubmit, formState, watch, setError, reset } =
    useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const watchPassword = watch('password');

  const submitForm = async (values) => {
    try {
      // console.log('Submitted values:');
      // console.log(values);

      const { data: responseData } = await authAxios({
        method: 'PUT',
        url: `/users/${slug}/change-password`,
        data: {
          oldPassword: values.currentPassword,
          newPassword: values.password,
        },
        headers: {
          Accept: 'application/ld+json',
        },
      });

      const { token, refresh_token: refreshToken } = responseData;
      setAuthTokens({ token, refreshToken });
      toast({
        position: 'top',
        render: (props) => (
          <CustomToast
            status="success"
            description="Your password has been changed successfully!"
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });

      reset();
    } catch (onErr) {
      console.log(onErr);
      const violations = onErr?.response?.data?.violations ?? [];
      if (violations?.length > 0) {
        const { message, propertyPath } = violations[0];
        if (propertyPath === 'oldPassword') {
          setError('currentPassword', {
            type: 'server',
            message,
          });
          toast({
            position: 'top',
            render: (props) => (
              <CustomToast
                status="error"
                description={`Current password you entered is incorrect.\nPlease try again.`}
                onClose={props.onClose}
                id={props.id}
              />
            ),
          });
        }
      }
      // console.log(onErr?.response?.data?.violations);
    }
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} noValidate>
      <VStack spacing="40px">
        <FormControl isInvalid={errors.currentPassword}>
          <FormLabel htmlFor="currentPassword" textStyle="formLabels">
            Current Password
          </FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color={errors.currentPassword ? 'error.default' : 'primary.light'}
              fontSize="22px"
              children={<PasswordIcon />}
            />
            <Input
              id="currentPassword"
              type={showPassword ? 'text' : 'password'}
              placeholder="* * * * * * * *"
              {...register('currentPassword')}
            />
            <InputRightElement
              children={
                <Button
                  h="40px"
                  size="lg"
                  mr={3}
                  variant="eyeButton"
                  alignItems="center"
                  onClick={handlePasswordVisibility}
                  fontSize="22px"
                  children={showPassword ? <EyeOnIcon /> : <EyeOffIcon />}
                />
              }
            />
          </InputGroup>
          <FormErrorMessage>{errors.currentPassword?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.password}>
          <FormLabel htmlFor="password" textStyle="formLabels">
            New Password
          </FormLabel>
          <InputGroup ref={ref}>
            <InputLeftElement
              pointerEvents="none"
              color={errors.password ? 'error.default' : 'primary.light'}
              fontSize="22px"
              children={<PasswordIcon />}
            />
            <Input
              id="password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="* * * * * * * *"
              {...register('password')}
              onFocus={onFocusHandler}
              onBlur={onBlurHandler}
            />
            <InputRightElement
              children={
                <Button
                  h="40px"
                  size="lg"
                  mr={3}
                  variant="eyeButton"
                  alignItems="center"
                  onClick={handlePasswordVisibility}
                  fontSize="22px"
                  children={showPassword ? <EyeOnIcon /> : <EyeOffIcon />}
                />
              }
            />
            {showPasswordTooltip && (
              <Box
                position="absolute"
                bottom="calc(100% - 4px)"
                right="0"
                zIndex="10"
                textStyle="bodyMedium"
                fontSize="14px"
                w="244px"
                p="16px"
                backgroundColor="primary.default"
                borderRadius="10px"
                color="white"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <div className="passwordBoxTriangle" />
                <VStack alignItems="flex-start" spacing={4}>
                  <Text>Your password needs to have:</Text>
                  <HStack spacing="10px">
                    {passwordLength(watchPassword, 8, 100) ? (
                      <PasswordCheckboxCheckedIcon />
                    ) : (
                      <PasswordCheckboxEmptyIcon />
                    )}
                    <Text
                      color={
                        passwordLength(watchPassword, 8, 100) ? 'accent.light' : 'white'
                      }
                    >
                      8 characters minimum
                    </Text>
                  </HStack>
                </VStack>
                <Box className="passwordTriangle" />
              </Box>
            )}
          </InputGroup>
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>

        <Box w="full" pt={5}>
          <Button
            isFullWidth
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </Box>
      </VStack>
    </form>
  );
};

export default ChangePasswordForm;
