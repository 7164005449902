import { Icon } from '@chakra-ui/react';

export const CategoryFamousIcon = (props) => (
  <Icon viewBox="0 0 55 51" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.2976 0.682647L1.66014 8.61037C0.37409 9.04214 -0.308663 10.4046 0.135716 11.6542L5.57276 26.9592C6.01713 28.2088 7.41942 28.8722 8.70547 28.4404L16.0127 25.9899L22.1575 29.078L25.0328 22.9632L32.34 20.5127C33.626 20.0809 34.3088 18.7184 33.8644 17.4688L28.4274 2.16382C27.983 0.914261 26.5807 0.250875 25.2947 0.682647H25.2976ZM19.397 8.41088C19.4368 8.41088 19.478 8.41232 19.5177 8.41375C21.794 8.49524 23.4156 10.8357 22.7755 13.0403C22.0648 15.4851 19.091 20.4461 19.091 20.4461C19.091 20.4461 13.601 18.3245 11.4894 16.8247C9.58683 15.4736 9.37641 12.6585 11.1171 11.2303C11.148 11.2045 11.1789 11.1802 11.2113 11.1559C12.6651 10.0465 14.8782 10.6584 16.1157 12.0695C16.1937 10.2166 17.5474 8.41088 19.397 8.41088Z"
      fill="#DB4049"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.7876 38.1353L44.7879 38.1333C44.7883 38.131 44.7886 38.1285 44.7889 38.1261C44.7885 38.1291 44.7881 38.1322 44.7876 38.1353Z"
      fill="#0096FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.2236 29.6442L30.5044 24.493C29.2683 24.2127 28.0323 24.9591 27.7439 26.16L24.2109 40.8703C23.9225 42.0712 24.6906 43.2721 25.9267 43.5524L32.9514 45.1451L36.2239 50.5508L41.6212 47.1109L48.6459 48.7036C49.882 48.9838 51.118 48.2375 51.4064 47.0366L54.9394 32.3263C55.2278 31.1254 54.4596 29.9244 53.2236 29.6442ZM44.6432 36.9538C44.8463 37.1825 44.939 37.4985 44.864 37.8144L44.8654 37.8159L44.8066 38.0603C44.8021 38.0775 44.7963 38.0932 44.7919 38.109C44.7904 38.1147 44.7897 38.1204 44.7889 38.1261L44.7879 38.1333L44.7876 38.1353C44.7872 38.1379 44.7867 38.1406 44.786 38.1433C44.7816 38.159 44.7742 38.1747 44.7639 38.189C44.6741 38.412 44.5079 38.5879 44.3019 38.6966C44.3902 38.8853 44.4181 39.1026 44.3666 39.3199L44.3078 39.5644C44.2224 39.9132 43.9531 40.1749 43.622 40.275C43.6706 40.4322 43.6794 40.6024 43.6382 40.7725L43.5794 41.017C43.4543 41.5417 42.9098 41.8705 42.3698 41.7476L36.5017 40.4165C36.1677 40.3407 35.9116 40.1091 35.7939 39.8189L32.8804 39.1584L34.2562 33.4324L36.9181 34.0357C38.3282 33.208 39.3002 31.7497 39.9632 30.7549C39.9909 30.7133 40.0181 30.6725 40.0447 30.6327C40.4443 30.0346 40.7246 29.6366 40.9175 29.708C41.4149 29.8953 41.9328 31.4866 40.9911 33.3609C40.9072 33.531 40.6615 33.9457 40.4967 34.203L44.2357 35.0508C44.7757 35.1723 45.1141 35.7013 44.9875 36.226L44.9287 36.4705C44.8831 36.6621 44.7801 36.8265 44.6432 36.9538Z"
      fill="#0096FF"
    />
  </Icon>
);
